const initialState = {
  data: null,
  isVerifyEmail: { email: null, verified: false },
  isvarifyDomain: false,
};
const customEmailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_CUSTOM_EMAIL":
      return {
        ...state,
        data: payload.data,
      };
    case "GET_CUSTOM_EMAIL":
      return {
        ...state,
        data: payload.data,
      };
    case "IS_VERIFY_EMAIL_ADDRESS":
      return {
        ...state,
        data: payload.data,
      };
    case "IS_VERIFY_DOMAIN":
      return {
        ...state,
        isvarifyDomain: payload.data,
      };
    default:
      return state;
  }
};

export default customEmailReducer;
