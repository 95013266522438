import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";

import Checkmark from "../../../../../assets/Images/Checkmark20.svg";
import {
  DeleteServiceSubTaskAction,
  UpdateServiceSubTaskActions,
  addNewSubTaskInService,
} from "../../../../../store/actions/serviceAction";
import {
  DeleteSubTaskAction,
  UpdateSubTaskActions,
  addNewSubTask,
} from "../../../../../store/actions/tasksAction";
// TODO: add to item and save NEW KEY - SUBTASKS

const Subtasks = ({
  isNew,
  item,
  user,
  types,
  clientDetail,
  user_type,
  access_level,
}) => {
  const dispatch = useDispatch();
  const [showContent, setShowTextArea] = useState(!!item?.description);
  const [subtasks, setSubtasks] = useState(item?.sub_tasks);
  const [isEdit, setIsEdit] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isNewSubtask, setIsNewSubtask] = useState(false);
  const currentItemRef = useRef(item);
  const [newSubtask, setNewSubtask] = useState({
    _id: Date.now(),
    name: "",
    is_completed: false,
    is_new: true,
  });

  useEffect(() => {
    if (item?.sub_tasks) {
      setSubtasks(item?.sub_tasks);
    }
    currentItemRef.current = item;
  }, [item]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEdit) {
        const editArea = document.getElementById("edit-subtasks-area");
        if (!editArea.contains(event.target)) {
          setIsEdit(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isEdit]);

  const handleCheckboxChange = (event, subtaskId) => {
    const { checked } = event.target;
    if (types === "task") {
      if (user_type === "client_user") {
        dispatch(
          UpdateSubTaskActions({
            id: item._id,
            sub_task_id: subtaskId,
            is_completed: checked,
            user_type: user_type,
          })
        );
      } else {
        dispatch(
          UpdateSubTaskActions({
            id: item._id,
            sub_task_id: subtaskId,
            is_completed: checked,
          })
        );
      }
    }
    if (types === "service") {
      dispatch(
        UpdateServiceSubTaskActions({
          id: item._id,
          sub_task_id: subtaskId,
          is_completed: checked,
        })
      );
    }
    setSubtasks((prevSubtasks) =>
      prevSubtasks.map((subtask) =>
        subtask._id === subtaskId
          ? { ...subtask, is_completed: checked }
          : subtask
      )
    );
  };

  const handleNewCheckboxAdd = (e) => {
    setIsNewSubtask(true);
  };

  const debouncedSave = useRef(
    debounce((subtaskId, newText) => {
      const currentItem = currentItemRef.current; // Access the current item
      if (newText === "") return;
      if (types === "task") {
        dispatch(
          UpdateSubTaskActions({
            id: currentItem._id,
            sub_task_id: subtaskId,
            name: newText,
            user_type: user_type === "client_user" ? "client_user" : null,
          })
        );
      }
      if (types === "service") {
        dispatch(
          UpdateServiceSubTaskActions({
            id: currentItem._id,
            sub_task_id: subtaskId,
            name: newText,
          })
        );
      }
    }, 1000)
  ).current;

  const onChangeText = (subtaskId, newText) => {
    setSubtasks((prevSubtasks) =>
      prevSubtasks.map((subtask) =>
        subtask._id === subtaskId ? { ...subtask, name: newText } : subtask
      )
    );
    debouncedSave(subtaskId, newText);
  };

  const handleDeleteSubtask = (subtaskId) => {
    if (types === "task") {
      if (user_type) {
        dispatch(
          DeleteSubTaskAction({
            id: item._id,
            sub_task_id: subtaskId,
            user_type: user_type,
          })
        );
      } else {
        dispatch(
          DeleteSubTaskAction({
            id: item._id,
            sub_task_id: subtaskId,
          })
        );
      }
    }
    if (types === "service") {
      dispatch(
        DeleteServiceSubTaskAction({
          id: item._id,
          sub_task_id: subtaskId,
        })
      );
    }
    setSubtasks((prevSubtasks) =>
      prevSubtasks.filter((subtask) => subtask._id !== subtaskId)
    );
  };

  const handleAddNewSubtask = async () => {
    if (newSubtask.name && newSubtask.name !== "") {
      const value = {
        id: item._id,
        name: newSubtask.name,
      };
      if (user_type === "client_user") {
        value["user_type"] = "client_user";
      }
      if (types === "service") {
        await dispatch(
          addNewSubTaskInService(value, setIsNewSubtask, setNewSubtask)
        );
      }
      if (types === "task") {
        await dispatch(addNewSubTask(value, setIsNewSubtask, setNewSubtask));
      }

      // Clear the input for a new subtask entry
      setNewSubtask({
        _id: Date.now(),
        name: "",
        is_completed: false,
        is_new: true,
      });
    } else {
      setIsNewSubtask(false);
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleAddNewSubtask(); // Await the submission of the new subtask
      // Focus on the new input for the next subtask entry if necessary
      // You might need a ref for the input field to call focus on it

      setIsNewSubtask(true);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <div className="relative w-full sm:min-w-[236px] bg-Neutral100 rounded-lg h-auto pl-5 py-3 pr-2 flex flex-col gap-4 self-start">
        <div
          className="flex items-center justify-between"
          onClick={() => {
            setShowTextArea(!showContent);
          }}
        >
          <div className="flex items-center">
            <img src={Checkmark} className="mr-[12px]" alt="" />
            <p className="text-Neutral600 text-[10px] font-semibold">
              SUBTASKS
            </p>
          </div>

          <button
            className="w-6 h-6 disabled:cursor-not-allowed"
            onClick={(e) => {
              handleNewCheckboxAdd();
            }}
            type="button"
            disabled={
              isNew
                ? isNew
                : (!user_type &&
                    (clientDetail?.is_admin ||
                    clientDetail?.is_manager ||
                    item?.created_by?._id === user?._id
                      ? false
                      : true)) ||
                  (user_type && access_level !== "full_access")
            }
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9497 5.99955H1.05025"
                stroke="#9C9CA6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 1.0498V10.9493"
                stroke="#9C9CA6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col gap-4" id="edit-subtasks-area">
          {subtasks?.map((subtask, index) => {
            return (
              <div
                key={subtask?.name + index}
                className="flex items-center gap-2"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <label className="flex self-start gap-2 cursor-pointer">
                  <span
                    className={`w-[20px] h-[20px] min-w-[20px] min-h-[20px] ${
                      subtask.is_completed
                        ? ""
                        : "border	rounded-full	border-Neutral400"
                    }`}
                  >
                    {subtask.is_completed && (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="20"
                          height="20"
                          rx="10"
                          fill="var(--accent-color)"
                        />
                        <path
                          d="M14.5833 7.5L8.56763 13.125L5.83325 10.5682"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </span>
                  <input
                    type="checkbox"
                    className={`mr-2 invisible hidden disabled:cursor-not-allowed`}
                    checked={subtask.is_completed}
                    onChange={(e) => {
                      handleCheckboxChange(e, subtask._id);
                    }}
                    disabled={
                      (!user_type &&
                        (clientDetail?.is_admin ||
                        clientDetail?.is_manager ||
                        item?.created_by?._id === user?._id
                          ? false
                          : true)) ||
                      (user_type && access_level !== "full_access")
                    }
                  />
                </label>

                <label
                  className="flex gap-2"
                  id={`subtask-number-${index}`}
                  data-tip={`${subtask.name}`}
                  data-for={`tooltip-${index}`}
                >
                  {isEdit ? (
                    <input
                      autoFocus
                      name={subtask._id}
                      className={`${
                        subtask.is_completed ? "line-through" : ""
                      } text-xs font-normal w-[147px] bg-transparent border-none outline-none pb-[3px]`}
                      value={subtask.name}
                      onChange={(e) => {
                        onChangeText(subtask._id, e.target.value);
                      }}
                      disabled={
                        (!user_type &&
                          (clientDetail?.is_admin ||
                          clientDetail?.is_manager ||
                          item?.created_by?._id === user?._id
                            ? false
                            : true)) ||
                        (user_type && access_level !== "full_access")
                      }
                    />
                  ) : (
                    <span
                      className={`${
                        subtask.is_completed ? "line-through" : ""
                      } text-xs font-normal w-[147px] bg-transparent border-none outline-none pb-[3px] truncate h-[20px] text-Neutral500`}
                      onClick={() => setIsEdit(true)}
                    >
                      {subtask.name ? subtask.name : "New subtask"}
                    </span>
                  )}
                  <ReactTooltip
                    place="bottom"
                    textColor="#F5F5F7"
                    className="tooltip-font-size"
                    backgroundColor="#292933"
                    id={`tooltip-${index}`}
                  />
                </label>

                {hoveredIndex === index && (
                  <button
                    className="flex items-center w-3 h-3 scale-125"
                    onClick={() => {
                      handleDeleteSubtask(subtask._id);
                    }}
                    type="button"
                    disabled={
                      (!user_type &&
                        (clientDetail?.is_admin ||
                        clientDetail?.is_manager ||
                        item?.created_by?._id === user?._id
                          ? false
                          : true)) ||
                      (user_type && access_level !== "full_access")
                    }
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 6L6 18"
                        stroke="#5A5A66"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 6L18 18"
                        stroke="#5A5A66"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </div>
            );
          })}

          {isNewSubtask && (
            <div className="flex items-center gap-2">
              <label className="flex self-start gap-2 cursor-pointer">
                <span
                  className={`w-[20px] h-[20px] min-w-[20px] min-h-[20px] ${
                    newSubtask.is_completed
                      ? ""
                      : "border	rounded-full	border-Neutral400"
                  }`}
                >
                  {newSubtask.is_completed && (
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="20"
                        height="20"
                        rx="10"
                        fill="var(--accent-color)"
                      />
                      <path
                        d="M14.5833 7.5L8.56763 13.125L5.83325 10.5682"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </span>

                <input
                  type="checkbox"
                  className={`mr-2 invisible hidden `}
                  checked={newSubtask.is_completed}
                  disabled={true}
                />
              </label>

              <label
                className="flex gap-2"
                id={`subtask-number-${subtasks.length}`}
              >
                <input
                  autoFocus
                  name={newSubtask._id}
                  className={`${
                    newSubtask.is_completed ? "line-through" : ""
                  } text-xs font-normal w-[147px] bg-transparent border-none outline-none pb-[3px]`}
                  value={newSubtask.name}
                  onChange={(e) => {
                    setNewSubtask({ ...newSubtask, name: e.target.value });
                  }}
                  onBlur={() => {
                    handleAddNewSubtask();
                  }}
                  onKeyDown={handleKeyDown}
                />
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subtasks;
