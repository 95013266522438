import React, { Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logo from "../../assets/Images/Logo.svg";
import { CollabLogoutAction } from "../../store/actions/collabAuthAction";
import SettingsIcon from "../Icons/SettingsIcon";
import SignOut from "../../assets/Images/Sign-Out.svg";

function TopMenu() {
  const navigate = useNavigate();
  const authSelector = useSelector((state) => state?.collab_auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authSelector.authToken) {
      navigate("/client-collab/signin");
    }
  }, [authSelector.authToken, navigate]);

  const handleLogout = () => {
    dispatch(CollabLogoutAction());
  };

  return (
    <div className=" fixed top-0 block z-[999] w-full bg-Neutral000 py-4 px-5 drop-shadow-Mobileheader">
      <div className="flex items-center justify-between">
        <div className="flex items-center px-3">
          <img
            className="w-[20px] site-logo"
            src={
              authSelector?.user?.brandIdentity?.logo
                ? authSelector?.user?.brandIdentity?.logo
                : Logo
            }
            alt="logo"
          />
          <Link to="/client-collab" className="focus-visible:outline-none">
            <h4 className="font-black text-sm ml-1 whitespace-nowrap focus-visible:outline-none">
              ClientManager
            </h4>
          </Link>
        </div>
        <div className="flex items-center">
          <Menu as="div" className="relative inline-block text-left">
            <div className="flex items-center">
              <div className="text-xs text-AccentRegular700 border-b-[2px] border-AccentRegular w-full min-w-[65px] mr-8">
                <Link to="/client-collab/all-projects">All Projects</Link>
              </div>
              <Menu.Button className="inline-flex justify-center w-full">
                {/* <img className="w-8 h-8" src={SidebarProfile} alt="" /> */}
                {authSelector?.user?.profile ? (
                  <img
                    className="!min-w-[36px] w-10 h-10 rounded-full group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50"
                    src={authSelector?.user?.profile_path}
                    alt=""
                  />
                ) : (
                  <div
                    className={`border border-Neutral300 md:w-[48px] md:h-[48px] h-[48px] w-[48px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50`}
                  >
                    <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey uppercase">
                      {authSelector?.user?.user_name
                        ? authSelector?.user?.user_name[0]
                        : authSelector?.user?.email[0]}
                    </span>
                  </div>
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-fit rounded-md drop-shadow-Simplebutton bg-white  focus:outline-none">
                <div className="">
                  <form method="POST" action="#">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to="/client-collab/settings">
                          <button
                            type="button"
                            className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800 hover:bg-Neutral100"
                          >
                            <SettingsIcon />
                            <span className="ml-1"> Settings</span>{" "}
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          type="button"
                          onClick={handleLogout}
                          className="5xl:w-[120px] text-left px-4 py-2 text-sm whitespace-nowrap flex items-center justify-center font-medium text-Neutral800"
                        >
                          <img className="ml-1" src={SignOut} alt="" />
                          Sign Out
                        </button>
                      )}
                    </Menu.Item>
                  </form>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default TopMenu;
