import { Formik } from "formik";
import React, { useState } from "react";
import { UpdateProfileAction } from "../../store/actions/authAction";
import { UpdateCollabProfileAction } from "../../store/actions/collabAuthAction";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import ButtonLoader from "../Loaders/ButtonLoader";

const UpdateProfileSchema = Yup.object().shape({
  user_name: Yup.string().required("User Name Required"),
  email: Yup.string().email("Email is not valid").required("Email Required"),
});

const Profile = ({ logedinUser, isCollab = false }) => {
  const dispatch = useDispatch();
  const [isInNormalState, setIsInNormalState] = useState(true);

  return (
    <Formik
      initialValues={{
        ...logedinUser,
        profile_new_image: null,
        is_profile_remove: false,
      }}
      enableReinitialize
      validationSchema={UpdateProfileSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        if (isCollab) {
          await dispatch(
            UpdateCollabProfileAction(values, setSubmitting, setErrors)
          );
        } else {
          await dispatch(UpdateProfileAction(values, setSubmitting, setErrors));
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        submitForm,
        /* and other goodies */
      }) => (
        <form
          className="w-full 5xl:max-w-[500px] md:max-w-[408px] mr-[40px]"
          onSubmit={handleSubmit}
          onChange={() => setIsInNormalState(false)}
        >
          <div className="">
            <div
              className={`w-full 5xl:max-w-[500px] md:max-w-[408px] rounded pt-8 px-10 pb-10 border border-Neutral300  ${
                isCollab ? "bg-white" : ""
              }`}
            >
              <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] mb-6 uppercase">
                Profile
              </h5>
              <div className="w-full flex items-center justify-between pb-2">
                {logedinUser.profile && logedinUser.profile_path ? (
                  <img
                    className="w-[75px] h-[75px] rounded-full"
                    src={logedinUser.profile_path}
                    alt=""
                  />
                ) : values.profile_new_image ? (
                  <img
                    className="w-[75px] h-[75px] rounded-full"
                    src={URL.createObjectURL(values.profile_new_image)}
                    alt=""
                  />
                ) : (
                  <div
                    className={`border border-Neutral300 p-[30px] w-[20px] h-[20px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700 focus-visible:outline-none`}
                  >
                    <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold">
                      {isCollab
                        ? logedinUser?.first_name
                        : logedinUser?.user_name[0]}
                    </span>
                  </div>
                )}
                <div className="w-full flex items-center ml-4">
                  {!values.profile_new_image ? (
                    <div className="relative w-full 5xl:max-w-[180px] max-w-[116px]">
                      <button
                        type="button"
                        className="text-xs text-center block rounded w-full 5xl:max-w-[180px] max-w-[116px] relative text-AccentRegular bg-Neutral200 hover:bg-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-medium transition duration-300"
                      >
                        <label
                          For="uploadlogo"
                          className="relative py-2 px-[15px] block cursor-pointer z-10 font-[600]"
                        >
                          Upload Image
                        </label>
                      </button>
                      <input
                        id="uploadlogo"
                        className="hidden"
                        type="file"
                        accept="image/x-png,image/jpeg"
                        onChange={(e) => {
                          setFieldValue(`profile_new_image`, e.target.files[0]);
                        }}
                        placeholder="Eg. very_good_password"
                      />
                    </div>
                  ) : (
                    <>
                      <button
                        onClick={() => submitForm()}
                        type="button"
                        className="text-xs text-center block rounded 5xl:max-w-[180px] max-w-[116px] relative text-AccentRegular bg-Neutral200 hover:bg-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-medium transition duration-300 border border-AccentLight"
                      >
                        {isSubmitting ? (
                          <ButtonLoader className="z-10" />
                        ) : (
                          <label className="relative py-2 px-[15px] block cursor-pointer z-10 font-[600]">
                            Save
                          </label>
                        )}
                      </button>{" "}
                      <button
                        type="button"
                        onClick={() => setFieldValue(`profile_new_image`, null)}
                        className="text-Red500 text-xs font-[600] ml-7 border-b border-Red500 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300"
                      >
                        Remove
                      </button>
                    </>
                  )}
                  {logedinUser.profile && logedinUser.profile_path && (
                    <button
                      type="button"
                      onClick={() => {
                        setFieldValue(`is_profile_remove`, true);
                        submitForm();
                      }}
                      className="text-Red500 text-xs font-[600] ml-6 border-b border-Red500 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight transition duration-300"
                    >
                      {isSubmitting ? (
                        <ButtonLoader className="z-10" />
                      ) : (
                        "Delete"
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <label className="text-Neutral900 font-bold text-xs">
                  Name
                </label>
                <div className="mt-2">
                  <input
                    className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-12 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                      errors.user_name && touched.user_name
                        ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                        : ""
                    }`}
                    type="text"
                    name="user_name"
                    value={values.user_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Rayna Korsgaard"
                  />
                  {errors.user_name && touched.user_name && (
                    <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none ">
                      {errors.user_name}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <label className="text-Neutral900 font-bold text-xs">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-12 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                      errors.email && touched.email
                        ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                        : ""
                    }`}
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={(e) => {
                      setFieldValue(`email`, e.target.value.toLowerCase());
                    }}
                    onBlur={handleBlur}
                    placeholder="rayna@gmail.com"
                  />
                  {errors.email && touched.email && (
                    <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none ">
                      {errors.email}
                    </p>
                  )}
                </div>
              </div>
              <button
                className={`large-primary-btn text-sm rounded mt-8 h-12 5xl:w-[160px] w-[148px] focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                  isInNormalState
                    ? "disabled bg-Neutral300 text-Neutral500"
                    : "bg-AccentRegular drop-shadow-Texts text-Neutral000 hover:bg-AccentMediumDark button-hover"
                }`}
                type="submit"
                disabled={isSubmitting || isInNormalState}
              >
                {isSubmitting ? (
                  <ButtonLoader className="z-10" />
                ) : (
                  <span className="relative z-10 flex items-center justify-center font-[600]">
                    Update Profile
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Profile;
