import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Cancel from "../../../../../assets/Images/Close-gray.svg";
import { getTasksCountByMemberAction } from "../../../../../store/actions/dashboard-v2";
import { UpdateServiceActions } from "../../../../../store/actions/serviceAction";
import { UpdateTaskActions } from "../../../../../store/actions/tasksAction";
import PeopleIcon from "../../../../Dashboard-v2/icons/PeopleIcon";
import CreateMemberModal from "../../../CreateMemberModal";
import MenuIcon from "../Icons/MenuIcon";

const AssignedNew = ({ isNew, member, item, user, types, clientDetail }) => {
  const dispatch = useDispatch();

  const [selectMember, setSelectMember] = useState(item?.members);
  const [memberList, setMemberList] = useState([]);
  const [createMemberModal, setCreateMemberModal] = useState(false);

  useEffect(() => {
    const temp123 = member?.filter(
      (e) => !selectMember?.find((item) => e?.user?._id === item?._id)
    );
    setMemberList(temp123);
  }, [selectMember?.length, clientDetail?.members]);

  useEffect(() => {
    setSelectMember(item?.members);
  }, [item?.members?.length]);

  const handleSelect = (ele) => {
    if (selectMember?.length) {
      setSelectMember([...selectMember, ele?.user]);
    } else {
      setSelectMember([ele?.user]);
    }
  };
  let cancelToken;

  // call remove API with debounce
  const handleRemove = (ele) => {
    const removeSelectedMember = selectMember.filter(
      (element) => element?._id !== ele?._id
    );
    setSelectMember(removeSelectedMember);
    dispatch(
      UpdateTaskActions(
        { ...item, members: removeSelectedMember },
        false,
        cancelToken
      )
    );
  };

  // const handleRemove = useRef(
  //   debounce((ele) => {
  //     const removeSelectedMember = selectMember.filter(
  //       (element) => element?._id !== ele?._id
  //     );
  //     setSelectMember(removeSelectedMember);
  //     dispatch(UpdateTaskActions({ ...item, members: removeSelectedMember }));
  //   }, 500)
  // ).current;

  // const handleRemove = (ele) => {
  //   const removeSelectedMember = selectMember.filter(
  //     (element) => element?._id !== ele?._id
  //   );
  //   setSelectMember(removeSelectedMember);
  //   debounceDispatch({ ...item, members: removeSelectedMember });
  // };

  // const debounceDispatch = useRef(
  //   debounce((editTask) => {
  //     if (types === "task") {
  //       dispatch(UpdateTaskActions(editTask));
  //     }
  //   }, 1000)
  // ).current;

  const handleBlur = () => {
    const tempMember = [];
    selectMember.map((ele) =>
      tempMember.push(ele?.user?._id ? ele?.user?._id : ele?._id)
    );
    if (
      tempMember?.length &&
      JSON.stringify(item?.members) !== JSON.stringify(tempMember)
    ) {
      if (types === "task") {
        dispatch(UpdateTaskActions({ ...item, members: tempMember }));
        dispatch(getTasksCountByMemberAction("all"));
      }
      if (types === "service") {
        dispatch(UpdateServiceActions({ ...item, members: tempMember }));
      }
    }
  };

  return (
    <div class="w-full sm:w-[353px] bg-Neutral100 py-[12px] pl-[20px] pr-[8px] rounded-lg overflow-auto">
      <div class="flex flex-wrap justify-between items-center">
        <div className="flex items-center gap-2 text-AccentRegular">
          {/* <img src={People} className="mr-[12px]" alt="" /> */}
          <PeopleIcon className="size-6" />
          <p className="text-AccentRegular text-[10px] font-semibold">
            ASSIGNED
          </p>
        </div>

        <div className="text-AccentRegular">
          <MenuIcon />
        </div>
      </div>
      <Menu as="div" className="inline-block w-full text-left">
        {({ open }) => (
          <>
            <Menu.Button
              className="relative inline-flex flex-wrap items-center justify-between w-full"
              disabled={
                isNew
                  ? true
                  : clientDetail?.is_admin ||
                    clientDetail?.is_manager ||
                    item?.created_by?._id === user?._id
                  ? false
                  : true
              }
            >
              {!selectMember?.length && (
                <span className="w-full focus-visible:outline-none text-start">
                  <div className="members-text flex flex-wrap text-[14px] items-center text-Neutral700">
                    Assign team members...
                  </div>
                  <div className="eam-text text-[10px] items-center text-Neutral700">
                    Team members will appear here...
                  </div>
                </span>
              )}

              {selectMember?.map((element, index) => {
                if (element) {
                  return (
                    <div
                      className="flex items-center rounded-lg py-[6px] cursor-pointer w-[50%] m-0"
                      key={index}
                    >
                      {element?.user?.profile || element?.profile ? (
                        <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                          <img
                            src={
                              element?.user?.profile_path ||
                              element?.profile_path
                            }
                            className="object-cover"
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
                          <span className="text-[13px] w-full h-full flex items-center justify-center rounded-full font-bold uppercase">
                            {element?.user?.user_name?.[0] ||
                              element?.user_name?.[0]}
                          </span>
                        </div>
                      )}
                      <p className="text-Neutral900 text-sm font-medium px-[10px] truncate">
                        {element?.user?.user_name || element?.user_name}
                      </p>
                      {open && (
                        <img
                          src={Cancel}
                          className="object-cover rounded-full cancel-btn hover:bg-Neutral400"
                          alt=""
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemove(element);
                          }}
                        />
                      )}
                    </div>
                  );
                }
              })}
            </Menu.Button>
            <Transition as={Fragment}>
              <Menu.Items
                onBlur={handleBlur}
                //className="origin-top-right absolute z-[999] top-[100%] -left-4 pt-3 pb-2 w-[216px] rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                className="rounded-lg border-top-set origin-top-right z-50 absolute bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-[90%] md:w-[300px] pb-4 px-4 "
              >
                <div className="bg-white">
                  <p className="text-Neutral600 text-[10px] font-normal	pt-[15px] pb-[13px] border-b">
                    Team members will appear here...
                  </p>
                  <p className="text-Neutral600 text-[10px] font-semibold		pt-[12px] pb-[13px] ">
                    Add Team Members
                  </p>
                  <div className="overflow-y-scroll h-[170px]">
                    {memberList?.map((ele, index) => {
                      return (
                        <div
                          className="flex items-center rounded-lg py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                          key={index}
                          onClick={() => {
                            handleSelect(ele);
                          }}
                        >
                          {ele?.user?.profile ? (
                            <div className="rounded-full h-[24px] w-[24px] overflow-hidden">
                              <img
                                src={ele?.user?.profile_path}
                                className="object-cover"
                                alt=""
                              />
                            </div>
                          ) : (
                            <div className="h-[24px] w-[24px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
                              <span className="text-[13px] w-full h-full flex items-center justify-center rounded-full font-bold uppercase">
                                {ele?.user?.user_name[0]}
                              </span>
                            </div>
                          )}
                          <p className="pl-4 text-sm font-normal text-Neutral900">
                            {ele?.user?.user_name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <hr className="my-2 border-none h-[1px] bg-Neutral300" />
                  <p className="text-Neutral600 text-[10px] font-semibold pt-[18px] pb-[12px]">
                    Don’t see who you’re looking for?{" "}
                    <span
                      onClick={() => setCreateMemberModal(true)}
                      className="cursor-pointer text-AccentRegular font-bold inline-block mt-2 tracking-[0.04em] focus-visible:outline-none hover:text-AccentMediumDark"
                    >
                      Invite a team member
                    </span>
                    .
                  </p>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <CreateMemberModal
        isOpen={createMemberModal}
        setModal={setCreateMemberModal}
      />
    </div>
  );
};

export default AssignedNew;
