import React, { useEffect } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import { customModalStyles } from "../modalsData";
import { getFullClientList } from "../../../store/actions/clientAction";
import { useDispatch } from "react-redux";
import ClientSelect from "../../comman/Client/ClientSelect";

function ClientSelectModal({
  isOpen = false,
  setIsOpen = () => {},
  setSelectedClient = () => {},
  selectedClient = {},
  handleOpenNewEntityTemplateModal = () => {},
  type = "",
}) {
  const dispatch = useDispatch();
  const allClients = useSelector((state) => state.client.fullClientList);
  useEffect(() => {
    if (allClients?.data?.length === 0 && isOpen) {
      dispatch(getFullClientList());
    }
  }, [dispatch, isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                id="modal-title"
              >
                Select Client
              </h3>
              <div className="mb-8">
                <label className="block mb-2 text-xs font-bold text-Neutral900">
                  Client
                </label>
                <ClientSelect
                  allClients={allClients}
                  onChange={(e) => {
                    setSelectedClient(e);
                  }}
                />
              </div>
              <button
                className={`btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px] py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                  !selectedClient && "pointer-events-none"
                }`}
                onClick={() => {
                  handleOpenNewEntityTemplateModal();
                }}
              >
                <span className="relative z-10 drop-shadow-Texts">
                  Continue to {type} Builder
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default ClientSelectModal;
