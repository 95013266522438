import React, { useEffect, useState, forwardRef } from "react";
import Modal from "react-modal";
import { Menu } from "@headlessui/react";
import Comment from "./Comment";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import ProposalsIcon from "../../Icons/ProposalsIcon";
import PhoneIcon from "../../Icons/PhoneIcon";
import MailIcon from "../../Icons/MailIcon";
import LeadsActionMenu from "../../Buttons/LeadsActionMenu";
import MoreIcon from "../../Icons/MoreIcon";
import LeadsControls from "../../Buttons/LeadsControls";
import DetailsSection from "./DetailsSection";
import DescriptionSection from "./DescriptionSection";
import { customModalStyles } from "../modalsData";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { updateLeadFollowUpAction } from "../../../store/actions/leadAction";
import ReactTooltip from "react-tooltip";

// for showing toast over blurred backdrop
customModalStyles.overlay.zIndex = 100;

function LeadDetailsModal({
  isOpen = false,
  setIsOpen = () => {},
  onDeleteLead = () => {},
  option = false,
}) {
  const [converted, setConverted] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const id = query.get("cardId");
  const lead = useSelector((state) => {
    return state.lead.leads?.data?.find((lead) => lead._id === id);
  });
  const user = useSelector((state) => state.auth.user);
  const userDateFormat = user?.date_format;
  const [startDate, setStartDate] = useState(
    lead?.reminder_date ? new Date(lead?.reminder_date) : lead?.reminder_date
  );
  const dispatch = useDispatch();
  const [prevLead, setPrevLead] = useState(null);

  // Reset startDate when modal closes
  useEffect(() => {
    if (!isOpen && prevLead) {
      setStartDate(null);
      setPrevLead(null);
    }
  }, [isOpen, prevLead]);

  useEffect(() => {
    if (lead?.client && lead?.client?._id) {
      setConverted(true);
    } else {
      setConverted(false);
    }

    if (lead?.reminder_date) {
      // Parse the date only if reminder_date is not null/undefined
      setStartDate(new Date(lead?.reminder_date));
    } else {
      // Otherwise, reset the startDate to null or some default value
      setStartDate(null);
    }
  }, [lead]); // Dependency on the lead prop

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <button
        className={`example-custom-input text-xs whitespace-nowrap font-bold color-Neutral100 text-Neutral100 px-0`}
        onClick={onClick}
        ref={ref}
        data-for="Follow Up"
        data-tip="Follow Up"
      >
        <p className="flex items-center text-Neutral600 text-[10px] font-semibold leading-[14px]">
          <ReactTooltip
            place="bottom"
            textColor="#F5F5F7"
            className="tooltip-font-size"
            backgroundColor="#292933"
            id="Follow Up"
          />
          <svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4993 8.39967C17.4993 6.96759 16.9304 5.59416 15.9178 4.58153C14.9051 3.56889 13.5317 3 12.0996 3C10.6675 3 9.29412 3.56889 8.28149 4.58153C7.26885 5.59416 6.69996 6.96759 6.69996 8.39967C6.69996 14.6993 4.00012 16.4992 4.00012 16.4992H20.1991C20.1991 16.4992 17.4993 14.6993 17.4993 8.39967Z"
              stroke="#5A5A66"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.6565 20.0986C13.4983 20.3714 13.2712 20.5978 12.998 20.7552C12.7247 20.9126 12.415 20.9954 12.0996 20.9954C11.7843 20.9954 11.4745 20.9126 11.2013 20.7552C10.928 20.5978 10.7009 20.3714 10.5427 20.0986"
              stroke="#5A5A66"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {value && moment(value).format(userDateFormat)}
        </p>
      </button>
    );
  });

  const onDateChange = async (date) => {
    setStartDate(date);
    if (lead?.reminder_date !== date) {
      await dispatch(updateLeadFollowUpAction(lead?._id, date));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="flex flex-col lg:flex-row h-[70vh] overflow-y-auto rounded-lg">
          <div className="bg-white px-6 md:px-12 py-6 md:rounded-lg 5xl:min-w-[592px] sm:min-w-[592px] w-[90vw] sm:w-[592px] overflow-y-auto">
            <div className="flex items-start justify-between mb-5">
              <div className="flex justify-start">
                {!lead?.profile ? (
                  <div className="w-[40px] h-[24px] sm:w-[48px] sm:h-[48px] rounded-full mr-2 sm:mr-4 bg-Neutral300 flex items-center justify-center">
                    <p className="text-Neutral700 text-sm sm:text-[20px] font-semibold leading-[14px] text-center">
                      {lead?.name?.charAt(0)}
                    </p>
                  </div>
                ) : (
                  <img
                    src={lead?.profile_path}
                    alt={`${lead?.name} avatar`}
                    width="36"
                    height="36"
                    className="w-[40px] h-[24px] sm:w-[48px] sm:h-[48px] rounded-full mr-2 sm:mr-4"
                  />
                )}
                <div>
                  <p className="block text-Neutral900 text-[24px] mb-[10px] font-bold leading-[100%]">
                    {lead?.name}
                  </p>
                  <div className="flex flex-row gap-x-[22px] text-Neutral700 text-[10px] leading-[14px] font-medium flex-wrap">
                    {lead?.business_name && (
                      <p className="flex items-center justify-start">
                        <ProposalsIcon
                          width={14}
                          className="inline-block mr-2"
                          currentColor="#9C9CA6"
                        />
                        {lead?.business_name}
                      </p>
                    )}
                    {lead?.email && (
                      <p className="flex items-center justify-start">
                        <MailIcon width={14} className="inline-block mr-2" />
                        {lead?.email}
                      </p>
                    )}
                    {lead?.phone_number && (
                      <p className="flex items-center justify-start">
                        <PhoneIcon width={14} className="inline-block mr-2" />
                        {lead?.phone_number}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <DatePicker
                  selected={startDate}
                  customInput={
                    <DateInput
                      value={
                        !startDate ? "Select date..." : moment(startDate).format(userDateFormat)
                      }
                    />
                  }
                  onChange={onDateChange}
                />

                <Menu as="div" className="relative inline-block">
                  <div className="flex items-center justify-center">
                    <Menu.Button
                      className="inline-flex items-center justify-center w-[100%] focus-visible:outline-none relative text-Neutral600 hover:text-Neutral800 duration-250 transition-all"
                      disabled={converted}
                    >
                      <span className="focus-visible:outline-none">
                        <MoreIcon />
                      </span>
                    </Menu.Button>
                  </div>
                  <LeadsActionMenu
                    noConvertToClient
                    onDeleteLead={onDeleteLead}
                    leadId={lead?._id}
                  />
                </Menu>
              </div>
            </div>
            {/* Controls */}
            <LeadsControls converted={converted} setConverted={setConverted} lead={lead} />
            <DetailsSection lead={lead} />
            <DescriptionSection item={lead} user={user} />
            {/* <NotesSection lead={lead} option={option} /> */}
            {lead?.custom_fields && lead?.custom_fields?.length > 0 && (
              <section className="w-[100%]  ">
                <h3 className="text-base font-semibold leading-[20px] text-Neutral800 mb-2">
                  Custom Fields
                </h3>
                <div className="h-[7vh] lg:h-[9vh] overflow-auto flex w-full flex-col pb-4 scrollbar-w-4">
                  {lead?.custom_fields?.map((item, index) => {
                    return (
                      <div className={`flex items-start justify-start w-full gap-2 mb-2 sm:mb-0`}>
                        <span className="text-xs font-semibold flex  leading-[18px] text-Neutral800 mb-[2px] capitalize">
                          {item?.key}:
                        </span>
                        <span className="text-xs font-medium leading-[20px] text-Neutral600">
                          {item?.value}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </section>
            )}
          </div>
          <Comment element={lead} lead_id={lead?._id} />
        </div>

        {/* CLose button */}
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default LeadDetailsModal;
