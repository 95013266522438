import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../store/actions";
import SigninBg from "../../assets/Images/signin-bg-shap.png";
import Logo from "../../assets/Images/Logo.svg";
import PasswordIcon from "../../assets/Images/password.svg";
import PasswordShowIcon from "../../assets/Images/password-show.svg";
import ButtonLoader from "../Loaders/ButtonLoader";
import { Formik } from "formik";
import * as Yup from "yup";
import ReactiveAccountModal from "../Modals/ReactiveAccountModal";
import AppsumoWarningModal from "../Modals/AppsumoWarningModal";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Email is not valid").required("Email Required"),
  password: Yup.string()
    .required("Password Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

const Signin = () => {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const [reactiveAccount, setReactiveAccount] = useState(false);
  const [loginReactiveResponce, setLoginReactiveResponce] = useState(null);
  const [isOpenAppsumoWarningModal, setIsOpenAppsumoWarningModal] =
    useState(false);
  const [isReactiveAccount, setIsReactiveAccount] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      <section>
        <div className="flex">
          <div className="w-full max-w-[50%]  sm:h-screen relative md:block hidden">
            <div className="absolute top-[50%] w-full 5xl:max-w-[440px] max-w-[384px] left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
              <div className="flex items-center">
                <img src={Logo} alt="logo" />
                <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
                  Client Manager
                </h2>
              </div>
              <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-[12px]">
                The Simple Client Onboarding & Management Tool
              </p>
            </div>
            <img
              className="w-full object-cover h-screen"
              src={SigninBg}
              alt="background"
            />
          </div>
          <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex md:flex-row flex-col md:items-center justify-center relative px-3 overflow-y-auto">
            <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
              <img className="w-full max-w-[32px]" src={Logo} alt="logo" />
              <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-[40px] text-Neutral900">
                Sign In To Continue<span className="text-AccentRegular">.</span>
              </h1>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={LoginSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  dispatch(
                    LoginAction(
                      { ...values, email: values.email.toLowerCase() },
                      setSubmitting,
                      setErrors,
                      setReactiveAccount,
                      setLoginReactiveResponce,
                      setIsOpenAppsumoWarningModal,
                      setIsReactiveAccount
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-[24px]">
                      <label className="text-Neutral900 font-bold text-xs">
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow transition duration-300 first-letter:${
                            errors.email && touched.email
                              ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                              : ""
                          }`}
                          type="email"
                          placeholder="skywalker@mail.com"
                          name="email"
                          onChange={(e) => {
                            setFieldValue(
                              "email",
                              e.target.value.toLowerCase()
                            );
                          }}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </div>
                      {errors.email && touched.email && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                          {errors.email}
                        </p>
                      )}
                    </div>
                    <div className="mb-2">
                      <label className="text-Neutral900 font-bold text-xs">
                        Password
                      </label>
                      <div className="relative mt-2">
                        <input
                          className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 focus:drop-shadow-Purpleboxshadow transition ease-in-out 
                        ${
                          errors.password && touched.password
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                          type={passwordShown ? "text" : "password"}
                          placeholder="••••••••••••"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <img
                          className="w-full absolute right-[12px] top-[8px] max-w-[32px] cursor-pointer"
                          src={passwordShown ? PasswordShowIcon : PasswordIcon}
                          alt="PasswordIcon"
                          onClick={togglePasswordVisiblity}
                        />
                      </div>
                      {errors.password && touched.password && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                          {errors.password}
                        </p>
                      )}
                    </div>
                    <Link
                      to="/forgot-password"
                      className="text-xs text-AccentRegular font-medium focus-visible:outline-none"
                    >
                      Forgot your password?
                    </Link>
                    <div className="mt-[48px] 2xl:pb-0 md:pb-16 pb-10">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                      >
                        {isSubmitting ? (
                          <ButtonLoader className="z-10" />
                        ) : (
                          <span className="relative z-10">Sign In</span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <div className="md:absolute md:top-2 3xl:right-[7%] ml:right-8 right-[6%] md:mb-0 mb-10 w-full mx-auto max-w-[248px] 5xl:max-w-[300px]">
              <div className="4xl:flex ml:block flex  text-right items-center md:justify-end justify-center 5xl:mt-4">
                <p className="text-xs text-Neutral700 font-medium 4xl:mr-[24px] ml:mr-0 mr-[24px] mb-0">
                  Don’t have an account?
                </p>
                <Link
                  to="/signup"
                  className="text-xs text-center inline-block text-AccentRegular font-[600] 4xl:mt-0 ml:mt-2 mt-0 py-1 px-2 rounded border-[1.5px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                >
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactiveAccountModal
        reactiveAccount={reactiveAccount}
        setReactiveAccount={setReactiveAccount}
        loginReactiveResponce={loginReactiveResponce}
      />
      {isOpenAppsumoWarningModal && (
        <AppsumoWarningModal
          setIsOpenAppsumoWarningModal={setIsOpenAppsumoWarningModal}
          isOpenAppsumoWarningModal={isOpenAppsumoWarningModal}
          isReactiveAccount={isReactiveAccount}
          setIsReactiveAccount={setIsReactiveAccount}
        />
      )}
    </>
  );
};

export default Signin;
