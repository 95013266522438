import { Tab } from "@headlessui/react";

import BrandIdentityCard from "./company/BrandIdentityCard";
import ClientPortalDomainCard from "./company/ClientPortalDomainCard";
import CustomEmailCard from "./company/CustomEmailCard";
import UpgradeToWhiteLabelCard from "./company/UpgradeToWhiteLabelCard";
import { useWhiteLabelSettings } from "../../contexts/WhiteLabelProvider";

function CompanyTab() {
  const { settings } = useWhiteLabelSettings();
  const userIsAWhiteLabelSubscriber = settings.get("isWhiteLabelSubscriber");
  
  return (
    <Tab.Panel className="flex flex-col gap-10">
      {/* UI-TODO: Only show this when user `DOES NOT` have white-labeling subscription */}
     {!userIsAWhiteLabelSubscriber && <UpgradeToWhiteLabelCard />}

      <ClientPortalDomainCard />
      <BrandIdentityCard />
      <CustomEmailCard />
    </Tab.Panel>
  );
}

export default CompanyTab;
