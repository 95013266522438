import { EditorState, convertFromRaw } from "draft-js";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import iconText from "../../../assets/Images/icon-text.svg";
import urgent from "../../../assets/Images/urgent.svg";
import CalendarIcon from "../../Icons/CalendarIcon";
import Subtasks from "../../Modals/TaskDetailModal/components/Subtasks";

const status = [
  { id: 1, status: "NOT STARTED", bg: "#E5675C", type: "not_started" },
  { id: 2, status: "IN PROGRESS", bg: "#5CACE5", type: "in_progress" },
  { id: 3, status: "COMPLETED", bg: "#59B347", type: "completed" },
];

const TasksBoxNew = ({ item, enabled = false }) => {
  const navigate = useNavigate();

  const [content, setContent] = useState(EditorState.createEmpty());

  const user = useSelector((state) => state.auth.user);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const projectDetail = useSelector((state) => state.project.projectDetail);

  const boxRef = useRef(null);

  const handleOpen = (data) => {
    navigate({ search: `?cardId=${data?._id}` });
  };

  const currentStatus = status.find((i) => i.type === item?.tag)?.status;
  const currentStatusBg = status.find((i) => i.type === item?.tag)?.bg;
  const bars = status.find((i) => i.type === item?.tag)?.id;
  const totalBars = 3;

  useEffect(() => {
    if (item?.description && item) {
      setContent(
        EditorState.createWithContent(
          convertFromRaw(
            item?.description?.entityMap
              ? item?.description
              : { ...item?.description, entityMap: {} }
          )
        )
      );
    }
  }, [item]);

  return (
    <div className="w-full" ref={boxRef}>
      <div
        onClick={() => {
          item?.title && handleOpen(item);
        }}
        className={`border border-Neutral300 bg-white py-3 px-4 rounded-[6px] relative cursor-pointer mb-3 flex flex-col items-start w-full`}
      >
        <div className="flex" title={`Task status: ${currentStatus}`}>
          {[...Array(totalBars)]?.map((_, index) => (
            <span
              key={index}
              className={`h-[2px] w-6 rounded-[10px] mr-2 ${
                index < bars ? `bg-[${currentStatusBg}]` : "bg-gray-300"
              }`}
            ></span>
          ))}
        </div>

        <div className="font-normal mt-[10px] w-full break-words flex items-center justify-between">
          <div className="flex w-[90%]">
            {item?.priority && item?.priority !== "normal" && (
              <img src={urgent} alt="Urgent Priority" title="Urgent Priority" />
            )}
            <div
              className="ml-1 text-sm truncate text-Neutral900"
              title={item?.title}
            >
              {item?.title ? item?.title : ""}
            </div>
          </div>

          {item?.comments?.length ? (
            <div className="relative">
              <img src={iconText} alt="" />
              <p className="text-[10px] text-AccentRegular absolute top-[40%] left-2/4 -translate-y-1/2 -translate-x-1/2 font-bold source-code-pro font-feature-settings">
                {item?.comments?.length}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="w-full truncate">
          {item?.description && (
            <Editor
              editorState={content}
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: [],
              }}
              toolbarHidden
              editorStyle={{
                color: "#5A5A66",
                fontSize: "12px",
                fontFamily: "Noto Sans",
              }}
              readOnly
            />
          )}
        </div>

        {enabled && (
          <div
            className="w-full mb-4 space-y-2"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-[10px] font-bold uppercase text-Neutral800">
              Sub Tasks
            </h2>

            <Subtasks
              user={user}
              item={item}
              clientDetail={clientDetail.data}
              types="task"
              access_level={projectDetail?.data?.access_level}
            />
          </div>
        )}

        {item?.title !== "" ? (
          <>
            <div className="flex items-center justify-start w-[100%]">
              <div className="w-[100%] flex items-center justify-start">
                {item?.media?.length ? (
                  <div className="flex-shrink-0 w-fit flex items-center justify-self-start bg-Neutral200 rounded-[22px] px-[8px] py-1 text-Neutral800 text-[10px] font-bold leading-[11px]">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0833 6.89689L7.06732 10.9129C6.57534 11.4048 5.90806 11.6812 5.21229 11.6812C4.51651 11.6812 3.84924 11.4048 3.35725 10.9129C2.86526 10.4209 2.58887 9.7536 2.58887 9.05782C2.58887 8.36205 2.86526 7.69477 3.35725 7.20278L7.37322 3.18681C7.70121 2.85882 8.14606 2.67456 8.60991 2.67456C9.07376 2.67456 9.51861 2.85882 9.8466 3.18681C10.1746 3.51481 10.3589 3.95966 10.3589 4.42351C10.3589 4.88736 10.1746 5.33221 9.8466 5.6602L5.82626 9.67617C5.66227 9.84016 5.43984 9.9323 5.20792 9.9323C4.97599 9.9323 4.75357 9.84016 4.58957 9.67617C4.42557 9.51217 4.33344 9.28975 4.33344 9.05782C4.33344 8.8259 4.42557 8.60347 4.58957 8.43948L8.29964 4.73377"
                        stroke="#9C9CA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="text-Neutral800 text-[10px] pl-[2px]">
                      {item?.media?.length}
                    </span>
                  </div>
                ) : null}
                {item?.sub_tasks?.length ? (
                  <div className="flex-shrink-0 w-fit ml-1 flex items-center justify-self-start bg-Neutral200 rounded-[22px] px-[8px] py-1 text-Neutral800 text-[10px] font-bold leading-[11px]">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 5.25L6.1875 9.75L4 7.70455"
                        stroke="#9C9CA6"
                        strokeWidth="1.20635"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="text-Neutral800 text-[10px] pl-[2px]">
                      {item?.sub_tasks?.length}
                    </span>
                  </div>
                ) : null}
                {item?.members?.length ? (
                  <div className="flex-shrink-0 w-fit ml-1 flex items-center justify-self-start bg-Neutral200 rounded-[22px] px-[8px] py-1 text-Neutral800 text-[10px] font-bold leading-[11px]">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.0002 11.5001V10.5C11.0002 9.9696 10.7894 9.46088 10.4144 9.0858C10.0393 8.71072 9.53057 8.5 9.00012 8.5H5.00004C4.4696 8.5 3.96088 8.71072 3.5858 9.0858C3.21072 9.46088 3 9.9696 3 10.5V11.5001"
                        stroke="#9C9CA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.00004 6.50008C8.10463 6.50008 9.00008 5.60463 9.00008 4.50004C9.00008 3.39545 8.10463 2.5 7.00004 2.5C5.89545 2.5 5 3.39545 5 4.50004C5 5.60463 5.89545 6.50008 7.00004 6.50008Z"
                        stroke="#9C9CA6"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="text-Neutral800 text-[10px] pl-[2px]">
                      {item?.members?.length}
                    </span>
                  </div>
                ) : null}
              </div>
              {item?.date && (
                <div className="flex-shrink-0 w-fit min-w-[60px] flex items-center justify-self-start bg-Neutral800 rounded-[22px] px-[10px] py-1 text-Neutral200 text-[10px] font-bold leading-[11px]">
                  <CalendarIcon className="mr-1" width={14} color="#C2C2CC" />
                  <span>{moment(item?.date).format("DD MMM")}</span>
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default TasksBoxNew;
