import { Field } from "formik";

import ButtonLoader from "../Loaders/ButtonLoader";
import AddressAutocomplete from "./AddressAutocomplete";
import CustomErrorMessage from "./CustomErrorMessage";

function Step2({ active, onPrevious, complete, setStep, isSubmitting }) {
  return (
    <div
      className={`bg-neutral-50 p-4 rounded w-full step flex h-auto ${
        active
          ? "h-full flex-col items-start justify-start"
          : "h-14 items-center"
      }
      `}
    >
      <div
        className={`flex-col justify-center items-start gap-0.5 flex w-full ${
          !active && "cursor-pointer"
        }`}
        onClick={() => !active && setStep(2)}
      >
        <span className="flex items-center w-full gap-6 text-xs font-bold tracking-wide">
          <span
            className={`min-w-[40px] ${
              complete ? "text-emerald-400" : "text-AccentRegular"
            } `}
          >
            STEP 2
          </span>

          {!active && (
            <span className="flex items-center justify-between w-full">
              <span className="text-xs font-bold text-zinc-500">
                Client Information
              </span>
              {!active && complete && (
                <span className="text-xs font-bold tracking-wide">
                  <span
                    className={`w-12 ${
                      complete ? "text-emerald-400" : "text-indigo-500"
                    }`}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="22" height="22" rx="11" fill="#42D6A6" />
                      <path
                        d="M16.6477 7.40332L9.20657 14.3613L5.82422 11.1986"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              )}
            </span>
          )}
        </span>
        <div
          className={`justify-start items-center gap-3 flex ${
            !active && "hidden"
          }`}
        >
          <div className="text-base font-bold tracking-wide text-zinc-800">
            Client Information
          </div>
        </div>
      </div>
      <div
        className={`justify-start items-start gap-6 flex flex-col mt-5 w-full ${
          !active && "hidden"
        }`}
      >
        <div className="flex flex-wrap items-start justify-start w-full gap-6 md:w-2/3">
          {/* CLIENT NAME */}
          <div className="w-full">
            <label
              htmlFor="clientName"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Client Name
            </label>
            <Field
              name="clientName"
              type="text"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
              placeholder="Eg. John P."
            />
            <CustomErrorMessage name="clientName" />
          </div>
          {/* CLIENT NAME */}

          {/* CLIENT EMAIL */}
          <div className="w-full">
            <label
              htmlFor="clientEmail"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Client Email{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="clientEmail"
              type="text"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
              placeholder="Eg. JohnP@yopmail.com"
            />
            <CustomErrorMessage name="clientEmail" />
          </div>
          {/* CLIENT EMAIL */}

          {/* COMPANY NAME */}
          <div className="w-full">
            <label
              htmlFor="clientCompanyName"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Company Name{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="clientCompanyName"
              type="text"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
              placeholder="Enter client company name"
            />
            <CustomErrorMessage name="clientCompanyName" />
          </div>
          {/* COMPANY NAME */}

          {/* PHONE NUMBER */}
          <div className="w-full">
            <label
              htmlFor="clientPhoneNumber"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Phone Number{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="clientPhoneNumber"
              type="text"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
              placeholder="Enter client phone number"
              length="10"
            />
            <CustomErrorMessage name="clientPhoneNumber" />
          </div>
          {/* PHONE NUMBER */}

          {/* ADDRESS */}
          <AddressAutocomplete label="Address" name="clientAddress" />
          {/* ADDRESS */}

          {/* LOGO */}
          {/* <div>
            <label
              htmlFor="clientLogo"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Client Logo
            </label>
            <Field
              name="clientLogo"
              type="file"
              className="p-2 px-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 w-80"
            />
            <CustomErrorMessage name="clientLogo"/>
          </div> */}
          {/* LOGO */}

          {/* VAT */}
          <div className="w-full">
            <label
              htmlFor="clientVAT"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              VAT Number{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="clientVAT"
              type="text"
              className="w-full p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500 md:w-80"
              placeholder="Enter client VAT number"
            />
            <CustomErrorMessage name="clientVAT" />
          </div>
          {/* VAT */}

          {/*  */}
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-6 md:flex-row">
          <button
            className={`w-full md:w-80 h-12 bg-AccentRegular rounded flex-col justify-center items-center gap-2 flex hover:bg-AccentMediumDark text-AccentLight`}
            disabled={isSubmitting}
            type="submit"
          >
            <div className="w-full text-sm font-bold leading-none text-center">
              {isSubmitting ? <ButtonLoader /> : "Next"}
            </div>
          </button>
          <button
            type="button"
            className="flex flex-col items-center justify-center h-12 gap-2 px-6 rounded bg-AccentLight hover:bg-AccentMediumLight"
            onClick={onPrevious}
          >
            <div className="text-sm font-bold leading-none text-center text-AccentRegular">
              Previous
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Step2;
