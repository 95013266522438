import clx from "classnames";
import { useSelector } from "react-redux";

import prepareTasksAndTimeCounter from "../../../../utils/prepareTasksAndTimeCounter";
import ClockIcon from "../../../Icons/ClockIcon";
import TeamAvatarsList from "../../../common/lists/TeamAvatarsList";
import SectionTitle from "../../../common/typography/SectionTitle";
import TrackerControl from "./TrackerControl";

function TimeTrackingManagement({
  timeTracking = [],
  setIsOpen = () => {},
  setIsTrackingEditModalOpen = () => {},
  className = "",
  setModalClientDetail = () => {},
  project = null,
  setIsStartTimerModalOpen = () => {},
}) {
  const isRunning = useSelector((state) => state.timer.isRunning);

  const entries = prepareTasksAndTimeCounter(timeTracking?.total_entries);

  const hours = prepareTasksAndTimeCounter(
    timeTracking?.total_time
      ? timeTracking?.total_time?.split(":")[0].charAt(0) === "0"
        ? timeTracking?.total_time?.split(":")[0].charAt(1)
        : timeTracking?.total_time?.split(":")[0]
      : 0,
    true
  );

  const minutes = prepareTasksAndTimeCounter(
    timeTracking?.total_time
      ? timeTracking?.total_time?.split(":")[1].charAt(0) === "0"
        ? timeTracking?.total_time?.split(":")[1].charAt(1)
        : timeTracking?.total_time?.split(":")[1]
      : 0,
    true
  );

  return (
    <div
      className={clx(
        "flex flex-col p-[28px] pt-[23px] rounded-xl bg-Neutral000 border-[0.5px] border-Neutral200 shadow-[0_4px_15px_rgba(0,0,0,0.08)] w-full xl:max-w-[460px]",
        className
      )}
    >
      <div className="flex items-center justify-between w-full mb-5">
        <SectionTitle
          text="Time Tracking"
          className="flex justify-center items-center mr-[6px]"
        />
        <TrackerControl
          isRunning={isRunning}
          project={project}
          setIsStartTimerModalOpen={setIsStartTimerModalOpen}
          isSmall
        />
      </div>
      <div className="flex items-stretch justify-between pb-1 mb-6 overflow-x-auto xl:justify-start md:overflow-x-hidden scrollbar-h-4">
        <div className="min-w-[96px] flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 pb-2 px-[13px] pt-[18px] mr-2">
          <ClockIcon width={24} height={24} filled className="mb-[10px]" />
          <span className="leading-[100%] tracking-[-0.04em] text-Neutral900 text-5xl font-normal text-center mb-[22px]">
            {entries}
          </span>
          <span className="leading-[14px] tracking-[0.02em] text-[10px] font-semibold uppercase text-Neutral800 whitespace-nowrap">
            Time entries
          </span>
        </div>
        {/* time group */}
        <div className="w-100% min-w-[284px] max-w-[284px] flex flex-wrap gap-1">
          <div className="flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 min-w-[140px] pt-4 pb-2 grow">
            <span
              className={`leading-[80%] tracking-[-0.04em] text-Neutral800 text-[32px] font-extrabold mb-[4px]`}
            >
              {hours}
            </span>
            <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 opacity-90">
              hrs
            </span>
          </div>

          <div className="flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 min-w-[140px] pt-4 pb-2 grow">
            <span
              className={`leading-[80%] tracking-[-0.04em] text-Neutral600 text-[32px] font-extrabold mb-[4px]`}
            >
              {minutes}
            </span>
            <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 opacity-90">
              mins
            </span>
          </div>

          {/* members */}
          <div className="flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 min-w-[140px] pt-3 pb-2 grow">
            <TeamAvatarsList
              users={timeTracking?.time_contributors}
              className="mb-[6px]"
            />
            <span className="leading-[14px] tracking-[0.02em] text-[10px] font-normal uppercase text-Neutral700 opacity-90">
              Time Contributors
            </span>
          </div>
        </div>
      </div>
      {/* controls */}
      <div className="flex justify-stretch items-stretch gap-[10px]">
        <button
          className="w-full rounded flex justify-center items-center tracking-[0.04em] text-xs text-Neutral000 bg-AccentRegular uppercase font-bold hover:bg-AccentMediumDark"
          onClick={() => {
            setIsTrackingEditModalOpen(true);
            setModalClientDetail(timeTracking);
          }}
        >
          Add Time Entry
        </button>

        <button
          className="w-full h-[48px] rounded flex justify-center items-center tracking-[0.04em] text-xs text-AccentRegular bg-Neutral200 uppercase font-bold transition duration-300 hover:bg-AccentMediumLight"
          onClick={() => setIsOpen(true)}
        >
          View All
        </button>
      </div>
    </div>
  );
}

export default TimeTrackingManagement;
