const initialState = {
  tasks: {
    isLoading: true,
    client: null,
    data: [],
  },
  all_tasks: {},
  events: {
    isLoading: true,
    start_date: null,
    end_date: null,
    data: [],
  },
};
const tasksReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_TASKS":
      let tempTasks = state.all_tasks;
      if (payload.is_archived) {
        tempTasks = {
          ...tempTasks,
          ...payload.data,
        };
      } else {
        if (tempTasks[payload.project]) {
          // remove duplicate tasks from project
          const temp = tempTasks[payload.project].filter((item) => {
            return !payload.data.some((e) => e._id === item._id);
          });
          tempTasks[payload.project] = [...temp, ...payload.data];
        } else {
          tempTasks[payload.project] = payload.data;
        }
      }
      return {
        ...state,
        all_tasks: {
          ...tempTasks,
        },
      };
    case "SET_ALL_PROJECTS_TASKS":
      let tempTaskss = state.all_tasks;
      if (payload?.data?.docs?.length) {
        payload?.data?.docs?.forEach((item) => {
          if (tempTaskss[item._id]) {
            const temp = tempTaskss[item._id].filter((e) => {
              return !item.tasks.some((i) => i._id === e._id);
            });
            tempTaskss[item._id] = [...temp, ...item.tasks];
          } else {
            tempTaskss[item._id] = item.tasks;
          }
        });
      }
      return {
        ...state,
        all_tasks: {
          ...tempTaskss,
        },
      };
    case "UPDATE_TASK_SUCCESS":
      let allTasks = state.all_tasks;
      allTasks[payload.data.project] = allTasks[payload.data.project].map(
        (item) => {
          if (item._id === payload.data._id) {
            return payload.data;
          }
          return item;
        }
      );
      return {
        ...state,
        all_tasks: {
          ...state.all_tasks,
          ...allTasks,
        },
      };
    case "UPDATE_TASK_ABORT":
      let allTasksAbort = state.all_tasks;
      const projectId = payload.data[0]?.project;
      payload.data.forEach((e) => {
        allTasksAbort[projectId] = allTasksAbort[projectId].map((item) => {
          if (item._id === e._id) {
            return e;
          }
          return item;
        });
      });
      return {
        ...state,
        all_tasks: {
          ...state.all_tasks,
          ...allTasksAbort,
        },
      };
    case "DELETE_TASK_SUCCESS":
      let allTasksData = state.all_tasks;
      allTasksData[payload.project] = allTasksData[payload.project].filter(
        (item) => item._id !== payload.id
      );
      // const newAllTasksData = update(allTasksData, {
      //   $unset: [payload.id],
      // });
      return {
        ...state,
        all_tasks: {
          ...allTasksData,
        },
      };
    case "CREATE_TASK_SUCCESS":
      return {
        ...state,
        all_tasks: {
          ...state.all_tasks,
          [payload.data.project]: [
            ...(state?.all_tasks[payload.data.project] || []),
            payload.data,
          ],
        },
      };
    case "CREATE_TASK_COMMENT_SUCCESS":
      const tempdata = state.all_tasks;
      tempdata[payload.project] = tempdata[payload.project].map((item) => {
        if (item._id === payload.data.task) {
          return {
            ...item,
            comments: [...item.comments, payload.data],
          };
        }
        return item;
      });
      // tempdata[payload.data.task].comments = [
      //   ...tempdata[payload.data.task].comments,
      //   payload.data,
      // ];
      return {
        ...state,
        all_tasks: {
          ...tempdata,
        },
      };
    case "UPDATE_TASK_COMMENT_SUCCESS":
      let tempUpdateCommentData = state.all_tasks;
      tempUpdateCommentData[payload.project] = tempUpdateCommentData[
        payload.project
      ].map((item) => {
        if (item._id === payload.data.task) {
          return {
            ...item,
            comments: item.comments.map((comment) => {
              if (comment._id === payload.data._id) {
                return {
                  ...comment,
                  message: payload.data.message,
                  attachments: payload.data.attachments,
                  isedited: true,
                };
              }
              return comment;
            }),
          };
        }
        return item;
      });
      // tempUpdateCommentData[payload.data.task].comments = tempUpdateCommentData[
      //   payload.data.task
      // ].comments.map((item) => {
      //   return {
      //     ...item,
      //     message:
      //       item?._id === payload.data._id
      //         ? payload?.data?.message
      //         : item?.message,
      //     isedited: item?._id === payload.data._id ? true : item?.isedited,
      //   };
      // });
      return {
        ...state,
        all_tasks: {
          ...tempUpdateCommentData,
        },
      };
    case "DELETE_TASK_COMMENT_SUCCESS":
      let tempDeleteData = state.all_tasks;
      tempDeleteData[payload.project] = tempDeleteData[payload.project].map(
        (item) => {
          if (item._id === payload.data.task) {
            return {
              ...item,
              comments: item.comments.filter((e) => {
                return e._id !== payload.data._id;
              }),
            };
          }
          return item;
        }
      );
      // tempDeleteData[payload.data.task].comments = tempDeleteData[
      //   payload.data.task
      // ].comments.filter((e) => {
      //   return e._id !== payload.data._id;
      // });
      return {
        ...state,
        all_tasks: {
          ...tempDeleteData,
        },
      };
    case "UPDATE_TASK_COLUMN_NAME":
      let tempColumnData = state.all_tasks;
      if (payload.data.tasks.length) {
        payload.data.tasks.forEach((e) => {
          tempColumnData[payload.data.project._id] = tempColumnData[
            payload.data.project._id
          ].map((item) => {
            if (item._id === e._id) {
              return e;
            }
            return item;
          });
        });
      }
      // if (payload?.data?.tasks?.length) {
      //   payload.data.tasks.forEach((e) => {
      //     tempColumnData[e._id] = e;
      //   });
      // }
      return {
        ...state,
        all_tasks: {
          ...tempColumnData,
        },
      };
    case "ARCHIVE_TASK_SUCCESS":
      let tempArchiveData = state.all_tasks;
      tempArchiveData[payload.project] = tempArchiveData[payload.project].map(
        (item) => {
          if (item._id === payload.id) {
            return {
              ...item,
              is_archived: !item.is_archived,
            };
          }
          return item;
        }
      );
      // tempArchiveData[payload.id].is_archived =
      //   !tempArchiveData[payload.id].is_archived;
      return {
        ...state,
        all_tasks: {
          ...tempArchiveData,
        },
      };
    case "SET_TASKS_BY_DUE_DATE":
      return {
        ...state,
        events: {
          ...state.events,
          ...payload,
        },
      };
    case "UPDATE_TASK_COLUMN_CHANGE_SUCCESS":
      let tempColumnChangeData = state.all_tasks;
      const project_id = payload.data[0].project;
      tempColumnChangeData[project_id] = payload.data;
      // if (payload?.data?.length) {
      //   payload?.data?.forEach((e) => {
      //     tempColumnChangeData[e._id] = e;
      //   });
      // }
      return {
        ...state,
        all_tasks: {
          ...tempColumnChangeData,
        },
      };
    case "CREATE_SUB_TASK_SUCCESS":
      let tempSubTaskData = state.all_tasks;
      tempSubTaskData[payload.data.project] = tempSubTaskData[
        payload.data.project
      ].map((item) => {
        if (item._id === payload.data._id) {
          return {
            ...item,
            sub_tasks: payload.data.sub_tasks,
          };
        }
        return item;
      });
      return {
        ...state,
        all_tasks: {
          ...tempSubTaskData,
        },
      };
    case "UPDATE_SUB_TASK_SUCCESS":
      let tempUpdateSubTaskData = state.all_tasks;
      tempUpdateSubTaskData[payload.data.project] = tempUpdateSubTaskData[
        payload.data.project
      ].map((item) => {
        if (item._id === payload.data._id) {
          return {
            ...item,
            sub_tasks: payload.data.sub_tasks,
          };
        }
        return item;
      });
      return {
        ...state,
        all_tasks: {
          ...tempUpdateSubTaskData,
        },
      };
    case "DELETE_SUB_TASK_SUCCESS":
      let tempDeleteSubTaskData = state.all_tasks;
      tempDeleteSubTaskData[payload.data.project] = tempDeleteSubTaskData[
        payload.data.project
      ].map((item) => {
        if (item._id === payload.data._id) {
          return {
            ...item,
            sub_tasks: payload.data.sub_tasks,
          };
        }
        return item;
      });
      return {
        ...state,
        all_tasks: {
          ...tempDeleteSubTaskData,
        },
      };
    case "ADD_MEDIA_TO_TASK_SUCCESS":
      let tempAddMediaData = state.all_tasks;
      tempAddMediaData[payload.data.project] = tempAddMediaData[
        payload.data.project
      ].map((item) => {
        if (item._id === payload.data._id) {
          return {
            ...item,
            media: payload.data.media,
          };
        }
        return item;
      });
      return {
        ...state,
        all_tasks: {
          ...tempAddMediaData,
        },
      };
    case "DELETE_MEDIA_FROM_TASK_SUCCESS":
      let tempDeleteMediaData = state.all_tasks;
      tempDeleteMediaData[payload.data.project] = tempDeleteMediaData[
        payload.data.project
      ].map((item) => {
        if (item._id === payload.data._id) {
          return {
            ...item,
            media: payload.data.media,
          };
        }
        return item;
      });
      return {
        ...state,
        all_tasks: {
          ...tempDeleteMediaData,
        },
      };
    case "DELETE_TASK_CUSTOM_FIELD":
      const { id: taskId, customFieldId, projectId: pId } = payload;

      const foundTask = state.all_tasks?.[pId]?.map((task) => {
        if (task._id === taskId) {
          task.custom_fields = task.custom_fields?.filter(
            (f) => f._id !== customFieldId
          );
          return task;
        }
        return task;
      });

      return {
        ...state,
        all_tasks: {
          ...state.all_tasks,
          [pId]: foundTask,
        },
      };
    default:
      return state;
  }
};

export default tasksReducer;
