import { Tab } from "@headlessui/react";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import AdminNavbar from "../Navbars/AdminNavbar";
import AccountTab from "./AccountTab";
import ClientPortalTab from "./ClientPortalTab";
import CompanyTab from "./CompanyTab";
import GeneralTab from "./GeneralTab";

function NewSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const signInPath = location.pathname.startsWith("/client-collab");

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const user = useSelector((state) => state.auth.user);

  const query = new URLSearchParams(location.search);
  const initialTab = query.get("tab") || "account";

  const tabIndexMap = useMemo(
    () => ({
      account: 0,
      company: 1,
      clientPortal: 2,
      general: 3,
    }),
    []
  );

  const initialIndex = tabIndexMap[initialTab] || 0;

  const [selectedIndex, setSelectedIndex] = useState(initialIndex);

  useEffect(() => {
    const currentTab = Object.keys(tabIndexMap).find(
      (key) => tabIndexMap[key] === selectedIndex
    );
    navigate(`?tab=${currentTab}`, { replace: true });
  }, [navigate, selectedIndex, tabIndexMap]);

  const isClientPortalActivatedForClient =
    clientDetail?.data?.invitedClient &&
    clientDetail?.data?.invitedClient?.status &&
    clientDetail?.data?.invitedClient?.accepted;

  return (
    <>
      {!signInPath && (
        <AdminNavbar title="Settings" subtitle="Manage your account here" />
      )}

      <Tab.Group
        as="div"
        className="flex flex-col gap-6 px-6 py-6 mb-20 md:mb-0"
        selectedIndex={selectedIndex}
        onChange={setSelectedIndex}
      >
        <Tab.List className="flex items-center gap-2">
          <Tab className="px-3 py-2 text-xs font-semibold tracking-wide uppercase border-b-2 border-transparent rounded ui-selected:text-AccentRegular ui-selected:border-AccentRegular focus:outline-none ui-selected:bg-Neutral200 hover:text-AccentMediumDark text-AccentRegular">
            Account
          </Tab>
          {user?.role === "master_admin" && (
            <Tab className="px-3 py-2 text-xs font-semibold tracking-wide uppercase border-b-2 border-transparent rounded ui-selected:text-AccentRegular ui-selected:border-AccentRegular focus:outline-none ui-selected:bg-Neutral200 hover:text-AccentMediumDark text-AccentRegular">
              Company
            </Tab>
          )}
          <Tab className="px-3 py-2 text-xs font-semibold tracking-wide uppercase border-b-2 border-transparent rounded ui-selected:text-AccentRegular ui-selected:border-AccentRegular focus:outline-none ui-selected:bg-Neutral200 hover:text-AccentMediumDark text-AccentRegular">
            Client Portal
          </Tab>
          <Tab className="px-3 py-2 text-xs font-semibold tracking-wide uppercase border-b-2 border-transparent rounded ui-selected:text-AccentRegular ui-selected:border-AccentRegular focus:outline-none ui-selected:bg-Neutral200 hover:text-AccentMediumDark text-AccentRegular">
            General
          </Tab>
        </Tab.List>

        <Tab.Panels>
          <AccountTab />
          <CompanyTab />
          <ClientPortalTab
            isClientPortalActivated={isClientPortalActivatedForClient}
          />
          <GeneralTab />
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}

export default NewSettings;
