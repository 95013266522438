import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import loader from "../../assets/Images/loader-icon.svg";
import ThreeDots from "../../assets/Images/three-dots.svg";
import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../store/actions/authAction";
import {
  DeleteProject,
  DeleteTimeEntry,
  GetTimeEntryAction,
  UpdateProjectTitle,
} from "../../store/actions/timeTrackingAction";
import TrackingEditTimeEntryModal from "../Modals/TrackingEditTimeEntryModal";
import Pagination from "../Pagination";

const minutes_to_hhmm = (numberOfMinutes) => {
  //create duration object from moment.duration
  var duration = moment.duration(numberOfMinutes, "minutes");

  //calculate hours
  var hh =
    duration.years() * (365 * 24) +
    duration.months() * (30 * 24) +
    duration.days() * 24 +
    duration.hours();

  hh = hh <= 9 ? `0${hh}` : hh;

  //get minutes
  var mm =
    duration.minutes() <= 9 ? `0${duration.minutes()}` : duration.minutes();

  //return total time in hh:mm format
  return hh + ":" + mm + ":" + "00";
};

const colorList = ["Green400", "Orange400", "Red400", "Blue400", "Pink400"];

const assignColorToTag = (index) => {
  return colorList[index % colorList.length];
};

const TimeTrackingTextDetail = ({
  index,
  item,
  addNewTimeEntry,
  setAddNewTimeEntry,
  setIsOpenModal,
  setModalClientDetail,
}) => {
  const dispatch = useDispatch();

  const loginUser = useSelector((state) => state.auth.user);

  const [timeEntryList, setTimeEntryList] = useState([]);
  const titleDropDownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [events, setEvents] = useState([]);
  const [customTitle, setCustomTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [editTimeEntry, setEditTimeEntry] = useState("");
  const [page, setPage] = useState(1);
  const [newTitle, setNewTitle] = useState("");
  const [color, setColor] = useState("");
  const [titleData, setTitleData] = useState([]);

  const handleClick = async (id) => {
    setIsActive(!isActive);
    if (!timeEntryList?.data?.length) {
      setIsLoading(true);
      const timeEntry = await GetTimeEntryAction({
        page: page,
        perPage: 10,
        id: id,
      });
      setTimeEntryList(timeEntry);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const callTimeEntryGet = async () => {
      if (addNewTimeEntry && addNewTimeEntry === item?._id) {
        const timeEntry = await GetTimeEntryAction({
          page: page,
          perPage: 10,
          id: addNewTimeEntry,
        });
        setAddNewTimeEntry(null);
        setTimeEntryList(timeEntry);
      }
    };
    callTimeEntryGet();
  }, [addNewTimeEntry, item?._id, page, setAddNewTimeEntry]);

  useEffect(() => {
    const callGetTimeEntryAction = async () => {
      if (timeEntryList?.data?.length && timeEntryList?.currentPage !== page) {
        const timeEntry = await GetTimeEntryAction({
          page: page,
          perPage: 10,
          id: item._id,
        });
        setTimeEntryList(timeEntry);
      }
    };
    callGetTimeEntryAction();
  }, [
    dispatch,
    item._id,
    page,
    timeEntryList?.currentPage,
    timeEntryList?.data?.length,
  ]);

  const updateTitle = (id, title, color) => {
    const value = { project: id, title: title };
    dispatch(UpdateProjectTitle(value));
    dispatch(AddUserCategoryAction(title));
    setNewTitle(title);
    setColor(color);
  };

  useEffect(() => {
    let tempCategoryMerge = [];
    if (loginUser?.category?.length) {
      tempCategoryMerge = [
        ...new Set([...tempCategoryMerge, ...loginUser?.category]),
      ];
    }

    // remove duplicate
    tempCategoryMerge = tempCategoryMerge.reduce((acc, current) => {
      const x = acc.find((item) => item === current);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const tempCategory = tempCategoryMerge.map((ele, index) => {
      return {
        tag: ele,
        color: assignColorToTag(index),
      };
    });

    setTitleData(tempCategory);
  }, [item?.category, loginUser?.category]);

  const handleRemoveCategory = async (data) => {
    if (newTitle !== data) {
      const removedCategory = dispatch(RemoverUserCategoryAction(data));

      if (removedCategory?.data) {
        setTitleData(titleData.filter((e) => e !== data));
      }
    } else {
      toast.error("Can't delete category, as it is selected title");
    }
  };

  useEffect(() => {
    // First, we sort the entries by date in descending order.
    const sortedData = [...(timeEntryList?.data ?? [])].sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    // Then, we group the sorted entries by their date.
    const groupedByDate = sortedData.reduce((acc, cur) => {
      // We'll use the date as the key for the grouping.
      const dateKey = new Date(cur.date).toISOString().split("T")[0]; // Formats the date as YYYY-MM-DD
      if (!acc[dateKey]) {
        acc[dateKey] = []; // Initialize an array if it doesn't exist for the dateKey
      }
      acc[dateKey].push(cur); // Push the current item into the array for that date
      return acc;
    }, {});

    setEvents(groupedByDate); // Finally, we update the state to the new grouped object
  }, [timeEntryList]);

  const handleRemove = async (id) => {
    dispatch(DeleteProject(id));
  };

  const handleRemoveTimeEntry = async (id) => {
    setIsLoading(true);
    dispatch(DeleteTimeEntry(id, setTimeEntryList, item?._id, setIsLoading));
  };

  return (
    <div className="w-[850px] md:w-full mb-4" key={index}>
      <div className="border-Neutral300 rounded-xl border mx-5 lg:mx-12 5xl:mx-[96px] time-tracking-shadow bg-white cursor-pointer">
        <div
          style={{ zIndex: 99 - (index + 5) }}
          className={`relative flex items-center justify-between duration-300 flex-wrap`}
          onClick={() => handleClick(item?._id)}
        >
          <div className="flex items-center py-6 xl:mr-2 2xl:mr-0 justify-between  min-w-[450px] max-w-[450px] xl:min-w-[650px] xl:max-w-[650px] 3xl:min-w-[850px] 3xl:max-w-[850px]">
            <div className="flex">
              <div className="h-[40px] w-[40px] 5xl:h-16 5xl:w-16 mr-4 ml-8 rounded min-w-[40px]">
                {!item?.client?.profile ? (
                  <div
                    className={`border border-Neutral300 w-full h-full flex items-center rounded justify-center bg-AccentMediumLight text-Accent focus-visible:outline-none`}
                  >
                    <span className="text-[20px] 5xl:text-[24px] flex items-center rounded justify-center font-bold">
                      {item?.client?.client_name[0]}
                    </span>
                  </div>
                ) : (
                  <img
                    className={`rounded w-full h-full focus-visible:outline-none`}
                    src={item?.client?.profile_path}
                    alt="UserButtons"
                  />
                )}
              </div>
              <div>
                <h4 className="text-base font-semibold text-Neutral900">
                  {item?.title}
                </h4>
                <p>
                  <span className="text-xs font-medium text-Neutral700">
                    {item?.client?.client_name}
                  </span>
                </p>
              </div>
            </div>
            {/* Support dropdown */}
            <div>
              <Menu
                as="div"
                className="relative z-20 inline-block text-left"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="flex items-center">
                  <Menu.Button
                    ref={titleDropDownRef}
                    className="relative inline-flex justify-center w-full mx-5 rounded focus-visible:outline-none popup-btn my-focus"
                  >
                    <span className="">
                      <div className="flex items-center px-2 py-3 rounded cursor-pointer hover:bg-Neutral300 group">
                        <div className="flex items-center justify-start pr-2">
                          <span
                            className={`w-2 h-2 rounded bg-${
                              color
                                ? color
                                : titleData?.find(
                                    (ele) =>
                                      ele?.tag === item?.tracking_category ||
                                      ele?.tag === newTitle
                                  )?.color || "AccentRegular"
                            }`}
                          ></span>
                        </div>
                        <p className="text-xs font-semibold text-left uppercase text-Neutral700 group-hover:text-AccentRegular">
                          {!newTitle ? item?.tracking_category : newTitle}
                        </p>
                      </div>
                    </span>
                  </Menu.Button>
                </div>
                {item?.is_admin === true ? (
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right max-h-[30vh] overflow-y-auto absolute z-[999] top-10 right-[-20px] pt-4 pb-2 w-[216px] px-2 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
                      <div className="">
                        <input
                          className="w-full px-2 mb-2 text-xs font-normal placeholder:text-Neutral600 text-Neutral700 focus:outline-none"
                          placeholder="+Add New Category"
                          value={customTitle}
                          onKeyUp={(e) => {
                            e.stopPropagation();
                          }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            if (e.key === "Enter") {
                              if (
                                titleData.filter((t) => t.tag === customTitle)
                                  .length === 0
                              ) {
                                const newColor = assignColorToTag(
                                  titleData?.length
                                );
                                setTitleData([
                                  ...titleData,
                                  {
                                    tag: customTitle,
                                    color: newColor,
                                  },
                                ]);
                                updateTitle(item?._id, customTitle, newColor);
                                setCustomTitle("");
                                setTimeout(() => {
                                  titleDropDownRef?.current?.click();
                                }, 0);
                              } else {
                                toast.error("Title already exists");
                              }
                            }
                          }}
                          onChange={(e) => {
                            setCustomTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div className="project-title-divide">
                        <div className="relative mb-2 overflow-hidden overflow-y-auto border-0 currancy-scrollbar scrollbar-w-4">
                          <h4 className="text-Neutral600 font-semibold text-[10px] pt-3 ml-2 mr-5 border-t-[1px] border-Neutral200">
                            Categories
                          </h4>
                          {titleData
                            .filter((title) =>
                              title.tag
                                ?.toLowerCase()
                                .startsWith(customTitle.toLowerCase())
                            )
                            .map((items, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex items-center p-2 mt-2 duration-300 rounded cursor-pointer hover:bg-Neutral200 group"
                                  onClick={(e) => {
                                    updateTitle(
                                      item?._id,
                                      items.tag,
                                      items?.color
                                    );
                                    setTimeout(() => {
                                      titleDropDownRef?.current?.click();
                                      e.stopPropagation();
                                    }, 0);
                                  }}
                                >
                                  <div className="flex items-center w-4 justify-left">
                                    <span
                                      className={`w-2 h-2 rounded ${
                                        items?.color ? `bg-${items?.color}` : ""
                                      }`}
                                    ></span>
                                  </div>
                                  <p className="w-full text-xs font-normal rounded cursor-pointer text-Neutral800 group-hover:text-Neutral900">
                                    {items?.tag}
                                  </p>
                                  <button
                                    className="text-transparent group-hover:text-Red500"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleRemoveCategory(items?.tag);
                                    }}
                                  >
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.23096 5.04419H4.33181H13.1386"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              );
                            })}
                        </div>
                        {customTitle && customTitle !== "" && (
                          <div
                            className="bottom-0 flex items-center bg-AccentLight p-2 rounded duration-300 w-[200px] cursor-pointer"
                            type="button"
                            onClick={(e) => {
                              if (
                                titleData.filter((t) => t.tag === customTitle)
                                  .length === 0
                              ) {
                                const newColor = assignColorToTag(
                                  titleData?.length
                                );
                                setTitleData([
                                  ...titleData,
                                  {
                                    tag: customTitle,
                                    color: newColor,
                                  },
                                ]);
                                updateTitle(item?._id, customTitle, newColor);
                                setCustomTitle("");
                                setTimeout(() => {
                                  titleDropDownRef?.current?.click();
                                }, 0);
                              } else {
                                toast.error("Title already exists");
                              }
                            }}
                          >
                            <div className="flex items-center">
                              <p className="text-xs font-normal rounded cursor-pointer text-AccentDark">
                                Create
                              </p>
                              <p className="text-white ml-[6px] py-[6px] px-2 rounded font-normal text-xs bg-AccentRegular">
                                {customTitle}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                ) : (
                  ""
                )}
              </Menu>
            </div>
          </div>
          <div className="text-center max-w-[132px] w-full py-6">
            <div className="relative flex items-center justify-start text-base font-semibold text-center text-Neutral900">
              {item?.time_contributors?.map((ele, i) => {
                return (
                  <div className="inline-block -mr-3 min-w-[36px]" key={i}>
                    {/* <img
                className=" hover:z-50 duration-300 relative border-[2px] rounded-[50%] border-white z-50"
                src={MarketingSiteUser1}
                alt=""
              /> */}
                    {!ele?.profile ? (
                      <div className="hover:z-50 duration-300 relative border-[2px] rounded-[50%] border-white z-50 w-[36px] h-[36px]">
                        <div className="absolute -z-10 top-0 w-full h-full rounded-[50%] border-[0.5px] border-black border-opacity-5 bg-Neutral400"></div>
                        <span className="flex items-center justify-center h-full">
                          {ele?.user_name[0].toUpperCase()}
                        </span>
                      </div>
                    ) : (
                      <div className="hover:z-50 duration-300 relative border-[2px] rounded-[50%] border-white z-50 w-[36px] h-[36px]">
                        <div className="absolute -z-10 top-0 w-full h-full rounded-[50%] border-[0.5px] border-black border-opacity-5 bg-Neutral400"></div>
                        <img
                          className="rounded-[50%]"
                          src={item?.base_url + ele?.profile}
                          alt="UserButtons"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex max-w-[359px] justify-between items-center pr-10 py-6">
            <div className="flex w-full">
              <div className="max-w-[132px] w-full text-right text-Neutral900 font-semibold text-base ml-8 xl:ml-[0px]">
                <div className="p-1 text-center placeholder:text-Neutral900">
                  <p>
                    {(item?.is_admin === true && item?.is_member === true) ||
                    item?.is_manager === true
                      ? item?.client?.currency?.symbol && item?.total_amount
                        ? item?.client?.currency?.symbol
                        : null
                      : null}
                    {(item?.is_admin === true && item?.is_member === true) ||
                    item?.is_manager === true
                      ? item?.total_amount
                        ? item?.total_amount.toFixed(2)
                        : null
                      : null}
                  </p>
                </div>
              </div>
              <div className="max-w-[132px] w-full text-right text-Neutral900 font-semibold text-base pl-[10px] 3xl:pl-[0px]">
                <div className="p-1 text-center placeholder:text-Neutral900">
                  <p>{item?.total_time ? item?.total_time : "00:00"}</p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end">
              {/* Support dropdown */}
              <Menu as="div" className="relative inline-block">
                <div className="flex items-center">
                  {item?.is_admin === true ? (
                    <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none popup-btn">
                      <span className="focus-visible:outline-none">
                        <img
                          className="cursor-pointer"
                          src={ThreeDots}
                          alt=""
                        />
                      </span>
                    </Menu.Button>
                  ) : null}
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute bottom-0 right-[-60px] w-[109px] rounded-[4px] Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none innermenu-main">
                    <Menu.Item>
                      <Menu
                        as="div"
                        className="relative"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <div className="">
                          <Menu.Button className="relative w-full focus-visible:outline-none popup-btn">
                            <span className="focus-visible:outline-none">
                              <div className="py-[11px] px-[15px] flex justify-between delet-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
                                <h3 className="flex text-xs text-Neutral800 group-hover:text-Red400">
                                  <svg
                                    className="mr-2"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M3.23096 5.04419H4.33181H13.1386"
                                      stroke="#5A5A66"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
                                      stroke="#5A5A66"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Delete
                                </h3>
                              </div>
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className="origin-top-right absolute -top-[80px] right-[-20px] pt-4 pb-6 w-[238px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                            onClick={(e) => {
                              if (e.target === e.currentTarget) {
                                e.stopPropagation();
                              }
                            }}
                          >
                            <div className="">
                              <p className="pb-2 text-xs font-semibold text-Neutral700">
                                Are you sure?
                              </p>
                            </div>
                            <div
                              className="overflow-hidden border-0"
                              onClick={() => handleRemove(item?._id)}
                            >
                              <h3 className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600">
                                Yes, Delete
                              </h3>
                            </div>
                            <div className="overflow-hidden border-0 !border-transparent divide-0">
                              <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                                No, Please Keep It
                              </h3>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <div
          className={
            isActive
              ? "bg-Neutral100 rounded-xl mx-4 pl-4 block border-Neutral300 border-[.25px] mb-4"
              : "hidden"
          }
        >
          {isLoading ? (
            <div className="flex justify-center mt-10 mb-10">
              <img
                src={loader}
                alt="loader"
                className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
              />
            </div>
          ) : (
            <>
              {timeEntryList?.data?.length ? (
                Object.entries(events)
                  .sort((a, b) =>
                    moment(b[0], "YYYY-MM-DD").diff(moment(a[0], "YYYY-MM-DD"))
                  )
                  .map(([key, value]) => {
                    // Calculate the total time for the date
                    let time_in_min = value.reduce((total, current) => {
                      const timeParts = current.time.split(":");
                      return (
                        total +
                        moment
                          .duration({
                            hours: timeParts[0],
                            minutes: timeParts[1],
                          })
                          .asMinutes()
                      );
                    }, 0);

                    const displayable = minutes_to_hhmm(time_in_min);
                    return (
                      <div key={key}>
                        <div className="5xl:mx-[96px] pt-6  border-Neutra400 flex items-center justify-between">
                          <div className="max-w-[347px] w-full flex items-center xl:mr-2 2xl:mr-0 justify-start">
                            <div className="max-w-[115px] w-full">
                              <h4 className="text-xs py-[6px] px-3 inline-block rounded-[20px] text-white bg-Neutral900">
                                {moment(key).format("MMM DD, YYYY")}
                              </h4>
                            </div>
                            <div className=""></div>
                          </div>
                          <div className="flex max-w-[340px] w-full items-center">
                            <div className="w-[132px] text-center text-Neutral800 font-medium text-base pl-[10px] 3xl:pl-[0px]">
                              <p
                                className={`text-xs py-[6px] px-3 inline-block rounded-[20px] text-Neutral900 bg-Neutral300 font-semibold ${
                                  value
                                    ?.filter(
                                      (value) =>
                                        (item?.is_admin === true &&
                                          item?.is_member === true) ||
                                        item?.is_manager === true
                                    )
                                    .reduce(
                                      (acc, cuu) => (acc = acc + cuu?.amount),
                                      0
                                    ) === 0 && "hidden"
                                }`}
                              >
                                {item?.client?.currency?.symbol
                                  ? item?.client?.currency?.symbol
                                  : null}

                                {value
                                  ?.filter(
                                    (value) =>
                                      (item?.is_admin === true &&
                                        item?.is_member === true) ||
                                      item?.is_manager === true
                                  )
                                  .reduce(
                                    (acc, cuu) => (acc = acc + cuu?.amount),
                                    0
                                  )
                                  .toFixed(2)}
                              </p>
                            </div>
                            <div className="w-[132px] text-center text-Neutral800 font-medium text-base pl-[10px] 3xl:pl-[0px]">
                              <p className="text-xs py-[6px] px-3 inline-block rounded-[20px] text-Neutral900 bg-Neutral300 font-semibold">
                                {displayable ? displayable : "00:00:00"}
                              </p>
                            </div>
                          </div>
                        </div>
                        {value.map((element, i) => {
                          return (
                            <div className="flex items-center justify-between rounded-xl border-Neutral400">
                              <div className="max-w-[347px] w-full flex items-center py-6 xl:mr-2 2xl:mr-0 justify-start">
                                <div className="">
                                  <h3 className="h-6 w-6 mr-[27px] ml-[7px] rounded-[50%] font-semibold flex justify-center items-center text-Neutral700 text-xs py-[6px] px-2 bg-Neutral200 border-Neutral300 border-[0.25px]">
                                    {i + 1}
                                  </h3>
                                </div>
                                <div className="">
                                  <h4 className="text-base font-medium text-Neutral900">
                                    {element?.title}
                                  </h4>
                                  <div className="flex items-center">
                                    {element?.category?.map((items, index) => {
                                      if (index <= 3) {
                                        return (
                                          <p className="uppercase text-[10px] text-Neutral700 px-2 py-[2px] border border-Neutral300 rounded-[6px] mr-1 mt-2">
                                            {items}
                                          </p>
                                        );
                                      }
                                    })}
                                    {element?.category?.length >= 3 ? (
                                      <span className="mt-2 ml-2 text-xs">
                                        +{element?.category?.length - 3}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="max-w-[132px] w-full items-center text-left text-Neutral900 font-semibold text-base flex">
                                {element?.user?.profile_path ? (
                                  <>
                                    <img
                                      className="mr-2  duration-300 relative border-[2px] rounded-[50%] border-white z-50 w-[36px] h-[36px]"
                                      src={element?.user?.profile_path}
                                      alt=""
                                    />
                                    <p className="text-xs font-medium whitespace-pre text-Neutral900">
                                      {element?.user?.user_name}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={`border mr-2 border-Neutral300 md:w-[36px] md:h-[36px] h-[36px] w-[36px] flex items-center justify-center rounded-full bg-Neutral400 text-Neutral700  focus-visible:outline-none group-hover:bg-indigo-500 bg-blend-overlay group-hover:bg-opacity-50`}
                                    >
                                      <span className="flex items-center justify-center w-full h-full font-bold uppercase border rounded-full border-Miscgrey">
                                        {element?.user?.user_name[0]}
                                      </span>
                                    </div>
                                    <p className="text-xs font-medium whitespace-pre text-Neutral900">
                                      {element?.user?.user_name}
                                    </p>
                                  </>
                                )}
                              </div>
                              <div className="flex max-w-[340px] w-full items-center">
                                <div className="max-w-[132px] w-full text-Neutral800 font-medium text-base pl-[10px] 3xl:pl-[0px] text-center">
                                  {(element?.amount &&
                                    item?.is_admin === true &&
                                    item?.is_member === true) ||
                                  (element?.amount &&
                                    item?.is_manager === true) ? (
                                    <input
                                      className="w-[80px] text-center p-1 placeholder:text-Neutral800 bg-Neutral100"
                                      type="text"
                                      value={`${
                                        item?.client?.currency?.symbol
                                          ? item?.client?.currency?.symbol
                                          : ""
                                      } ${element?.amount}`}
                                    />
                                  ) : null}
                                </div>
                                <div className="max-w-[132px] w-full text-Neutral800 font-medium text-base pl-[10px] 3xl:pl-[0px] text-center">
                                  <input
                                    className="w-[80px] text-center p-1 placeholder:text-Neutral800 bg-Neutral100"
                                    type="text"
                                    value={
                                      element?.time
                                        ? moment(element?.time, "HH:mm").format(
                                            "HH:mm:ss"
                                          ) === "Invalid date"
                                          ? `${element?.time}:00`
                                          : moment(
                                              element?.time,
                                              "HH:mm"
                                            ).format("HH:mm:ss")
                                        : "00:00:00"
                                    }
                                  />
                                </div>

                                <Menu
                                  as="div"
                                  className="relative inline-block"
                                >
                                  <div className="flex items-center">
                                    {(item?.is_admin === true &&
                                      item?.is_member === true) ||
                                    (item?.is_admin === false &&
                                      item?.is_member === true &&
                                      loginUser?._id === element?.user?._id) ||
                                    item?.is_manager === true ? (
                                      <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none popup-btn">
                                        <span className="focus-visible:outline-none">
                                          <img
                                            className="cursor-pointer"
                                            src={ThreeDots}
                                            alt=""
                                          />
                                        </span>
                                      </Menu.Button>
                                    ) : null}
                                  </div>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="origin-top-right absolute bottom-0 right-[-60px] w-[109px] rounded-[4px] Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none innermenu-main">
                                      <div className="">
                                        <div
                                          className="relative w-full focus-visible:outline-none popup-btn"
                                          onClick={() => {
                                            setEditTimeEntry(element);
                                            setIsOpen(true);
                                          }}
                                        >
                                          <span className="focus-visible:outline-none">
                                            <div className="py-[11px] px-[15px] flex justify-between edit-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
                                              <h3 className="flex text-xs text-Neutral800 group-hover:text-AccentRegular">
                                                <svg
                                                  className="mr-2"
                                                  width="16"
                                                  height="16"
                                                  viewBox="0 0 16 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M7.77149 4.12354H4.06033C3.77911 4.12354 3.50941 4.23525 3.31056 4.4341C3.11171 4.63295 3 4.90265 3 5.18387V12.6062C3 12.8874 3.11171 13.1571 3.31056 13.356C3.50941 13.5548 3.77911 13.6665 4.06033 13.6665H11.4827C11.7639 13.6665 12.0336 13.5548 12.2324 13.356C12.4313 13.1571 12.543 12.8874 12.543 12.6062V8.89503"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                  <path
                                                    d="M11.7469 3.32916C11.9578 3.11825 12.2439 2.99976 12.5422 2.99976C12.8404 2.99976 13.1265 3.11825 13.3374 3.32916C13.5483 3.54007 13.6668 3.82613 13.6668 4.12441C13.6668 4.42268 13.5483 4.70874 13.3374 4.91966L8.30084 9.95623L6.18018 10.4864L6.71034 8.36574L11.7469 3.32916Z"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  />
                                                </svg>
                                                Edit
                                              </h3>
                                            </div>
                                          </span>
                                        </div>
                                      </div>
                                      <Menu.Item>
                                        <div className="">
                                          <div
                                            className="relative w-full focus-visible:outline-none popup-btn"
                                            onClick={async () => {
                                              await handleRemoveTimeEntry(
                                                element?._id
                                              );
                                            }}
                                          >
                                            <span className="focus-visible:outline-none">
                                              <div className="py-[11px] px-[15px] flex justify-between delet-icon group duration-300 download hover:bg-Neutral200 overflow-hidden rounded-b-[4px] w-full">
                                                <h3 className="flex text-xs text-Neutral800 group-hover:text-Red400">
                                                  <svg
                                                    className="mr-2"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M3.23096 5.04419H4.33181H13.1386"
                                                      stroke="#5A5A66"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                    <path
                                                      d="M5.98234 5.04469V4.02247C5.98234 3.75136 6.09832 3.49135 6.30477 3.29965C6.51122 3.10794 6.79123 3.00024 7.08319 3.00024H9.2849C9.57687 3.00024 9.85687 3.10794 10.0633 3.29965C10.2698 3.49135 10.3858 3.75136 10.3858 4.02247V5.04469M12.037 5.04469V12.2002C12.037 12.4714 11.9211 12.7314 11.7146 12.9231C11.5082 13.1148 11.2281 13.2225 10.9362 13.2225H5.43191C5.13994 13.2225 4.85994 13.1148 4.65349 12.9231C4.44704 12.7314 4.33105 12.4714 4.33105 12.2002V5.04469H12.037Z"
                                                      stroke="#5A5A66"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  Delete
                                                </h3>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
              ) : (
                <div className="py-[24px]">
                  <span className="text-sm font-normal text-Neutral700">
                    There are currently no time entries for this project.
                  </span>
                  <br />
                  <button
                    className="mt-[20px] hover:bg-Neutral200 text-sm text-center inline-block text-Neutral800 bg-Neutral300 font-semibold w-full 5xl:max-w-[180px] max-w-[186px] py-3 rounded focus:outline-0 focus:outline-Neutral200 focus-visible:outline-0 transition duration-300 ease-in-out leading-6"
                    onClick={() => {
                      setModalClientDetail(item);
                      setIsOpenModal(true);
                    }}
                  >
                    <span className="relative z-10 drop-shadow-Texts">
                      Add New Time Entry
                    </span>
                  </button>
                </div>
              )}
              {timeEntryList?.data?.length ? (
                <div className="5xl:mx-[96px] pt-6 border-Neutra400 ml-[10px] mr-[55px] items-center justify-between">
                  <Pagination
                    currantPage={timeEntryList?.currentPage}
                    totalData={timeEntryList?.totalRecord}
                    hasPrevPage={timeEntryList?.hasPrevPage}
                    hasNextPage={timeEntryList?.hasNextPage}
                    fromRecord={timeEntryList?.fromRecord}
                    outOfCounter1={timeEntryList?.data?.length}
                    setPage={setPage}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      {isOpen && (
        <TrackingEditTimeEntryModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          editTimeEntry={editTimeEntry}
          setTimeEntryList={setTimeEntryList}
        />
      )}
    </div>
  );
};

export default TimeTrackingTextDetail;
