import Checkmark from "../../assets/Images/Checkmark.svg";
import { cn } from "../../utils";

function MeetingFilter({ filterBtn, setFilterBtn, addFilter, setAddFilter }) {
  const handleChange = (name, value) => {
    if (addFilter?.[name]?.includes(value)) {
      const removedFilter = addFilter[name]?.filter((res) => res !== value);
      setAddFilter({
        ...addFilter,
        [name]: removedFilter,
      });
    } else {
      setAddFilter({
        ...addFilter,
        [name]: [...addFilter[name], value],
      });
    }
  };

  const handleClearFilter = (e) => {
    setAddFilter({
      date: [],
      time: [],
      length: [],
    });
  };

  const renderClearClassNames = () => {
    if (addFilter) {
      for (const item in addFilter) {
        if (addFilter[item].length > 0) {
          return "!text-[var(--accent-color)] text-xs";
        }
      }
    }
    return "text-Neutral500 text-xs";
  };

  return (
    <div
      className={`fixed bg-[#292933B2] top-0 z-[999] left-0 w-full h-screen flex transition-all ease-in-out ${
        filterBtn ? "duration-500" : "invisible"
      }`}
    >
      <div
        className={`w-[calc(100%-352px)] ${filterBtn ? "" : ""}`}
        onClick={() => setFilterBtn(false)}
      ></div>
      <div
        className={cn(
          `h-screen overflow-scroll relative bg-Neutral100 w-fit transition-all duration-500 ease-in-out`,
          filterBtn ? "right-0" : "-right-[100%]"
        )}
      >
        <div className="text-Neutral700 p-8 w-[344px]">
          <div className="flex items-center justify-between">
            <span className="text-sm font-bold text-Neutral800">FILTER</span>
            <button
              className={renderClearClassNames()}
              onClick={handleClearFilter}
            >
              CLEAR
            </button>
          </div>

          {/* Meeting date filter */}
          <div className="pt-9">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Meeting Dates
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                This Week
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.date?.includes("this_week")}
                  onChange={(e) => handleChange("date", "this_week")}
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                This Month
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.date?.includes("this_month")}
                  onChange={(e) => handleChange("date", "this_month")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                Next Month
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.date?.includes("next_month")}
                  onChange={(e) => handleChange("date", "next_month")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* Meeting time filter */}
          <div className="pt-9">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Meeting Time
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                Morning
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.time?.includes("morning")}
                  onChange={(e) => handleChange("time", "morning")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                Afternoon
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.time?.includes("afternoon")}
                  onChange={(e) => handleChange("time", "afternoon")}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                Evening
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.time?.includes("evening")}
                  onChange={(e) => handleChange("time", "evening")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/* Meeting length filter */}
          <div className="pt-9">
            <span className="text-Neutral500 text-xs font-semibold leading-[16.34px] uppercase">
              Meeting Length
            </span>
            <div className="flex items-center justify-between pt-5">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                30min
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.length?.includes("30min")}
                  onChange={(e) => handleChange("length", "30min")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                60min
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.length?.includes("60min")}
                  onChange={(e) => handleChange("length", "60min")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-[20px]">
              <span className="block text-xs font-medium capitalize text-Neutral700">
                90min
              </span>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  checked={addFilter?.length?.includes("90min")}
                  onChange={(e) => handleChange("length", "90min")}
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-[1.5px] cursor-pointer hover:bg-AccentLight hover:border-AccentRegular custom-chackbox rounded-md border-Neutral300 w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingFilter;
