import success from "../../assets/Images/Success-icon.svg";
import alertClose from "../../assets/Images/alert-Close.svg";
import profileImg from "../../assets/Images/profile-img.svg";

function SuccessAlert({ titleDescription, description }) {
  return (
    <div className="w-[525px] h-[172px] flex pl-6 pt-6 pb-6 alert-shadow relative items-center bg-white">
      <div className="h-[124px] min-w-[200px] rounded-lg overflow-hidden relative mr-6">
        <img src={success} alt="" />
        <img className="absolute bottom-0 right-0" src={profileImg} alt="" />
      </div>
      <div className="">
        <p className="text-sm font-bold text-Neutral900">{titleDescription}</p>
        <p
          className="pt-4 pr-8 text-xs font-medium text-AccentRegular"
          style={{ textTransform: "initial" }}
        >
          {description}
        </p>
      </div>
      <img
        className="absolute w-6 h-6 top-[16px] right-[16px] cursor-pointer"
        src={alertClose}
        alt=""
      />
    </div>
  );
}

export default SuccessAlert;
