import React from "react";

function PauseIcon({
  width = 24,
  className = "",
  color = "var(--accent-color)",
}) {
  return (
    <svg
      className={className}
      width={width}
      height={width}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4H6V20H10V4Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 4H14V20H18V4Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PauseIcon;
