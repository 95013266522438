import { Tab } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UpdateProfileAction } from "../../store/actions/authAction";
import { generateInitials } from "../../utils";
import Spinner from "../Spinner";
import ChartArea from "./ChartArea";
import Infographic from "./Infographic";
import Stats from "./Stats";
import TaskSection from "./TaskSection";
import TrackedTime from "./TrackedTime";
import EditIcon from "./icons/EditIcon";

function OverviewContent() {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.auth.user);

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const fileInputRef = useRef(null);

  const handleImageUpload = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      dispatch(
        UpdateProfileAction(
          {
            ...loggedInUser,
            profile_new_image: file,
            is_profile_remove: false,
          },
          setLoading,
          null,
          true
        )
      );
    }
  };

  return (
    <Tab.Panel className="flex flex-col gap-8">
      <section className="flex flex-col gap-6">
        <TaskSection />

        <div className="flex flex-col gap-5 md:flex-row">
          <ChartArea />

          {/* Stats Layout */}
          <div className="flex flex-col flex-1 gap-5">
            <div className="flex items-center gap-2 md:items-stretch md:justify-start">
              <div className="w-[35%] aspect-square overflow-hidden bg-Neutral100 rounded-lg border border-Neutral-200 grid place-items-center relative group">
                {selectedFile ? (
                  <img
                    src={preview}
                    alt="Preview"
                    className="object-cover size-full"
                  />
                ) : loggedInUser?.profile_path ? (
                  <img
                    src={loggedInUser.profile_path}
                    alt={loggedInUser?.user_name}
                    className="object-cover size-full"
                  />
                ) : (
                  <span className="px-4 py-3 text-3xl font-bold text-Neutral700">
                    {generateInitials(loggedInUser?.user_name)}
                  </span>
                )}
                {loading ? (
                  <div className="absolute inset-0 flex flex-col-reverse items-end p-2 ml-auto bg-black/25 justify-items-end">
                    <Spinner
                      className="size-8"
                      spinnerBg="#757580"
                      thumbColor="#FFFFFF"
                    />
                  </div>
                ) : (
                  <div className="absolute inset-0 flex-col-reverse items-end hidden p-2 ml-auto bg-black/25 group-hover:flex justify-items-end">
                    <button
                      className="text-Neutral000"
                      onClick={handleImageUpload}
                    >
                      <EditIcon className="size-8" />
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileChange}
                  hidden
                />
              </div>

              <Stats />
            </div>

            <TrackedTime />
          </div>
        </div>
      </section>

      <Infographic />
    </Tab.Panel>
  );
}

export default OverviewContent;
