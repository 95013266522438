import React from "react";

function PaperclipIcon({ width = 20, className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8334 9.85319L10.0963 15.5903C9.39344 16.2931 8.44019 16.688 7.44623 16.688C6.45226 16.688 5.49901 16.2931 4.79617 15.5903C4.09334 14.8874 3.69849 13.9342 3.69849 12.9402C3.69849 11.9463 4.09334 10.993 4.79617 10.2902L10.5333 4.55308C11.0018 4.08452 11.6373 3.82129 12.3 3.82129C12.9626 3.82129 13.5981 4.08452 14.0667 4.55308C14.5352 5.02164 14.7985 5.65714 14.7985 6.31978C14.7985 6.98242 14.5352 7.61793 14.0667 8.08649L8.32334 13.8236C8.08906 14.0579 7.77131 14.1895 7.43998 14.1895C7.10866 14.1895 6.79091 14.0579 6.55663 13.8236C6.32235 13.5893 6.19074 13.2716 6.19074 12.9402C6.19074 12.6089 6.32235 12.2912 6.55663 12.0569L11.8567 6.76302"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PaperclipIcon;
