import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function useBgColor() {
  const userProfile = useSelector((state) => state?.auth?.user);
  const [bgColor, setBgColor] = useState("#F0F0FF"); // for, bg-AccentLight : #F0F0FF 

  useEffect(() => {
    const bg_color = userProfile?.brandIdentity?.bg_color;
    if (bg_color) {
      return setBgColor(bg_color);
    }
  }, [userProfile?.brandIdentity?.bg_color, bgColor]);

  return bgColor;
}

export default useBgColor;
