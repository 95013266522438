import axios from "axios";
import { toast } from "react-toastify";

export const getIdentityAction = (user, setLoding) => {
  return async (dispatch) => {
    try {
      // setLoding({
      //   bg_color: true,
      //   accent_color: true,
      //   logo: true,
      // });
      let { data } = await axios.post(
        "api/v1/brand-identity/get-brand-identity",
        user
      );
      if (data?.data?.bg_color) {
        dispatch({
          type: "UPDATE_BRAND_BG_COLOR",
          payload: { backgroundColor: data.data.bg_color },
        });
      }
      if (data?.data?.accent_color) {
        dispatch({
          type: "UPDATE_BRAND_ACCENT_COLOR",
          payload: { accentColor: data.data.accent_color },
        });
      }
      if (data?.data?.logo) {
        dispatch({
          type: "UPDATE_BRAND_LOGO_URL",
          payload: { brandLogoUrl: data.data.logo },
        });
      }
      // setLoding({
      //   bg_color: false,
      //   accent_color: false,
      //   logo: false,
      // });
    } catch (error) {
      // setLoding({
      //   bg_color: false,
      //   accent_color: false,
      //   logo: false,
      // });
      console.log("Error :", error);
    }
  };
};

export const UpdateIdentityAction = (
  formData,
  authSelector,
  type,
  setLoding
) => {
  return async (dispatch) => {
    try {
      setLoding({
        bg_color: type === "bg_color" ? true : false,
        accent_color: type === "accent_color" ? true : false,
        logo: type === "logo" ? true : false,
      });
      let { data } = await axios.post(
        "api/v1/brand-identity/update-brand-identity",
        formData
      );
      if (data?.data) {
        if (type === "bg_color") {
          dispatch({
            type: "UPDATE_BRAND_BG_COLOR",
            payload: { backgroundColor: data?.data?.bg_color },
          });
          setLoding({
            bg_color: false,
          });
        }

        if (type === "accent_color") {
          dispatch({
            type: "UPDATE_BRAND_ACCENT_COLOR",
            payload: { accentColor: data?.data?.accent_color },
          });
          setLoding({
            accent_color: false,
          });
        }
        if (type === "logo") {
          dispatch({
            type: "UPDATE_BRAND_LOGO_URL",
            payload: { brandLogoUrl: data?.data?.logo?.path },
          });
          setLoding({
            logo: false,
          });
        }
        dispatch({
          type: "SET_USER_DATA",
          payload: {
            data: {
              ...authSelector,
              brandIdentity: data?.data,
            },
          },
        });
      }
    } catch (error) {
      setLoding({
        bg_color: false,
        accent_color: false,
        logo: false,
      });
      console.log("Error :", error);
      toast.error(error?.response?.data?.meassge || "Something went wrong");
    }
  };
};
