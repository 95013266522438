const FilledStarIcon = ({ size = 12, ...props }) => {
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.00003 1.37744L7.50211 4.4205L10.8611 4.91147L8.43058 7.27883L9.00419 10.6233L6.00003 9.04341L2.99586 10.6233L3.56947 7.27883L1.13892 4.91147L4.49794 4.4205L6.00003 1.37744Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default FilledStarIcon;
