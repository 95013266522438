import { Formik } from "formik";
import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import Close from "../../assets/Images/Close-gray.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { CreateAssetsActions } from "../../store/actions/assetsAction";
import UploadAssetAndDocumentFiles from "../Buttons/UploadAssetAndDocumentFiles";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const EditAssetSchema = Yup.object().shape({
  title: Yup.string().required("Title Subject Required"),
  file: Yup.array().when("file_type", {
    is: "file",
    then: Yup.array()
      .min(1)
      .required("You must select at list one file")
      .nullable(),
  }),
  url: Yup.array().when("file_type", {
    is: "url",
    then: Yup.lazy((val) =>
      Array.isArray(val) && val?.length > 0
        ? Yup.array().of(
            Yup.string()
              .matches(
                /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
                "Enter correct url!"
              )
              .nullable()
          )
        : Yup.array().min(1, "You must enter at list one url")
    ),
  }),
});

function AddEditAssetsModal({
  isModal,
  setModal,
  type,
  item,
  clientId,
  dragFile,
  setDragFile,
}) {
  const inputArr = [""];
  const dispatch = useDispatch();
  const [arr, setArr] = useState(inputArr);

  const addInput = () => {
    setArr((s) => {
      return [...s, ""];
    });
  };

  return (
    <Modal
      isOpen={isModal}
      onRequestClose={() => {
        setModal(false);
        setDragFile(null);
      }}
      style={customStyles}
      closeTimeoutMS={100}
      contentLabel="Edit Assets Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 py-8  rounded-[8px] max-h-[90vh] overflow-y-auto">
          <Formik
            initialValues={{
              id: "",
              title: "",
              file: dragFile?.length ? dragFile : "",
              url: [],
              client_id: clientId,
              item_id: "",
              assets_id: "",
              type,
              file_type: "file",
              remove_file: null,
            }}
            enableReinitialize
            validationSchema={EditAssetSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              const closeModal = () => {
                setModal(false);
                setDragFile(null);
              };
              await dispatch(
                CreateAssetsActions(
                  values,
                  type,
                  setSubmitting,
                  setErrors,
                  closeModal
                )
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlue,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                  <h3
                    className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full"
                    id="modal-title"
                  >
                    NEW ASSET
                  </h3>

                  <div className="flex flex-col gap-4">
                    <div className="space-y-2">
                      <label className="text-xs font-bold text-Neutral900">
                        Name
                      </label>
                      <input
                        className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-base leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                        type="text"
                        placeholder="Asset Name"
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {errors.title && touched.title && (
                        <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                          {errors.title}
                        </p>
                      )}
                    </div>

                    <div>
                      {values.file_type === "url" ? (
                        <div className="space-y-2">
                          <label className="text-xs font-bold text-Neutral900">
                            URL
                          </label>
                          <div className="flex flex-col w-full gap-2">
                            {arr?.map((item, i) => {
                              return (
                                <div className="space-y-4">
                                  <div className="flex items-center">
                                    <div
                                      className={`text-Neutral800 w-full flex justify-between font-medium border-[1.5px] rounded border-Neutral300 px-3 focus-within:border-AccentRegular ${
                                        touched?.url &&
                                        errors?.url &&
                                        "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                      }`}
                                    >
                                      <input
                                        onChange={(e) => {
                                          e.preventDefault();
                                          const index = e.target.id;
                                          setArr((s) => {
                                            const newArr = s.slice();
                                            newArr[index] = e.target.value;
                                            setFieldValue("url", newArr);
                                            return newArr;
                                          });
                                        }}
                                        value={item}
                                        id={i}
                                        type="text"
                                        name="url"
                                        onBlur={handleBlue}
                                        placeholder="Eg. http://www.drive.google.com/my-asset"
                                        className="w-full py-3 text-base leading-5 transition duration-300 bg-transparent placeholder:text-Neutral500 placeholder:text-sm 5xl:placeholder:text-lg focus:outline-none hover:border-Neutral800"
                                      />
                                      <img
                                        className={`cursor-pointer ${
                                          item === "" && "hidden"
                                        }`}
                                        src={Close}
                                        alt=""
                                        name="file"
                                        onClick={() => {
                                          const notRemovedFile = arr.filter(
                                            (url) => url !== item
                                          );
                                          setArr(
                                            notRemovedFile?.length
                                              ? notRemovedFile
                                              : inputArr
                                          );
                                          setFieldValue(`url`, notRemovedFile);
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {arr?.length === i + 1 && (
                                    <div className="flex justify-center">
                                      <button
                                        onClick={addInput}
                                        className="p-2 px-3 text-[30px] leading-5 w-full focus-visible:outline-none border-[1px] rounded text-AccentRegular border-Neutral400 hover:border-AccentRegular transition duration-300 border-dashed inline-block cursor-pointer"
                                      >
                                        +
                                      </button>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                            {errors.url && touched.url && (
                              <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors.url}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="space-y-2">
                          <label className="text-xs font-bold text-Neutral900">
                            File
                          </label>
                          <UploadAssetAndDocumentFiles
                            title="Upload your file"
                            value={values}
                            error={errors}
                            touched={touched}
                            handleBlue={handleBlue}
                            accept="image/*,.pdf,.doc,.docx,.xlsx,.svg,.csv"
                            setFieldValue={setFieldValue}
                            multipal={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    {values.file_type === "file" ? (
                      <p
                        className={`text-xs text-Neutral700 transition duration-300 font-medium`}
                      >
                        Have existing asset URL?&nbsp;
                        <button
                          type="button"
                          className="text-AccentRegular"
                          name="file_type"
                          onClick={() => {
                            setFieldValue("file_type", "url");
                            setFieldValue("file", "");
                            setArr(inputArr);
                          }}
                        >
                          Paste it here.
                        </button>
                      </p>
                    ) : (
                      <p
                        className={`text-xs text-Neutral700 transition duration-300 font-medium`}
                      >
                        Have assets already?&nbsp;
                        <button
                          type="button"
                          className="text-AccentRegular"
                          name="file_type"
                          onClick={() => {
                            setFieldValue("file_type", "file");
                            setFieldValue("url", "");
                          }}
                        >
                          Upload it here.
                        </button>
                      </p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="inline-block w-full py-3 text-sm font-semibold text-center transition duration-300 ease-in-out rounded btn button-hover large-primary-btn text-Neutral100 bg-AccentRegular drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0"
                  >
                    {isSubmitting ? (
                      <span className="relative z-10">
                        <svg
                          className="w-5 h-5 m-auto text-white animate-spin"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      <span className="relative z-10 drop-shadow-Texts">
                        Add to {type}
                      </span>
                    )}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setModal(false);
            setDragFile(null);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default AddEditAssetsModal;
