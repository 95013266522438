import React, { useEffect, useRef, useState } from "react";
import AddComment from "./commponent/AddComment";
import EditComment from "./commponent/EditComment";

const Comment = ({
	isNew,
	element,
	task_id,
	clientDetail,
	types,
	user_type = null,
	access_level,
}) => {
	const [comments, setComments] = useState(element?.comments);

	const commentsEndRef = useRef(null);

	const scrollToBottom = () => {
		commentsEndRef.current?.scrollIntoView({
			behavior: "smooth",
		});
	};

	useEffect(() => {
		scrollToBottom();
	}, [comments]);

	useEffect(() => {
		setComments(element?.comments);
	}, [element?.comments]);

	return (
		<div className="lg:min-w-[314px] lg:max-w-[314px] sm:h-[80vh] border-l bg-Neutral100 border-Neutral300 relative rounded-bl-lg sm:rounded-bl-none rounded-r-lg flex flex-col items-end justify-between h-[33vh]">
			<div
				className={`h-fill overflow-auto max-h-[560px] rounded-r-lg w-full self-start ${
					comments?.length !== 0 ? "pt-4" : ""
				}`}
			>
				{comments?.map((item, index) => {
					return (
						<EditComment
							key={index}
							item={item}
							clientDetail={clientDetail}
							types={types}
							task_id={task_id}
							project_id={element?.project}
							user_type={user_type}
							access_level={access_level}
						/>
					);
				})}
				<div ref={commentsEndRef} className="mb-7" />
			</div>
			{comments?.length === 0 && (
				<div className="text-Neutral500 text-[14px] flex justify-center w-full italic">
					No comments...{" "}
				</div>
			)}
			{(user_type === null || (user_type && access_level === "full_access")) && (
				<AddComment
					isNew={isNew}
					task_id={task_id}
					clientDetail={clientDetail}
					types={types}
					project_id={element?.project}
					user_type={user_type}
				/>
			)}
		</div>
	);
};

export default Comment;
