import { Link } from "react-router-dom";

import FilterImg from "../../assets/Images/Filter.svg";
import Search from "../../assets/Images/search.svg";

const SearchBar = ({
  searchValue,
  handleChangeSearch,
  isFilter = false,
  setFilterBtn,
}) => {
  return (
    <div className="relative w-full max-w-[600px] h-10 sm:h-12">
      <input
        type="text"
        onChange={handleChangeSearch}
        value={searchValue}
        placeholder="Search..."
        className="w-full max-w-[600px] h-10 sm:h-12 border-[1.5px] placeholder:text-Neutral600 text-Neutral900 px-2 text-sm leading-5 rounded border-Neutral400 pl-[44px] pr-3 focus:outline-none hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow"
      />
      <img
        className="absolute left-3 top-[50%] translate-y-[-50%] w-[20px] sm:w-[24px]"
        src={Search}
        alt=""
      />
      {isFilter && (
        <div className="absolute right-3 top-[50%] translate-y-[-50%]">
          <Link
            to="#0"
            onClick={() => setFilterBtn(true)}
            className="5xl:text-sm text-[12px] uppercase text-AccentRegular bg-Neutral200 5xl:h-8 h-7 5xl:w-[120px] w-[88px] flex justify-center items-center rounded hover:bg-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Neutral200 font-medium"
          >
            <img src={FilterImg} className={"mr-1"} alt="" />
            filter
          </Link>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
