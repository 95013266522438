import { useState } from "react";

import { cn } from "../../utils";
import CheckMarkIcon from "../Icons/CheckMarkIcon";
import CloseIcon from "../Icons/CloseIcon";
import UpgradeBadgeIcon from "../Icons/UpgradeBadgeIcon";
import FeaturesModal from "./FeaturesModal";
import { useNavigate } from "react-router";

const UpgradeCard = ({
  title = "",
  features = [],
  className = "",
  onViewPlanClick = () => {},
}) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!isOpen && (
        <div
          className={cn(
            "fixed flex flex-col max-h-[100px] justify-between p-4 text-sm font-medium text-left text-gray-800 border-l-4 rounded-lg shadow-md cursor-pointer bg-Neutral000 top-4 right-4 w-80 hover:bg-Neutral100 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 border-AccentRegular overflow-hidden hover:max-h-fit ease-in-out transition-all duration-500",
            className
          )}
          onClick={() => setIsOpen(true)}
        >
          <div className="flex items-center justify-between w-full mb-3">
            <div className="flex items-center gap-1 px-3 py-2 pr-4 rounded-3xl text-AccentRegular bg-AccentLight">
              <UpgradeBadgeIcon className="size-[18px]" />
              <span className="text-xs font-semibold uppercase text-AccentRegular">
                UPGRADE
              </span>
            </div>
            <button className="text-Neutral500">
              <CloseIcon className="size-5" />
            </button>
          </div>

          <h3 className="mb-4 text-base text-Neutral900">
            {title || "Customise what your clients see..."}
          </h3>

          <ul className="mb-4 space-y-2 text-xs text-Neutral900">
            {features.map((feature, idx) => {
              const uniqueId = feature?.substring(0, 5)?.trim() + idx;

              return (
                <li key={uniqueId} className="flex items-center gap-2">
                  <CheckMarkIcon width={18} color="#34B288" />
                  {feature}
                </li>
              );
            })}
          </ul>

          <button
            className="w-full py-2 text-sm font-bold border rounded border-Neutral300 text-AccentRegular hover:bg-AccentRegular hover:text-AccentMediumLight"
            onClick={
              onViewPlanClick ||
              (() => {
                navigate("/white-label/plans");
              })
            }
          >
            View Plans
          </button>
        </div>
      )}

      <FeaturesModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={{ features, title, onViewPlanClick }}
      />
    </>
  );
};

export default UpgradeCard;
