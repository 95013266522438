import React from "react";
import { useState } from "react";

const SendIcon = ({ onClick }) => {
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  return (
    <button
      onClick={onClick}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      className={`hover:bg-AccentLight hover:text-AccentRegular rounded	text-Neutral700 p-1`}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0095 10.176H6.89777"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0095 10.1757L4.37242 16.7417L6.89781 10.1757L4.37242 3.60975L18.0095 10.1757Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default SendIcon;
