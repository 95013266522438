import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getContactList } from "../../store/actions/contactAction";
import EditContactModal from "../Modals/EditContactModal";

function ContactCard({ name, position, imageUrl, email }) {
  return (
    <div className="flex gap-3 pt-4">
      <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden rounded-full bg-Neutral200 shrink-0">
        <span className="text-Neutral700 text-sm text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey uppercase">
          {name
            ? name
                ?.split(" ")
                ?.map((char) => char.charAt(0))
                ?.join("")
                ?.toUpperCase()
            : ""}
        </span>
      </div>

      <div className="-space-y-1">
        <h5 className="text-xs font-semibold capitalize text-Neutral900">
          {position}
        </h5>
        <div className="space-x-2">
          <span className="text-[10px] text-Neutral800 capitalize">{name}</span>
          <span className="text-[10px] font-semibold text-AccentRegular">
            {email}
          </span>
        </div>
      </div>
    </div>
  );
}

function Contacts() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const contact = useSelector((state) => state.contact.contact);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [contactLists, setContactLists] = useState(null);

  useEffect(() => {
    if (contact?.length === 0) {
      dispatch(getContactList(auth.user._id));
    } else {
      setContactLists(contact);
    }
  }, [auth.user._id, dispatch]);

  return (
    <>
      <div className="border relative border-Neutral300 max-h-[385px] px-6 py-6 space-y-2 rounded-lg 2xl:px-10 bg-Neutral000">
        <div className="flex justify-between">
          <h3 className="text-Neutral800 text-xs font-bold tracking-[0.1em] uppercase">
            Default Contacts
          </h3>
          <button
            className="text-xs font-bold uppercase text-AccentRegular hover:text-AccentMediumDark"
            onClick={() => setIsContactModalOpen(true)}
          >
            Edit
          </button>
        </div>

        <div className="flex flex-col divide-y divide-Neutral200 gap-y-4 max-h-[300px] overflow-y-auto min-w-[290px]">
          {!!contactLists?.length ? (
            contactLists?.map((contact, i) => (
              <ContactCard
                key={contact.name + i}
                name={contact.name}
                position={contact.title}
                // imageUrl={contact.imageUrl}
                email={contact.email}
              />
            ))
          ) : (
            <div className="absolute inset-0 grid top-10 place-items-center">
              <p className="text-sm italic font-semibold uppercase text-Neutral500">
                No contacts
              </p>
            </div>
          )}
        </div>
      </div>

      <EditContactModal
        isOpen={isContactModalOpen}
        setOpen={setIsContactModalOpen}
        contactLists={contactLists}
        setContactLists={setContactLists}
        // getContactsList={getContactsList}
      />
    </>
  );
}

export default Contacts;
