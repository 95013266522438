import { ErrorMessage, Field, useFormikContext } from "formik";

import Project_Arrow from "../../assets/Images/Project_Arrow.svg";
import ButtonLoader from "../Loaders/ButtonLoader";
import { BankIcon } from "./BankIcon";
import { PaypalIcon } from "./PaypalIcon";
import { StripeIcon } from "./StripeIcon";

const Icons = {
  Bank: <BankIcon />,
  Paypal: <PaypalIcon />,
  Stripe: <StripeIcon />,
};

function Step5({
  active,
  onPrevious,
  complete,
  setStep,
  values,
  isSubmitting,
  handlePaypal,
  handleStripe,
  invoice,
  user,
  loadingPaymentLink,
  setIsCreateInvoice,
}) {
  const { setFieldValue } = useFormikContext();

  return (
    <div
      className={`bg-neutral-50 p-4 rounded-lg w-full step flex ${
        active ? " flex-col items-start justify-start" : "h-14 items-center"
      }
     `}
    >
      <div
        className={`flex-col justify-center items-start gap-0.5 flex w-full ${
          !active && "cursor-pointer"
        }`}
        onClick={() => !active && setStep(5)}
      >
        <span className="flex items-center w-full gap-6 text-xs font-bold tracking-wide">
          <span
            className={`min-w-[40px] ${
              complete ? "text-emerald-400" : "text-AccentRegular"
            } `}
          >
            STEP 5
          </span>

          {!active && (
            <span className="flex items-center justify-between w-full">
              <span className="text-xs font-bold text-zinc-500">
                Payment Information
              </span>

              {!active && complete && (
                <span className="text-xs font-bold tracking-wide">
                  <span
                    className={`w-12 ${
                      complete ? "text-emerald-400" : "text-indigo-500"
                    }`}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="22" height="22" rx="11" fill="#42D6A6" />
                      <path
                        d="M16.6477 7.40332L9.20657 14.3613L5.82422 11.1986"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </span>
              )}
            </span>
          )}
        </span>
        <div
          className={`justify-start items-center gap-3 flex ${
            !active && "hidden"
          }`}
        >
          <div className="text-base font-bold tracking-wide text-zinc-800">
            Payment Information
          </div>
        </div>
      </div>
      <div
        className={`justify-start items-start gap-6 flex flex-col mt-5 w-full ${
          !active && "hidden"
        }`}
      >
        <div className="flex flex-col items-start justify-start w-full gap-6">
          {/* DISCOUNT */}
          <div className="w-full">
            <label
              htmlFor="discount"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Discount %{" "}
              <span className="text-xs text-gray-400">(Optional)</span>
            </label>
            <Field
              name="discount"
              type="number"
              id="discount"
              className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full md:w-80"
              placeholder="Enter percent discount"
              min={0}
              max={100}
              value={values.discount}
              onChange={(e) => {
                let newValue = e.target.value;
                if (newValue > 100) {
                  newValue = 100;
                } else if (newValue < 0) {
                  newValue = 0;
                }
                setFieldValue("discount", newValue);
              }}
            />
          </div>
          {/* DISCOUNT */}

          {/* PAYMENT METHOD */}
          <div className="flex flex-col w-full">
            <label htmlFor="paymentMethod" className="mb-2 text-xs font-bold">
              Payment Method
            </label>
            <div className="flex gap-2.5 items-center py-2.5">
              <div className="flex flex-col items-center border rounded-lg w-full md:w-80 p-2.5 bg-white">
                <button
                  id="optionsButton"
                  data-dropdown-toggle="options"
                  className="flex items-center justify-between w-full text-sm text-gray-900 bg-transparent border-none outline-none"
                  type="button"
                  onClick={() => {
                    const options = document.getElementById("options");
                    options.classList.toggle("hidden");
                  }}
                >
                  <span className="sr-only">Open Payment Methods</span>
                  {/* <BankIcon /> */}
                  {Icons[values.paymentMethod]}
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {/* <input name="discount" type="number" id="discount" class="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80" placeholder="Enter percent discount" min="0" max="100" value="0"> */}
                <div
                  id="options"
                  className="z-10 hidden bg-white rounded-lg w-80 text-zinc-800 border-l border-r py-2.5"
                >
                  <ul
                    className="overflow-auto text-sm font-medium"
                    aria-labelledby="dropdownInformOptionsButton"
                  >
                    <li>
                      <button
                        type="button"
                        className="flex items-center w-full gap-2 p-2 text-left hover:bg-gray-100"
                        onClick={() => {
                          setFieldValue("paymentMethod", "Bank");
                          // setFieldValue("paymentLink", null);
                          document
                            .getElementById("options")
                            .classList.add("hidden");
                        }}
                      >
                        <BankIcon />
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="flex items-center w-full gap-2 p-2 text-left hover:bg-gray-100"
                        onClick={() => {
                          setFieldValue("paymentMethod", "Stripe");
                          setFieldValue("paymentLink", "");
                          setFieldValue("bankDetails", "");
                          document
                            .getElementById("options")
                            .classList.add("hidden");
                        }}
                      >
                        <StripeIcon />
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="flex items-center w-full gap-2 p-2 text-left hover:bg-gray-100"
                        onClick={() => {
                          setFieldValue("paymentMethod", "Paypal");
                          setFieldValue("paymentLink", "");
                          setFieldValue("bankDetails", "");
                          document
                            .getElementById("options")
                            .classList.add("hidden");
                        }}
                      >
                        <PaypalIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/* {["Paypal", "Stripe"].includes(values.paymentMethod) && (
                <a
                  href={`https://www.${values.paymentMethod?.toLowerCase()}.com`}
                  target="_blank"
                  rel="noreferrer"
                  data-for="stripe"
                  data-tip={values.paymentMethod === 'Paypal' ? 'Integrate with paypal for automatic invoice creation or manually add a invice URL here' : `Go to the ${values.paymentMethod} website and create a special link for the amount of the entire invoice`}
                >
                  <QuestionMarkIcon />
                  <ReactTooltip
                    place="bottom"
                    textColor="#F5F5F7"
                    className="tooltip-font-size"
                    backgroundColor="#292933"
                    id="stripe"
                  />
                </a>
              )} */}
            </div>
            {/* {values?.paymentMethod !== "Bank" && (
              <div
                className="mt-1 text-xs font-medium text-neutral-500 w-80"
                id="paymentMethodDescription"
              > */}
            {/* Payment Link will be created automatic with invoice creation Or manually create a payment link from the paypal website and add here. */}
            {/*Integrate with paypal for automatic payment link creation or manually create a payment link from the paypal website and add here.*/}
            {/* The invoice will show a{" "}
                {values.paymentMethod === "Stripe" ? "Stripe" : "Paypal"}{" "}
                payment link for your client to pay from our site. */}
            {/* </div>
            )} */}
            <ErrorMessage name="paymentMethod">
              {(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
            </ErrorMessage>
          </div>

          {/* PAYMENT METHOD */}
          {/* BANK DETAILS */}
          <div
            className={`flex flex-col w-full ${
              values.paymentMethod === "Bank" ? "" : "hidden"
            }`}
          >
            <label
              htmlFor="bankDetails"
              className="block mb-2 text-xs font-bold text-zinc-800"
            >
              Bank Details
            </label>
            <Field
              name="bankDetails"
              as="textarea"
              id="bankDetails"
              className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-full md:w-80 h-24"
              placeholder="Enter all bank details your client will need to complete the payment..."
              maxLength={1000}
            />
            {/* bottom right */}
            <div className="flex items-end justify-end text-xs text-gray-400">
              <span className="text-gray-500">
                {values.bankDetails?.length}/1000
              </span>
            </div>
            <ErrorMessage name="bankDetails">
              {(msg) => <div className="mt-2 text-sm text-red-600">{msg}</div>}
            </ErrorMessage>
          </div>
          {/* BANK DETAILS */}

          {/* LINK */}
          <div
            className={`flex  gap-6 ${
              values.paymentMethod === "Bank" && "hidden"
            }`}
          >
            <div className="items-center gap-6">
              <div
                className={`${
                  (!user?.payPalInfo?.refresh_token && values.paymentMethod === "Paypal") ||
                  (!user?.stripeInfo && values.paymentMethod === "Stripe") ||
                  (values.paymentLink &&
                    ((values.paymentMethod === "Paypal" &&
                      values?.paymentLink?.includes("paypal")) ||
                      (values.paymentMethod === "Stripe" &&
                        values?.paymentLink?.includes("stripe"))))
                    ? "block"
                    : "hidden"
                }`}
              >
                <label
                  htmlFor="paymentLink"
                  className="block mb-2 text-xs font-bold text-zinc-800"
                >
                  Payment Link
                </label>
                <Field
                  name="paymentLink"
                  type="text"
                  value={values.paymentLink}
                  disabled={
                    (values.paymentMethod === "Paypal" &&
                      values?.paymentLink?.includes("paypal")) ||
                    (values.paymentMethod === "Stripe" &&
                      values?.paymentLink?.includes("stripe"))
                  }
                  id="paymentLink"
                  className="bg-white border border-gray-100 rounded-lg p-2.5 text-neutral-500 text-sm font-medium outline-none w-80"
                  placeholder="Generate Payment Link"
                />
                <ErrorMessage name="paymentLink">
                  {(msg) => (
                    <div className="mt-2 text-sm text-red-600">{msg}</div>
                  )}
                </ErrorMessage>
              </div>
              <div>
                {user?.payPalInfo?.refresh_token &&
                  values.paymentMethod === "Paypal" &&
                  !invoice?.paymentLink?.includes("paypal") && (
                    <button
                      disabled={loadingPaymentLink}
                      className="self-end px-6 my-2 py-3 h-[42px] w-full rounded grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out text-AccentRegular border border-indigo-400 bg-white disabled:bg-neutral-300"
                      type="button"
                      onClick={() => handlePaypal()}
                    >
                      <p className="mx-1">
                        {loadingPaymentLink
                          ? "Generating..."
                          : "Generate Payment Link"}
                      </p>
                    </button>
                  )}
                {user?.stripeInfo &&
                  values.paymentMethod === "Stripe" &&
                  !invoice?.paymentLink?.includes("stripe") && (
                    <button
                      disabled={loadingPaymentLink}
                      className="self-end px-6 my-2 py-3 h-[42px] w-full rounded grow text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out text-AccentRegular border border-indigo-400 bg-white disabled:bg-neutral-300"
                      type="button"
                      onClick={() => handleStripe()}
                    >
                      <p className="mx-1">
                        {loadingPaymentLink
                          ? "Generating..."
                          : "Generate Payment Link"}
                      </p>
                    </button>
                  )}
              </div>
            </div>
            {!user?.payPalInfo?.refresh_token && values.paymentMethod === "Paypal" && (
              <div className="flex items-center gap-5 p-2 bg-Neutral200">
                <img className="w-12 h-12" src={Project_Arrow} alt="" />
                <p className="text-[12px] text-neutral-800 w-[450px]">
                  Manually create a payment link from the paypal website and add
                  here OR
                  <a
                    className="text-indigo-500"
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_PAYPAL_BASE_URL}/signin/authorize?flowEntry=static&client_id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&response_type=code&scope=openid profile&redirect_uri=${process.env.REACT_APP_PUBLIC_BASE_URL}`}
                  >
                    {" "}
                    Integrate with paypal for automatic payment link creation.
                  </a>
                </p>
              </div>
            )}
            {!user?.stripeInfo && values.paymentMethod === "Stripe" && (
              <div className="flex items-center gap-5 p-2 bg-Neutral200">
                <img className="w-12 h-12" src={Project_Arrow} alt="" />
                <p className="text-[12px] text-neutral-800 w-[450px]">
                  Manually create a payment link from the stripe website and add
                  here OR
                  <a
                    className="text-indigo-500"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${
                      process.env.REACT_APP_PUBLIC_STRIPE_OAUTH_CLIENT_ID
                    }&scope=read_write&state=${
                      Math.random() * 100
                    }&return_url=${process.env.REACT_APP_PUBLIC_BASE_URL}`}
                  >
                    {" "}
                    Integrate with stripe for automatic payment link creation.
                  </a>
                </p>
              </div>
            )}
            {/* {values.paymentMethod === "Stripe" && (
              <div className="flex items-center gap-5 p-2 bg-Neutral200">
                <img className="w-12 h-12" src={Project_Arrow} alt="" />
                <p className="text-[12px] text-neutral-800 w-[450px]">
                  Go to the {values.paymentMethod} website and create a special
                  link for the amount of the entire invoice
                </p>
              </div>
            )} */}
          </div>
          {/* LINK */}
        </div>
        {/*  */}

        <div className="flex flex-col items-start justify-start w-full gap-6 md:flex-row">
          <button
            className={`w-full md:w-80 h-12 bg-AccentRegular rounded flex-col justify-center items-center gap-2 flex hover:bg-AccentMediumDark`}
            type="submit"
            onClick={setIsCreateInvoice}
            disabled={isSubmitting}
          >
            <div className="w-full text-sm font-bold leading-none text-center text-AccentLight">
              {isSubmitting ? <ButtonLoader /> : "Create Invoice"}
            </div>
          </button>

          <button
            type="button"
            className="flex flex-col items-center justify-center h-12 gap-2 px-6 rounded bg-AccentLight hover:bg-AccentMediumLight"
            onClick={onPrevious}
          >
            <div className="text-sm font-bold leading-none text-center text-AccentRegular">
              Previous
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Step5;
