import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import ClientCollabTaskListing from "../comman/ClientCollabTaskListing";
import {
    GetCollabProjectDetailAction,
} from "../../store/actions/projectAction";
import { GetCollabTasksActions } from "../../store/actions/tasksAction";
import TopMenu from "./TopMenu";
import moment from "moment";
import loader from "../../assets/Images/loader-icon.svg";
import Filter from '../filter/filter';
import FilterImg from '../../assets/Images/Filter.svg';
import { Link } from 'react-router-dom';


function ClientCollabProjectDashboard() {
    const { projectId = null } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const projectDetail = useSelector((state) => state.project.projectDetail);
    const allTaskList = useSelector((state) => state.tasks.all_tasks);
    const isGeneral = "GENERAL";
    const [filterBtn, setFilterBtn] = useState(false);
    const [isAnimated, setIsAnimated] = useState(false);

    const [isLoading, setIsLoading] = useState({
        status: false,
        id: "",
    });
    const [tasks, setTasks] = useState(
        Object.fromEntries(Object.entries(allTaskList))
    );
    const [addfilter, setAddFilter] = useState({
        urgentonly: [],
        status: [],
        assignedto: [],
        date: [],
        categories: [],
    });

    useEffect(() => {
        if (state?.project) {
            dispatch({
                type: "SET_PROJECT_DETAIL",
                payload: {
                    isLoading: false,
                    data: state?.project,
                },
            });
        } else {

            if (projectId !== projectDetail.data._id) {
                setIsLoading({ status: true, id: projectId });
                dispatch(GetCollabProjectDetailAction(projectId));
            }
        }
    }, [projectId, dispatch]);

    const handelSet = () => {
        setIsAnimated(true);
        setTimeout(() => {
            setIsAnimated(false);
            setFilterBtn(!filterBtn);
        }, 350);
    };

    useEffect(() => {
        if (
            (!Object.keys(allTaskList).filter((id) => id === projectId)?.length ||
                Object.keys(allTaskList).length === 0) && projectDetail?.data?.client?._id
        ) {
            dispatch(GetCollabTasksActions(projectDetail?.data?.client?._id, projectId, false, setIsLoading));
        }
    }, [projectDetail?.data?.client?._id, projectId]);

    useEffect(() => {
        if (Object.keys(allTaskList).filter((id) => id === projectId)?.length) {
            setTasks(allTaskList[projectId]);
        }
    }, [allTaskList, projectId]);

    useEffect(() => {
        const getAllData = Object.fromEntries(
            Object.entries(allTaskList).map(([key, value]) => [
                key,
                value.filter((item) => item.type === isGeneral),
            ])
        );
        setTasks({ ...getAllData });
    }, [isGeneral, allTaskList]);

    useEffect(() => {
        const getfilterdata = Object.fromEntries(
            Object.entries(allTaskList).map(([key, value]) => [
                key,
                value.filter((item) => {
                    return (
                        item?.type === isGeneral &&
                        (addfilter.urgentonly?.length
                            ? addfilter.urgentonly.includes(item?.priority)
                            : true) &&
                        (addfilter.status?.length
                            ? addfilter.status.includes(item?.tag)
                            : true) &&
                        (addfilter.assignedto?.length
                            ? addfilter?.assignedto?.some((res) =>
                                item?.members?.some((rr) => rr._id === res)
                            )
                            : true) &&
                        (addfilter.categories?.length
                            ? addfilter?.categories?.some((res) =>
                                item?.category?.some((rr) => rr === res)
                            )
                            : true) &&
                        (addfilter?.date?.length
                            ? (addfilter?.date.includes("Within 7 Days") &&
                                moment()
                                    .add(7, "days")
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm:ss") > item?.date &&
                                moment().format("YYYY-MM-DD") < item?.date) ||
                            (addfilter?.date.includes("This Month") &&
                                moment().startOf("Month").format("YYYY-MM-DD") <=
                                item?.date &&
                                moment().endOf("Month").format("YYYY-MM-DD") >= item?.date) ||
                            (addfilter?.date.includes("After this month") &&
                                moment().add(1, "M").format("YYYY-MM-DD") > item?.date &&
                                moment().endOf("month").format("YYYY-MM-DD") < item?.date)
                            : true)
                    );
                }),
            ])
        );
        setTasks({ ...getfilterdata });
    }, [addfilter]);

    return (

        <div className={`bg-gray-100 min-h-screen`}>
            <TopMenu />
            <Filter
                filterBtn={filterBtn}
                setFilterBtn={setFilterBtn}
                handelSet={handelSet}
                isAnimated={isAnimated}
                setIsAnimated={setIsAnimated}
                addfilter={addfilter}
                setAddFilter={setAddFilter}
                members={null}
                categories={null}
            />

            <div className=" py-5 mt-16 md:mt-0 mx-auto w-full flex flex-col items-center justify-center">
                <header className="w-full flex justify-between items-center pb-4 mt-20 5xl:px-[96px] px-12">
                    <h1 className="text-2xl font-semibold">{projectDetail?.data?.title}</h1>
                    <div className="flex space-x-2">
                        <Link
                            to="#0"
                            onClick={() => setFilterBtn(true)}
                            className="5xl:text-sm text-[12px] uppercase text-Neutral800 bg-Neutral300 5xl:h-8 h-7 5xl:w-[120px] w-[88px] flex justify-center items-center rounded hover:bg-Neutral400 hover:text-Neutral900 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Neutral200 font-medium transition duration-300"
                        >
                            <img src={FilterImg} className={"mr-1"} alt="" />
                            filter
                        </Link>
                    </div>
                </header>
                <div className="5xl:px-[96px] px-12 w-full pt-0">
                    <div className="md:pb-0 pb-[100px] mb-[68px]">
                        <div className="flex flex-col">
                            {/* tasks board */}
                            {((isLoading?.status && isLoading?.id === projectId) || projectDetail?.isLoading) ? (
                                <div className="flex justify-center mt-10 mb-10">
                                    <img
                                        src={loader}
                                        alt="loader"
                                        className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
                                    />
                                </div>
                            ) : (
                                <ClientCollabTaskListing
                                    //frontFilter={frontFilter}
                                    clientId={projectDetail?.data?.client?._id}
                                    project={projectDetail?.data}
                                    allTaskList={tasks[projectDetail?.data?._id] || []}
                                    types="task"
                                    isAll={false}
                                    projectId={projectId}
                                    user_type='client_user'
                                    access_level={projectDetail?.data?.access_level}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientCollabProjectDashboard;
