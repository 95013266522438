import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SigninBg from "../../assets/Images/signin-bg-shap.png";
import { useDispatch } from "react-redux";
import Logo from "../../assets/Images/Logo.svg";
import PasswordIcon from "../../assets/Images/password.svg";
import ImgIcon from "../../assets/Images/img-icon.svg";
import PasswordShowIcon from "../../assets/Images/password-show.svg";
import Close from "../../assets/Images/Close-gray.svg";
import PasswordStrengthBar from "react-password-strength-bar";
import ButtonLoader from "../Loaders/ButtonLoader";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AcceptInvitationAction,
  CreateInvitedAccountAction,
} from "../../store/actions/authAction";
import PageLoader from "../Loaders/PageLoader";
import Checkmark from "../../assets/Images/Checkmark.svg";

const SignupSchema = Yup.object().shape({
  user_name: Yup.string().required("User Name Required"),
  email: Yup.string().email("Email is not valid").required("Email Required"),
  password: Yup.string()
    .required("Password Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  is_terms: Yup.boolean().oneOf(
    [true],
    "Please indicate that you have read and agree to the Terms and Conditions and Privacy Policy"
  ),
});

function MemberInvation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const auth = useSelector((state) => state.auth);
  const { search } = useLocation();
  const [ip, setIP] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [errors, setErrors] = useState([]);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const token = search?.split("=")?.slice(-1)[0];

  //To get IP address
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const callVerifyInvitation = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `api/v1/team/verify-invitation-token?token=${
            search?.split("=")?.slice(-1)[0]
          }`
        );
        setResponse(data.data);
        setLoading(false);
        return data;
      } catch (error) {
        if (error.response.status === 422) {
          let err = {};
          error.response.data.errors.forEach((element) => {
            err[element.param] = element.msg;
          });
          setErrors(err.token);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(error?.response?.data?.message);
        }
      }
    };
    callVerifyInvitation();
  }, []);

  const handleAccept = () => {
    dispatch(AcceptInvitationAction(token, navigate));
  };

  return (
    <section>
      <div className="flex">
        <div className="w-full max-w-[50%] sm:h-screen h-full relative md:block hidden">
          <div className="absolute top-[50%] w-full  5xl:max-w-[440px] max-w-[384px]	left-[50%] -translate-x-1/2 -translate-y-1/2 ml:px-0 px-3">
            <div className="flex items-center">
              <img src={Logo} alt="logo" />
              <h2 className="5xl:text-[60px] lg:text-[56px] text-[40px] font-black w-full max-w-[260px] leading-[0.85] text-Neutral000 ml-4">
                Client Manager
              </h2>
            </div>
            <p className="text-Neutral100 5xl:text-[18px] text-[16px] mt-3">
              The Simple Client Onboarding & Management Tool
            </p>
          </div>
          <img
            className="w-full object-cover h-screen"
            src={SigninBg}
            alt="background"
          />
        </div>
        {!loading ? (
          <>
            {!response?.verified_invitation &&
            response?.token_valid &&
            !errors.length ? (
              <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex md:flex-row flex-col 2xl:items-center justify-center relative px-3 overflow-y-auto">
                <div className="w-full max-w-[320px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
                  <img className="w-full max-w-[32px]" src={Logo} alt="logo" />
                  <h1 className="mt-3 text-[32px] 5xl:text-[36px] font-bold mb-[40px] text-Neutral900 2xs:whitespace-nowrap tracking-[-0.32px]">
                    Create Your Account
                    <span className="text-AccentRegular">.</span>
                  </h1>
                  <Formik
                    initialValues={{
                      email: response?.user?.email ? response?.user?.email : "",
                      password: "",
                      user_name: response?.user?.user_name
                        ? response?.user?.user_name
                        : "",
                      ip_address: ip,
                      profile: null,
                      token: token,
                      is_terms: false,
                    }}
                    enableReinitialize
                    validationSchema={SignupSchema}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      setSubmitting(true);
                      dispatch(
                        CreateInvitedAccountAction(
                          values,
                          setSubmitting,
                          setErrors,
                          navigate
                        )
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      isValid,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                          {errors.token && touched.token && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.token}
                            </p>
                          )}
                          <label className="text-Neutral900 font-bold text-xs">
                            Name
                          </label>
                          <div className="mt-2">
                            <input
                              className={`text-Neutral800 font-medium placeholder:text-sm 5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 px-4 text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition
                        ${
                          errors.user_name && touched.user_name
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                              type="text"
                              placeholder="Eg. Luke Skywalker"
                              name="user_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.user_name}
                              disabled={response?.user?.user_name}
                            />
                          </div>
                          {errors.user_name && touched.user_name && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.user_name}
                            </p>
                          )}
                        </div>
                        <div className="mb-6">
                          <label className="text-Neutral900 font-bold text-xs">
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition
                        ${
                          errors.email && touched.email
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                              type="email"
                              placeholder="Eg. luke@skywalker.com"
                              name="email"
                              onChange={(e) => {
                                setFieldValue(
                                  "email",
                                  e.target.value.toLowerCase()
                                );
                              }}
                              onBlur={handleBlur}
                              value={values.email}
                              disabled={response?.user?.email}
                            />
                          </div>
                          {errors.email && touched.email && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.email}{" "}
                            </p>
                          )}
                        </div>
                        <div className="mb-6">
                          <label className="text-Neutral900 font-bold text-xs">
                            Password
                          </label>
                          <div className="relative passwordshow mt-2">
                            <input
                              className={`placeholder:text-Neutral500 placeholder:font-normal py-3 px-4 text-sm leading-5 w-full focus:outline-none text-Neutral800 font-medium border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow  transition duration-300 ease-in-out ${
                                values.password && values.password.length
                                  ? ""
                                  : ""
                              }
                        ${
                          errors.password && touched.password
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                              type={passwordShown ? "text" : "password"}
                              placeholder="Eg. very_good_password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <img
                              className="w-full absolute right-[12px] top-[8px] max-w-[32px] cursor-pointer"
                              src={
                                passwordShown ? PasswordShowIcon : PasswordIcon
                              }
                              alt="PasswordIcon"
                              onClick={togglePasswordVisiblity}
                            />
                            {errors.password && touched.password && (
                              <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                                {errors.password}
                              </p>
                            )}
                            {values.password && values.password.length && (
                              <PasswordStrengthBar
                                className="strength-bar duration-300 transition mt-2 pb-3 px-1"
                                scoreWordStyle={{
                                  color: "#757580",
                                  fontSize: "12px",
                                }}
                                scoreWordClassName="strength-word"
                                password={values.password}
                                barColors={[
                                  "#DFDFE5",
                                  "#6D6DF2",
                                  "#6D6DF2",
                                  "#6D6DF2",
                                  "#6D6DF2",
                                ]}
                                scoreWords={[
                                  "Password Strength",
                                  "Password Strength",
                                  "Password Strength",
                                  "Password Strength",
                                  "Password Strength",
                                ]}
                                shortScoreWord="Password Strength"
                              />
                            )}
                          </div>
                        </div>
                        <div className="text-Neutral900 mb-[12px]">
                          <div className="flex items-center justify-between">
                            <span className="font-bold text-xs">
                              Profile Image
                            </span>
                            <span className="text-xs font-medium italic text-Neutral700">
                              Optional
                            </span>
                          </div>
                          <div className="relative mt-2">
                            <label
                              htmlFor={values?.profile ? null : "uploadlogo"}
                              className={`placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed flex justify-between ${
                                !values?.profile && "cursor-pointer"
                              }`}
                            >
                              <span className="flex items-center">
                                <img
                                  src={
                                    values?.profile
                                      ? URL.createObjectURL(values?.profile)
                                      : ImgIcon
                                  }
                                  alt=""
                                  height={values?.profile && 150}
                                  width={values?.profile && 100}
                                />
                                <p
                                  className={`text-Neutral600 ml-2 text-xs font-medium text-ellipsis overflow-hidden ${
                                    values?.profile && "w-[160px]"
                                  }`}
                                >
                                  {values?.profile?.name
                                    ? values?.profile?.name
                                    : "Upload your photo or company logo."}
                                </p>
                              </span>
                              {values?.profile ? (
                                <img
                                  className="cursor-pointer"
                                  src={Close}
                                  alt=""
                                  onClick={() => setFieldValue(`profile`, null)}
                                />
                              ) : null}
                            </label>
                            <input
                              id="uploadlogo"
                              className="hidden"
                              type="file"
                              name="profile"
                              accept="image/x-png,image/jpeg"
                              multiple={false}
                              onChange={(e) => {
                                setFieldValue(`profile`, e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                        <div className="mt-[40px] pb-6">
                          <button
                            type="submit"
                            disabled={isSubmitting || !isValid}
                            className={`btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100  font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out ${
                              !isValid
                                ? "bg-Neutral300 text-Neutral500"
                                : "bg-AccentRegular"
                            }`}
                          >
                            {isSubmitting ? (
                              <ButtonLoader />
                            ) : (
                              <span className="relative z-10">
                                Create Account
                              </span>
                            )}
                          </button>
                        </div>
                        <div
                          className={`2xl:pb-0 md:pb-16 pb-10 ${
                            errors.is_terms && touched.is_terms
                              ? "text-Red400"
                              : ""
                          }`}
                        >
                          <div
                            className={`font-semibold text-xs flex items-center `}
                          >
                            <div className="flex items-center filter-checkbox">
                              <input
                                type="checkbox"
                                id="is_terms"
                                onChange={(e) => {
                                  setFieldValue("is_terms", !values?.is_terms);
                                }}
                                name="is_terms"
                                value="yes"
                                className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                              />
                              <div
                                className={`bg-white ${
                                  errors.is_terms && touched.is_terms
                                    ? "border-Red400"
                                    : "border-Neutral300"
                                } border-Neutral300 border-[1.5px] custom-chackbox rounded-md w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300`}
                              >
                                <img src={Checkmark} alt="" />
                              </div>
                            </div>
                            <label htmlFor="is_terms">
                              <span className="cursor-default text-Neutral800">
                                I agree to the&nbsp;
                              </span>
                              <a
                                href="https://www.clientmanager.io/terms"
                                target="_blank"
                                rel="noreferrer"
                                className={`w-[90%] m-auto ${
                                  errors.is_terms && touched.is_terms
                                    ? "text-Red400"
                                    : "text-AccentRegular"
                                } font-bold`}
                              >
                                Terms & Conditions
                              </a>
                            </label>
                          </div>
                          {errors.is_terms && touched.is_terms && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                              {errors.is_terms}
                            </p>
                          )}
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            ) : (
              <>
                {response?.verified_invitation &&
                response?.token_valid &&
                !errors.length ? (
                  <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full text-center flex flex-col 2xl:items-center justify-center relative px-3 overflow-y-auto">
                    <div className="w-full max-w-[400px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
                      <li className="pb-[10px] text-Neutral700 text-xl">
                        Accept the invitation to join{" "}
                        {response?.user?.invited_by?.user_name} Team
                      </li>
                    </div>
                    <div className="mt-[30px] w-[100px] 2xl:pb-0 md:pb-16 pb-10">
                      <button
                        type="submit"
                        className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-4 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                        onClick={handleAccept}
                      >
                        {/* <ButtonLoader /> */}
                        <span className="relative z-10">Accept</span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="w-full md:max-w-[50%] max-w-full md:h-screen h-full flex flex-col 2xl:items-center justify-center relative px-3 overflow-y-auto">
                    <div className="w-full max-w-[400px] 5xl:max-w-[400px] md:mt-0 mt-12 2xl:pt-0 md:pt-24 md:mx-0 mx-auto">
                      <span className="pb-[20px] text-Red400 text-xl">
                        Error: {errors}
                      </span>
                    </div>
                    <Link
                      to="/"
                      className="text-xs text-center inline-block text-AccentRegular font-[600] 4xl:mt-0 ml:mt-2 mt-0 py-1 px-2 rounded border-[1.5px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                    >
                      OK
                    </Link>
                  </div>
                )}
              </>
            )}
            <div className="md:absolute md:top-2 3xl:right-[7%] ml:right-8 right-[6%] md:mb-0 mb-10 w-full mx-auto max-w-[248px]">
              <div className="4xl:flex ml:block flex text-right items-center md:justify-end justify-center 5xl:mt-4">
                <p className="text-xs text-Neutral700 font-medium 4xl:mr-[24px] ml:mr-0 mr-[24px] mb-0">
                  Have an account?
                </p>
                <Link
                  to="/signin"
                  className="text-xs text-center inline-block text-AccentRegular font-[600] 4xl:mt-0 ml:mt-2 mt-0 py-1 px-2 rounded border-[1.5px] border-Neutral300 hover:border-AccentRegular focus-visible:outline focus-visible:outline-4 focus-visible:outline-AccentLight transition duration-300 ease-in-out"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="flex w-full justify-center">
            <PageLoader />
          </div>
        )}
      </div>
    </section>
  );
}

export default MemberInvation;
