
const initialState = {
  backgroundColor: "#F0F0FF",
  accentColor: "#732AFF",
  brandLogoUrl: "",
  brandLogoFile: null,
};

const brandIdentitiesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "UPDATE_BRAND_BG_COLOR":
      return {
        ...state,
        backgroundColor: payload?.backgroundColor,
      };
    case "UPDATE_BRAND_ACCENT_COLOR":
      return {
        ...state,
        accentColor: payload?.accentColor,
      };
    case "UPDATE_BRAND_LOGO_FILE":
      return {
        ...state,
        brandLogoFile: payload?.brandLogoFile,
        brandLogoUrl: "",
      };
    case "UPDATE_BRAND_LOGO_URL":
      return {
        ...state,
        brandLogoUrl: payload?.brandLogoUrl,
        brandLogoFile: null,
      };
    default:
      return state;
  }
};

export default brandIdentitiesReducer;
