function UploadIcon(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0833 13.1401V16.1031C17.0833 16.496 16.9272 16.8728 16.6494 17.1507C16.3716 17.4285 15.9948 17.5846 15.6019 17.5846H5.23148C4.83857 17.5846 4.46175 17.4285 4.18392 17.1507C3.90608 16.8728 3.75 16.496 3.75 16.1031V13.1401"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1201 7.95386L10.4164 4.25015L6.71271 7.95386"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.416 4.25052L10.416 13.1394"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadIcon;
