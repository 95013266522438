import React, { useState, Fragment } from "react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DotsHorizontalIcon,
} from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import MonthGridView from "./components/MonthGridView";
import WeekGridView from "./components/WeekGridView";
import DayGridView from "./components/DayGridView";
import CreateEventsModal from "../../components/Modals/CreateEventsModal";
import EditeEventsModal from "../Modals/EditeEventsModal";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DashboardCalender() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [isEvent, setIsEvent] = React.useState(false);
  const [editEvent, setEditEvent] = React.useState(null);
  const [calendarView, setCalenderView] = useState("monthGrid");
  const [calenderViewDate, setCalenderViewDate] = useState(
    moment(new Date()).format()
  );
  function openModal() {
    setIsOpen(true);
  }

  function setModal(value) {
    setIsOpen(value);
  }

  const changeCalenderView = (val) => {
    setCalenderView(val);
    if (val === "monthGrid") {
      setCalenderViewDate(moment(new Date()).startOf("month").format());
      return;
    }
    if (val === "weekGrid") {
      setCalenderViewDate(moment(new Date()).startOf("week").format());
      return;
    }
    if (val === "dayGrid") {
      setCalenderViewDate(moment(new Date()).format());
      return;
    }
  };

  const onCalanderNavigate = (val) => {
    if (val === "next") {
      if (calendarView === "monthGrid") {
        setCalenderViewDate(
          moment(calenderViewDate).add(1, "M").startOf("month").format()
        );
        return;
      }
      if (calendarView === "weekGrid") {
        setCalenderViewDate(
          moment(calenderViewDate).add(1, "W").startOf("week").format()
        );
        return;
      }
      if (calendarView === "dayGrid") {
        setCalenderViewDate(moment(calenderViewDate).add(1, "d").format());
        return;
      }
      return;
    }
    if (val === "prev") {
      if (calendarView === "monthGrid") {
        setCalenderViewDate(
          moment(calenderViewDate).subtract(1, "M").startOf("month").format()
        );
      }
      if (calendarView === "weekGrid") {
        setCalenderViewDate(
          moment(calenderViewDate).subtract(1, "W").startOf("week").format()
        );
        return;
      }
      if (calendarView === "dayGrid") {
        setCalenderViewDate(moment(calenderViewDate).subtract(1, "d").format());
        return;
      }
      return;
    }
    if (val === "today") {
      if (calendarView === "monthGrid") {
        setCalenderViewDate(moment().format());
      }
      if (calendarView === "weekGrid") {
        setCalenderViewDate(moment(new Date()).startOf("week").format());
        return;
      }
      if (calendarView === "dayGrid") {
        setCalenderViewDate(moment().format());
        return;
      }
      return;
    }
  };

  return (
    <div className="border border-Neutral400 md:mb-[36px] rounded-lg overflow-hidden">
      <div className="lg:flex lg:h-full lg:flex-col">
        {/* Calender Header */}
        <header className="relative z-20 flex items-center justify-between border-b border-Neutral300 py-4 px-6 lg:flex-none bg-Neutral200">
          <h1 className="text-bs md:text-[16px] md:font-semibold tracking-[0.08em] md:tracking-normal font-bold text-Neutral900">
            <time dateTime="2022-01">
              {calendarView === "dayGrid"
                ? moment(calenderViewDate).format("DD MMMM YYYY")
                : null}

              {/* Week Calender */}
              {calendarView === "weekGrid"
                ? moment(calenderViewDate).format("MMM DD") +
                " - " +
                moment(calenderViewDate).add(6, "d").format("MMM DD, YYYY")
                : null}

              {/** Month Calender */}
              {calendarView === "monthGrid"
                ? moment(calenderViewDate).format("MMMM YYYY")
                : null}
            </time>
          </h1>
          <div className="flex items-center">
            <div className="flex items-center justify-between rounded-md shadow-sm md:items-stretch h-8 w-full max-w-[120px]">
              <button
                type="button"
                className="flex items-center justify-center rounded-l-md border-[0.5px] border-r-0 border-Neutral500 bg-white text-Neutral400 hover:text-Neutral900 focus:relative md:w-9 duration-300 transition focus-visible:outline-none"
                onClick={() => onCalanderNavigate("prev")}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => onCalanderNavigate("today")}
                className="hidden border-[0.5px] border-x-0 border-Neutral500 bg-white px-2 text-xs font-semibold text-Neutral700 hover:text-Neutral900 focus:relative md:block duration-300 transition focus-visible:outline-none"
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-Neutral500 md:hidden" />
              <button
                type="button"
                className="flex items-center justify-center rounded-r-md border-[0.5px] border-l-0 border-Neutral500 bg-white text-Neutral400 hover:text-Neutral900 focus:relative md:w-9 md duration-300 transition focus-visible:outline-none"
                onClick={() => onCalanderNavigate("next")}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <Menu as="div" className="relative">
                <Menu.Button
                  type="button"
                  className="flex items-center rounded-md border-[0.5px] border-Neutral500 bg-white h-8 py-1 pl-2 pr-2 text-xs font-semibold text-Neutral700 hover:text-Neutral900 shadow-sm focuse:border-0 focus-visible:outline-none"
                >
                  {calendarView === "dayGrid"
                    ? "Day View"
                    : calendarView === "weekGrid"
                      ? "Week View"
                      : calendarView === "monthGrid"
                        ? "Month view"
                        : "Year View"}
                  <ChevronDownIcon
                    className="ml-1 h-5 w-5 text-Neutral400"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs w-full text-left"
                            )}
                            onClick={() => changeCalenderView("dayGrid")}
                          >
                            Day view
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs w-full text-left"
                            )}
                            onClick={() => changeCalenderView("weekGrid")}
                          >
                            Week view
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? "bg-Neutral200 text-Neutral900"
                                : "text-Neutral700",
                              "block px-4 py-2 text-xs w-full text-left"
                            )}
                            onClick={() => changeCalenderView("monthGrid")}
                          >
                            Month view
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="ml-5 h-6 w-px bg-Neutral500" />
              <button
                type="button"
                onClick={openModal}
                className="button-hover large-primary-btn text-xs text-center inline-block text-Neutral100 relative bg-AccentRegular font-semibold py-2 px-[15px] ml-5 rounded drop-shadow-Texts focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out"
              >
                <span className="relative z-10">Add event</span>
              </button>
            </div>
            <Menu as="div" className="relative ml-6 md:hidden">
              <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-Neutral700 hover:text-Neutral900">
                <span className="sr-only">Open menu</span>
                <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-Neutral200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          // href="#"
                          className={classNames(
                            active
                              ? "bg-Neutral200 text-Neutral900"
                              : "text-Neutral700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          Create event
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          // href="#"
                          className={classNames(
                            active
                              ? "bg-Neutral200 text-Neutral900"
                              : "text-Neutral700",
                            "block px-4 py-2 text-xs"
                          )}
                        >
                          Go to today
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          // href="#"
                          className={classNames(
                            active
                              ? "bg-Neutral200 text-Neutral900"
                              : "text-Neutral700",
                            "block px-4 py-2 text-xs"
                          )}
                          onClick={() => changeCalenderView("dayGrid")}
                        >
                          Day view
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          // href="#"
                          className={classNames(
                            active
                              ? "bg-Neutral200 text-Neutral900"
                              : "text-Neutral700",
                            "block px-4 py-2 text-xs"
                          )}
                          onClick={() => changeCalenderView("weekGrid")}
                        >
                          Week view
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          // href="#"
                          className={classNames(
                            active
                              ? "bg-Neutral200 text-Neutral900"
                              : "text-Neutral700",
                            "block px-4 py-2 text-xs"
                          )}
                          onClick={() => changeCalenderView("monthGrid")}
                        >
                          Month view
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </header>

        {/* Day Calender */}
        {calendarView === "dayGrid" ? (
          <DayGridView
            changeCalenderViewDate={(value) => {
              setCalenderViewDate(value);
            }}
            calenderViewDate={calenderViewDate}
          />
        ) : null}

        {/* Week Calender */}
        {calendarView === "weekGrid" ? (
          <WeekGridView calenderViewDate={calenderViewDate} />
        ) : null}

        {/** Month Calender */}
        {calendarView === "monthGrid" ? (
          <MonthGridView
            setIsEvent={setIsEvent}
            setEditEvent={setEditEvent}
            calenderViewDate={calenderViewDate}
          />
        ) : null}
      </div>
      <CreateEventsModal isOpen={modalIsOpen} setModal={setModal} />
      <EditeEventsModal isEvent={isEvent} setIsEvent={setIsEvent} setEditEvent={setEditEvent} editEvent={editEvent} />
      {/* Modal */}
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={setModal}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                  id="modal-title"
                >
                  ADD EVENT
                </h3>
                <div className="mb-6">
                  <label className="text-Neutral900 font-bold text-xs">
                    What
                  </label>
                  <div className="mt-2">
                    <input
                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                      type="text"
                      placeholder="Eg. Wireframing"
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <label className="text-Neutral900 font-bold text-xs">
                    When
                  </label>
                  <div className="mt-2 mb-6 flex add-event-time-date">
                    <input
                      className="min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings py-4 h-[48px] px-4 placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                      type="time"
                      placeholder="14:00"
                    />
                    <div className="ml-[8px] w-full">
                      <input
                        className="text-Neutral800 h-[48px] w-full font-medium font-feature-settings pl-[17.5px] pr-[46.5px] placeholder:text-sm text-sm leading-5 5xl:max-w-[180px] max-w-[148px] focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                        required
                        type="date"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 2xl:pb-0 md:pb-10 pb-10">
                  <button className="btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded drop-shadow-Texts overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out">
                    <span className="relative z-10">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => closeModal(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal> */}
    </div>
  );
}

export default DashboardCalender;
