function ScribbleArrow({ size = 53, ...props }) {
  return (
    <svg
      width={size}
      height={size - 1}
      viewBox="0 0 53 52"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5973 30.6636C34.8485 31.5596 36.4149 32.1479 38.3181 32.2676C44.2057 32.6375 47.2732 30.2279 48.5359 26.7354C50.3796 21.6358 48.3156 14.199 45.7877 10.1004C45.6683 9.90622 45.3694 9.79131 45.1216 9.8431C44.8725 9.89457 44.7674 10.0936 44.8868 10.2878C47.3407 14.2658 49.3543 21.4838 47.5655 26.4332C46.4059 29.6399 43.6236 31.8832 38.2159 31.5429C36.6066 31.4424 35.2862 30.943 34.2261 30.187C34.775 29.7606 35.2969 29.3259 35.7902 28.8836C37.1516 27.6624 39.6947 23.9404 39.966 20.775C40.1597 18.5043 39.1875 16.5221 36.1067 15.7571C34.3943 15.3322 32.9655 15.7336 31.8995 16.6959C30.4327 18.0181 29.6592 20.4386 29.7602 22.9835C29.8438 25.1023 30.5301 27.3028 31.8809 29.0181C32.1787 29.3947 32.5065 29.7492 32.8671 30.0749C25.0694 35.8615 12.4004 39.8316 0.504622 39.7502C0.233762 39.7491 0.0525932 39.9094 0.102367 40.1106C0.150903 40.3114 0.411315 40.4756 0.681945 40.4776C12.7716 40.5596 25.6472 36.5376 33.5973 30.6636ZM33.5 29.593C34.0144 29.1912 34.5042 28.7819 34.968 28.3655C36.272 27.1949 38.7081 23.6293 38.9678 20.5965C39.1275 18.7353 38.4763 17.0682 35.9514 16.4412C34.6252 16.1124 33.549 16.4681 32.7222 17.2123C31.8141 18.0318 31.2086 19.309 30.9279 20.7685C30.5017 22.9843 30.828 25.6207 32.0537 27.7532C32.4415 28.4289 32.9216 29.0532 33.5 29.593Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2621 31.0153C10.205 34.7338 5.2909 37.9634 0.432409 39.8098C0.208954 39.8947 0.158884 40.1113 0.320241 40.2928C0.481597 40.4744 0.794037 40.5536 1.01749 40.4687C6.02822 38.565 11.0789 35.2226 13.2 31.3893C13.2951 31.2178 13.1622 30.9944 12.9028 30.891C12.6446 30.7879 12.3572 30.8438 12.2621 31.0153Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.06176 40.5571C5.74774 40.0242 12.3117 41.5356 16.8251 43.6511C17.0754 43.7686 17.3736 43.7321 17.4891 43.5688C17.6059 43.4059 17.4979 43.179 17.2476 43.0616C12.5193 40.8454 5.64144 39.2801 0.73201 39.8383C0.471642 39.8675 0.334067 40.0533 0.424483 40.2516C0.516139 40.4503 0.801158 40.5871 1.06176 40.5571Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ScribbleArrow;
