import { Menu } from "@headlessui/react";
import { useState } from "react";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import WarningIcon from "../../components/Icons/WarningIcon";
import { updateTeamMemberRoleAction } from "../../store/actions/memberAction";
import { cn } from "../../utils";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

const MemberItem = ({ data, loggedInUser, dispatch, handleRemoveId }) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });

  const roleMap = {
    general: "border border-Neutral300 text-Neutral800",
    admin: "border border-AccentRegular text-AccentRegular",
    project_manager: "bg-Neutral900 text-Neutral200",
  };

  return (
    <div className="flex items-center justify-between w-full px-4 py-2 mb-4 hover:bg-Neutral200">
      <div className="flex items-center">
        <div className="w-8 h-8 overflow-hidden rounded-full">
          {!data?.member?.profile && !data?.member?.profile_path ? (
            <span className="w-full bg-Neutral400 row-start-1 row-end-6 col-start-1 text-[12px] 5xl:text-[24px] h-full flex items-center justify-center font-bold border-Miscgrey text-Neutral700">
              {data?.user_name[0]}
            </span>
          ) : (
            <img
              className={`w-full h-full object-cover`}
              src={data?.member?.profile_path || data?.profile_path}
              alt="UserButtons"
            />
          )}
        </div>
        <div>
          <p className="ml-4 text-xs font-medium text-Neutral900">
            {data?.member ? data?.member?.user_name : data.user_name}
          </p>
          {loggedInUser?._id !== data?.member?._id && (
            <div
              className="text-[10px] font-medium ml-4 text-Red400 cursor-pointer hover:text-Red600"
              onClick={() => handleRemoveId(data)}
            >
              REMOVE
            </div>
          )}
        </div>
      </div>
      <div>
        {!data?.member?._id ? (
          <div className="inline-flex items-center flex-shrink-0 gap-1 text-Neutral800">
            <WarningIcon className="size-3" />
            <p className="text-xs">Invitation Pending</p>
          </div>
        ) : (
          <div className="relative mt-2">
            <Menu as="div" className="relative inline-block w-full text-left">
              <Menu.Button
                ref={setReferenceElement}
                className={cn(
                  "rounded inline-flex w-full focus-visible:outline-none popup-btn relative px-[12px] pb-[10px] pt-[10px]",
                  data?.member?.role
                    ? roleMap[data?.member?.role]
                    : roleMap["general"]
                )}
              >
                <span className="focus-visible:outline-none">
                  <div className="members-text flex flex-wrap text-[10px] items-center uppercase justify-between font-medium">
                    {data?.member?.role ? data?.member?.role : "Select role..."}
                    <svg
                      className="ml-1"
                      width="9"
                      height="6"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </span>
              </Menu.Button>
              <Portal>
                <div
                  ref={setPopperElement}
                  className="absolute"
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <Menu.Items className="bg-white border border-Neutral300 focus:outline-none w-[130px] pt-2 pb-1 px-1 rounded shadow-lg">
                    <div className="bg-white">
                      <p className="text-Neutral600 text-[10px] font-semibold px-2 mb-1">
                        Select new role
                      </p>
                      <div className="divide-y divide-Neutral200">
                        <div
                          className="flex items-center text-Neutral800 text-[10px] uppercase rounded p-2 hover:bg-Neutral200 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              updateTeamMemberRoleAction({
                                role: "admin",
                                id: data?.member?._id,
                              })
                            );
                          }}
                        >
                          Admin
                        </div>

                        <div
                          className="flex items-center text-Neutral800 text-[10px] uppercase rounded p-2 hover:bg-Neutral200 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              updateTeamMemberRoleAction({
                                role: "general",
                                id: data?.member?._id,
                              })
                            );
                          }}
                        >
                          <p className="text-Neutral800 text-[10px] uppercase font-normal">
                            General
                          </p>
                        </div>

                        <div
                          className="flex items-center text-Neutral800 text-[10px] uppercase rounded p-2 hover:bg-Neutral200 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              updateTeamMemberRoleAction({
                                role: "project_manager",
                                id: data?.member?._id,
                              })
                            );
                          }}
                        >
                          <p className="text-Neutral800 text-[10px] uppercase font-normal">
                            Project Manager
                          </p>
                        </div>
                      </div>
                    </div>
                  </Menu.Items>
                </div>
              </Portal>
            </Menu>
            {/* <MenuButton data={data} dispatch={dispatch} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

const TeamMember = ({
  setIsOpen,
  userAccountDetail,
  setCreateMemberModal,
  member,
  logedinUser,
  handleRemoveId,
}) => {
  const dispatch = useDispatch();

  const RenderMembers = () => {
    if (member && member.data && member.data.length > 0) {
      return member.data.map((data, index) => {
        return (
          <MemberItem
            key={index}
            data={data}
            loggedInUser={logedinUser}
            dispatch={dispatch}
            handleRemoveId={handleRemoveId}
          />
        );
      });
    }
    return;
  };

  const renderRemainingMembers = () => {
    if (logedinUser && member) {
      if (userAccountDetail && userAccountDetail?.planData) {
        if (
          userAccountDetail?.planData?.selected_account_plan === "large_team"
        ) {
          const memberCount =
            userAccountDetail?.planData?.payment_gateway === "paddle"
              ? 50 - member?.data?.length >= 0
                ? 50 - member?.data?.length
                : 0
              : 9999 - member?.data?.length >= 0
              ? 9999 - member?.data?.length
              : 0;
          return memberCount;
        } else if (
          userAccountDetail?.planData?.selected_account_plan === "small_team"
        ) {
          const memberCount =
            userAccountDetail?.planData?.payment_gateway === "paddle" ||
            userAccountDetail?.planData?.payment_gateway === "lemonsqueezy"
              ? 10 - member?.data?.length >= 0
                ? 10 - member?.data?.length
                : 0
              : 5 - member?.data?.length >= 0
              ? 5 - member?.data?.length
              : 0;
          return memberCount;
        } else {
          return;
        }
      }
      return;
    }
    return;
  };

  return (
    <div className="w-full 5xl:max-w-[500px] md:max-w-[408px] max-h-[382px] rounded border border-Neutral300 pt-8 px-10">
      <div className="flex items-center justify-between w-full mb-10">
        <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
          Team Members
        </h5>
        <button
          onClick={() => setIsOpen(true)}
          className="text-[#CCCCCC] text-[10px] font-semibold"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
              stroke="#9C9CA6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.0489 14.6702H10.0576"
              stroke="#9C9CA6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="9.5"
              stroke="#C2C2CC"
            />
          </svg>
        </button>
      </div>
      <div className="overflow-x-auto max-h-[200px] mb-4">
        {RenderMembers()}
      </div>
      {userAccountDetail?.planData?.selected_account_plan === "large_team" &&
      (userAccountDetail?.planData?.payment_gateway === "appsumo" ||
        userAccountDetail?.planData?.payment_gateway === "lemonsqueezy") ? (
        <p className="text-Neutral800 text-[10px] mb-5 font-semibold">
          You can add unlimited team members on your current plan.
          <br />
          <div
            className="font-bold cursor-pointer text-AccentRegular hover:text-AccentMediumDark"
            onClick={() => setCreateMemberModal(true)}
          >
            Add a new team member.
          </div>
        </p>
      ) : renderRemainingMembers() > 0 ? (
        <p className="text-Neutral800 text-[10px] mb-5 font-semibold">
          You can still add <b>{renderRemainingMembers()}</b> more members on
          your current plan.
          <br />
          <div
            className="font-bold cursor-pointer text-AccentRegular hover:text-AccentMediumDark"
            onClick={() => setCreateMemberModal(true)}
          >
            Add a new team member.
          </div>
        </p>
      ) : (
        <p className="text-Neutral800 text-[10px] mb-8 md:mb-5 font-semibold">
          Your plan has reached its member limit.
          <br />
          To add more team members,&nbsp;
          {userAccountDetail?.planData?.payment_gateway === "paddle" ? (
            <Link
              className="font-bold cursor-pointer text-AccentRegular"
              to={"/upgrade-plan"}
            >
              upgrade your account.
            </Link>
          ) : (
            <a
              href={
                userAccountDetail?.response?.data?.attributes?.urls
                  ?.customer_portal_update_subscription
              }
              target="_blank"
              rel="noreferrer"
              className="font-bold cursor-pointer text-AccentRegular"
            >
              upgrade your account.
            </a>
          )}
        </p>
      )}
    </div>
  );
};

export default TeamMember;
