function BagIcon({
  width = 20,
  height = 15,
  color = "var(--accent-color)",
  className = "",
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2 7.5H4.8C3.80589 7.5 3 8.30589 3 9.3V18.3C3 19.2941 3.80589 20.1 4.8 20.1H19.2C20.1941 20.1 21 19.2941 21 18.3V9.3C21 8.30589 20.1941 7.5 19.2 7.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5999 20.0999V5.6999C15.5999 5.22251 15.4103 4.76468 15.0727 4.42711C14.7351 4.08954 14.2773 3.8999 13.7999 3.8999H10.1999C9.72251 3.8999 9.26468 4.08954 8.92711 4.42711C8.58955 4.76468 8.3999 5.22251 8.3999 5.6999V20.0999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BagIcon;
