import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { useLocation } from "react-router";
import * as Yup from "yup";
import loader from "../../../../../assets/Images/loader-icon.svg";
import PasswordIcon from "../../../../../assets/Images/password.svg";
import PasswordShowIcon from "../../../../../assets/Images/password-show.svg";
import Checkmark from "../../../../../assets/Images/Checkmark.svg";
import { SignupWithAppsumo } from "../../../../../store/actions/authAction";

const NamePasswordInput = ({ signInDetail, setIsOpenPaymentModal }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState({
    digit: false,
    specialChar: false,
    minimum: false,
  });

  useEffect(() => {
    if (state?.password) {
      setPasswordCheck({ digit: true, specialChar: true, minimum: true });
    }
  }, [state?.password]);

  const passwordChange = (e) => {
    const password = e.target.value;
    const regDigit = new RegExp("(?=.*?[0-9])");
    const checkRegDigit = regDigit.test(password);
    const regSpecial = new RegExp("(?=.*?[#?!@$%^&*-])");
    const checkRegSpecial = regSpecial.test(password);
    const regMinimum = new RegExp(".{8,}");
    const checkRegMinimum = regMinimum.test(password);
    setPasswordCheck({
      digit: checkRegDigit,
      specialChar: checkRegSpecial,
      minimum: checkRegMinimum,
    });
  };

  const disabled =
    !passwordCheck.digit ||
    !passwordCheck.specialChar ||
    !passwordCheck.minimum ||
    !isCheckTerms;

  return (
    <Formik
      initialValues={{
        name: "",
        password: "",
        email: signInDetail?.email,
        license_key: signInDetail?.license_key,
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string().required("User name is Required"),
        password: Yup.string().required("Password is Required"),
        email: Yup.string().required("Email is Required"),
      })}
      onSubmit={async (values, { setErrors }) => {
        setLoading(true);
        const value = {
          user_name: values?.name,
          email: values?.email,
          password: values?.password,
          license_key: values?.license_key,
        };
        await dispatch(
          SignupWithAppsumo(value, setLoading, setErrors, setIsOpenPaymentModal)
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="mt-2 mb-4">
            <label className="text-Neutral900 font-bold text-xs">Email</label>
            <div className="mt-2">
              <input
                className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow transition duration-300 first-letter:${
                  errors?.email && touched?.email
                    ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                    : ""
                }`}
                type="email"
                placeholder="example@gmail.com"
                name="email"
                disabled
                value={values?.email}
              />
            </div>
            {errors?.email && touched?.email && (
              <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                {errors?.email}
              </p>
            )}
          </div>
          <div className="mt-2 mb-4">
            <label className="text-Neutral900 font-bold text-xs">
              User Name
            </label>
            <div className="mt-2">
              <input
                className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow transition duration-300 first-letter:${
                  errors.name && touched.name
                    ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                    : ""
                }`}
                type="text"
                placeholder="Eg. Luke"
                name="name"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.name}
                autoComplete="off"
              />
            </div>
            {errors.name && touched.name && (
              <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                {errors.name}
              </p>
            )}
          </div>
          <div className="mb-2">
            <label className="text-Neutral900 font-bold text-xs">
              Password
            </label>
            <div className="relative mt-[2px]">
              <input
                className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 text-sm placeholder:text-sm 5xl:placeholder:text-lg leading-5 w-full focus-visible:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 focus:drop-shadow-Purpleboxshadow transition ease-in-out 
                     ${
                       errors.password && touched.password
                         ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                         : ""
                     }`}
                type={showPassword ? "text" : "password"}
                placeholder="••••••••••••"
                value={values.password}
                onChange={(e) => {
                  handleChange(e);
                  passwordChange(e);
                }}
                name="password"
                autoComplete="new-password"
              />
              <img
                className="w-full absolute right-[12px] top-[8px] max-w-[32px] cursor-pointer"
                src={showPassword ? PasswordShowIcon : PasswordIcon}
                alt="PasswordIcon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {errors.password && touched.password && (
              <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                {errors.password}
              </p>
            )}
            <div className="mt-6">
              <p
                className={`${
                  passwordCheck?.digit
                    ? "text-AccentMediumDark"
                    : "text-Neutral500"
                }  font-medium text-xs flex items-center`}
              >
                <svg
                  className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="8"
                    fill={passwordCheck.digit ? "#6D6DF2" : "#DFDFE6"}
                  />
                  <path
                    d="M11.3942 5.71118L6.92199 10.139L4.88916 8.12635"
                    stroke="white"
                    strokeWidth="1.09091"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Contains at least 1 number
              </p>
              <p
                className={`${
                  passwordCheck?.specialChar
                    ? "text-AccentMediumDark"
                    : "text-Neutral500"
                }  font-semibold text-xs flex items-center py-4`}
              >
                <svg
                  className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="8"
                    fill={passwordCheck.specialChar ? "#6D6DF2" : "#DFDFE6"}
                  />
                  <path
                    d="M11.3942 5.71118L6.92199 10.139L4.88916 8.12635"
                    stroke="white"
                    strokeWidth="1.09091"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Contains at least 1 special character
              </p>
              <p
                className={`${
                  passwordCheck?.minimum
                    ? "text-AccentMediumDark"
                    : "text-Neutral500"
                }  font-semibold text-xs flex items-center`}
              >
                <svg
                  className="mr-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="8"
                    fill={passwordCheck.minimum ? "#6D6DF2" : "#DFDFE6"}
                  />
                  <path
                    d="M11.3942 5.71118L6.92199 10.139L4.88916 8.12635"
                    stroke="white"
                    strokeWidth="1.09091"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Contains at least 8 characters
              </p>
            </div>
          </div>
          <div className="w-full lg:mt-[20px] mt-[24px] mb-3">
            <div>
              <button
                type="submit"
                className={`uppercase rounded-lg  ${
                  disabled
                    ? "text-Neutral500 bg-Neutral300"
                    : "bg-AccentRegular text-Neutral000 button-hover"
                } font-bold md:h-[46px] h-[60px]  p-[0px_24px] lg:mr-4 lg:w-auto w-full`}
                // onClick={handleSubmit}
                disabled={disabled}
              >
                {loading ? (
                  <img src={loader} alt="loader" className="animate-spin" />
                ) : (
                  "SignUp"
                )}
              </button>
            </div>
            <div className={`font-semibold text-xs flex items-center pt-6`}>
              <div className="flex items-center filter-checkbox">
                <input
                  type="checkbox"
                  id="is_terms"
                  onChange={(e) => {
                    setIsCheckTerms(!isCheckTerms);
                  }}
                  name="A3-confirmation"
                  value="yes"
                  className="opacity-0 absolute cursor-pointer h-[24px] w-[24px] transition duration-300"
                />
                <div className="bg-white border-Neutral300 border-[1.5px] custom-chackbox rounded-md hover:bg-AccentLight hover:border-black w-[24px] h-[24px] flex flex-shrink-0 justify-center items-center mr-2 transition duration-300 ">
                  <img src={Checkmark} alt="" />
                </div>
              </div>
              <label htmlFor="is_terms">
                <span className="cursor-default text-Neutral800">
                  I agree to the&nbsp;
                </span>
                <a
                  href="https://www.clientmanager.io/terms"
                  target="_blank"
                  rel="noreferrer"
                  className="w-[90%] m-auto text-AccentRegular font-bold"
                >
                  Terms & Conditions
                </a>
              </label>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default NamePasswordInput;
