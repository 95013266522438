import { clsx } from "clsx";
import { twMerge } from "tw-merge";

export const cn = (...values) => twMerge(clsx(...values));

export function generateInitials(name) {
  if (!name || typeof name !== "string") return "";
  const words = name.trim().split(/\s+/);
  const initials = words.map((word) => word[0]?.toUpperCase()).join("");
  return initials;
}
