import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { getFullClientList } from "../../../store/actions/clientAction";
import ClientSelect from "../../comman/Client/ClientSelect";
import { customModalStyles } from "../modalsData";

const SelectClientAndProjectModal = ({
  isOpen,
  setIsOpen,
  isTask = false,
  setSelectedClient,
  selectedClient,
  setSelectedProject,
  selectedProject,
  handleSave,
}) => {
  const dispatch = useDispatch();

  const allClients = useSelector((state) => state.client.fullClientList);

  const [projectList, setProjectList] = useState([]);

  const handleClose = () => {
    setIsOpen(false);
    setSelectedClient(null);
    setSelectedProject(null);
    setProjectList([]);
  };

  useEffect(() => {
    if (!allClients?.data?.length && isOpen) {
      dispatch(getFullClientList());
    }
  }, [dispatch, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                id="modal-title"
              >
                {isTask
                  ? "Create Task: For who and for what?"
                  : "Time Tracking: For who and for what?"}
              </h3>
              <div className="mb-8">
                <label className="block mb-2 text-xs font-bold text-Neutral900">
                  Client
                </label>
                <ClientSelect
                  allClients={allClients}
                  onChange={(e) => {
                    setSelectedProject("");
                    setProjectList(e.projects);
                    setSelectedClient(e._id);
                  }}
                />
              </div>
              <div className="mb-8">
                <label className="block mb-2 text-xs font-bold text-Neutral900">
                  Project
                </label>
                <div className="relative">
                  <span className="absolute text-AccentRegular pointer-events-none z-[999] top-[50%] translate-y-[-50%] right-[16px]">
                    <svg
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                  <select
                    id="countries"
                    className="relative text-AccentRegular font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-AccentRegular focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition"
                    onChange={(e) => {
                      setSelectedProject(e.target.value);
                    }}
                    value={selectedProject}
                  >
                    <option selected disabled value="">
                      Select Project
                    </option>
                    {projectList?.length === 0 && (
                      <option disabled>No Projects Found</option>
                    )}
                    {projectList?.map((item, i) => {
                      return (
                        <option value={item?._id} key={`1${i}`}>
                          {item?.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <button
                onClick={() => {
                  handleSave();
                }}
                className={`text-sm text-center inline-block text-AccentRegular bg-Neutral200 font-semibold w-full min-w-[148px] py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 transition duration-300 ease-in-out disabled:cursor-not-allowed hover:bg-Neutral300`}
                disabled={!selectedClient || !selectedProject}
              >
                <span className="relative z-10 drop-shadow-Texts">
                  {isTask ? "Create Task" : "Select Task"}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={handleClose}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default SelectClientAndProjectModal;
