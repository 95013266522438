import { Field, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { currencies } from "../../../assets/json/currencies";
import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../../store/actions/authAction";
import { getFullClientList } from "../../../store/actions/clientAction";
import {
  CreateProjectAction,
  UpdateProjectAction,
} from "../../../store/actions/projectAction";
import { getTagColor } from "../../../utils/getTagColor";
import { Select } from "../../Inputs/CreatableSelect";
import ClientSelect from "../../comman/Client/ClientSelect";

const NewTemplateSchema = Yup.object().shape({
  name: Yup.string().required("Project Name Required"),
  category_tags: Yup.array().min(1, "Select at least one category"),
  client: Yup.string().required("Choose client name"),
  due_date: Yup.date().optional(),
  budget: Yup.number().optional(),
});

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "hidden",
  },
};

function NewProjectModal({ isOpen, setIsOpen, editProject, focusField }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editingMode = editProject !== null || editProject !== undefined;

  const allClients = useSelector((state) => state.client.fullClientList);
  const user = useSelector((state) => state.auth.user);

  const [categoryTags, setCategoryTags] = useState([]);
  const budgetInputRef = useRef(null);
  const dueDateInputRef = useRef(null);

  const modalColor = "Orange";

  useEffect(() => {
    if (!allClients?.data?.length && isOpen) dispatch(getFullClientList());
  }, [allClients?.data?.length, dispatch, isOpen]);

  useEffect(() => {
    let tempCategoryTags = [];

    if (user?.category?.length) {
      const tempCategory = user?.category?.map((item, i) => {
        return { value: item, label: item };
      });
      tempCategoryTags = [...tempCategoryTags, ...tempCategory];
    }

    tempCategoryTags = tempCategoryTags.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.value?.toLowerCase() === current?.value?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = tempCategoryTags?.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );

      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });

    setCategoryTags(optionsWithColors);
  }, [user]);

  useEffect(() => {
    if (isOpen && focusField) {
      const timer = setTimeout(() => {
        if (focusField === "budget") {
          budgetInputRef.current?.focus();
        } else if (focusField === "due_date") {
          dueDateInputRef.current?.focus();
        }
      }, 100); // Delay to ensure modal is fully open
      return () => clearTimeout(timer);
    }
  }, [isOpen, focusField]);

  const handleRemove = (data) => {
    const removedCategory = dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  const handleCreateNewTag = (inputValue) => {
    dispatch(AddUserCategoryAction(inputValue));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="New Template Modal"
      >
        <div className="relative bg-white rounded-lg transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp sm:max-w-sm">
          <div className="bg-white px-6 xs:px-12 py-6 rounded-[8px]">
            <h3
              className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-2 uppercase"
              id="modal-title"
            >
              {editingMode ? "Update" : "New"} Project
            </h3>

            <Formik
              initialValues={{
                name: editProject?.title ? editProject?.title : "",
                category_tags: editProject?.category?.length
                  ? editProject?.category?.map((item, i) => {
                      const { bgColor: optionColor, text: optionText } =
                        getTagColor(modalColor, i, true);

                      const { bgColor: labelColor, text: labelText } =
                        getTagColor(modalColor, i, true);

                      return {
                        value: item,
                        label: item,
                        color: optionColor,
                        text: optionText,
                        labelColor,
                        labelText,
                      };
                    })
                  : [],
                client: editProject?.client?._id
                  ? editProject?.client?._id
                  : "",
                due_date: editProject?.due_date
                  ? moment(editProject.due_date).format("YYYY-MM-DD")
                  : "",
                budget: editProject?.budget || "",
                currency: editProject?.currency?.short_name ? editProject?.currency?.short_name : user?.currency?.short_name ? user?.currency?.short_name : "USD",
              }}
              enableReinitialize
              validationSchema={NewTemplateSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                if (editProject && editProject?._id) {
                  const formData = new FormData();

                  formData.append("title", values.name);
                  formData.append("due_date", values.due_date);
                  formData.append("budget", values.budget);
                  values?.category_tags?.forEach((item, index) =>
                    formData.append(`category[${index}]`, item.value)
                  );
                  formData.append("id", editProject?._id);
                  formData.append("description", !editProject?.description ? "" : editProject?.description);
                  const currencydata = currencies.filter((data) => values?.currency === data.code);
                  const currency = {
                    name : !currencydata[0]?.name ? "" : currencydata[0]?.name,
                    short_name : !currencydata[0]?.code ? "" : currencydata[0]?.code,
                    symbol : !currencydata[0]?.symbol ? "" : currencydata[0]?.symbol,
                  }
                  formData.append("currency", !currency?.name ? "" : JSON.stringify(currency));
                  dispatch(
                    UpdateProjectAction(
                      formData,
                      setSubmitting,
                      setErrors,
                      setIsOpen
                    )
                  );
                } else {
                  const formData = new FormData();

                  formData.append("title", values.name);
                  formData.append("client", values.client);
                  values?.category_tags?.forEach((item, index) =>
                    formData.append(`category[${index}]`, item.value)
                  );
                  formData.append("due_date", values.due_date);
                  formData.append("budget", values.budget);

                  dispatch(
                    CreateProjectAction(
                      formData,
                      setSubmitting,
                      setErrors,
                      setIsOpen,
                      navigate
                    )
                  );
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <Form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-4 max-h-[70dvh] overflow-y-auto"
                  >
                    <div className="max-h-[385px] overflow-y-auto flex flex-col gap-4 pr-2">
                      <div className="space-y-2">
                        <label className="text-xs font-bold text-Neutral900">
                          Project Name
                        </label>
                        <input
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="text"
                          placeholder={`Eg. Web Design`}
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors.name}
                          </p>
                        )}
                      </div>

                      <div className="space-y-2">
                        <label
                          htmlFor="client"
                          className="inline-block text-xs font-bold text-Neutral900"
                        >
                          Client
                        </label>
                        <ClientSelect
                          onChange={(e) => {
                            setFieldValue("client", e?._id);
                          }}
                          onBlur={handleBlur}
                          allClients={allClients}
                          value={values.client}
                        />
                        {errors?.client && touched?.client && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors?.client}
                          </p>
                        )}
                      </div>

                      <div className="space-y-2">
                        <label className="text-xs font-bold text-Neutral900">
                          Project Category
                        </label>

                        <Select
                          id="category_tags"
                          name="category_tags"
                          isMulti
                          isSearchable
                          isClearable={false}
                          options={categoryTags}
                          value={values.category_tags}
                          onBlur={handleBlur}
                          handleCreateNewTag={handleCreateNewTag}
                          handleRemove={handleRemove}
                          {...{ setFieldValue, modalColor }}
                        />

                        {errors.category_tags && touched.category_tags && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors.category_tags}
                          </p>
                        )}
                      </div>

                      <div className="space-y-2">
                        <label
                          htmlFor="budget"
                          className="flex items-center justify-between text-xs font-bold text-zinc-800"
                        >
                          Due Date
                          <span className="text-xs italic font-normal text-Neutral600">
                            Optional
                          </span>
                        </label>

                        <input
                          ref={dueDateInputRef}
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                          type="date"
                          placeholder="13-06-2024"
                          name="due_date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.due_date}
                        />

                        {errors.due_date && touched.due_date && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors.due_date}
                          </p>
                        )}
                      </div>

                      <div className="space-y-2">
                        <label
                          htmlFor="budget"
                          className="flex items-center justify-between text-xs font-bold text-zinc-800"
                        >
                          Budget
                          <span className="text-xs italic font-normal text-Neutral600">
                            Optional
                          </span>
                        </label>

                        <div className="flex gap-2">
                          <div className="flex w-full gap-3">
                            <div className="relative">
                              <Field
                                name="currency"
                                as="select"
                                id="currency"
                                className="p-3 text-sm font-medium bg-white border border-gray-100 rounded outline-none text-neutral-500"
                                value={values.currency || currencies[0].code}
                              >
                                {currencies
                                  ?.sort((a, b) => a.code.localeCompare(b.code))
                                  .map((currency) => (
                                    <option
                                      className="text-sm text-zinc-800"
                                      value={currency.code}
                                      key={currency.code}
                                    >
                                      {currency.code} ({currency.symbol})
                                    </option>
                                  ))}
                              </Field>
                              <span
                                style={{
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                                className="absolute pointer-events-none text-AccentRegular"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7 10L12 15L17 10"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              ref={budgetInputRef}
                              className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular duration-300 transition w-full"
                              type="number"
                              placeholder="$1500"
                              name="budget"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.budget}
                            />
                          </div>

                          {errors.budget && touched.budget && (
                            <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                              {errors.budget}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                    >
                      {isSubmitting ? (
                        <span className="relative">
                          <svg
                            className="w-5 h-5 m-auto text-white animate-spin"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        <span className="relative drop-shadow-Texts">Save</span>
                      )}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div
            className="absolute right-0 -top-[40px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NewProjectModal;
