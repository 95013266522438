import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

// Step 1 validation schema using Yup
const StepOneValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

const StepOneForm = ({ onSubmit, formData, setFormData, isEditMode }) => {
  return (
    <Formik
      initialValues={formData}
      validationSchema={StepOneValidationSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        setFormData(values); // Update the parent state with new values
        onSubmit(); // Proceed to next step
        actions.setSubmitting(false);
      }}
    >
      {({ errors, touched }) => (
        <Form className="bg-white p-8 rounded-lg shadow-lg min-w-[400px]">
          <div className="flex justify-between ">
            <h3
              className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
              id="modal-title"
            >
              {!isEditMode ? "CREATE" : "EDIT"} MEETING SCHEDULE
            </h3>
            <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 mb-8">
              1/3
            </h3>
          </div>

          <div className="mb-5">
            <label
              htmlFor="title"
              className="block mb-1 text-xs font-bold text-Neutral900"
            >
              Name of Schedule
            </label>
            <Field
              name="title"
              id="title"
              className="text-Neutral900 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-xs 5xl:placeholder:text-lg text-xs leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              type="text"
              placeholder="E.g. 15 min Discovery Call"
              defaultValue={formData?.title}
            />
            {touched.title && errors.title && (
              <div className="mt-1 text-xs text-red-500">{errors.title}</div>
            )}
          </div>

          <button
            type="submit"
            className="w-full p-3 text-sm transition duration-300 rounded text-Neutral100 bg-AccentRegular"
          >
            Next
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default StepOneForm;
