import copy from "copy-to-clipboard";
import { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { useWhiteLabelSettings } from "../../../contexts/WhiteLabelProvider";
import {
  CreateSubDoamainActions,
  getSubDomain,
  updateSubDoamainActions,
} from "../../../store/actions/subDomainAction";
import {
  getACMCertificateAction,
  getDomainDetailsAction,
  updateDomainDetailsAction,
} from "../../../store/actions/WhiteLabel/customDomainAction";
import { customStyles } from "../../../utils/modalStyles";
import CheckMarkIcon from "../../Icons/CheckMarkIcon";
import LinkIcon from "../../Icons/LinkIcon";
import LoadingIcon from "../../Icons/LoadingIcon";
import FeaturesModal from "../../White-Label/FeaturesModal";

function ClientPortalDomainCard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { settings } = useWhiteLabelSettings();

  // const userIsAWhiteLabelSubscriber = true; // [❗] Only for development
  const userIsAWhiteLabelSubscriber = settings.get("isWhiteLabelSubscriber");

  const domainDetails = useSelector((state) => state.customDomain);

  const [isWhiteLabelFeaturesModalOpen, setIsWhiteLabelFeaturesModalOpen] =
    useState(false);

  const [domainVal, setDomainVal] = useState();
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cnameDetailsOpen, setCnameDetailsOpen] = useState(false);
  const [copyName, setCopyName] = useState("Copy");
  const [copyValue, setCopyValue] = useState("Copy");
  const [isReloadSite, setIsReloadSite] = useState(false);
  const [tab, setTab] = useState(1);

  const domain = useSelector((state) => state.subDomain.domain);

  const [error, setErrors] = useState({});
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  // const defaultClientPortalDomainPrefix = useMemo(() => {
  //   const normalizedPrefix = loggedInUser?.user_name
  //     ?.replace(/\s+/g, "_")
  //     ?.toLowerCase();
  //   return normalizedPrefix;
  // }, [loggedInUser?.user_name]);

  //  ========== Custom Domain ==============

  const getACMCertificate = useCallback(() => {
    if (
      domainDetails &&
      domainDetails?.data?.acm_certificateArn?.cartificate &&
      domainDetails?.data?.acm_certificateArn?.isCertiVerified !== "ISSUED"
    ) {
      dispatch(getACMCertificateAction());
    }
  }, [dispatch, domainDetails]);

  const callGetDomainDetailAPI = useCallback(() => {
    dispatch(getDomainDetailsAction(setIsReloadSite, isReloadSite, setTab));
  }, [dispatch, isReloadSite]);

  useEffect(() => {
    callGetDomainDetailAPI();
  }, [callGetDomainDetailAPI]);

  useEffect(() => {
    if (domainDetails?.data?.custom_domain_name) {
      setDomainVal(domainDetails?.data?.custom_domain_name);
    }
  }, [domainDetails?.data?.custom_domain_name]);

  useEffect(() => {
    if (isReloadSite && domainDetails?.data?.custom_domain_name) {
      getACMCertificate();
      callGetDomainDetailAPI();
    }
  }, [isReloadSite, domainDetails, getACMCertificate, callGetDomainDetailAPI]);

  const handleUpdateDomainDetails = () => {
    if (!domainVal) {
      return setIsError("Domain name required");
    }

    if (domainVal === domainDetails?.data?.custom_domain_name && !isError) {
      const certificateData = domainDetails?.data?.acm_certificateArn;
      if (
        certificateData?.cartificate === null &&
        !certificateData?.isReqForCertificate
      ) {
        dispatch(
          updateDomainDetailsAction(
            { domain: domainVal },
            setIsLoading,
            setCnameDetailsOpen
          )
        );
      } else if (
        // IF ACM STATUS IS FILED THEN NEED TO REQUEST AGAIN SO,
        // HERE DELETE OLD ACM AND REQ FOR NEW.
        certificateData?.cartificate &&
        certificateData?.isCertiVerified === "ISSUED" &&
        certificateData?.isCertiVerified === "PENDING_VALIDATION"
      ) {
        dispatch(
          updateDomainDetailsAction(
            { domain: domainVal },
            setIsLoading,
            setCnameDetailsOpen
          )
        );
      } else {
        // THIS ERROR IS IMPORTANT BECAUSE IF THIS ERROR IS NOT DISPLAY HERE THEN AGAIN CALL REQCERTIFICATE API
        // AND IN AWS WE WILL SEE DUPLICATE CERTIFICATE WITH SAME DOMAIN.
        setIsError(
          "It's already saved please try with another or refresh the page"
        );
      }
    } else {
      if (!isError) {
        dispatch(
          updateDomainDetailsAction(
            { domain: domainVal },
            setIsLoading,
            setCnameDetailsOpen
          )
        );
      }
    }
  };

  const handleCopy = (text, index, type) => {
    copy(text);
    if (type === "name") {
      setCopyName((prev) => ({ ...prev, [index]: "Copied" }));
      setTimeout(() => {
        setCopyName((prev) => ({ ...prev, [index]: "Copy" }));
      }, 1500);
    } else {
      setCopyValue((prev) => ({ ...prev, [index]: "Copied" }));
      setTimeout(() => {
        setCopyValue((prev) => ({ ...prev, [index]: "Copy" }));
      }, 1500);
    }
  };

  // =========== SUBDOMAIN ===========

  useEffect(() => {
    if (!domain) dispatch(getSubDomain());
    setUrl(domain?.subdomain);
  }, [dispatch, domain]);

  const handleSaveSubDomain = () => {
    if (!error.subDomain && url) {
      if (domain?.subdomain) {
        dispatch(updateSubDoamainActions(url, setErrors, setLoading));
      } else {
        dispatch(CreateSubDoamainActions(url, setErrors, setLoading));
      }
    }
  };

  const handleSubDomain = async () => {
    let regex = /^[A-Za-z]+$/;
    let localUrl = url;
    const condition =
      localUrl === "portal" ||
      localUrl === "devportal" ||
      localUrl === "dev" ||
      localUrl === "app";

    if (localUrl?.length < 3) {
      if (localUrl && !regex.test(localUrl)) {
        localUrl = localUrl.slice(0, -1);
      } else {
        setUrl(localUrl);
        setErrors({
          subdomain: "Subdomain must be at least 3 characters",
        });
      }
    } else if (condition) {
      setUrl(localUrl);
      setErrors({
        subdomain: "This Sub Domain already exist, please try with another.",
      });
    } else {
      if (localUrl && !regex.test(localUrl)) {
        localUrl = localUrl?.slice(0, -1);
        setErrors({
          subdomain: "Allowed only A to Z and a to z letters",
        });
      } else {
        handleSaveSubDomain();
        setUrl(localUrl);
      }
    }
  };

  return (
    <section className="w-full max-w-[830px] rounded-sm border border-Neutral300 p-4 md:p-7 bg-Neutral000">
      <div className="flex items-center justify-between mb-7">
        <h3 className="text-xs font-bold tracking-widest uppercase text-Neutral600">
          Client portal domain
        </h3>
        <div className="flex items-center gap-5">
          {/* UI-TODO: This should link to a document which shows a guide on how to create a custom domain */}
          <Link
            to={"https://www.clientmanager.io/cm-custom-domain"}
            target="_blank"
            className="inline-flex items-center gap-1 text-xs font-medium text-AccentRegular"
          >
            <span>How to create a custom domain</span>
            <LinkIcon className="size-4 shrink-0" />
          </Link>
        </div>
      </div>

      {domainDetails?.data?.acm_certificateArn?.domain_details?.length ? (
        <div className="flex justify-end w-full mb-7">
          <button
            type="button"
            className={`text-xs rounded px-4 py-2 transition duration-300 ease-in-out bg-AccentRegular text-white font-bold hover:bg-AccentMediumDark`}
            onClick={() => setCnameDetailsOpen(!cnameDetailsOpen)}
          >
            Authenticate your domain
          </button>
        </div>
      ) : null}

      <div className="flex flex-col gap-8 md:flex-row">
        <div className="flex flex-col flex-1 gap-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <label className="text-xs font-bold text-Neutral900">
                Add your own prefix
              </label>
              <div
                data-for="domain"
                data-tip="Valid only letters, A-Z and a-z."
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                    stroke="#9C9CA6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.0489 14.6702H10.0576"
                    stroke="#9C9CA6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="9.5"
                    stroke="#C2C2CC"
                  />
                </svg>
                <ReactTooltip
                  place="bottom"
                  textColor="#F5F5F7"
                  className="tooltip-font-size"
                  backgroundColor="#292933"
                  id="domain"
                />
              </div>
            </div>
            <span className="inline-flex items-center justify-between text-xs italic text-AccentRegular gap-0.5">
              <CheckMarkIcon width={16} color="#6D6DF2" />
              Current domain
            </span>
          </div>

          <div className="relative flex items-center gap-2 px-3 py-3 border rounded-md">
            {/* UI-TODO: By default this input must have the user's name value */}
            <input
              type="text"
              className="flex-grow text-sm font-medium focus:outline-none text-Neutral900 placeholder:text-Neutral500"
              placeholder="E.g. rayna"
              name="subdomain"
              value={url}
              disabled={domainDetails?.data?.custom_domain_name}
              onChange={(event) => {
                setUrl(event.target.value?.toLowerCase());
                setErrors({});
              }}
            />

            <div className="inline-flex items-center gap-1.5 absolute right-3 bg-Neutral000">
              <span className="text-xs font-medium md:text-sm text-Neutral500">
                .clientmanager.io
              </span>
              {domain?.subdomain && !error?.subdomain && (
                <span className="rounded-full size-3 md:size-4 bg-Green400 p-0.5">
                  <CheckMarkIcon width={"100%"} color="#FFFFFF" />
                </span>
              )}
            </div>
          </div>
          {error?.subdomain && (
            <p className="text-xs text-red-500">{error?.subdomain}</p>
          )}

          {/* UI-TODO: This button is used to open up a Modal (which has not been created or finalized) yet! */}
          {userIsAWhiteLabelSubscriber ? (
            <button
              onClick={handleSubDomain}
              disabled={loading || domainDetails?.data?.custom_domain_name}
              className="w-full py-3 text-xs font-bold rounded text-AccentRegular bg-Neutral200 disabled:text-Neutral500 disabled:cursor-not-allowed hover:bg-Neutral300 disabled:bg-AccentRegular"
            >
              {loading ? <LoadingIcon /> : "Edit Prefix"}
            </button>
          ) : (
            <button
              className="w-full py-3 text-xs font-bold transition rounded group bg-Neutral200 disabled:text-Neutral500 disabled:cursor-not-allowed hover:bg-AccentRegular"
              onClick={() => setIsWhiteLabelFeaturesModalOpen(true)}
            >
              {/* Show this text by default */}
              <span className="group-hover:hidden text-Neutral500">
                Edit Prefix
              </span>
              {/* Show this text on hover */}
              <span className="hidden group-hover:inline-block text-Neutral100">
                View Plans
              </span>
            </button>
          )}
        </div>

        <div className="flex items-center md:justify-center md:flex-col">
          <div className="md:w-[2px] flex-grow md:flex-grow-0 bg-Neutral200 md:h-full h-[2px]" />
          <div className="grid text-xs font-semibold border rounded-full shrink-0 place-items-center size-8 text-AccentRegular border-Neutral300">
            OR
          </div>
          <div className="md:w-[2px] flex-grow md:flex-grow-0 bg-Neutral200 md:h-full h-[2px]" />
        </div>

        <div className="flex flex-col flex-1 gap-3">
          <div className="flex items-center justify-between">
            <label className="text-xs font-bold text-Neutral900">
              Use a custom domain
            </label>
            {/* <span className="inline-flex items-center justify-between text-xs italic text-AccentRegular gap-0.5">
              <CheckMarkIcon width={16} color="#6D6DF2" />
              Current domain
            </span> */}
          </div>

          <div className="flex items-center gap-2 px-3 py-3 border rounded-md">
            <input
              type="text"
              className="flex-grow text-sm font-medium focus:outline-none text-Neutral900 placeholder:text-Neutral500"
              placeholder="example.example_company.com"
              name="domainVal"
              value={domainVal}
              onChange={(event) => {
                setIsError(null);
                setDomainVal(event.target.value);
              }}
              disabled={isLoading || domain?.subdomain}
            />
          </div>
          {isError && <p className="text-xs text-red-500">{isError}</p>}

          {/* UI-TODO: Disable this button if the user does not have white-labeling feature subscription */}
          {userIsAWhiteLabelSubscriber ? (
            <button
              type="button"
              onClick={() => handleUpdateDomainDetails()}
              disabled={isLoading || domain?.subdomain}
              className="w-full py-3 text-xs font-bold rounded text-Neutral200 bg-AccentRegular hover:bg-AccentMediumDark disabled:cursor-not-allowed"
            >
              {isLoading ? <LoadingIcon /> : "Use Custom Domain"}
            </button>
          ) : (
            <button
              className="w-full py-3 text-xs font-bold rounded group bg-AccentRegular disabled:cursor-not-allowed hover:bg-AccentMediumDark"
              onClick={() => setIsWhiteLabelFeaturesModalOpen(true)}
            >
              {/* Show this text by default */}
              <span className="group-hover:hidden text-Neutral500">
                Use Custom Domain
              </span>
              {/* Show this text on hover */}
              <span className="hidden group-hover:inline-block text-Neutral100">
                View Plans
              </span>
            </button>
          )}
        </div>
      </div>

      <Modal
        isOpen={cnameDetailsOpen}
        onRequestClose={() => setCnameDetailsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="CNAME Records Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 max-w-[100%] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
            <div className="flex items-center justify-between pb-5 border-b">
              <div className="flex items-center gap-6">
                <span className="text-lg font-semibold text-neutral-700">
                  {domainDetails?.data?.custom_domain_name}
                </span>
                <button
                  onClick={() => setIsReloadSite(true)}
                  className="text-xl text-blue-950"
                >
                  {isReloadSite ? <LoadingIcon /> : <>↻</>}
                </button>
              </div>
              <button
                type="button"
                disabled
                className={`ml-2 text-xs rounded px-2 py-1 ${
                  domainDetails?.data?.isDomainPropogated ===
                  true
                    ? "bg-green-500"
                    : "bg-orange-500"
                }`}
              >
                {domainDetails?.data?.isDomainPropogated ? "ISSUED" : 'PENDING'}
              </button>
            </div>
            <br />
            <p className="mb-6 font-medium text-neutral-500">
              Copy the following DNS Records into your hosting provider. If you
              have questions please refer to our{" "}
              
              <Link
                to={"https://www.clientmanager.io/cm-custom-domain"}
                target="_blank"
                className="font-medium text-AccentRegular"
              >
                <span>documentation</span>
              </Link>
              .
            </p>
            <div className="flex items-center gap-4 mb-6 text-neutral-700">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setTab(1)}
              >
                <span
                  className={`px-4 py-2.5 text-xs ${
                    domainDetails?.data?.acm_certificateArn?.domain_details[0]
                      ?.status === "SUCCESS" &&
                    domainDetails?.data?.acm_certificateArn?.domain_details[1]
                      ?.status === "SUCCESS"
                      ? "bg-green-400 text-black"
                      : "bg-neutral-400 text-white"
                  }   rounded-full`}
                >
                  1
                </span>
                <div className={`text-sm font-medium`}>Certificate Records</div>
              </div>
              <div className="w-[100px] h-[2px] bg-neutral-400"></div>
              <div
                className={`flex gap-2 items-center ${
                  domainDetails?.data?.cloudfront?.domain_name
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                onClick={() =>
                  domainDetails?.data?.cloudfront?.domain_name && setTab(2)
                }
              >
                <span
                  className={`px-4 py-2.5 text-xs ${
                    domainDetails?.data?.isDomainPropogated
                      ? "bg-green-400 text-black"
                      : "bg-neutral-400 text-white"
                  } rounded-full`}
                >
                  2
                </span>
                <div className="text-sm font-medium">Domain</div>
              </div>
            </div>
            <table className="min-w-full overflow-x-auto bg-white border shadow-sm border-Neutral400">
              <thead className="bg-[#F5F5F7] text-neutral-700">
                <tr>
                  <th className="w-1/12 px-4 py-3 text-sm font-semibold text-left uppercase border"></th>
                  <th className="w-1/12 px-4 py-3 text-sm font-semibold text-center uppercase border">
                    IS Verified
                  </th>
                  <th className="w-1/12 px-4 py-3 text-sm font-semibold text-left uppercase border">
                    Dns Type
                  </th>
                  <th className="w-1/2 px-4 py-3 text-sm font-semibold text-left uppercase border">
                    Name
                  </th>
                  <th className="w-1/2 px-4 py-3 text-sm font-semibold text-left uppercase border">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {tab === 1 &&
                  domainDetails?.data?.acm_certificateArn?.domain_details
                    ?.length &&
                  domainDetails?.data?.acm_certificateArn?.domain_details?.map(
                    (record, index) => {
                      return (
                        <tr key={index} className={`border-b `}>
                          <td className="w-1/12 px-4 py-3 text-sm font-medium text-left border text-neutral-800">
                            ACM Certificate
                          </td>
                          <td className="w-1/12 px-4 py-3 text-sm font-medium text-center border text-neutral-800">
                            {record?.status === "SUCCESS" ? (
                              <span className="inline-flex bg-green-400 rounded-full">
                                <CheckMarkIcon width={16} color="#028d17" />
                              </span>
                            ) : (
                              <span className="inline-flex rounded-full">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#9e0d08"
                                  class="bi bi-x-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                                </svg>{" "}
                              </span>
                            )}
                          </td>
                          <td className="w-1/12 px-4 py-3 text-base font-medium text-left border text-neutral-800">
                            CNAME
                          </td>
                          <td className="w-1/2 px-4 py-3 text-left border">
                            <div className="flex items-center">
                              <span
                                className={`truncate text-base  ${
                                  copyName[index] === "Copied"
                                    ? "text-neutral-950"
                                    : "text-neutral-800"
                                }`}
                              >
                                {record?.cname_name}
                              </span>
                              <button
                                type="button"
                                onClick={() =>
                                  handleCopy(record?.cname_name, index, "name")
                                }
                                className={`ml-2 text-center ${
                                  copyName[index] === "Copied"
                                    ? "text-neutral-950"
                                    : "text-Neutral700"
                                }`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                                  />
                                </svg>
                              </button>
                            </div>
                          </td>
                          <td className="w-1/2 px-4 py-3 text-left border">
                            <div className="flex items-center">
                              <span
                                className={`truncate text-base ${
                                  copyValue[index] === "Copied"
                                    ? "text-neutral-950"
                                    : "text-neutral-800"
                                }`}
                              >
                                {record?.cname_validation}
                              </span>
                              <button
                                type="button"
                                onClick={() =>
                                  handleCopy(
                                    record?.cname_validation,
                                    index,
                                    "value"
                                  )
                                }
                                className={`ml-2 ${
                                  copyValue[index] === "Copied"
                                    ? "text-neutral-900"
                                    : "text-Neutral700"
                                }`}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="size-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                                  />
                                </svg>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                {tab === 2 && domainDetails?.data?.cloudfront?.domain_name && (
                  <tr>
                    <td className="w-1/12 px-4 py-3 text-sm font-medium text-left border text-neutral-800">
                      clodefront
                    </td>
                    <td className="w-1/12 px-4 py-3 text-sm font-medium text-center border text-neutral-800">
                      {domainDetails?.data?.isDomainPropogated ? (
                        <span className="inline-flex bg-green-400 rounded-full">
                          <CheckMarkIcon width={16} color="#028d17" />
                        </span>
                      ) : (
                        <span className="inline-flex rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#9e0d08"
                            class="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                          </svg>{" "}
                        </span>
                      )}
                    </td>
                    <td className="w-1/12 px-4 py-3 text-base font-medium text-left border text-neutral-800">
                      CNAME
                    </td>
                    <td className="w-1/2 px-4 py-3 text-left border">
                      <div className="flex items-center">
                        <span
                          className={`truncate text-base  ${
                            copyName[3] === "Copied"
                              ? "text-neutral-950"
                              : "text-neutral-800"
                          }`}
                        >
                          {domainDetails?.data?.custom_domain_name}
                        </span>
                        <button
                          type="button"
                          onClick={() =>
                            handleCopy(
                              domainDetails?.data?.custom_domain_name,
                              3,
                              "name"
                            )
                          }
                          className={`ml-2 text-center ${
                            copyName[3] === "Copied"
                              ? "text-neutral-950"
                              : "text-Neutral700"
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                    <td className="w-1/2 px-4 py-3 text-left border">
                      <div className="flex items-center">
                        <span
                          className={`truncate text-base ${
                            copyValue[3] === "Copied"
                              ? "text-neutral-950"
                              : "text-neutral-800"
                          }`}
                        >
                          {domainDetails?.data?.cloudfront.domain_name}
                        </span>
                        <button
                          type="button"
                          onClick={() =>
                            handleCopy(
                              domainDetails?.data?.cloudfront.domain_name,
                              3,
                              "value"
                            )
                          }
                          className={`ml-2 ${
                            copyValue[3] === "Copied"
                              ? "text-neutral-900"
                              : "text-Neutral700"
                          }`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setCnameDetailsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <FeaturesModal
        isOpen={isWhiteLabelFeaturesModalOpen}
        setIsOpen={setIsWhiteLabelFeaturesModalOpen}
        data={{
          title: "Customise what your clients see...",
          features: [
            'Remove "Made with ClientManager"',
            "Use your company logo & domain",
            "Modify background & button colours",
            "And more...",
          ],
          onViewPlanClick: () => {
            navigate("/white-label/plans");
          },
        }}
      />
    </section>
  );
}

export default ClientPortalDomainCard;
