import { Tab } from "@headlessui/react";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import CalenderViewContent from "../../components/Dashboard-v2/CalenderViewContent";
import OverviewContent from "../../components/Dashboard-v2/OverviewContent";
import OverviewContentForTeamMembers from "../../components/Dashboard-v2/OverviewContentForTeamMember";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import useIsAdminAndPM from "../../hooks/useIsAdminAndPM";
import {
  getDashboardCountAction,
  getTasksCountAction,
  getYearlyTimeTrackingCountAction,
} from "../../store/actions/dashboard-v2";
import NewContentAlertModal from "../../components/Modals/NewContentAlertModal";

function Dashboard2() {
  const dispatch = useDispatch();
  const isAdminOrPM = useIsAdminAndPM();

  const fetchAllData = useCallback(() => {
    dispatch(getDashboardCountAction());
    dispatch(getTasksCountAction());
    dispatch(getYearlyTimeTrackingCountAction());
  }, [dispatch]);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return (
    <div>
      <AdminNavbar
        title="Dashboard"
        subtitle="A bird's eye view of what's cooking."
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <Tab.Group as="div" className="flex flex-col gap-6 py-6">
          <Tab.List className="flex items-center gap-8">
            <Tab className="text-xs font-bold tracking-wide uppercase text-Neutral500 ui-selected:text-Neutral700 focus:outline-none">
              Overview
            </Tab>
            <Tab className="text-xs font-bold tracking-wide uppercase text-Neutral500 ui-selected:text-Neutral700 focus:outline-none">
              Calendar
            </Tab>
          </Tab.List>
          <Tab.Panels>
            {isAdminOrPM ? (
              <OverviewContent />
            ) : (
              <OverviewContentForTeamMembers />
            )}
            <CalenderViewContent />
          </Tab.Panels>
        </Tab.Group>
      </div>

      {/* Triggers automatically */}
      <NewContentAlertModal />
    </div>
  );
}

export default Dashboard2;
