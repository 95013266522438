import Modal from "react-modal";
import { useDispatch } from "react-redux";

import CloseBtn from "../../assets/Images/close-btn.svg";
import { CancelLemonsqueezyWhiteLabelSubscription } from "../../store/actions/authAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function ConfirmWhiteLabelCancelModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(CancelLemonsqueezyWhiteLabelSubscription(setIsOpen));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Confirm Deletion of Signed Proposal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="flex flex-col items-center justify-center p-5">
          <div className="w-full">
            <p className="text-xs text-Neutral700 tracking-[4%] font-semibold">
              CANCEL WHITE LABEL CONFIRMATION
            </p>
            <div className="h-[1px] border-b mt-2" />
          </div>

          <p className="w-[320px] text-sm text-Neutral800 text-center py-10">
            Are you sure you would like to cancel your white label subscription?
            Once you cancel it, you won't be able to recover it.
          </p>

          <div className="flex w-full gap-x-4">
            <button
              className="w-full py-4 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600"
              onClick={handleDelete}
            >
              Yes, delete
            </button>
            <button
              className="w-full py-4 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              No, Please keep it
            </button>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmWhiteLabelCancelModal;
