import copy from "copy-to-clipboard";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import LinkCopied from "../../assets/Images/CheckmarkAccent.svg";
import { DeleteMeetingAction } from "../../store/actions/meetingAction";
import CalendarIcon from "../Icons/CalendarIcon";
import ClockIcon from "../Icons/ClockIcon";
import TimeTrackingIcon from "../Icons/newicons/_TimeTrackingIcon";
import CancelMeetingModal from "../Modals/CancelMeetingModal";

function MeetingCard({ parentMeetingId, meetingDetails }) {
  const { _id, attendee_name, date, time_slot, time, link, attendee_email } =
    meetingDetails;
  const [copyStatus, setCopyStatus] = useState("Copy Meeting Link");
  const [openCancelMeetingModal, setOpenCancelMeetingModal] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteMeeting = async (e) => {
    try {
      dispatch(
        DeleteMeetingAction(parentMeetingId, _id, setOpenCancelMeetingModal)
      );
    } catch (error) {
      console.log("Error deleting meeting:", error);
    }
  };

  return (
    <>
      <div
        style={{ boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.08)" }}
        className="flex flex-col p-4 space-y-5 bg-white border rounded-xl"
      >
        <div className="flex justify-between">
          <div className="flex items-center space-x-3">
            <img
              src="https://clientmanager-documents-images.s3.amazonaws.com/profile/1672084410937.jpeg"
              alt="Profile"
              className="object-contain border rounded-full size-9 bg-Neutral200"
            />
            <div className="flex flex-col gap-0.5">
              <p className="font-medium">{attendee_name}</p>
              <p className="text-xs text-Neutral600">{attendee_email}</p>
            </div>
          </div>
          {link && (
            <span
              className="flex items-center justify-end h-8 mr-1 text-xs font-semibold rounded cursor-pointer text-AccentRegular"
              onClick={() => {
                setCopyStatus("Copied");
                copy(link);
                setTimeout(() => {
                  setCopyStatus("Copy Meeting Link");
                }, 1500);
              }}
              title={copyStatus}
              key={_id}
            >
              {copyStatus === "Copied" ? (
                <img src={LinkCopied} alt="" />
              ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.1818 8.36377H16.3636C16.8412 8.36377 17.314 8.45783 17.7552 8.64057C18.1964 8.82332 18.5973 9.09117 18.9349 9.42884C19.2726 9.7665 19.5405 10.1674 19.7232 10.6086C19.9059 11.0497 20 11.5226 20 12.0001C20 12.4777 19.9059 12.9505 19.7232 13.3917C19.5405 13.8329 19.2726 14.2338 18.9349 14.5714C18.5973 14.9091 18.1964 15.177 17.7552 15.3597C17.314 15.5424 16.8412 15.6365 16.3636 15.6365H14.1818M9.81818 15.6365H7.63636C7.15883 15.6365 6.68597 15.5424 6.24479 15.3597C5.8036 15.177 5.40273 14.9091 5.06507 14.5714C4.38312 13.8895 4 12.9646 4 12.0001C4 11.0357 4.38312 10.1108 5.06507 9.42884C5.74702 8.74689 6.67194 8.36377 7.63636 8.36377H9.81818"
                    stroke="var(--accent-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.09082 12H14.909" stroke="var(--accent-color)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
              )}
            </span>
          )}
        </div>

        <div className="flex items-center space-x-2">
          <div className="flex items-center justify-center flex-1 py-2 space-x-1 text-xs bg-gray-100 rounded">
            <CalendarIcon width={16} color="var(--accent-color)" />
            <span>{moment(date).format("DD MMM")}</span>
          </div>
          <div className="flex items-center justify-center flex-1 py-2 space-x-1 text-xs bg-gray-100 rounded">
            <ClockIcon width={16} color="var(--accent-color)" />
            <span>{time}</span>
          </div>
          <div className="flex items-center justify-center flex-1 py-2 space-x-1 text-xs bg-gray-100 rounded">
            <TimeTrackingIcon width={16} color="var(--accent-color)" />
            <span>{time_slot.replace(/minutes?/i, "min")}</span>
          </div>
        </div>

        <button
          className="w-full text-xs py-3 border rounded-[4px] border-AccentLight text-AccentRegular hover:text-red-600 hover:border-red-100"
          onClick={() => setOpenCancelMeetingModal(true)}
        >
          CANCEL MEETING
        </button>
      </div>

      <CancelMeetingModal
        meetingId={_id}
        isOpen={openCancelMeetingModal}
        setIsOpen={setOpenCancelMeetingModal}
        handleClick={handleDeleteMeeting}
      />
    </>
  );
}

export default MeetingCard;
