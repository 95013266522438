import moment from "moment";
import React, { useEffect, useState, Fragment } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Menu, Transition } from "@headlessui/react";
import ThreeDots from "../../../../../../assets/Images/three-dots.svg";
import EditIcon from "../../../../../../assets/Images/Chat-Edit-Icon.svg";
import TrashIcon from "../../../../../../assets/Images/delete-icon-servies.svg";
import {
  DeleteTaskCommentAction,
  UpdateTaskCommentActions,
} from "../../../../../../store/actions/taskCommentAction";
import {
  DeleteServiceCommentAction,
  UpdateServiceCommentActions,
} from "../../../../../../store/actions/serviceCommentAction";
import DraftEditor from "../../../../../comman/DraftEditor/DraftEditor";
import loader from "../../../../../../assets/Images/loader-icon.svg";

const EditComment = ({
  item,
  clientDetail,
  types,
  project_id,
  user_type = null,
  access_level,
}) => {
  const dispatch = useDispatch();
  const [members, setMembersList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(EditorState.createEmpty());
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const authSelector = useSelector((state) => state.auth);
  const authCollabSelector = useSelector((state) => state?.collab_auth);

  const handleRemove = (data) => {
    if (types === "task") {
      if (user_type) {
        dispatch(
          DeleteTaskCommentAction(data?._id, data?.task, project_id, user_type)
        );
      } else {
        dispatch(DeleteTaskCommentAction(data?._id, data?.task, project_id));
      }
    }

    if (types === "service")
      dispatch(DeleteServiceCommentAction(data?._id, data?.service));
  };

  useEffect(() => {
    if (item?.message) {
      setContent(EditorState.createWithContent(convertFromRaw(item?.message)));
    }
    if (item?.attachments) {
      setAttachments(item?.attachments);
    }
  }, [item]);

  const onEditorStateChange = (editorState) => {
    setContent(editorState);
  };

  const handleEdit = async () => {
    if (types === "task") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        "message",
        JSON.stringify(convertToRaw(content.getCurrentContent()))
      );
      formData.append("task_id", item?.task);
      formData.append("client_id", clientDetail?._id);
      attachments.forEach((file) => {
        if (!file?.url) {
          formData.append("files", file);
        }
      });
      const oldAttachments = attachments.filter(
        (item) => item?.url && !item?.isDelete
      );
      formData.append("attachments", JSON.stringify(oldAttachments));
      formData.append("_id", item?._id);
      if (user_type) {
        await dispatch(
          UpdateTaskCommentActions(
            formData,
            project_id,
            setIsEdit,
            setIsLoading,
            user_type
          )
        );
      } else {
        await dispatch(
          UpdateTaskCommentActions(
            formData,
            project_id,
            setIsEdit,
            setIsLoading
          )
        );
      }
    }
    if (types === "service") {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        "message",
        JSON.stringify(convertToRaw(content.getCurrentContent()))
      );
      formData.append("service_id", item?.service);
      formData.append("client_id", clientDetail?._id);
      attachments.forEach((file) => {
        if (!file?.url) {
          formData.append("files", file);
        }
      });
      const oldAttachments = attachments.filter(
        (item) => item?.url && !item?.isDelete
      );
      formData.append("attachments", JSON.stringify(oldAttachments));
      formData.append("_id", item?._id);
      await dispatch(
        UpdateServiceCommentActions(formData, setIsEdit, setIsLoading)
      );
    }
  };

  useEffect(() => {
    const getcomment = clientDetail?.members?.map((item) => ({
      text: item?.user?.user_name,
      value: item?.user?.user_name,
      url: item?.user?._id,
    }));
    setMembersList(getcomment);
  }, []);

  return (
    <div className="flex pl-6 pr-8 flex-wrap items-center relative py-4 border-b-[1px] task-edit-comment">
      {item?.member?.profile || item?.client_member?.profile ? (
        <div className="rounded-full h-[32px] w-[32px] overflow-hidden">
          <img
            src={
              item?.member?.profile_path || item?.client_member?.profile_path
            }
            className="object-cover"
            alt=""
          />
        </div>
      ) : (
        <div className="h-[32px] w-[32px] flex items-center justify-center rounded-full text-Neutral700 bg-Neutral400 focus-visible:outline-none">
          <span className="text-[18px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold uppercase">
            {item?.member?.user_name[0] || item?.client_member?.user_name[0]}
          </span>
        </div>
      )}
      <div className="pl-3">
        <h6 className="text-Neutral900 font-semibold text-sm">
          {item?.member?.user_name || item?.client_member?.user_name}
        </h6>
        <p className="text-Neutral700 font-normal text-sm">
          {moment(item?.createdAt).format("HH:mm DD MMM")}
        </p>
      </div>
      {(authSelector?.user?._id &&
        authSelector?.user?._id === item?.member?._id) ||
      (authCollabSelector?.user?._id === item?.client_member?._id &&
        access_level === "full_access") ? (
        <Menu
          as="div"
          className="absolute top-[13px] right-[17px] flex items-center text-left"
        >
          <div className="flex items-center">
            {!isEdit && (
              <Menu.Button className="inline-flex justify-center w-full">
                <img src={ThreeDots} alt="Options" />
              </Menu.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 right-0 w-[100px] rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none ">
              <form method="POST" action="#">
                <Menu.Item>
                  {({ active }) => (
                    <div>
                      <div
                        className="w-[100px] text-left text-Neutral800 px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center cursor-pointer font-semibold hover:bg-Neutral200 hover:text-AccentRegular transition duration-300"
                        onClick={() => setIsEdit(true)}
                      >
                        <img className="mr-2" src={EditIcon} alt="" />
                        Edit
                      </div>
                      <div
                        className="text-Neutral800 w-[100px] text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200 hover:text-AccentRegular transition duration-300"
                        onClick={() => {
                          handleRemove(item);
                        }}
                      >
                        <img className="mr-2" src={TrashIcon} alt="" />
                        Delete
                      </div>
                    </div>
                  )}
                </Menu.Item>
              </form>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : null}

      {isEdit ? (
        <>
          <DraftEditor
            comment={content}
            setComment={setContent}
            clientDetail={clientDetail}
            attachments={attachments}
            setAttachments={setAttachments}
            isEdit={isEdit}
          />
          <div className={`flex w-full mb-2 mt-2`}>
            <button
              type="button"
              disabled={!isEdit || isLoading}
              className={`overflow-hidden relative rounded-[4px] focus:outline focus:outline-8 focus:outline-AccentMediumLight ${
                !isEdit
                  ? "bg-Neutral300 text-Neutral800"
                  : "bg-AccentRegular text-white"
              } focus-visible:outline-0 transition duration-300 ease-in-out button-hover mr-[5px] py-2 text-[10px] font-semibold w-full`}
              onClick={() => {
                if (isEdit) {
                  handleEdit();
                }
              }}
            >
              {isLoading ? (
                <div className="flex justify-center">
                  <img
                    src={loader}
                    alt="loader"
                    className="animate-spin relative drop-shadow-Texts z-10"
                  />
                </div>
              ) : (
                <span className="relative drop-shadow-Texts z-10">Save</span>
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                setIsEdit(false);
              }}
              className="hover:bg-AccentMediumLight rounded-[4px] ml-[5px] py-2 bg-Neutral300 text-Neutral800 text-[10px] font-semibold w-full focus-visible:outline-none focus:outline focus:outline-8 focus:outline-AccentMediumLight transition duration-300"
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <Editor
            type="text"
            name="message"
            autoFocus
            wrapperClassName="w-full text-Neutral800 focus:outline-none text-sm task-comment-editor"
            // editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
            editorState={content}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: members,
            }}
            toolbarHidden
            readOnly={!isEdit}
            hashtag={{}}
          />
          <div className="">
            {attachments?.length
              ? attachments?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="rounded-[4px] bg-Neutral200 text-Neutral700 text-[10px] font-semibold px-2 py-1 mt-2"
                    >
                      <a href={item?.url} target="_blank" rel="noreferrer">
                        {item?.name}
                      </a>
                    </div>
                  );
                })
              : null}
          </div>
        </>
      )}
      {item?.isedited && (
        <span className="cursor-pointer text-[8px] text-Neutral700 border border-[#EDEDF2] font-bold p-[2px] rounded-[4px]">
          Edited
        </span>
      )}
    </div>
  );
};

export default EditComment;
