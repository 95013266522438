function DarkNotificationIcon({ size = 24, className = "" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4993 8.39967C17.4993 6.96759 16.9304 5.59416 15.9178 4.58153C14.9051 3.56889 13.5317 3 12.0996 3C10.6675 3 9.29412 3.56889 8.28149 4.58153C7.26885 5.59416 6.69996 6.96759 6.69996 8.39967C6.69996 14.6993 4.00012 16.4992 4.00012 16.4992H20.1991C20.1991 16.4992 17.4993 14.6993 17.4993 8.39967Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6565 20.0986C13.4983 20.3714 13.2712 20.5978 12.998 20.7552C12.7247 20.9126 12.415 20.9954 12.0996 20.9954C11.7843 20.9954 11.4745 20.9126 11.2013 20.7552C10.928 20.5978 10.7009 20.3714 10.5427 20.0986"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DarkNotificationIcon;
