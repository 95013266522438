import { combineReducers } from "redux";

import assetsReducer from "./assets";
import authReducer from "./auth";
import clientReducer from "./client";
import collabAuthReducer from "./collab_auth";
import contactsReducer from "./contacts";
import dashboardV2Reducer from "./dashboard-v2";
import documentsReducer from "./documents";
import eventsReducer from "./events";
import foldersReducer from "./folders";
import goalsReducer from "./goals";
import invoiceReducer from "./invoices";
import leadReducer from "./lead";
import meetingReducer from "./meeting";
import memberReducer from "./members";
import modalsReducer from "./modals";
import noteReducer from "./notes";
import notificationsReducer from "./notifications";
import projectReducer from "./project";
import proposalsReducer from "./proposals";
import questionnaireReducer from "./questionnaire";
import serviceReducer from "./service";
import SubDomainReducer from "./subDomain";
import tasksReducer from "./tasks";
import templateReducer from "./template";
import trackingReducer from "./timeTracking";
import timerReducer from "./timer";
import autoEmailSendFollowupReducer from "./AutoEmailFollowup";
import customEmailReducer from "./WhiteLabel/customEmail";
import brandIdentitiesReducer from "./WhiteLabel/brandIdentity";
import customDoaminReducer from "./WhiteLabel/customDomain";
import contentReducer from "./new-content";

const appReducer = combineReducers({
  auth: authReducer,
  assets: assetsReducer,
  client: clientReducer,
  collab_auth: collabAuthReducer,
  contact: contactsReducer,
  dashboard: dashboardV2Reducer,
  document: documentsReducer,
  event: eventsReducer,
  folders: foldersReducer,
  goal: goalsReducer,
  invoice: invoiceReducer,
  lead: leadReducer,
  meeting: meetingReducer,
  member: memberReducer,
  modals: modalsReducer,
  note: noteReducer,
  notifications: notificationsReducer,
  project: projectReducer,
  proposals: proposalsReducer,
  questionnaire: questionnaireReducer,
  service: serviceReducer,
  subDomain: SubDomainReducer,
  tasks: tasksReducer,
  template: templateReducer,
  tracking: trackingReducer,
  timer: timerReducer,
  followupSchedule: autoEmailSendFollowupReducer,
  customEmail: customEmailReducer,
  brandIdentity: brandIdentitiesReducer,
  customDomain: customDoaminReducer,
  contentSwitch: contentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
