import clx from "classnames";

import prepareTasksAndTimeCounter from "../../../../utils/prepareTasksAndTimeCounter";
import BarChartIcon from "../../../Icons/BarChartIcon";

const filtersOptions = [
  {
    id: 1,
    title: "Total",
    icon: <BarChartIcon className="mr-3" />,
  },
  {
    id: 2,
    title: "Weekly",
    icon: (
      <span className="inline-block uppercase text-base font-semibold text-Neutral900 leading-[20px] mr-3 px-[7px]">
        W
      </span>
    ),
  },
  {
    id: 3,
    title: "Monthly",
    icon: (
      <span className="inline-block uppercase text-base font-semibold text-Neutral900 leading-[20px] mr-3 px-[7px]">
        M
      </span>
    ),
  },
];

function TimeTrackingFilter({
  className = "",
  active = "Total",
  setActive = () => {},
  totalTasks = 0,
}) {
  const filters = filtersOptions.map((item) => (
    <label
      key={item?.id}
      className={`w-full h-[40px] flex items-center rounded justify-between px-3 py-2 pr-4 cursor-pointer ${
        active === item.title ? "opacity-100 bg-AccentLight" : "opacity-70"
      }`}
    >
      <div className="flex items-center">
        {item?.icon}
        <input
          type="radio"
          name="task"
          id={item?.title}
          className="mr-4 visually-hidden"
          value={item?.title}
          onChange={() => setActive(item?.title)}
          checked={active === item?.title}
        />
        <span className="uppercase text-[10px] font-bold text-AccentDark leading-[14px] cursor-pointer">
          {item?.title}
        </span>
      </div>
      {active === item.title && (
        <span className="flex items-center justify-center rounded-full bg-AccentDark w-[20px] h-[20px] text-Neutral000 text-[10px] leading-[100%] font-bold">
          {prepareTasksAndTimeCounter(totalTasks)}
        </span>
      )}
    </label>
  ));

  return (
    <div
      className={clx(
        "flex flex-col items-center justify-between gap-y-2 w-[148px] mr-7",
        className
      )}
    >
      {filters}
    </div>
  );
}

export default TimeTrackingFilter;
