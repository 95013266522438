import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  DeleteClientAction,
  ResoreArchivedClientAction,
  GetArchivedClientAction,
} from "../../store/actions/clientAction";
import { useDispatch, useSelector } from "react-redux";
// import Profile1 from "../../assets/Images/profile-1.png";
import moment from "moment";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ClientItem = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [archiving, setArchiving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleArchiving = async (id) => {
    setArchiving(true);
    await dispatch(ResoreArchivedClientAction(id, navigate));
    setArchiving(false);
  };

  const handleDeleting = async (id) => {
    setDeleting(true);
    await dispatch(DeleteClientAction(id, navigate));
    setDeleting(false);
  };
  return (
    <div className="mt-6 flex items-start">
      <div>
        {/* <img
          className="max-w-[68px] h-[68px] rounded"
          src={Alenapress}
          alt=""
        /> */}
        <div
          className={`h-[68px] w-12 min-w-[68px] 5xl:h-16 5xl:w-16 rounded items-center justify-center bg-Neutral400 text-Neutral700`}
        >
          <span className=" text-[20px] 5xl:text-[24px] w-full h-full flex items-center justify-center rounded-full font-bold border border-Miscgrey capitalize">
            {item.client_name[0]}
          </span>
        </div>
      </div>
      <div className="md:flex ml-4 border-b-[0.5px] border-Neutral300 justify-between pb-6 w-[44vw] sm:w-full">
        <div className="flex justify-between flex-col">
          <div>
            <h3 className="text-sm font-bold text-Neutral900 leading-[1.4] pb-[2px]">
              {item.client_name}
            </h3>
            <p className="text-[10px] 5xl:text-sm text-Neutral800  mt-1 md:mt-0 leading-[13.6px] font-semibold">
              {item.email}
            </p>
          </div>
          <p className="text-Neutral800 text-[10px] 5xl:text-sm mt-1 md:mt-0 leading-[14px]">
            Archive Date: {moment(item.archived_at).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex flex-row flex-wrap sm:flex-nowrap md:flex-col mt-5 md-mt-0">
          <button
            className="text-[10px] 5xl:text-sm text-zinc-600 bg-Neutral300 flex items-center justify-center  h-[28px] 5xl:w-[160px] w-[122px] rounded font-semibold focus-visible:outline-none hover:bg-Neutral500 transition-all duration-300s"
            onClick={() => handleArchiving(item._id)}
            disabled={archiving}
          >
            {archiving ? "Restoring" : "Restore"}
          </button>
          <button
            className="text-[10px] 5xl:text-sm text-white bg-rose-400 flex items-center justify-center min-[555px]:ml-5 min-[555px]:mt-0  h-[28px] 5xl:w-[160px] w-[122px] mt-2 sm:mt-0 ml-0 sm:ml-5 md:ml-0 md:mt-2 rounded font-semibold focus-visible:outline-none hover:bg-rose-500 transition-all duration-300s"
            onClick={() => handleDeleting(item._id)}
            disabled={deleting}
          >
            {deleting ? "Deleting" : "Permanently Delete"}
          </button>
        </div>
      </div>
    </div>
  );
};

function ArchivedClientsListModal({ isOpen, setModal }) {
  const dispatch = useDispatch();
  const archivedClientList = useSelector(
    (state) => state.client.archivedClientList
  );

  useEffect(() => {
    dispatch(GetArchivedClientAction());
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setModal(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Archived client list Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[560px] sm:min-w-[480px] sm:w-[480px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp w-[85vw]">
        <div className="bg-white px-12 py-8 rounded-[8px]">
          <div className="h-[506px] block">
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-2"
                id="modal-title"
              >
                ARCHIVED CLIENTS
              </h3>
              <p className="text-Orange900 bg-Orange100 px-3 py-2 text-[12px] 5xl:text-sm leading-[1.4] font-normal rounded">
                <span className="font-semibold">Warning:</span> Deleting a
                client is an irreversible action.
              </p>
              {!archivedClientList.isLoading && archivedClientList.data.length
                ? archivedClientList.data.map((item, index) => {
                    return <ClientItem item={item} key={index} />;
                  })
                : null}
              {!archivedClientList.isLoading &&
              !archivedClientList.data.length ? (
                <h5 className="text-Neutral700 text-sm font-normal mb-3 text-center pt-10">
                  There are currently no archived clients.
                </h5>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default ArchivedClientsListModal;
