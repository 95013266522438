function VisibleIcon({ size = 14, ...props }) {
  return (
    <svg
      width={size}
      height={size - 1}
      viewBox="0 0 13 12"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 5.63599C1.5 5.63599 3.31818 2 6.5 2C9.68182 2 11.5 5.63599 11.5 5.63599C11.5 5.63599 9.68182 9.27199 6.5 9.27199C3.31818 9.27199 1.5 5.63599 1.5 5.63599Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49987 6.99958C7.25298 6.99958 7.8635 6.38912 7.8635 5.63608C7.8635 4.88304 7.25298 4.27258 6.49987 4.27258C5.74675 4.27258 5.13623 4.88304 5.13623 5.63608C5.13623 6.38912 5.74675 6.99958 6.49987 6.99958Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default VisibleIcon;
