import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

import CheckMarkIcon from "../Icons/CheckMarkIcon";
import UpgradeBadgeIcon from "../Icons/UpgradeBadgeIcon";
import { cn } from "../../utils";

function PlanStep1() {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [selectedPlan, setSelectedPlan] = useState("monthly");

  return (
    <div className="flex flex-col gap-12 py-12">
      <div>
        <h5 className="text-base font-semibold text-Neutral800">Step 1 of 2</h5>
        <h2 className="text-[32px] font-bold text-Neutral900">
          White-Label Feature
        </h2>
        <p className="text-base font-normal text-Neutral800">
          This is as an add-on to your current plan.
        </p>
      </div>

      <div className="flex flex-col gap-10 md:flex-row">
        <div
          className={cn(
            "p-5 pr-8 bg-Neutral000 rounded-2xl cursor-pointer relative transition ease-in-out select-none",
            selectedPlan === "monthly" &&
              "scale-110 shadow-2xl outline outline-2"
          )}
          onClick={() => setSelectedPlan("monthly")}
        >
          <div className="inline-flex items-center gap-1 px-3 py-2 pr-4 mb-3 rounded-3xl text-AccentRegular bg-AccentLight">
            <UpgradeBadgeIcon className="size-[18px]" />
            <span className="text-xs font-semibold uppercase text-AccentRegular">
              UPGRADE
            </span>
          </div>

          <h2 className="text-Neutral900 font-bold text-[32px] mb-4">
            Monthly
          </h2>

          <ul className="mb-5 space-y-2 text-xs text-Neutral900">
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              Remove "Made with ClientManager"
            </li>
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              Use your company logo & domain
            </li>
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              Modify background and accent colours
            </li>
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              And much more...
            </li>
          </ul>

          <div className="flex items-end justify-between gap-2 mb-14">
            <h3 className="font-bold text-7xl text-Neutral900">
              <span className="font-light text-Neutral500">$</span>15
            </h3>
            <span className="mb-2 text-xs font-semibold tracking-widest uppercase text-Neutral600">
              / per month
            </span>
          </div>

          <hr className="mb-4 border-Neutral300" />

          <p className="text-xs font-medium capitalize text-Neutral600">
            Cancel anytime, no fee
          </p>
        </div>

        <div
          className={cn(
            "p-5 pr-8 bg-Neutral900 rounded-2xl cursor-pointer relative transition ease-in-out select-none",
            selectedPlan === "lifetime" &&
              "scale-110 shadow-2xl outline outline-2 outline-AccentRegular"
          )}
          onClick={() => setSelectedPlan("lifetime")}
        >
          <div className="flex items-center justify-between mb-4">
            <div className="inline-flex items-center gap-1 px-3 py-2 pr-4 rounded-3xl text-AccentRegular bg-AccentLight">
              <UpgradeBadgeIcon className="size-[18px]" />
              <span className="text-xs font-semibold uppercase text-AccentRegular">
                UPGRADE
              </span>
            </div>

            <button className="px-3 py-1 text-base font-bold tracking-wider rounded-lg text-Neutral000 bg-AccentRegular">
              Save $$$
            </button>
          </div>

          <h2 className="text-Neutral100 font-bold text-[32px] mb-4">
            Lifetime
          </h2>

          <ul className="mb-5 space-y-2 text-xs text-Neutral100">
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              Remove "Made with ClientManager"
            </li>
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              Use your company logo & domain
            </li>
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              Modify background and accent colours
            </li>
            <li className="flex items-center gap-2">
              <CheckMarkIcon width={18} color="#34B288" />
              And much more...
            </li>
          </ul>

          <div className="flex items-end justify-between gap-2 mb-14">
            <h3 className="font-bold text-7xl text-Neutral100">
              <span className="font-light text-Neutral500">$</span>150
            </h3>
            <span className="mb-2 text-xs font-semibold tracking-widest uppercase text-Neutral600">
              / once off
            </span>
          </div>

          <hr className="mb-4 border-Neutral700" />

          <p className="text-xs font-medium capitalize text-Neutral600">
            Pay once, access forever
          </p>
        </div>
      </div>

      <div className="flex gap-6">
        <button
          disabled={!selectedPlan}
          className="px-4 py-2 text-base font-bold tracking-wider uppercase rounded bg-AccentRegular text-Neutral000 hover:bg-AccentMediumDark"
          onClick={() => {
            if (selectedPlan === "monthly") {
              window.location.href = `${process.env.REACT_APP_LEMONSQUEEZY_WHITE_LABEL_URL}?checkout[custom][user_id]=${user?._id}&checkout[custom][is_white_label]=true&checkout[email]=${user?.email}`;
            }

            if (selectedPlan === "lifetime") {
              window.location.href = `${process.env.REACT_APP_LEMONSQUEEZY_WHITE_LABEL_LIFETIME_URL}?checkout[custom][user_id]=${user?._id}&checkout[custom][is_white_label]=true&checkout[email]=${user?.email}`;
            }
          }}
        >
          Continue
        </button>
        <button
          className="px-4 py-2 text-base font-bold tracking-wider uppercase rounded bg-Neutral300 text-Neutral900 hover:bg-Neutral400"
          onClick={() => navigate(-1)}
        >
          Go Back
        </button>
      </div>
    </div>
  );
}

export default PlanStep1;
