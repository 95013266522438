import PlanStep1 from "../../components/White-Label/PlanStep1";

function WhiteLabelPlans() {
  return (
    <section className="relative flex flex-col items-center justify-center min-h-screen bg-Neutral200">
      <PlanStep1 />

      <div className="absolute bottom-5 right-5">
        <p className="text-sm font-medium text-Neutral800">
          Have a question? Email us at{" "}
          <a
            href="mailto:help@client.manager"
            className="font-semibold text-AccentRegular hover:text-AccentMediumDark"
          >
            help@client.manager
          </a>
        </p>
      </div>
    </section>
  );
}

export default WhiteLabelPlans;
