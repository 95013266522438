import React from "react";

function LightningIcon({
  width = 12,
  height = 14,
  color = "var(--accent-color)",
  filled = false,
  className = "",
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 12 14"
      fill={filled ? color : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      {filled ? (
        <path
          fill={color}
          color={color}
          d="M6.44444 2L2 8H6L5.55556 12L10 6H6L6.44444 2Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          color={color}
          d="M6.44444 2L2 8H6L5.55556 12L10 6H6L6.44444 2Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}

export default LightningIcon;
