import copy from "copy-to-clipboard";
import Modal from "react-modal";

import LinkIcon from "../../assets/Images/Link Icon.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function CopyLinkModalForInvoice({
  setCopyStatus,
  closeModal,
  linkIsOpen,
  url,
  handleEmailModal,
}) {
  return (
    <Modal
      isOpen={linkIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Copy Link Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
          <div>
            <div className="text-left">
              <div className="flex items-center justify-between w-full border-b border-Neutral200">
                <span className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2">
                  LINK COPIED
                </span>

                <img
                  src={LinkIcon}
                  alt=""
                  className="pb-2 cursor-pointer"
                  onClick={() => {
                    copy(url);
                    setCopyStatus("Copied");
                    setTimeout(() => {
                      setCopyStatus("Copy Link");
                    }, 1000);
                  }}
                />
              </div>
              <div className="flex flex-col gap-6 items-center justify-center w-[354px] py-10">
                <svg
                  width="107"
                  height="92"
                  viewBox="0 0 107 92"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M52.4575 0.303001C76.8197 0.303001 106.147 25.6099 106.147 50.089C106.147 74.568 66.6155 91.2822 42.2533 91.2822C17.891 91.2822 0.78125 64.4887 0.78125 40.0097C0.781249 15.5307 28.0953 0.303001 52.4575 0.303001Z"
                    fill="#F0F0FF"
                  />
                  <path
                    d="M46.2012 51.3635C47.3858 52.9472 48.8971 54.2576 50.6327 55.2059C52.3683 56.1541 54.2875 56.718 56.2602 56.8592C58.2329 57.0005 60.2128 56.7159 62.0658 56.0247C63.9188 55.3335 65.6015 54.2518 66.9997 52.8531L75.275 44.5778C77.7874 41.9766 79.1775 38.4927 79.1461 34.8764C79.1147 31.2601 77.6642 27.8009 75.107 25.2437C72.5498 22.6866 69.0906 21.236 65.4743 21.2046C61.8581 21.1732 58.3741 22.5634 55.7729 25.0757L51.0284 29.7926"
                    stroke="#292933"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M59.5997 43.4831C58.4151 41.8994 56.9038 40.589 55.1682 39.6408C53.4326 38.6925 51.5134 38.1287 49.5407 37.9874C47.5681 37.8461 45.5881 38.1307 43.7351 38.822C41.8821 39.5132 40.1994 40.5948 38.8012 41.9935L30.5259 50.2688C28.0136 52.8701 26.6234 56.354 26.6548 59.9702C26.6862 63.5865 28.1367 67.0457 30.6939 69.6029C33.2511 72.1601 36.7103 73.6106 40.3266 73.642C43.9428 73.6734 47.4268 72.2833 50.028 69.7709L54.7449 65.054"
                    stroke="#292933"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="text-sm font-normal text-center text-Neutral800 w-80">
                  Send this copied link to your client for them to{" "}
                  <span className="font-semibold">view the invoice online</span>
                </span>
              </div>

              <div className="mt-8">
                <button
                  onClick={(e) => {
                    copy(url);
                    setCopyStatus("Copied");
                    setTimeout(() => {
                      setCopyStatus("Copy Link");
                    }, 1000);
                    closeModal();
                  }}
                  className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular drop-shadow-Texts focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                >
                  <span className="relative z-10 drop-shadow-Texts">
                    Got it! I’ll send the link
                  </span>
                </button>

                <button
                  onClick={(e) => {
                    handleEmailModal();
                  }}
                  className="relative block w-full py-3 mt-4 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out border rounded text-AccentRegular bg-Neutral100 focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight border-AccentRegular hover:bg-Neutral300"
                >
                  SEND AS EMAIL
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => closeModal()}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default CopyLinkModalForInvoice;
