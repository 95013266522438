import { Disclosure } from "@headlessui/react";
import moment from "moment/moment";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router";

import { generateInitials } from "../../utils";
import TaskDetailModal from "../Modals/TaskDetailModal";
import ArrowFilter from "./icons/ArrowFilter";
import BoltIcon from "./icons/BoltIcon";
import BoxIcon from "./icons/BoxIcon";
import CalendarIcon from "./icons/CalendarIcon";
import PeopleIcon from "./icons/PeopleIcon";
import WarningIcon from "./icons/WarningIcon";

const generateDotColor = (status) => {
  const colorMap = {
    to_do: "bg-Red400",
    doing: "bg-Blue400",
    done: "bg-Green400",
  };
  return colorMap[status] ?? "bg-Neutral400";
};

function TaskRow({
  userImage,
  userName,
  incomplete,
  inProgress,
  completed,
  clients,
  projects,
  tasks,
}) {
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState(null);

  const openTaskModal = (data) => {
    navigate({ search: `?cardId=${data?._id}` });
  };

  return (
    <Disclosure>
      {({ open }) => (
        <div className="px-4 py-3 space-y-4 border cursor-pointer rounded-xl border-Neutral300 font-noto">
          <Disclosure.Button as={Fragment}>
            <div className="flex items-center">
              <div className="w-[40%] flex items-center gap-3">
                <div className="grid overflow-hidden rounded-full size-10 bg-Neutral200 place-items-center">
                  {userImage ? (
                    <img
                      src={userImage}
                      alt={userName}
                      className="object-cover size-full"
                    />
                  ) : (
                    <span className="text-xs font-semibold text-Neutral800">
                      {generateInitials(userName)}
                    </span>
                  )}
                </div>
                <h3
                  className={`text-base text-Neutral900 ${
                    open && "font-semibold"
                  }`}
                >
                  {userName}
                </h3>
              </div>
              <div className="w-[60%] flex items-center gap-3 justify-around">
                <div className="flex items-center gap-1 text-base text-Neutral800">
                  <BoltIcon className="text-Red400 size-4" />
                  <span className={`${open && "font-semibold"}`}>
                    {incomplete}
                  </span>
                </div>
                <div className="flex items-center gap-1 text-base text-Neutral800">
                  <BoltIcon className="text-Blue400 size-4" />
                  <span className={`${open && "font-semibold"}`}>
                    {inProgress}
                  </span>
                </div>
                <div className="flex items-center gap-1 text-base text-Neutral800">
                  <BoltIcon className="text-Green400 size-4" />
                  <span className={`${open && "font-semibold"}`}>
                    {completed}
                  </span>
                </div>
                <div className="flex items-center gap-1 text-base text-Neutral800">
                  <PeopleIcon className="text-Neutral700 size-5" />
                  <span className={`${open && "font-semibold"}`}>
                    {clients}
                  </span>
                </div>
                <div className="flex items-center gap-1 text-base text-Neutral800">
                  <BoxIcon className="text-Neutral700 size-4" />
                  <span className={`${open && "font-semibold"}`}>
                    {projects}
                  </span>
                </div>
              </div>
            </div>
          </Disclosure.Button>
          {open && <hr className="border-Neutral300" />}
          <Disclosure.Panel>
            <header className="grid grid-cols-5 w-full text-[10px] uppercase text-Neutral600">
              <div className="flex items-center col-span-2 gap-2">
                <BoltIcon className="size-4" />
                <span>Task</span>
                <ArrowFilter className="size-4 text-Blue500" />
              </div>
              <div className="flex items-center col-span-1 gap-2">
                <PeopleIcon className="size-5" />
                <span>Client</span>
              </div>
              <div className="flex items-center col-span-1 gap-2">
                <CalendarIcon className="size-4" />
                <span>Assigned Date</span>
              </div>
              <div className="flex items-center col-span-1 gap-2">
                <WarningIcon className="size-3" />
                <span>Due Date</span>
              </div>
            </header>

            {/* Tasks List Container */}
            <div className="flex flex-col gap-4 mt-3 md:max-h-[215px] overflow-y-auto">
              {/* Task Item */}
              {!!tasks?.length &&
                tasks.map((task) => {
                  const clientName =
                    task.client?.client_name ||
                    task.client?.contact_name ||
                    task.client?.business_name;
                  return (
                    <div
                      key={task._id}
                      className="grid items-center grid-cols-5 cursor-pointer"
                      onClick={() => {
                        setSelectedTask(task);
                        openTaskModal(task);
                      }}
                    >
                      <div className="flex items-center col-span-2 gap-3 pr-5">
                        <span
                          className={`flex-shrink-0 rounded-full size-1.5 ${generateDotColor(
                            task?.status
                          )}`}
                        />
                        <h3 className="text-sm truncate text-Neutral800">
                          {task?.title}
                        </h3>
                      </div>
                      <div className="flex items-center col-span-1 gap-2 pr-2">
                        <div className="grid overflow-hidden rounded-full shrink-0 size-5 bg-Neutral200 place-items-center">
                          {task?.client?.profile_path ? (
                            <img
                              src={task?.client?.profile_path}
                              alt={clientName}
                              className="object-cover size-full"
                            />
                          ) : (
                            <span className="text-[10px] font-medium text-Neutral700">
                              {generateInitials(clientName)}
                            </span>
                          )}
                        </div>
                        <h4 className="text-sm truncate text-Neutral800">
                          {clientName}
                        </h4>
                      </div>
                      <div className="col-span-1 pl-2">
                        <p className="text-sm text-Neutral800">
                          {moment(task?.createdAt).format("D MMM YYYY")}
                        </p>
                      </div>
                      <div className="flex items-center justify-between col-span-1 pl-2">
                        <p className="text-sm text-Neutral800">
                          {task?.date && moment(task.date).format("D MMM YYYY")}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Disclosure.Panel>

          {selectedTask && (
            <TaskDetailModal
              key={selectedTask?._id}
              clientId={selectedTask?.client?._id}
              types="task"
              projectId={selectedTask?.project?._id}
              columnsName={selectedTask?.project?.task_column}
              project={selectedTask?.project}
              column={selectedTask?.column}
              setSelectedTask={setSelectedTask}
              dashboardTask={selectedTask}
            />
          )}
        </div>
      )}
    </Disclosure>
  );
}

export default TaskRow;
