import React from "react";

function ContractIcon({ width = 20, className = "" }) {
  return (
    <svg
      width={width}
      height={width + 1}
      viewBox="0 0 24 25"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 3.98846H6.75C6.28587 3.98846 5.84075 4.17289 5.51256 4.50118C5.18437 4.82947 5 5.27472 5 5.73899V19.7432C5 20.2075 5.18437 20.6528 5.51256 20.981C5.84075 21.3093 6.28587 21.4938 6.75 21.4938H17.25C17.7141 21.4938 18.1592 21.3093 18.4874 20.981C18.8156 20.6528 19 20.2075 19 19.7432V9.24005L13.75 3.98846Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 3.98846V9.24005H19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 13.6164H8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 17.1174H8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 10.1153H9.375H8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ContractIcon;
