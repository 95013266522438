import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { GetEventsAction } from "../../../store/actions/eventAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MonthGridView({
  calenderViewDate,
  setIsEvent,
  setEditEvent,
}) {
  const dispatch = useDispatch();
  const [days, setDays] = useState([]);
  const [opneMenu, setOpneMenu] = useState(false);
  useEffect(() => {
    function getDaysForCalendarMonth(date) {
      var firstDayOfMonth = moment(date).startOf("month");
      var firstDayOfCal = firstDayOfMonth.clone().startOf("week");
      var lastDayOfMonth = firstDayOfMonth.clone().endOf("month");
      var lastDayOfCal = lastDayOfMonth.clone().endOf("week");
      var temp = firstDayOfCal.clone();
      var days = [];
      while (temp.isBefore(lastDayOfCal) && days.length < 42) {
        days.push({
          date: temp.format("YYYY-MM-DD"),
          day: temp.format("DD"),
          events: [],
          isCurrentMonth: moment(temp).isSame(date, "month"),
        });
        temp.add(1, "day");
      }
      while (days.length <= 42) {
        days.push({
          date: temp.format("YYYY-MM-DD"),
          day: temp.format("DD"),
          events: [],
          isCurrentMonth: moment(temp).isSame(date, "month"),
        });
        // if (days.length === 42) {

        dispatch(
          GetEventsAction(
            firstDayOfCal.format("YYYY-MM-DD"),
            temp.format("YYYY-MM-DD")
          )
        );
        // }
        temp.add(1, "day");
      }
      return days;
    }
    setDays(getDaysForCalendarMonth(calenderViewDate));
  }, [calenderViewDate]);
  const eventsData = useSelector((state) => state?.event?.events);
  useEffect(() => {
    const newDays = days.map((item) => {
      let events = eventsData.data.filter((e) =>
        moment(e?.end_date).isSame(item?.date, "date")
      );
      return { ...item, events: events };
    });
    if (newDays && newDays.length) {
      setDays(newDays);
    }
  }, [eventsData.start_date, eventsData.start_date, eventsData.data]);

  return (
    <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
      <div className="grid grid-cols-7 gap-px border-b-[0.5px] border-Neutral500 bg-Neutral400 text-center text-xs font-semibold leading-5 text-Neutral900 lg:flex-none">
        <div className="bg-Neutral000 py-2">
          S<span className="sr-only sm:not-sr-only">un</span>
        </div>
        <div className="bg-Neutral000 py-2">
          M<span className="sr-only sm:not-sr-only">on</span>
        </div>
        <div className="bg-Neutral000 py-2">
          T<span className="sr-only sm:not-sr-only">ue</span>
        </div>
        <div className="bg-Neutral000 py-2">
          W<span className="sr-only sm:not-sr-only">ed</span>
        </div>
        <div className="bg-Neutral000 py-2">
          T<span className="sr-only sm:not-sr-only">hu</span>
        </div>
        <div className="bg-Neutral000 py-2">
          F<span className="sr-only sm:not-sr-only">ri</span>
        </div>
        <div className="bg-Neutral000 py-2">
          S<span className="sr-only sm:not-sr-only">at</span>
        </div>
      </div>
      <div className="flex bg-Neutral300 text-xs leading-6 text-Neutral700 lg:flex-auto">
        <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
          {days.map((day) => (
            <div
              key={day.date}
              className={classNames(
                day.isCurrentMonth
                  ? "bg-white"
                  : "bg-Neutral200 text-Neutral700",
                "relative py-2 px-3 text-Neutral900 5xl:h-[152px] h-[140px]"
              )}
            >
              <time
                dateTime={day.date}
                className={
                  moment(day.date).isSame(new Date(), "day")
                    ? "flex h-6 w-6 5xl:h-7 5xl:w-7 items-center justify-center rounded-full bg-AccentRegular font-semibold text-white"
                    : undefined
                }
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
              <ReactTooltip
                place="bottom"
                textColor="#F5F5F7"
                className="tooltip-font-size"
                backgroundColor="#292933"
                id="test"
              >
                Design handoff to developers and project manager
              </ReactTooltip>
              {day.events.length > 0 && (
                <div
                  className={`mb-4 ${day.events.length > 2 ? "more-btn" : ""} `}
                >
                  <div className=" relative">
                    <ol
                      className={`mt-3 pr-[10px] pb-2 scrollbar-with ${
                        opneMenu
                          ? "overflow-auto h-[80px] pb-[15px]"
                          : "overflow-hidden h-[56px]"
                      }`}
                    >
                      {day.events.map((event) => (
                        <li
                          onClick={() => {
                            setIsEvent(true);
                            setEditEvent(event);
                          }}
                          key={event._id}
                          data-tip=""
                          data-for="test"
                        >
                          <p className="group flex cursor-pointer">
                            <p className="flex-auto truncate font-medium text-Neutral900">
                              {event.title}
                            </p>
                            <time
                              dateTime={event.datetime}
                              className="ml-3 hidden flex-none text-Neutral700 xl:block"
                            >
                              {event.time}
                            </time>
                          </p>
                        </li>
                      ))}
                    </ol>
                  </div>
                  {day.events.length > 2 && (
                    <button
                      onClick={() => setOpneMenu(true)}
                      type="button"
                      className={`text-Neutral900 w-full text-left ${
                        opneMenu ? "hidden" : ""
                      }`}
                    >
                      + {day.events.length - 2} more
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
          {days.map((day) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                day.isCurrentMonth ? "bg-white" : "bg-Neutral200",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-white",
                !day.isSelected && day.isToday && "text-AccentRegular ",
                !day.isSelected &&
                  day.isCurrentMonth &&
                  !day.isToday &&
                  "text-Neutral900",
                !day.isSelected &&
                  !day.isCurrentMonth &&
                  !day.isToday &&
                  "text-Neutral900",
                "flex h-14 flex-col py-2 px-3 hover:bg-Neutral200 focus:z-10 cursor-pointer"
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  day.isSelected &&
                    "flex h-6 w-6 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-AccentRegular",
                  day.isSelected && !day.isToday && "bg-Neutral900",
                  "ml-auto"
                )}
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
              <span className="sr-only">{day.events.length} events</span>
              {day.events.length > 0 && (
                <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                  {day.events.map((event) => (
                    <span
                      key={event.id}
                      className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
                    />
                  ))}
                </span>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MonthGridView;
