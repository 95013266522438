import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import formatCurrency from "../../utils/currencyFormatter";
import BarChart from "./Barchart";
import ChevronDown from "./icons/ChevronDown";
import { getYearlyInvoiceCountAction } from "../../store/actions/dashboard-v2";
import Spinner from "../Spinner";

function ChartArea({ isAdmin = true }) {
  const dispatch = useDispatch();
  const yearlyInvoiceCount = useSelector(
    (state) => state.dashboard.yearlyInvoiceCount
  );
  const yearlyTimeTrackingCount = useSelector(
    (state) => state.dashboard.yearlyTimeTrackingCount
  );

  const [invoiceOverviewFilter, setInvoiceOverviewFilter] = useState("month");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getYearlyInvoiceCountAction(invoiceOverviewFilter, setLoading));
  }, [dispatch, invoiceOverviewFilter]);

  return (
    <div className="flex flex-col flex-1 gap-5 overflow-hidden bg-Blue100 rounded-3xl">
      <div className="flex flex-col gap-6 p-4 bg-Blue200 rounded-b-[36px] max-h-full">
        {/* Chart Header */}
        <div className="flex items-center justify-between">
          <h3 className="text-base font-medium text-Blue900">
            {isAdmin ? "Invoices Overview" : "Your Hours"}
          </h3>
          <Menu as="div" className="relative">
            <Menu.Button className="inline-flex items-center gap-1 py-1.5 pl-3 pr-2 text-sm font-medium bg-Blue100 rounded-3xl text-Blue900">
              {invoiceOverviewFilter === "month" ? "Monthly" : "Weekly"}
              {loading ? (
                <Spinner
                  size={16}
                  className="ml-1.5"
                  spinnerBg="#CEDEFD"
                  thumbColor="var(--accent-color)" // #406ABF
                />
              ) : (
                <ChevronDown className="var(--accent-color) size-4 md:size-5" />
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                as="div"
                className="absolute z-20 w-full p-1 mt-1 border divide-y rounded-md shadow-xl bg-Neutral000 border-Neutral300 divide-Blue100"
              >
                <Menu.Item
                  as="button"
                  type="button"
                  className="w-full p-1 text-sm text-left rounded text-Neutral800 hover:bg-Blue100"
                  onClick={() => setInvoiceOverviewFilter("week")}
                >
                  Weekly
                </Menu.Item>
                <Menu.Item
                  as="button"
                  type="button"
                  className="w-full p-1 text-sm text-left rounded text-Neutral800 hover:bg-Blue100"
                  onClick={() => setInvoiceOverviewFilter("month")}
                >
                  Monthly
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {/* Chart Body */}
        <BarChart
          data={
            isAdmin
              ? yearlyInvoiceCount.data?.monthwiseTotal
              : yearlyTimeTrackingCount.data?.monthWiseHoursAndAmount
          }
        />
      </div>
      {/* Stats Boxes */}
      <div className="flex justify-around mb-4">
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-medium md:text-base text-Blue900/40">
            Clients
          </p>
          <h4 className="text-Blue900 text-sm md:text-[20px] font-bold">
            {isAdmin
              ? yearlyInvoiceCount.data?.clientsLength
              : yearlyTimeTrackingCount.data?.clients}
          </h4>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-medium md:text-base text-Blue900/40">
            {isAdmin ? "Sent" : "Projects"}
          </p>
          <h4 className="text-Blue900 text-sm md:text-[20px] font-bold">
            {isAdmin
              ? yearlyInvoiceCount.data?.sentInvoices
              : yearlyTimeTrackingCount.data?.projects}
          </h4>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-medium md:text-base text-Blue900/40">
            This Month
          </p>
          <h4 className="text-Blue900 text-sm md:text-[20px] font-bold">
            {isAdmin
              ? `${
                  formatCurrency(yearlyInvoiceCount.data?.currentMonthTotal) ||
                  ""
                }`
              : `${yearlyTimeTrackingCount.data?.currentMonthTotalMinutes}`}
          </h4>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-medium md:text-base text-Blue900/40">
            {isAdmin ? "Value" : "Total"}
          </p>
          <h4 className="text-Blue900 text-sm md:text-[20px] font-bold">
            {isAdmin
              ? `${formatCurrency(yearlyInvoiceCount.data?.totalAmounts) || ""}`
              : yearlyTimeTrackingCount.data?.totalCurrentYearMinutes}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default ChartArea;
