import { Tab } from "@headlessui/react";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import CloseBtn from "../../assets/Images/close-btn.svg";
import { GetUserPlanDetailAction } from "../../store/actions/authAction";
import { getMemberList } from "../../store/actions/memberAction";
import PaymentDetail from "../Accountplan/PaymentDetail";
import CancelAccountModal from "../Modals/CancelAccountModal";
import CreateMemberModal from "../Modals/CreateMemberModal";
import RemoveMemberModal from "../Modals/RemoveMemberModal";
import Profile from "../Settings/Profile";
import Security from "../Settings/Security";
import TeamMember from "../Settings/TeamMember";
import DiscountOfferModal from "../Modals/30PercentDiscountOfferModal";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function AccountTab() {
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.auth.user);
  const userAccountDetail = useSelector((state) => state.auth.accountDetail);
  const members = useSelector((state) => state.member.members);

  const [cancelPlan, setCancelPlan] = useState(false);
  const [createMemberModal, setCreateMemberModal] = useState(false);
  const [modalIsOpen, setIsTeamMemberModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [removeMember, setRemoveMember] = useState(null);
  const [isWarning, setIsWarning] = useState(false);
  const [modalDiscount, setModalDiscount] = useState(false);

  const location = useLocation();
  const signInPath = location.pathname.startsWith("/client-collab");

  useEffect(() => {
    if (loggedInUser && !loggedInUser?.invited && !signInPath) {
      dispatch(GetUserPlanDetailAction());
    }
    if (!members?.data?.length && !signInPath) {
      dispatch(getMemberList());
    }
  }, [dispatch, loggedInUser, members?.data?.length, signInPath]);

  const handleRemoveId = (member) => {
    setRemoveMember(member);
    setOpenModal(true);
  };

  return (
    <Tab.Panel className="5xl:px-[96px] px-6 md:px-0 w-full pt-0">
      <div className="flex flex-col gap-10 md:gap-16 md:flex-row">
        <div className="flex flex-col gap-10">
          <Profile logedinUser={loggedInUser} />
          <Security />
        </div>

        <div className="flex flex-col gap-10">
          {loggedInUser?.invited === false && (
            <PaymentDetail
              data={
                userAccountDetail?.response && userAccountDetail?.response[0]
                  ? userAccountDetail?.response[0]
                  : null
              }
              isLoading={userAccountDetail?.isLoading}
              planData={userAccountDetail?.planData}
              setcancelPlan={setCancelPlan}
              lemonsqueezy={userAccountDetail?.response}
            />
          )}

          {loggedInUser?.invited === false ||
          (loggedInUser?.owner && loggedInUser?.invited === true) ? (
            <TeamMember
              setCreateMemberModal={setCreateMemberModal}
              userAccountDetail={userAccountDetail}
              setIsOpen={setIsTeamMemberModalOpen}
              logedinUser={loggedInUser}
              member={members}
              handleRemoveId={handleRemoveId}
            />
          ) : null}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsTeamMemberModalOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Archived client list Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
            <h6 className="uppercase font-medium text-xs text-Neutral700 tracking-[0.04em] pb-2 border-b-[0.56px] border-Neutral300 w-full">
              how it works
            </h6>
            <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
              <div className="w-full">
                <h3 className="text-xs font-bold uppercase text-Neutral900">
                  General
                </h3>
                <p className="text-Neutral600 font-medium text-[10px]">
                  Ideal for <span className="font-bold">employees</span>
                </p>
              </div>
              <div className="md:min-w-[225px]">
                <p className="text-xs font-normal text-right">
                  This is the most restricted role preventing members from
                  viewing sensitive client & project information.
                </p>
              </div>
            </div>
            <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
              <div className="w-full">
                <h3 className="text-xs font-bold uppercase text-Neutral900">
                  Project Manager
                </h3>
                <p className="text-Neutral600 font-medium text-[10px]">
                  Ideal for <span className="font-bold">managers</span>
                </p>
              </div>
              <div className="md:min-w-[225px]">
                <p className="text-xs font-normal text-right">
                  A supervisory role, a member assigned this can view and access
                  most of the app but does not have full access.
                </p>
              </div>
            </div>
            <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
              <div className="w-full">
                <h3 className="text-xs font-bold uppercase text-Neutral900">
                  Admin
                </h3>
                <p className="text-Neutral600 font-medium text-[10px]">
                  Ideal for <span className="font-bold">owners</span>
                </p>
              </div>
              <div className="md:min-w-[225px]">
                <p className="text-xs font-normal text-right">
                  Has full access and can change permissions for other users.
                </p>
              </div>
            </div>
            <h6 className="italic font-medium text-xs text-Neutral700 tracking-[0.04em] pt-6">
              Only Admin + PM roles can view/edit contracts, invoices,
              proposals, leads pipeline, and hourly rates.
            </h6>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsTeamMemberModalOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>

      <CancelAccountModal
        setcancelPlan={setCancelPlan}
        setmodalDiscount={setModalDiscount}
        cancelPlan={cancelPlan}
        planData={userAccountDetail?.planData}
        cancle_url={userAccountDetail?.response?.[0]?.cancel_url}
        setIsWarning={setIsWarning}
      />
          <DiscountOfferModal
            modalDiscount={modalDiscount}
            setmodalDiscount={setModalDiscount}
            cancle_url={userAccountDetail?.response?.[0]?.cancel_url}
            payment_gateway={userAccountDetail?.planData?.payment_gateway}
            logedinUser={loggedInUser}
            isWarning={isWarning}
            setIsWarning={setIsWarning}
          />
      <RemoveMemberModal
        setModal={setOpenModal}
        isOpen={openModal}
        member={removeMember}
      />

      <CreateMemberModal
        isOpen={createMemberModal}
        setModal={setCreateMemberModal}
      />
    </Tab.Panel>
  );
}

export default AccountTab;
