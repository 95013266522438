import React, { useState, useRef, useEffect } from "react";
import { useField } from "formik";
import CustomErrorMessage from "./CustomErrorMessage";

function AddressAutocomplete({ label, ...props }) {
  const [field, helpers] = useField(props);
  const [suggestions, setSuggestions] = useState([]);
  const containerRef = useRef(null);

  const handleInputChange = async (e) => {
    const query = e.target.value;
    helpers.setValue(query);

    if (query?.length > 2) {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            query
          )}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
        );
        const data = await response.json();
        setSuggestions(data.features);
      } catch (error) {
        console.error("Error fetching address suggestions", error);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full" ref={containerRef}>
      <label
        htmlFor={props.id || props.name}
        className="block mb-2 text-zinc-800 text-xs font-bold"
      >
        {label}
        {" "}
        <span className="text-xs text-gray-400">(Optional)</span>
      </label>
      <input
        type="text"
        {...field}
        onChange={handleInputChange}
        placeholder="Enter address"
        className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none w-full  md:w-80"
      />
      {suggestions?.length > 0 && (
        <ul
          style={{ maxHeight: "200px" }}
          className="absolute z-10 w-full overflow-y-auto"
        >
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => {
                helpers.setValue(suggestion.place_name);
                setSuggestions([]);
              }}
              className="bg-white border border-gray-100 rounded p-3 text-neutral-500 text-sm font-medium outline-none w-80 cursor-pointer hover:bg-gray-100"
            >
              {suggestion.place_name}
            </li>
          ))}
        </ul>
      )}
      <CustomErrorMessage name={props.name} />
    </div>
  );
}

export default AddressAutocomplete;
