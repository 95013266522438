import React, { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { useDispatch } from "react-redux";
import loader from "../../../../../../assets/Images/loader-icon.svg";
import { CreateTaskCommentActions } from "../../../../../../store/actions/taskCommentAction";
import { CreateServiceCommentActions } from "../../../../../../store/actions/serviceCommentAction";
import SendIcon from "./SendIcon";
import DraftEditor from "../../../../../comman/DraftEditor/DraftEditor";

const AddComment = ({
  isNew,
  task_id,
  clientDetail,
  types,
  project_id,
  user_type,
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const handleSubmit = async () => {
    if (comment) {
      setLoading(true);
      if (types === "task") {
        const formData = new FormData();
        formData.append(
          "message",
          JSON.stringify(convertToRaw(comment.getCurrentContent()))
        );
        formData.append("task_id", task_id);
        formData.append("client_id", clientDetail._id);
        attachments.forEach((file) => {
          formData.append("files", file);
        });
        await dispatch(
          CreateTaskCommentActions(
            formData,
            project_id,
            setLoading,
            setComment,
            EditorState,
            setAttachments,
            user_type
          )
        );
      }
      if (types === "service") {
        const formData = new FormData();
        formData.append(
          "message",
          JSON.stringify(convertToRaw(comment.getCurrentContent()))
        );
        formData.append("service_id", task_id);
        formData.append("client_id", clientDetail._id);
        attachments.forEach((file) => {
          formData.append("files", file);
        });
        await dispatch(
          CreateServiceCommentActions(
            formData,
            setLoading,
            setComment,
            EditorState,
            setAttachments
          )
        );
      }
    }
  };

  return (
    <div
      className={`bottom-[8px] w-full sm:max-h-[22vh] bg-white border-Neutral300 px-3 py-3 border-t sm:overflow-auto z-10 task-add-comment rounded-bl-lg sm:rounded-bl-none rounded-br-lg`}
    >
      <DraftEditor
        comment={comment}
        setComment={setComment}
        clientDetail={clientDetail}
        attachments={attachments}
        setAttachments={setAttachments}
      />

      <div className="flex justify-end mt-[-20px]">
        <div className="flex items-center pl-3.5 border-l border-Neutral300">
          {loading ? (
            <div className="flex justify-center">
              <img
                src={loader}
                alt="loader"
                className="relative z-10 animate-spin drop-shadow-Texts"
              />
            </div>
          ) : (
            <SendIcon onClick={!isNew && handleSubmit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddComment;
