import { useEffect, useState } from "react";
import { generateInitials } from "../../utils";

const gradients = [
  "linear-gradient(to right, #2952A3, #CEDEFD)",
  "linear-gradient(to right, #406ABF, #CEDEFD)",
  "linear-gradient(to right, #5281E0, #CEDEFD)",
  "linear-gradient(to right, #73A2FF, #CEDEFD)",
  "linear-gradient(to right, #A1BEF7, #CEDEFD)",
];

function TimeTrackItemBar({ item, index, maxTime }) {
  const [animatedWidth, setAnimatedWidth] = useState(0);

  useEffect(() => {
    setAnimatedWidth(0);

    const timer = setTimeout(() => {
      const normalizedWidth = (item.total_hours / maxTime) * 100;
      setAnimatedWidth(normalizedWidth);
    }, 300); // Delay to start the animation

    return () => clearTimeout(timer);
  }, [item.total_hours, maxTime]);

  return (
    <div className="flex w-full group">
      <div className="z-10 grid overflow-hidden border-[1.5px] rounded-full shrink-0 outline outline-8 outline-Neutral100 bg-Neutral100 place-items-center size-8 border-Neutral100">
        {item?.user?.member?.profile_path ? (
          <img
            src={item?.user?.member?.profile_path}
            alt=""
            className="object-cover bg-Neutral100 size-full"
          />
        ) : (
          <span className="text-xs font-bold text-Neutral700">
            {generateInitials(item?.user?.member?.user_name)}
          </span>
        )}
      </div>
      <div className="flex w-full gap-2">
        <div
          style={{
            width: `${animatedWidth}%`,
            background: gradients[Math.min(index, gradients.length - 1)],
          }}
          className={`inline-flex overflow-hidden items-center justify-end px-4 text-xs font-semibold rounded-r-md text-Neutral100 bg-Blue300 transition-all duration-1000 ease-in-out`}
        >
          {item.total_hours?.toFixed(2)}
        </div>
        <div className="self-center hidden px-2 py-1 ml-1 text-xs font-semibold group-hover:block bg-Blue900 text-Neutral100 rounded-2xl">
          ${item?.total_amount?.toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export default TimeTrackItemBar;
