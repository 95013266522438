import axios from "axios";
import { toast } from "react-toastify";

export const GetDocumentActions = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_DOCUMENTS",
        payload: {
          isLoading: true,
        },
      });
      const { data } = await axios.get(
        `api/v1/documents/get-documents-by-client?id=${id}`
      );
      return dispatch({
        type: "SET_DOCUMENTS",
        payload: { client: id, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_DOCUMENTS_ERROR",
        payload: error,
      });
    }
  };
};

export const CreateDocumentActions = (
  value,
  type,
  setSubmitting,
  setErrors,
  closeModal
) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("client_id", value.client_id);
      formData.append("title", value.title);
      formData.append("type", value.type);
      formData.append("file_type", value.file_type);
      value?.file_type === "url" &&
        value?.url?.map((url) => {
          formData.append("url", url);
        });
      value?.file_type === "file" &&
        value.file?.map((file) => {
          formData.append("file", file);
        });
      const { data } = await axios.post(
        "api/v1/documents/add-documents",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      setSubmitting(false);
      closeModal();
      return dispatch({
        type: "SET_DOCUMENTS",
        payload: { data: { [type]: data.data } },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "CREATE_DOCUMENTS_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_DOCUMENTS_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateDocumentActions = (
  value,
  type,
  setSubmitting,
  setErrors,
  closeModal
) => {
  return async (dispatch) => {
    try {
      let formData = new FormData();
      formData.append("item_id", value.id);
      formData.append("document_id", value.document_id);
      formData.append("title", value.title);
      formData.append("type", value.type);
      formData.append("file_type", value.file_type);
      value?.file_type === "url" &&
        value?.url?.map((url) => {
          formData.append("url", url);
        });
      value?.file_type === "file" &&
        value.file?.map((file) => {
          if (file?.key) {
            formData.append("file[]", JSON.stringify(file));
          } else {
            formData.append("file", file);
          }
        });
      value?.remove_file?.length &&
        value.remove_file?.map((file) => {
          formData.append("remove_file[]", JSON.stringify(file));
        });
      const { data } = await axios.put(
        "api/v1/documents/update-documents",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      setSubmitting(false);
      closeModal();
      return dispatch({
        type: "SET_DOCUMENTS",
        payload: { data: { [type]: data.data } },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        setErrors(err);
        return dispatch({
          type: "UPDATE_DOCUMENTS_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_DOCUMENTS_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteDocumentsAction = (
  formData,
  type,
  setLoading,
  setaccountCencelled
) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/documents/delete-documents`, formData);
      setLoading(false);
      setaccountCencelled(false);
      return dispatch({
        type: "DELETE_DOCUMENT_SUCCESS",
        payload: { data: formData, type: type },
      });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_DOCUMENT_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_DOCUMENT_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const UploadExistingContract = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/contract/upload-existing-contract`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return dispatch({
        type: "SET_CONTRACT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_CONTRACT_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_CONTRACT_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetDocumentContractActions = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_DOCUMENTS",
        payload: {
          isLoading: true,
        },
      });
      const { data } = await axios.get(
        `api/v1/contract/get-content-by-client/${id}`
      );
      return dispatch({
        type: "SET_DOCUMENTS",
        payload: {
          client: id,
          data: {
            contract: data.data,
          },
        },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "GET_DOCUMENTS_ERROR",
        payload: error,
      });
    }
  };
};

export const SaveContractContent = (content, setUnsavedChanges) => {
  return async () => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content.content, "text/html");
      const sign = doc.getElementById("signature-block");
      if (sign) {
        sign.remove();
      }
      content.content = doc.body.innerHTML;

      const { data } = await axios.post(
        `api/v1/contract/save-content`,
        content
      );
      setUnsavedChanges(false);
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while Saving content");
      setUnsavedChanges(false);
      return {
        error: error,
      };
    }
  };
};

export const SaveContractTitle = (value, setUnsavedChanges) => {
  return async () => {
    try {
      const { data } = await axios.post(
        `api/v1/contract/save-title`,
        value
      );
      setUnsavedChanges(false);
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while Saving title");
      setUnsavedChanges(false);
      return {
        error: error,
      };
    }
  };
};

export const RemoveContractImage = (
  key,
  client,
  is_cover,
  SetIsDeleting,
  setImages
) => {
  return async () => {
    try {
      const { data } = await axios.post(`api/v1/contract/remove-images`, {
        key: key,
        client: client,
        is_cover: is_cover,
      });
      SetIsDeleting(false);
      if (setImages) {
        setImages({
          url: null,
          key: null,
        });
      }
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while Removing image");
      return {
        error: error,
      };
    }
  };
};

export const UpdateContractSignatureAction = (
  type,
  client,
  file,
  who,
  text,
  is_dotted,
  draw_signature,
  setIsSaving,
  setIsOpen,
  contractId,
  html
) => {
  return async () => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("who", who);
      formData.append("type", type);
      formData.append("client", client);
      formData.append("text", text);
      formData.append("is_dotted", is_dotted);
      formData.append("draw_signature", draw_signature);
      formData.append("contract", contractId);
      formData.append("html", html);
      const { data } = await axios.post(
        `api/v1/contract/add-update-signature/${contractId}`,
        formData
      );
      // isSuccess();
      setIsSaving(false);
      setIsOpen(false);

      return data?.data;
    } catch (error) {
      // haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const updateExistingContract = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/contract/update-existing-contract`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return dispatch({
        type: "UPDATE_CONTRACT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_CONTRACT_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_CONTRACT_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteDocumentsContratsAction = (
  id,
  setLoading,
  setIsOpen,
  setContract
) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/contract/delete/${id}`);
      setLoading(false);
      setIsOpen(false);
      setContract(null);
      return dispatch({
        type: "DELETE_DOCUMENT_CONTRATS_SUCCESS",
        payload: { isLoading: false, id: id },
      });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_DOCUMENT_CONTRATS_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_DOCUMENT_CONTRATS_ERROR",
          payload: { error },
        });
      }
    }
  };
};

export const GetContractContentByClientIdAction = (client_id, contract_id) => {
  return async () => {
    try {
      const { data } = await axios.post(
        `api/v1/contract/get-content-by-client/${client_id}/${
          contract_id ? contract_id : ""
        }`
      );
      return { data: data?.contract };
    } catch (error) {
      toast.error("Something went wrong while fetcing content");
      return {
        error: error,
      };
    }
  };
};

export const GetPublicContentByClientIdContract = (clientId, contract_id) => {
  return async () => {
    try {
      const { data } = await axios.get(
        `api/v1/contract/get-content-by-client/${clientId}/${
          contract_id ? contract_id : ""
        }`
      );
      return { data: data.contract };
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return (window.location.href = "https://www.clientmanager.io/");
      } else {
        toast.error(error?.response?.data?.message);
        return (window.location.href = "https://www.clientmanager.io/");
      }
    }
  };
};

export const initContractContent = (values, setIsOpen, navigate, setErrors) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/contract/init-contract`,
        values
      );
      setIsOpen(false);
      navigate(
        `/admin/clients/manage/${values?.client_id}/contract/${data?.data?._id}`
      );
      return dispatch({
        type: "SET_NEW_CONTRACT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        setErrors(err);
      }
      toast.error("Something went wrong while creating content");
      return {
        error: error,
      };
    }
  };
};

export const ChangeContractStatusViewedAction = ({ status, contract_id }) => {
  return async () => {
    try {
      await axios.post(`api/v1/contract/viewed-status/${contract_id}`, {
        status,
        contract_id,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};
