import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import { GetClientDetailAction } from "../../store/actions/clientAction";
import { getContentByClientId } from "../../store/actions/proposalAction";
import AdminNavbar from "../Navbars/AdminNavbar";
import FroalaBuilderComp from "../TextEditor/FroalaBuilderComp";

const ProposalBuilder = () => {
  const { clientId, proposalId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  const clientDetail = useSelector((state) => state.client.clientDetail);

  const [navBarText, setNavbarText] = useState("Back");
  const [textEditor, setTextEditor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSigned, setIsSigned] = useState(false);

  const getServerProposalConnent = async () => {
    setIsLoading(true);
    const serverContent = await dispatch(
      getContentByClientId(clientId, proposalId)
    );
    if (serverContent?.data) {
      let proposalContent = serverContent?.data;
      setTextEditor({
        images: {
          url: proposalContent?.content?.cover?.url,
          key: proposalContent?.content?.cover?.key,
        },
        text: proposalContent?.content,
        ...proposalContent,
      });
    }
    setIsSigned(!!serverContent?.data.client_signature.signature);
    setIsLoading(false);
  };

  useEffect(() => {
    getServerProposalConnent();
  }, [clientId, dispatch, proposalId]);

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientId, dispatch, navigate]);

  useEffect(() => {
    if (clientId !== clientDetail.data._id) {
      setNavbarText("Back");
    } else {
      setNavbarText(
        `Back to ${
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        } Proposals Dashboard`
      );
    }
  }, [clientDetail]);

  return (
    <>
      <AdminNavbar
        link={`/admin/clients/manage/${clientId}`}
        title="Proposal Builder"
        backLink={navBarText}
      />

      {isSigned ? (
        <div className="p-2 text-sm text-center bg-AccentRegular text-AccentLight">
          Client has signed your proposal.
        </div>
      ) : (
        <div className="mt-[10px] pb-[1px]" />
      )}

      <FroalaBuilderComp
        clientDetail={clientDetail}
        textEditor={textEditor}
        type="proposal"
        isLoading={isLoading}
        setTextEditor={setTextEditor}
        setIsLoading={setIsLoading}
        handleReload={getServerProposalConnent}
      />
    </>
  );
};

export default ProposalBuilder;
