import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { GetWhiteLabelPlanDetailAction } from "../../../store/actions/authAction";
import CheckMarkIcon from "../../Icons/CheckMarkIcon";
import UpgradeBadgeIcon from "../../Icons/UpgradeBadgeIcon";
import ConfirmWhiteLabelCancelModal from "../../Modals/ConfirmWhiteLabelCancelModal";

const features = [
  [
    "Remove 'Made with ClientManager'",
    "Modify background and accent colours",
    "Send updates from your own email",
  ],
  [
    "Use your company logo",
    "Customise your Client Portal domain",
    "Modify background and button colours",
  ],
];

function UpgradeToWhiteLabelCard() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const whiteLabelPlanDetail = useSelector(
    (state) => state.auth.whiteLabelAccountDetail
  );

  const [openCancelModal, setOpenCancelModal] = useState(false);

  useEffect(() => {
    dispatch(GetWhiteLabelPlanDetailAction());
  }, [dispatch]);

  const isWhiteLabelActive =
    user?.white_label_payment?._id &&
    (user?.white_label_payment?.status === "active" ||
      user?.white_label_payment?.status === "paid");

  const isLifeTimePlan =
    user?.white_label_payment?.selected_payment_type === "life_time";

  const handlePlanChange = () => {
    window.location.href =
      whiteLabelPlanDetail?.data?.data?.attributes?.urls?.customer_portal_update_subscription;
  };

  const renderFeatureList = (features) => (
    <ul className="flex flex-col gap-3 text-xs text-Neutral700">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center gap-2">
          <CheckMarkIcon width={18} color="#34B288" />
          {feature}
        </li>
      ))}
    </ul>
  );

  const renderUpgradeButton = () => (
    <button
      className="flex-1 py-3 text-xs font-semibold rounded bg-AccentRegular text-Neutral000 hover:bg-AccentMediumDark"
      onClick={handlePlanChange}
    >
      Change Plan
    </button>
  );

  const renderCancelButton = () => (
    <button
      className="flex-1 py-3 text-xs font-semibold text-center rounded bg-Neutral200 text-AccentRegular hover:bg-Neutral300"
      onClick={() => setOpenCancelModal(true)}
    >
      Cancel Plan
    </button>
  );

  const renderViewPlansLink = () => (
    <Link
      to="/white-label/plans"
      className="flex-1 py-3 mt-5 text-xs font-semibold text-center rounded bg-Neutral200 text-AccentRegular hover:bg-Neutral300"
    >
      View Plans
    </Link>
  );

  return (
    <section className="w-full max-w-[830px] rounded-md px-5 py-4 bg-Neutral000 shadow-lg border-l-[3px] border-AccentRegular">
      <div className="inline-flex items-center gap-1 px-3 py-2 pr-4 mb-3 rounded-3xl bg-AccentLight text-AccentRegular">
        <UpgradeBadgeIcon className="size-[18px]" />
        <span className="text-xs font-semibold uppercase">UPGRADE</span>
      </div>

      <h3 className="mb-6 text-base font-semibold text-Neutral900">
        Customise what your clients can see with additional features...
      </h3>

      <div className="flex flex-col gap-0 md:gap-16 md:flex-row">
        {features.map(renderFeatureList)}
      </div>

      <div className="flex flex-col gap-3 md:gap-5 md:flex-row">
        {isWhiteLabelActive ? (
          !isLifeTimePlan && renderUpgradeButton()
        ) : (
          <Link
            to="/white-label/plans"
            className="flex-1 py-3 mt-5 text-xs font-semibold text-center rounded bg-AccentRegular text-Neutral000 hover:bg-AccentMediumDark"
          >
            Upgrade Now
          </Link>
        )}

        {isWhiteLabelActive
          ? !isLifeTimePlan && renderCancelButton()
          : renderViewPlansLink()}
      </div>

      <ConfirmWhiteLabelCancelModal
        isOpen={openCancelModal}
        setIsOpen={setOpenCancelModal}
      />
    </section>
  );
}

export default UpgradeToWhiteLabelCard;
