import { Form, Formik } from "formik";
import { useParams } from "react-router";
import * as Yup from "yup";
import EditIcon from "../../../assets/Images/Edit.svg";
import { getTagColor } from "../../../utils/getTagColor";
import { ActionMenu } from "../../ActionMenu/ActionMenu";
import { MODAL_COLORS } from "../../Modals/Templates/NewTemplateModal";
import { useTemplateForm } from "./hooks/useTemplateForm";
import { useDispatch } from "react-redux";
import { GetDocumentContractActions } from "../../../store/actions/documentsAction";

const NewTemplateSchema = Yup.object().shape({
  name: Yup.string().required("Title Required"),
  category_tags: Yup.array().required("Category Tags Required"),
});

export const TemplateForm = ({
  type,
  handleTitleChange,
  textEditor,
  handleChangeCategory,
  inRow = false,
}) => {
  const {
    showTitleInput,
    showCategoryTags,
    handleShowTitleInput,
    handleShowCategoryTags,
    handleHideCategoryTags,
    handleHideTitleInput,
  } = useTemplateForm();
  const dispatch = useDispatch();
  const params = useParams();

  const modalColor = MODAL_COLORS[type];
  const UrlType = params.type;
  const options = [
    { value: "Web Design", label: "Web Design" },
    { value: "App Development", label: "App Development" },
    { value: "SEO", label: "SEO" },
    { value: "Content Writing", label: "Content Writing" },
  ];

  const optionsWithColors = options.map((option, i) => {
    const { bgColor: optionColor, text: optionText } = getTagColor(
      modalColor,
      i,
      true
    );

    const { bgColor: labelColor, text: labelText } = getTagColor(
      modalColor,
      i,
      true
    );

    return {
      ...option,
      color: optionColor,
      text: optionText,
      labelColor,
      labelText,
    };
  });

  return (
    <Formik
      initialValues={{
        name: textEditor?.title || "",
        category_tags: textEditor?.category?.length
          ? textEditor.category?.map((tag, i) => {
              const { bgColor: optionColor, text: optionText } = getTagColor(
                modalColor,
                i,
                true
              );
              const { bgColor: labelColor, text: labelText } = getTagColor(
                modalColor,
                i,
                true
              );
              return {
                value: tag?.name,
                label: tag?.name,
                color: optionColor,
                text: optionText,
                labelColor,
                labelText,
              };
            })
          : [],
      }}
      enableReinitialize
      validationSchema={NewTemplateSchema}
      onSubmit={(values) => {}}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        return (
          <Form
            onSubmit={handleSubmit}
            className={
              inRow
                ? "flex items-start flex-row xl:flex-col justify-center gap-4 xl:gap-y-9"
                : ""
            }
          >
            <div className={"min-w-[180px] h-[70px]"}>
              <div className={inRow ? "" : "mt-2"}>
                <div className="flex items-center justify-between h-6 mb-2 gap-y-4">
                  <h6 className="pl-1 text-xs font-semibold leading-4 text-Neutral600">
                    {type} Title
                  </h6>

                  <div className={showTitleInput ? "hidden" : ""}>
                    <ActionMenu
                      actions={[
                        {
                          label: "Edit",
                          icon: EditIcon,
                          onClick: handleShowTitleInput,
                        },
                      ]}
                    />
                  </div>
                </div>

                {showTitleInput ? (
                  <div>
                    <input
                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                      type="text"
                      placeholder={`Name of ${type}`}
                      name="name"
                      onChange={handleChange}
                      onBlur={(e) => {
                        if (e.target.value === "") return;
                        handleBlur(e);
                        handleTitleChange(e);
                        handleHideTitleInput();
                      }}
                      value={values.name}
                    />

                    {errors.name && touched.name && (
                      <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                        Please add a {UrlType} title.
                        {/* {errors.name} */}
                      </p>
                    )}
                  </div>
                ) : (
                  <h4 className="font-medium text-sm text-Neutral800 line-height-[19px]">
                    {values.name}
                  </h4>
                )}
              </div>
            </div>

            {/* <div className={inRow ? 'flex flex-col' : ''}>
              <div
                className={`flex items-center gap-y-4 ${inRow ? '' : 'mt-9'}`}
              >
                <h6 className="text-xs font-semibold leading-4 text-Neutral600">
                  Category Tags
                </h6>

                <ActionMenu
                  actions={[
                    {
                      label: 'Edit',
                      icon: EditIcon,
                      onClick: () => handleShowCategoryTags(true),
                    },
                  ]}
                />
              </div>

              <div className={inRow ? 'xl:mt-2' : 'mt-2'}>
                {showCategoryTags ? (
                  <>
                    <Select
                      id="category_tags"
                      name="category_tags"
                      isMulti
                      isSearchable
                      isClearable={false}
                      options={optionsWithColors}
                      value={values?.category_tags}
                      onBlur={(e) => {
                        handleBlur('category_tags');
                        handleChangeCategory(values.category_tags);
                        handleHideCategoryTags();
                      }}
                      {...{ setFieldValue, modalColor }}
                    />

                    {errors.name && touched.name && (
                      <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                        {errors.categoryTags}
                      </p>
                    )}
                  </>
                ) : (
                  <div className="items-center gap-x-1 mt-2 inline-flex flex-wrap relative max-h-[23px]">
                    {values?.category_tags?.length ? (
                      values.category_tags?.map((tag, i) => {
                        const { bgColor, text } = getTagColor(modalColor, i);

                        return (
                          <div
                            className={`bg-${bgColor} py-1 px-2 text-${text} rounded-lg text-[10px] uppercase font-medium tracking-[0.04em] mb-2`}
                            key={i}
                          >
                            {tag?.value}
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className={`uppercase inline-flex bg-${modalColor}200 py-1 px-2 rounded-md font-medium text-xs leading-[14px] text-${modalColor}800`}
                      >
                        NONE
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div> */}
          </Form>
        );
      }}
    </Formik>
  );
};
