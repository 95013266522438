import { useEffect, useState } from "react";
import clx from "classnames";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";

import SectionTitle from "../../../common/typography/SectionTitle";
import AddIcon from "../../../Icons/AddIcon";
import ResetIcon from "../../../Icons/ResetIcon";
import TasksFilter from "./TasksFilter";
import CommonTaskListingNew from "../../../comman/CommanTaskListingNew";
import { GetTasksActions } from "../../../../store/actions/tasksAction";
import loader from "../../../../assets/Images/loader-icon.svg";

function TasksModal({
  project,
  className = "",
  isOpen = false,
  checkedTask,
  setCheckedTask,
  frontFilter,
  onSwitchModals = () => {},
  setIsOpen = () => {},
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId, projectId } = useParams();
  const allTaskList = useSelector((state) => state.tasks.all_tasks);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [taskByClient, setTaskByClient] = useState([]);
  const [isLoading, setIsLoading] = useState({ status: false, id: "" });

  useEffect(() => {
    if (
      !Object.keys(allTaskList).filter((id) => id === projectId)?.length ||
      Object.keys(allTaskList).length === 0
    ) {
      setIsLoading({ status: true, id: projectId });
      dispatch(GetTasksActions(clientId, projectId, false, setIsLoading));
    }
  }, [dispatch, clientId, projectId, allTaskList]);

  useEffect(() => {
    if (Object.keys(allTaskList).filter((id) => id === projectId)?.length) {
      setTaskByClient(allTaskList[projectId]);
    }
  }, [allTaskList, projectId]);

  return (
    <div
      className="absolute top-0 left-0 h-full z-[1] w-full bg-Neutral000"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="bg-Neutral000 border border-transparent shadow-[0_4px_15px_rgba(0,0,0,0.08)]">
        <div
          className={clx(
            "flex flex-col p-[28px] pt-[23px] pr-9 rounded-xl bg-Neutral000 border-[0.5px] border-Neutral200  mb-5 overflow-x-auto pb-0",
            className
          )}
        >
          <div className="flex items-center justify-between w-full mb-5">
            <SectionTitle
              text="Tasks"
              className="flex justify-center items-center mr-[6px]"
            />
            <button
              className="flex items-center justify-center transition-all duration-300 rounded-full hover:bg-Neutral100"
              onClick={() => setIsOpen(false)}
              aria-label="CLose modal"
            >
              <AddIcon
                width={24}
                height={24}
                color="#E56780"
                className="rotate-45"
              />
            </button>
          </div>
          <div className="flex flex-wrap items-start justify-between mb-10">
            {/* tasks filter */}
            <div className="flex items-stretch overflow-x-auto justify-stretch md:overflow-x-hidden scrollbar-h-4">
              <TasksFilter
                tasks={project}
                checkedTask={checkedTask}
                setCheckedTask={setCheckedTask}
              />
            </div>
            {/* controls */}
            <div className="flex flex-wrap justify-start items-start gap-x-3 gap-y-[17px] w-[372px] py-4">
              <button
                className="w-full h-[48px] rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-xs text-Neutral000 bg-AccentRegular uppercase font-bold button-hover"
                onClick={() => {
                  navigate(
                    `/admin/clients/manage/${clientDetail?.data?._id}/project/${project?._id}?isNew=true`
                  );
                }}
              >
                Add Task
              </button>
              {/* <TrackerControl isRunning={isRunning} /> */}
              <button
                className="w-full h-[48px] rounded flex justify-center items-center tracking-[0.04em] text-xs text-AccentRegular border border-AccentRegular uppercase font-bold transition duration-300 hover:bg-AccentLight"
                onClick={onSwitchModals}
              >
                <ResetIcon className="mr-2" /> Switch To Time Tracking
              </button>
            </div>
          </div>
          {/* tasks board */}
          {isLoading?.status && isLoading?.id === projectId ? (
            <div className="flex justify-center mt-10 mb-10">
              <img
                src={loader}
                alt="loader"
                className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
              />
            </div>
          ) : (
            <CommonTaskListingNew
              frontFilter={frontFilter}
              clientId={clientId}
              project={project}
              allTaskList={taskByClient}
              types="task"
              isAll={false}
              projectId={projectId}
              checkedTask={checkedTask}
              clientDetail={clientDetail?.data}
              setCheckedTask={setCheckedTask}
            />
          )}
          {/* <TasksBoard list={tasksList} /> */}
        </div>
      </div>
    </div>
  );
}

export default TasksModal;
