import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import { GetClientDetailAction } from "../../../store/actions/clientAction";
import { getProjectList } from "../../../store/actions/projectAction";
import AddButton from "../../Buttons/AddButton";
import ProjectCardLoader from "../../ContentLoaders/ProjectCardLoader";
import EditContactDetailModal from "../../Modals/EditContactDetailModal";
import NoteModal from "../../Modals/NoteModal";
import CreateProjectModal from "../../Modals/ProjectModal/CreateProjectModal";
import { NewEntityTemplateModal } from "../../Modals/Templates/NewEntityTemplateModal";
import { SelectTemplate } from "../../Modals/Templates/SelectTemplate";
import ViewClientDetail from "../../Modals/ViewClientDetail";
import ProjectCard from "../../common/cards/ProjectCard";
import ProjectArrowsControls from "../../common/controls/ProjectArrowsControls";
import SectionTitle from "../../common/typography/SectionTitle";

function ProjectDashboardSection({
  clientDetailOpen,
  setClientDetailOpen,
  notIsOpen,
  setNotIsOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showSelectTemplateModal, setShowSelectTemplateModal] = useState(false);

  const projectsList = useSelector((state) => state.project.project);
  const allProjects = useSelector((state) => state.project.all_projects);
  const clientDetail = useSelector((state) => state.client.clientDetail);

  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);

  useEffect(() => {
    if (clientId !== clientDetail?.data?._id) {
      dispatch(GetClientDetailAction(clientId, navigate));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (
      allProjects?.data?.docs?.filter(
        (project) => project.client?._id === clientId
      ).length === 0
    ) {
      dispatch(getProjectList(clientId));
    } else {
      dispatch({
        type: "GET_PROJECT_LIST_SUCCESS",
        payload: {
          isLoading: false,
          data: allProjects?.data?.docs?.filter(
            (project) => project.client?._id === clientId
          ),
        },
      });
      dispatch(getProjectList(clientId));
    }
  }, [dispatch, clientId]);

  function scrollLeft() {
    document
      .querySelector(".projectsScrollable")
      .scrollBy({ left: -320, behavior: "smooth" });
  }

  function scrollRight() {
    document
      .querySelector(".projectsScrollable")
      .scrollBy({ left: 320, behavior: "smooth" });
  }

  useEffect(() => {
    const container = document.querySelector(".projectsScrollable");

    const checkScrollPosition = () => {
      if (container) {
        setAtStart(container.scrollLeft === 0);
        setAtEnd(
          container.scrollLeft >=
            container.scrollWidth - container.offsetWidth - 1
        );
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      checkScrollPosition();
    });

    if (container) {
      container.addEventListener("scroll", checkScrollPosition);
      resizeObserver.observe(container);
      checkScrollPosition(); // Initial check
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", checkScrollPosition);
      }
      resizeObserver.disconnect();
    };
  }, []);

  const generateDuplicateTitle = (title) => {
    const tempLength = projectsList?.data?.filter((project) =>
      project.title.includes(title)
    ).length;
    return `${title} (${tempLength})`;
  };

  const projectsData = projectsList?.data?.map((project, index) => (
    <ProjectCard
      key={project._id}
      {...project}
      project_no={index + 1}
      generateDuplicateTitle={generateDuplicateTitle}
    />
  ));

  const handleDefaultTemplateClick = () => {
    setIsOpen(false);
    setShowCreateProjectModal(true);
  };

  const handleCustomTemplateClick = () => {
    setIsOpen(false);
    setShowCreateProjectModal(false);
    setShowSelectTemplateModal(true);
  };

  return (
    <section className="w-100% relative">
      <div className="flex items-center px-2 mb-5">
        <SectionTitle text="Project Dashboards" />

        <AddButton title="create" onClick={() => setIsOpen(true)} />

        {projectsList?.data?.length >= 5 && (
          <ProjectArrowsControls
            scrollLeft={scrollLeft}
            scrollRight={scrollRight}
            atStart={atStart}
            atEnd={atEnd}
          />
        )}
      </div>
      <div className="projectsScrollable flex overflow-x-auto overflow-y-hidden gap-x-[22px] w-full py-4 mb-16">
        {projectsList?.isLoading ? <ProjectCardLoader /> : projectsData}
      </div>
      <ViewClientDetail
        isOpen={clientDetailOpen}
        setIsOpen={setClientDetailOpen}
        clientDetail={clientDetail}
        handleEditOpen={() => {
          setClientDetailOpen(false);
          setModalIsOpen(true);
        }}
      />
      <NoteModal setNotIsOpen={setNotIsOpen} notIsOpen={notIsOpen} />
      <CreateProjectModal
        isOpen={showCreateProjectModal}
        setIsOpen={setShowCreateProjectModal}
      />
      <NewEntityTemplateModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type="Project"
        onDefaultTemplateClick={handleDefaultTemplateClick}
        onCustomTemplateClick={handleCustomTemplateClick}
      />
      <EditContactDetailModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        client={clientDetail.data}
      />
      <SelectTemplate
        type={"Project"}
        isOpen={showSelectTemplateModal}
        setIsOpen={setShowSelectTemplateModal}
        clientId={clientId}
      />
    </section>
  );
}

export default ProjectDashboardSection;
