import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import DangerZone from "../../components/DangerZone";
import ContractIcon from "../../components/Icons/ContractIcon";
import UserIcon from "../../components/Icons/UserIcon";
import PortalIcon from "../../components/Icons/newicons/_PortalIcon";
import ConfirmClientPortalActivationModal from "../../components/Modals/ConfirmClientPortalActivationModal";
import CreateMemberModal from "../../components/Modals/CreateMemberModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import AssetsAndManagementSection from "../../components/sections/Assets&ClientManagementSection/AssetsAndManagementSection";
import ProjectDashboardSection from "../../components/sections/ProjectDashboardsSection/ProjectDashboardSection";
import TeamMembersSection from "../../components/sections/TeamMemberSection/TeamMembersSection";
import {
  ArchiveClientAction,
  DeleteClientAction,
  GetClientDetailAction,
  ResoreArchivedClientAction,
} from "../../store/actions/clientAction";

let CLIENT_PORTAL_BASE_URL = "";
if (["dev", "development"].includes(process.env.REACT_APP_ENV)) {
  CLIENT_PORTAL_BASE_URL = "https://devportal.clientmanager.io/";
} else {
  CLIENT_PORTAL_BASE_URL = "https://portal.clientmanager.io/";
}

function ProjectDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { clientId } = useParams();

  const clientDetail = useSelector((state) => state.client.clientDetail);
  const user = useSelector((state) => state.auth.user);

  const [createMemberModal, setCreateMemberModal] = useState(false);
  const [
    isClientPortalActivationModalOpen,
    setIsClientPortalActivationModalOpen,
  ] = useState(false);

  // const CLIENT_PORTAL_BASE_URL = `https://${user?.subDomain?.subdomain}.clientmanager.io/`

  useEffect(() => {
    if (state) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: state },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId, navigate));
      }
    }
  }, [clientDetail.data._id, clientId, dispatch, navigate, state]);

  const [archiving, setArchiving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleArchiving = (value) => {
    if (!value) {
      setArchiving(true);
      dispatch(ArchiveClientAction(clientId, navigate));
      setArchiving(false);
    } else {
      setArchiving(true);
      dispatch(ResoreArchivedClientAction(clientId, navigate));
      setArchiving(false);
    }
  };

  const handleDeleting = () => {
    setDeleting(true);
    dispatch(DeleteClientAction(clientId, navigate));
    setDeleting(false);
  };

  const [clientDetailOpen, setClientDetailOpen] = useState(false);
  const [notIsOpen, setNotIsOpen] = useState(false);

  // Check the client is verified and activated
  const isClientPortalActivatedForClient =
    clientDetail?.data?.invitedClient &&
    clientDetail?.data?.invitedClient?.status &&
    clientDetail?.data?.invitedClient?.accepted;

  // Check the client's invitation is pending
  const isActivationPendingForClientPortal =
    clientDetail?.data?.invitedClient &&
    clientDetail?.data?.invitedClient?.status &&
    !clientDetail?.data?.invitedClient?.accepted;

  // Check the client is not invited
  const noActionsTakes = clientDetail?.data?.invitedClient === null;

  return (
    <div>
      <AdminNavbar
        title={
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        }
        subtitle={`Manage ${
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        }'s projects and everything else`}
        link="/admin/clients"
        backLink="Back to Client Dashboard"
      />
      <div className="5xl:px-[96px] px-6 md:px-12 w-full  pt-0 border-b border-gray-100 flex gap-7">
        <button
          className="flex items-center justify-start gap-1"
          onClick={() => setClientDetailOpen(true)}
        >
          <UserIcon className="w-4 h-4 text-AccentRegular" />
          <div className="text-xs font-semibold text-AccentRegular">
            Client Details
          </div>
        </button>
        <button
          className="flex items-center gap-1 py-2 text-xs font-semibold text-indigo-500"
          onClick={() => setNotIsOpen(true)}
        >
          <ContractIcon className="w-4 h-4 text-AccentRegular" />
          <div className="text-xs font-semibold text-AccentRegular">Notes</div>
        </button>
        <Link
          to={
            isClientPortalActivatedForClient
              ? `${CLIENT_PORTAL_BASE_URL}user/${user?._id}/${clientDetail?.data?._id}/home`
              : `/admin/client-portal?clientId=${clientDetail?.data?._id}`
          }
          {...(isClientPortalActivatedForClient && { target: "_blank" })}
          data-tip={`Invitation to join portal sent to ${clientDetail?.data?.email} (make sure they check spam)`}
          data-for="portalMessage"
          className="flex items-center gap-1 py-2 text-xs font-semibold text-indigo-500"
        >
          <PortalIcon className="w-4 h-4 text-AccentRegular" />
          <div className="text-xs font-semibold text-AccentRegular">
            {isClientPortalActivatedForClient
              ? "Edit/View Client Portal"
              : isActivationPendingForClientPortal
              ? "Client Portal Invitation Pending"
              : noActionsTakes
              ? "Activate Client Portal" // if no actions are taken or client portal is disabled
              : "Activate Client Portal"}
          </div>
        </Link>
        <ReactTooltip
          id="portalMessage"
          disable={!isActivationPendingForClientPortal}
          delayShow={200}
        />
      </div>
      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div className="md:pb-0 pb-[100px] mb-[68px]">
          <div className="flex flex-col">
            {/* New Team Members Section */}
            <TeamMembersSection />

            {/* Project Dashboards Section */}
            <ProjectDashboardSection
              clientDetailOpen={clientDetailOpen}
              setClientDetailOpen={setClientDetailOpen}
              notIsOpen={notIsOpen}
              setNotIsOpen={setNotIsOpen}
            />

            {/* Assets & Client Management Section*/}
            <AssetsAndManagementSection />

            {/* Danger Zone Section */}
            {clientDetail?.data?.is_admin === true ? (
              <DangerZone
                clientDetail={clientDetail}
                handleArchiving={handleArchiving}
                handleDeleting={handleDeleting}
                archiving={archiving}
                deleting={deleting}
              />
            ) : null}
          </div>
        </div>
      </div>

      <CreateMemberModal
        isOpen={createMemberModal}
        setModal={setCreateMemberModal}
      />
      <ConfirmClientPortalActivationModal
        isOpen={isClientPortalActivationModalOpen}
        setOpen={setIsClientPortalActivationModalOpen}
        clientDetails={clientDetail?.data}
      />
    </div>
  );
}

export default ProjectDashboard;
