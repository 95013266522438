import React, { useState, useEffect } from "react";
import SearchBar from "../Search";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TeamAvatarsList from "../common/lists/TeamAvatarsList";
import { GetAllCollabProjectsActions } from "../../store/actions/projectAction";
import loader from "../../assets/Images/loader-icon.svg";

function ClientCollabProjects() {
  const [expandedDescriptionId, setExpandedDescriptionId] = useState(null);
  const [searchdata, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const allProjectsList = useSelector(
    (state) => state?.project?.all_collab_projects
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      GetAllCollabProjectsActions({
        search: searchValue,
      })
    );
  }, [dispatch, searchValue]);

  useEffect(() => {
    setSearchData(allProjectsList?.data);
  }, [allProjectsList?.data]);

  const toggleDescription = (id) => {
    setExpandedDescriptionId(expandedDescriptionId === id ? null : id);
  };

  const renderDescription = (description, id) => {
    const isLong = description?.length > 110;
    const shouldTruncate = isLong && expandedDescriptionId !== id;
    const truncatedDescription = description?.substring(0, 110);
    const moreText = isLong ? (
      <span className="text-AccentRegular cursor-pointer"> more</span>
    ) : (
      ""
    );

    return (
      <p
        className={`text-[10px] text-Neutral800 ${isLong ? "cursor-pointer" : ""
          }`}
        onClick={() => isLong && toggleDescription(id)}
      >
        {shouldTruncate ? (
          <>
            {truncatedDescription}...{moreText}
          </>
        ) : (
          description
        )}
      </p>
    );
  };

  const handleChangeSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  return (
    <div className="bg-gray-100 py-5 lg:px-24 xl:px-40 w-full h-full flex flex-col items-center justify-start min-h-screen">
      <div className="w-full">
        <header className="w-full flex justify-between items-center pb-4 mt-20 px-10">
          <h1 className="text-2xl font-semibold">All Projects</h1>
          <div className="flex space-x-2">
            {allProjectsList?.data?.length > 0 || searchValue !== "" ? (
              <SearchBar
                searchValue={searchValue}
                handleChangeSearch={handleChangeSearch}
              />
            ) : null}

            {/*    <button className="border p-2 rounded bg-blue-500 text-white">Filter</button> */}
          </div>
        </header>
        {allProjectsList?.isLoading && !allProjectsList?.data && !searchdata ? (
          <div className="flex justify-center mt-10 mb-10">
            <img
              src={loader}
              alt="loader"
              className="animate-spin relative drop-shadow-Texts z-10 h-[30px]"
            />
          </div>
        ) : (
          <div className="flex flex-wrap justify-center gap-[40px]">
            {allProjectsList?.data?.map((projectData, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md w-[296px]"
                onClick={() =>
                  navigate(
                    `/client-collab/project/${projectData?.project_id}`,
                    { state: { projectData } }
                  )
                }
              >
                <div className="p-6 flex flex-col">
                  <h3 className="text-base text-Neutral900 mb-2">
                    {projectData?.title}
                  </h3>
                  <div className="text-gray-600 text-sm mb-4">
                    {projectData?.description && (
                      <>
                        <p className="text-[10px] text-Neutral500 mb-2">
                          DESCRIPTION
                        </p>
                        {renderDescription(
                          projectData?.description,
                          projectData?._id
                        )}
                      </>
                    )}
                  </div>
                  <div className="mb-4">
                    <p className="text-[10px] text-Neutral500 mb-2">TASKS</p>
                    {Object.entries(projectData?.total_tasks_by_column).map(
                      ([taskName, taskCount], index) => (
                        <div className="flex justify-between">
                          <div
                            key={index}
                            className="text-xs text-Neutral800 my-1"
                          >{`${taskName}`}</div>
                          <div
                            key={index}
                            className="text-xs text-Neutral900 my-1"
                          >{`${taskCount}`}</div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="mb-4">
                    <p className="text-[10px] text-Neutral500 mb-2">
                      CONTRIBUTORS
                    </p>
                    <div className="flex -space-x-1 overflow-hidden">
                      <TeamAvatarsList
                        users={projectData?.time_contributors}
                        className="mb-[6px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientCollabProjects;
