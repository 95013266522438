import Modal from "react-modal";

import CloseBtn from "../../assets/Images/close-btn.svg";
import DownloadIcon from "../Icons/DownloadIcon";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const AddImportModal = ({ isOpen, setIsOpen, type }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-8 sm:min-w-[500px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                id="modal-title"
              >
                ADD{" "}
                {(type === "client" && "CLIENT") || (type === "lead" && "LEAD")}
                S
              </h3>
            </div>
            <div className="w-full">
              <a
                href={
                  (type === "client" &&
                    "https://clientmanager-documents-images.s3.amazonaws.com/public/clients.csv") ||
                  (type === "lead" &&
                    "https://clientmanager-documents-images.s3.amazonaws.com/public/Lead.csv")
                }
                download
                className="text-xs text-AccentRegular leading-[18px] font-semibold border border-AccentRegular w-full mb-5 text-center py-4 rounded flex items-center justify-center gap-1.5"
              >
                <DownloadIcon />
                Download CSV Template
              </a>
              <label
                className="text-xs text-Neutral000 bg-AccentRegular leading-[18px] font-semibold border border-Neutral200 w-full text-center py-4 rounded flex items-center justify-center cursor-pointer"
                htmlFor={
                  (type === "client" && "import-clients") ||
                  (type === "lead" && "import-leads")
                }
              >
                Import via CSV
              </label>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default AddImportModal;
