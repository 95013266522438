import axios from "axios";
import { toast } from "react-toastify";
import confetti from "canvas-confetti";

import {
  GetPublicInvoiceByClientId,
  getInvoiceListByClientId,
} from "./invoicesAction";
import { GetDocumentContractActions } from "./documentsAction";
import { GetQuestionnaireOfClientId } from "./questionnaireAction";

export const RemoveImage = (
  key,
  client,
  is_cover,
  SetIsDeleting,
  setImages
) => {
  return async () => {
    try {
      const { data } = await axios.post(`api/v1/proposal/remove-images`, {
        key: key,
        client: client,
        is_cover: is_cover,
      });
      SetIsDeleting(false);
      if (setImages) {
        setImages({
          url: null,
          key: null,
        });
      }
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while Removing image");
      return {
        error: error,
      };
    }
  };
};

export const InitialContent = (values, setIsOpen, navigate, setErrors) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/init-proposal`,
        values
      );
      setIsOpen(false);
      if (values?.lead) {
        navigate(
          `/admin/leads/manage/${values?.lead}/proposal/${data.data?._id}`
        );
      } else {
        navigate(
          `/admin/clients/manage/${values?.client_id}/proposal/${data.data?._id}`
        );
      }
      return dispatch({
        type: "INIT_PROPOSAL_SUCCESS",
        payload: data.data,
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        setErrors(err);
      }
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "INIT_PROPOSAL_ERROR",
        payload: error,
      });
    }
  };
};

export const SaveContent = (content, setUnsavedChanges) => {
  return async () => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content.content, "text/html");
      const sign = doc.getElementById("signature-block");
      if (sign) {
        sign.remove();
      }
      content.content = doc.body.innerHTML;

      const { data } = await axios.post(`api/v1/proposal/save-content`, {
        ...content,
      });
      setUnsavedChanges(false);
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while Saving content");
      setUnsavedChanges(false);
      return {
        error: error,
      };
    }
  };
};

export const SaveTitle = (value, setUnsavedChanges) => {
  return async () => {
    try {
      const { data } = await axios.post(`api/v1/proposal/save-title`, value);
      setUnsavedChanges(false);
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while Saving title");
      setUnsavedChanges(false);
      return {
        error: error,
      };
    }
  };
};

export const getContentByClientId = (client_id, proposal_id) => {
  return async () => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/get-content-by-client/${client_id}/${
          proposal_id ? proposal_id : ""
        }`
      );
      return { data: data?.proposal };
    } catch (error) {
      toast.error("Something went wrong while fetcing content");
      return {
        error: error,
      };
    }
  };
};

export const GetPublicContentByClientId = (clientId, proposalId) => {
  return async () => {
    try {
      const { data } = await axios.get(
        `api/v1/proposal/get-content-by-client/${clientId}/${
          proposalId ? proposalId : ""
        }`
      );
      return { data: data.proposal };
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return (window.location.href = "https://www.clientmanager.io/");
      } else {
        toast.error(error?.response?.data?.message);
        return (window.location.href = "https://www.clientmanager.io/");
      }
    }
  };
};

export const GetProposalList = ({ page, perPage, search }) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/get-proposal-list?page=${page}&limit=${perPage}&search=${search}`
      );
      return dispatch({
        type: "SET_PROPOSALS_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UploadExistingPurposal = (
  values,
  reloadProposal,
  setSubmitting,
  closeModal
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/upload-existing-proposal`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setSubmitting(false);
      closeModal();
      if (reloadProposal) reloadProposal();
      return dispatch({
        type: "SET_PROPOSAL",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateExistingProposal = (
  values,
  reloadProposal,
  setSubmitting,
  closeModal
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/update-existing-proposal`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (reloadProposal) reloadProposal();
      if (setSubmitting) setSubmitting(false);
      if (closeModal) closeModal();
      return dispatch({
        type: "UPDATE_PROPOSAL_BY_CLIENT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ChangeProposalStatusAction = (
  { id, status, proposal_id },
  haveError
) => {
  return async () => {
    try {
      await axios.post(`api/v1/proposal/change-status/${id}/${proposal_id}`, {
        status,
        proposal_id,
      });
      return;
    } catch (error) {
      haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const ChangeProposalStatusViewedAction = ({ status, proposal_id }) => {
  return async () => {
    try {
      await axios.post(`api/v1/proposal/viewed-status/${proposal_id}`, {
        status,
        proposal_id,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const UpdateSignatureAction = (
  type,
  client,
  file,
  who,
  text,
  is_dotted,
  draw_signature,
  setIsSaving,
  setIsOpen,
  proposalId,
  html,
  leadId
) => {
  return async () => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("who", who);
      formData.append("type", type);
      formData.append("client", client);
      formData.append("text", text);
      formData.append("is_dotted", is_dotted);
      formData.append("draw_signature", draw_signature);
      formData.append("proposal", proposalId);
      formData.append("html", html);
      formData.append("lead", leadId);
      formData.append("is_lead", leadId ? true : false);
      const id = proposalId;
      const { data } = await axios.post(
        `api/v1/proposal/add-update-signature/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      );
      // isSuccess();
      setIsSaving(false);
      setIsOpen(false);
      return data?.data;
    } catch (error) {
      // haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const RemoveSignatureAction = (type, proposalId) => {
  return async () => {
    try {
      const { data } = await axios.delete(
        `api/v1/proposal/remove-signature/${proposalId}/${type}`
      );
      toast.success(data.message);
      return data?.data;
    } catch (error) {
      // haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const RemoveContractSignatureAction = (type, contractId) => {
  return async () => {
    try {
      const { data } = await axios.delete(
        `api/v1/contract/remove-signature/${contractId}/${type}`
      );
      toast.success(data.message);
      return data?.data;
    } catch (error) {
      // haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        return;
      }
    }
  };
};

export const DeleteExistingProposal = (id, reloadProposal) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`api/v1/proposal/delete-proposal/${id}`);
      if (reloadProposal) {
        reloadProposal();
        return dispatch({
          type: "DELETE_PROPOSAL",
          payload: { isLoading: false, data: data.data, id },
        });
      } else {
        return dispatch({
          type: "DELETE_PROPOSAL_BY_CLIENT",
          payload: { isLoading: false, data: data.data, id },
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_PROPOSAL_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_PROPOSAL_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetLeadProposalAction = (leadId, proposalId) => {
  return async () => {
    try {
      const { data } = await axios.get(
        `api/v1/proposal/get-lead-proposal/${leadId}/${proposalId}`
      );
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while fetcing content");
      return {
        error: error,
      };
    }
  };
};

export const getLeadProposalAction = (leadId, proposalId) => {
  return async () => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/get-lead-proposal/${leadId}/${proposalId}`
      );
      return { data: data.data };
    } catch (error) {
      toast.error("Something went wrong while fetcing content");
      return {
        error: error,
      };
    }
  };
};

export const GetProposalListByClientId = (clientId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/proposal/get-proposal-list-by-client/${clientId}`
      );
      return dispatch({
        type: "SET_PROPOSALS_LIST_BY_CLIENT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_PROPOSALS_LIST_ERROR",
          payload: error,
        });
      }
    }
  };
};

export function GetProposalStatusList() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "api/v1/proposal-status/get-proposal-status-list"
      );
      dispatch({ type: "GET_PROPOSAL_STATUS_LIST", payload: data });
    } catch (err) {
      console.error(err);
    }
  };
}

export const FollowUpEmailAction = (
  values,
  type,
  setSubmitting,
  closeModal
) => {
  return async () => {
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        `api/v1/proposal/follow-up-email`,
        values
      );

      setSubmitting(false);
      closeModal();

      if (type === "Invoice") {
        toast.success("Invoice Sent Successfully");
        // Party animation only on sending an invoice
        confetti({ ticks: 500, spread: 70, particleCount: 80 });
      } else {
        toast.success(data.message);
      }

      return { data: data.data };
    } catch (error) {
      setSubmitting(false);
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error(error?.response?.data?.message);
        return;
      }
    }
  };
};

export const followUpAutoEmailAction = (
  values,
  setIsLoading,
  closeModal,
  type,
  IsDashboard,
  selectedData,
  setTextEditor
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `api/v1/proposal/update-auto-email-details`,
        values
      );

      const clientId = data?.data?.client;

      if (type === "Proposal") {
        if (IsDashboard === "dash") {
          dispatch({
            type: "UPDATE_FOLLOWUP_EMAIL_PROPOSAL",
            payload: data.data,
          });
        } else if (IsDashboard === "froala") {
          setTextEditor(data.data);
          // await dispatch(getContentByClientId(clientId, proposalId));
        } else {
          await dispatch(GetProposalListByClientId(clientId));
        }
      } else if (type === "Invoice") {
        const invoiceId = data.data._id;
        if (IsDashboard === "dash") {
          dispatch({
            type: "UPDATE_FOLLOWUP_EMAIL_INVOICE",
            payload: data.data,
          });
        } else if (IsDashboard === "froala") {
          dispatch(GetPublicInvoiceByClientId(clientId, invoiceId));
        } else if (IsDashboard === "detailsPage") {
          dispatch({
            type: "FOLLOW_UP_EMAIL_DETAILS",
            payload: { data: data.data },
          });
        } else {
          dispatch(getInvoiceListByClientId(clientId));
        }
      } else if (type === "Contract") {
        if (IsDashboard === "froala") {
          setTextEditor(data.data);
        } else {
          await dispatch(GetDocumentContractActions(clientId));
        }
      } else {
        if (IsDashboard === "detailsPage") {
          dispatch({
            type: "SET_QUESTIONNAIRE",
            payload: { isLoading: false, data: data.data },
          });
        } else {
          await dispatch(GetQuestionnaireOfClientId(data?.data?.client_id));
        }
      }
      setIsLoading(false);
      closeModal();
      toast.success(data.message);

      return { data: data.data };
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.status === 422) {
        error?.response?.data?.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error(error?.response?.data?.message || "Something went wrong");
        return;
      }
    }
  };
};

export const enabledFollowUpAction = (
  values,
  type,
  IsDashboard,
  setTextEditor
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/proposal/enable-disable-follow-up`,
        values
      );
      const clientId = data?.data?.client;

      if (type === "Proposal") {
        if (IsDashboard === "dash") {
          dispatch({
            type: "UPDATE_FOLLOWUP_EMAIL_PROPOSAL",
            payload: data.data,
          });
        } else if (IsDashboard === "froala") {
          setTextEditor(data.data);
          // await dispatch(getContentByClientId(clientId, proposalId));
        } else {
          await dispatch(GetProposalListByClientId(clientId));
        }
      } else if (type === "Invoice") {
        const invoiceId = data.data._id;
        if (IsDashboard === "dash") {
          dispatch({
            type: "UPDATE_FOLLOWUP_EMAIL_INVOICE",
            payload: data.data,
          });
        } else if (IsDashboard === "froala") {
          dispatch(GetPublicInvoiceByClientId(clientId, invoiceId));
        } else if (IsDashboard === "detailsPage") {
          dispatch({
            type: "FOLLOW_UP_EMAIL_DETAILS",
            payload: { data: data.data },
          });
        } else {
          dispatch(getInvoiceListByClientId(clientId));
        }
      } else if (type === "Contract") {
        if (IsDashboard === "froala") {
          setTextEditor(data.data);
        } else {
          await dispatch(GetDocumentContractActions(clientId));
        }
      } else {
        if (IsDashboard === "detailsPage") {
          dispatch({
            type: "SET_QUESTIONNAIRE",
            payload: { isLoading: false, data: data.data },
          });
        } else {
          await dispatch(GetQuestionnaireOfClientId(data?.data?.client_id));
        }
      }
      return { data: data.data };
    } catch (error) {
      if (error?.response?.status === 422) {
        error?.response?.data?.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return;
      } else {
        toast.error(error?.response?.data?.message || "Something went wrong");
        return;
      }
    }
  };
};
