import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";

import {
  AddUpdateSchedule,
  enabledFollowUp,
  getEmailSendSchedule,
} from "../../store/actions/AutoEmailFollowUpAction";
import SelectDownArrow from "../Icons/SelectDownArrow";
import CloseBtn from "../../assets/Images/close-btn.svg";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

function AutoFollowUpEmail() {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.followupSchedule);
  const authSelector = useSelector((state) => state.auth.user);

  const [error, setError] = useState(null);
  const [isEmailHintBoxOpen, setIsEmailHintBoxOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [followupSchedule, setFollowupSchedule] = useState({
    total_times: 3,
    total_days: 3,
    enable: false,
  });

  useEffect(() => {
    if (!schedule?.data) {
      dispatch(getEmailSendSchedule());
    } else {
      setFollowupSchedule({
        total_times: schedule?.data?.total_times,
        total_days: schedule?.data?.total_days,
        enable: schedule?.data?.enable,
      });
    }
  }, [dispatch, schedule?.data]);

  useMemo(() => {
    if (schedule?.error) {
      setError(schedule?.error);
      console.log("error from email followup:", error);
    }
  }, [schedule?.error, error]);

  const handleFollowSchedule = () => {
    dispatch(
      AddUpdateSchedule(
        {
          ...followupSchedule,
          user_id: authSelector?._id,
        },
        setIsLoading
      )
    );
  };

  const handleEnabled = (enable) => {
    dispatch(
      enabledFollowUp({
        enable: enable,
        user_id: authSelector?._id,
      })
    );
  };

  return (
    <div>
      <div className="w-full 5xl:max-w-[500px] max-w-[408px] rounded border border-Neutral300 py-8 px-10">
        <div className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase flex gap-2 items-center">
          Send follow-up reminder email
          <div
            className="cursor-pointer"
            onClick={() => setIsEmailHintBoxOpen(true)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.3334 7.2042C7.5528 6.58051 7.98586 6.05459 8.55586 5.7196C9.12586 5.3846 9.79603 5.26215 10.4477 5.37392C11.0993 5.48569 11.6904 5.82448 12.1161 6.33028C12.5419 6.83608 12.775 7.47625 12.774 8.1374C12.774 10.0038 9.97437 10.937 9.97437 10.937V12.0003"
                stroke="#9C9CA6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.0489 14.6702H10.0576"
                stroke="#9C9CA6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="0.5"
                y="0.5"
                width="19"
                height="19"
                rx="9.5"
                stroke="#C2C2CC"
              />
            </svg>
            <ReactTooltip
              place="bottom"
              textColor="#F5F5F7"
              className="tooltip-font-size"
              backgroundColor="#292933"
              id="follow_up"
            />
          </div>
        </div>

        <div className="overflow-x-auto max-h-[300px] mb-4">
          <div className="relative mt-4">
            <label className="text-xs font-bold text-Neutral700">
              How many times?
              <span className="absolute pointer-events-none z-[999] top-[65%] right-[16px]">
                <SelectDownArrow />
              </span>
              <select
                disabled={!schedule.isEnabled || isLoading}
                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded hover:border-Neutral800 cursor-pointer appearance-none duration-300 transition disabled:cursor-not-allowed bg-white"
                value={followupSchedule.total_times}
                name="total_times"
                onChange={(event) =>
                  setFollowupSchedule((followupSchedule) => ({
                    ...followupSchedule,
                    total_times: parseInt(event.target.value),
                  }))
                }
              >
                <option
                  value={3}
                  className="text-Neutral800 accent-AccentLight"
                >
                  3 times
                </option>
                <option
                  value={5}
                  className="text-Neutral800 hover:bg-AccentLight"
                >
                  5 times
                </option>
                <option
                  value={7}
                  className="text-Neutral800 hover:bg-AccentLight"
                >
                  7 times
                </option>
              </select>
            </label>
          </div>
          <div className="relative mt-4">
            <label className="text-xs font-bold text-Neutral700">
              Every X days
              <span className="absolute pointer-events-none z-[999] top-[65%] right-[16px]">
                <SelectDownArrow />
              </span>
              <select
                disabled={!schedule.isEnabled || isLoading}
                className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded hover:border-Neutral800 cursor-pointer appearance-none duration-300 transition disabled:cursor-not-allowed bg-white"
                value={followupSchedule.total_days}
                name="total_days"
                onChange={(event) =>
                  setFollowupSchedule((followupSchedule) => ({
                    ...followupSchedule,
                    total_days: parseInt(event.target.value),
                  }))
                }
              >
                <option
                  value={3}
                  className="text-Neutral800 hover:bg-AccentLight"
                >
                  3 days
                </option>
                <option
                  value={5}
                  className="text-Neutral800 hover:bg-AccentLight"
                >
                  5 days
                </option>
                <option
                  value={7}
                  className="text-Neutral800 hover:bg-AccentLight"
                >
                  7 days
                </option>
              </select>
            </label>
          </div>
          <div className="mt-4 w-fit">
            <label className="flex items-center gap-4 cursor-pointer select-none toggle-switch">
              <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
                Enabled
              </h5>
              <div className="relative">
                <input
                  type="checkbox"
                  className="sr-only"
                  value={followupSchedule.enable}
                  checked={followupSchedule.enable}
                  onChange={(event) => {
                    handleEnabled(event.target.checked);
                    setFollowupSchedule((followupSchedule) => ({
                      ...followupSchedule,
                      enable: event.target.checked,
                    }));
                  }}
                />
                <div className="block h-[24px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                <div className="dot absolute left-0 top-0 h-6 w-6 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
              </div>
            </label>
          </div>

          <div className="flex justify-end w-full gap-3">
            <button
              disabled={!schedule.isEnabled || isLoading}
              className={`text-sm rounded mt-4 h-10 px-3  5xl:w-fit w-fit transition duration-300 ease-in-out bg-Neutral200 drop-shadow-Texts text-AccentRegular hover:bg-Neutral300 button-hover disabled:cursor-not-allowed font-semibold`}
              onClick={handleFollowSchedule}
              type="button"
            >
              {isLoading ? "Saving..." : "Save Reminders"}
            </button>
            {/* <button
              type="button"
              onClick={() =>
                setFollowupSchedule({
                  total_times: schedule?.data?.total_times,
                  total_days: schedule?.data?.total_days,
                  disable: schedule?.data?.disable,
                })
              }
              className={`text-sm rounded mt-4 h-10 px-3  5xl:w-fit w-fit transition duration-300 ease-in-out bg-neutral-700 drop-shadow-Texts text-Neutral000 hover:bg-AccentMediumDark button-hover`}
            >
              Cancel
            </button> */}
          </div>
        </div>
      </div>

      <Modal
        isOpen={isEmailHintBoxOpen}
        onRequestClose={() => setIsEmailHintBoxOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Archived client list Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-white p-[24px_32px_24px_32px] rounded-[8px]">
            <h6 className="font-medium text-xs text-Neutral700 tracking-[0.04em] w-full">
              Remind your client to sign/complete documents and to pay your
              invoices
            </h6>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => setIsEmailHintBoxOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AutoFollowUpEmail;
