import { Formik } from "formik";
import { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { UpdatePasswordAction } from "../../store/actions/authAction";
import { UpdateCollabPasswordAction } from "../../store/actions/collabAuthAction";
import ButtonLoader from "../Loaders/ButtonLoader";

const UpdatePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required("New Password is Required"),
  new_password: Yup.string()
    .required("New Password is Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_new_password: Yup.string()
    .required("Confirm Password is Required")
    .min(8, "Min 8 character required")
    .oneOf(
      [Yup.ref("new_password")],
      "Confirm Password must match with new password"
    ),
});

const Security = ({ isCollab = false }) => {
  const dispatch = useDispatch();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  return (
    <Formik
      initialValues={{
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      }}
      enableReinitialize
      validationSchema={UpdatePasswordSchema}
      onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
        setSubmitting(true);
        if (isCollab) {
          dispatch(
            UpdateCollabPasswordAction(
              values,
              setSubmitting,
              setErrors,
              resetForm
            )
          );
        } else {
          dispatch(
            UpdatePasswordAction(values, setSubmitting, setErrors, resetForm)
          );
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form
          onSubmit={handleSubmit}
          onChange={() => setIsPasswordChanged(false)}
        >
          <div
            className={`w-full 5xl:max-w-[500px] md:max-w-[408px] rounded border border-Neutral300 pt-8 px-10 pb-10 ${
              isCollab ? "bg-white" : ""
            } `}
          >
            <h5
              className={`text-Neutral700 text-xs font-bold tracking-[0.1em] ${
                isCollab ? "" : "mb-10"
              } uppercase `}
            >
              SECURITY
            </h5>
            <div className={isCollab ? "mt-5" : "mt-6"}>
              <label className="text-xs font-bold text-Neutral900">
                Old Password
              </label>
              <div className="mt-2">
                <input
                  className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-11 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                    errors.old_password && touched.old_password
                      ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                      : ""
                  }`}
                  type="password"
                  name="old_password"
                  value={values.old_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.old_password && touched.old_password && (
                  <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none ">
                    {errors.old_password}
                  </p>
                )}
              </div>
            </div>
            <div className={isCollab ? "mt-5" : "mt-6"}>
              <label className="text-xs font-bold text-Neutral900">
                New Password
              </label>
              <div className="mt-2">
                <input
                  className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-12 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                    errors.new_password && touched.new_password
                      ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                      : ""
                  }`}
                  type="password"
                  name="new_password"
                  value={values.new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.new_password && touched.new_password && (
                  <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none ">
                    {errors.new_password}
                  </p>
                )}
              </div>
            </div>
            {values.new_password && values.new_password.length && (
              <PasswordStrengthBar
                className="px-1 mt-2 transition duration-300 strength-bar"
                scoreWordStyle={{ color: "#757580", display: "none" }}
                scoreWordClassName="strength-word"
                password={values.new_password}
                barColors={[
                  "#DFDFE5",
                  "#6D6DF2",
                  "#6D6DF2",
                  "#6D6DF2",
                  "#6D6DF2",
                ]}
                scoreWords={[
                  "Password Strength",
                  "Password Strength",
                  "Password Strength",
                  "Password Strength",
                  "Password Strength",
                ]}
                shortScoreWord="Password Strength"
              />
            )}
            <div className="mt-6">
              <label className="text-xs font-bold text-Neutral900">
                Confirm New Password
              </label>
              <div className="mt-2">
                <input
                  className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-12 focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                    errors.confirm_new_password && touched.confirm_new_password
                      ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                      : ""
                  }`}
                  type="password"
                  name="confirm_new_password"
                  value={values.confirm_new_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.confirm_new_password &&
                  touched.confirm_new_password && (
                    <p className="mt-2 text-xs font-medium text-Red400 focus-visible:outline-none ">
                      {errors.confirm_new_password}
                    </p>
                  )}
              </div>
            </div>
            <button
              className={`large-primary-btn text-sm rounded mt-8 h-12 5xl:w-[184px] w-[172px] focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0 transition duration-300 ease-in-out ${
                isPasswordChanged
                  ? "disabled bg-Neutral300 text-Neutral500"
                  : "bg-Neutral200 text-AccentRegular hover:bg-Neutral300 button-hover drop-shadow-Texts"
              }`}
              type="submit"
              disabled={isSubmitting || isPasswordChanged}
            >
              {isSubmitting ? (
                <ButtonLoader className="z-10" />
              ) : (
                <span className="relative z-10 font-[600]">
                  Update Password
                </span>
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Security;
