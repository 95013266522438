import React from "react";

function ResetIcon({ width = 21, className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 7.33331H5.66667C4.74619 7.33331 4 8.07951 4 8.99998V17.3333C4 18.2538 4.74619 19 5.66667 19H19C19.9205 19 20.6667 18.2538 20.6667 17.3333V8.99998C20.6667 8.07951 19.9205 7.33331 19 7.33331Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6667 19V5.66667C15.6667 5.22464 15.4911 4.80072 15.1785 4.48816C14.866 4.17559 14.442 4 14 4H10.6667C10.2246 4 9.80072 4.17559 9.48816 4.48816C9.1756 4.80072 9 5.22464 9 5.66667V19"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ResetIcon;
