import moment from "moment";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

import ResetIcon from "../Icons/ResetIcon";
import SuitcaseIcon from "../Icons/SuitcaseIcon";

const frequencyList = [
  {
    id: 1,
    frequency: "Weekly",
    value: "weekly",
    Icon: <ResetIcon className="mr-[1.5px]" color="currentColor" width={20} />,
  },
  {
    id: 2,
    frequency: "Monthly",
    value: "monthly",
    Icon: <ResetIcon className="mr-[1.5px]" color="currentColor" width={20} />,
  },
  {
    id: 3,
    frequency: "Yearly",
    value: "yearly",
    Icon: <ResetIcon className="mr-[1.5px]" width={20} />,
  },
  {
    id: 4,
    frequency: "once-off",
    value: "1",
    Icon: <SuitcaseIcon className="mr-[1.5px]" width={20} />,
  },
  {
    id: 5,
    frequency: "indefinitely",
    value: "indefinitely",
    Icon: <ResetIcon className="mr-[1.5px]" width={20} />,
  },
];

const FrequencyButton = ({ frequency, invoice }) => {
  const [openFrequency, setOpenFrequency] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState(
    frequencyList.find((item) => item.value === frequency) || frequencyList[0]
  );

  const handleFrequencyChange = (frequency) => {
    setSelectedFrequency(
      frequencyList.find((item) => item.value === frequency)
    );
    setOpenFrequency(false);
  };

  return (
    <div className="relative">
      <div
        className={`text-[10px] xl:text-xs whitespace-nowrap w-fit font-bold rounded-[20px] py-[6px] px-[6px] focus-visible:outline-none focus:outline focus:outline-none uppercase flex items-center border border-Neutral400 hover:bg-Neutral200 relative flex-wrap hover:text-AccentRegular`}
        disabled
        data-tip={`Invoice date: ${moment(invoice?.invoiceDate).format(
          "DD MMM YYYY"
        )} - Due date: ${moment(invoice?.dueDate).format(
          "DD MMM YYYY"
        )} - Repeat: ${invoice?.repeats}`}
        data-for={
          invoice?.invoiceDate && invoice?.dueDate && invoice?.repeats
            ? "frequency"
            : ""
        }
        // onClick={() => setOpenFrequency(!openFrequency)}
      >
        {selectedFrequency.Icon}
        <span>{selectedFrequency.frequency}</span>
        <ReactTooltip
          id="frequency"
          place="bottom"
          effect="solid"
          textColor="#F5F5F7"
          className="tooltip-font-size"
          backgroundColor="#292933"
        />
      </div>
      {openFrequency && (
        <div className="absolute top-0 left-[190px] w-full h-full flex flex-row justify-center items-center">
          {frequencyList
            ?.filter((item) => item.frequency !== selectedFrequency.frequency)
            .map((item) => (
              <button
                key={item.id}
                onClick={() => handleFrequencyChange(item.frequency)}
                className={`text-[10px] xl:text-xs whitespace-nowrap font-bold rounded-[20px] py-[6px] px-[12px] focus-visible:outline-none focus:outline focus:outline-none transition-all duration-300 uppercase flex items-center frequency-button bg-Neutral200 mr-2`}
              >
                {item.Icon}
                <span>{item.frequency}</span>
              </button>
            ))}
        </div>
      )}
    </div>
  );
};

export default FrequencyButton;
