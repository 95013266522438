import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  DeleteProjectAction,
  makeDuplicateProject,
} from "../../../store/actions/projectAction";
import prepareCategories from "../../../utils/prepareCategories";
import ProjectActionMenuButton from "../../Buttons/ProjectActionMenuButton";
import CreateProjectModal from "../../Modals/ProjectModal/CreateProjectModal";
import ShareProjectModal from "../../Modals/ProjectModal/ShareProjectModal";
import CardControls from "../../sections/ProjectDashboardsSection/components/CardControls";
import TasksBlock from "../../sections/ProjectDashboardsSection/components/TasksBlock";
import TrackingTimeBlock from "../../sections/ProjectDashboardsSection/components/TrackingTimeBlock";
import ProjectCategoryBadge from "../badges/ProjectCategoryBadge";
import ProjectCounterBadge from "../badges/ProjectCounterBadge";
import TeamAvatarsList from "../lists/TeamAvatarsList";
import ProjectTitle from "../typography/ProjectTitle";
import { cn } from "../../../utils";

function ProjectCard({ generateDuplicateTitle, project_no, ...project }) {
  const {
    _id,
    title = "Project Name",
    category = [],
    className = "",
    total_tasks = 0,
    total_tasks_by_column = {},
    total_entries = 0,
    total_time = 0,
    client,
    time_contributors = [],
    task_contributors = [],
  } = project;

  const dispatch = useDispatch();

  const clientDetail = useSelector((state) => state.client.clientDetail);

  const [editIsOpen, setEditNotIsOpen] = useState(false);
  const [shareProjectModal, setShareProjectModal] = useState(false);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    id: null,
  });

  const categoriesBadges = prepareCategories(category).map((category, idx) => (
    <ProjectCategoryBadge
      key={category?.id ?? idx}
      text={category}
      className="mr-1"
    />
  ));

  const removeDuplicates = () => {
    const unique = [...time_contributors, ...task_contributors].reduce(
      (acc, current) => {
        const x = acc.find((item) => item._id === current._id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    );
    return unique;
  };

  const handleDeleteProject = (id) => {
    setIsLoading({ loading: true, id });
    dispatch(DeleteProjectAction(id, setIsLoading));
  };

  const handleDuplicateProject = (id) => {
    const tempTitle = generateDuplicateTitle(title);
    dispatch(
      makeDuplicateProject({
        id,
        client: client?._id,
        title: tempTitle,
      })
    );
  };

  return (
    <div
      className={cn(
        "flex flex-col p-6 rounded-xl bg-Neutral000 border border-Neutral200 shadow-[0_4px_15px_rgba(0,0,0,0.08)] min-w-[320px]",
        className
      )}
    >
      <div className="flex justify-between mb-3 ml-[-16px] py-1">
        <div className="flex items-center">
          {isLoading.loading && isLoading.id === _id ? (
            <div className="w-[20px] h-[20px] flex justify-center items-center">
              <svg
                className="w-5 h-5 animate-spin text-AccentRegular"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                />
              </svg>
            </div>
          ) : (
            <ProjectActionMenuButton
              clientDetail={clientDetail}
              onEdit={() => setEditNotIsOpen(true)}
              onDelete={() => handleDeleteProject(_id)}
              onDuplicate={() => handleDuplicateProject(_id)}
            />
          )}
          <Link to={`/admin/clients/manage/${client?._id}/project/${_id}`}>
            <ProjectTitle
              text={title}
              className="mr-3 truncate max-w-[260px] w-full"
            />
          </Link>
        </div>
        <ProjectCounterBadge number={project_no} />
      </div>
      <div className="flex flex-wrap gap-1 mb-3 px-[6px]">
        {categoriesBadges}
      </div>
      <TeamAvatarsList users={removeDuplicates()} className="px-2 mb-5" />
      <TasksBlock
        tasks={{
          actual: total_tasks,
          ...total_tasks_by_column,
        }}
        className="pl-1 mb-4"
        id={_id}
      />
      <TrackingTimeBlock
        trackingTime={{
          entries: total_entries,
          hours: total_time
            ? total_time?.split(":")[0].charAt(0) === "0"
              ? total_time?.split(":")[0].charAt(1)
              : total_time?.split(":")[0]
            : 0,
          minutes: total_time
            ? total_time?.split(":")[1].charAt(0) === "0"
              ? total_time?.split(":")[1].charAt(1)
              : total_time?.split(":")[1]
            : 0,
        }}
        className="pl-1 mb-10"
      />
      <CardControls
        className="mt-auto"
        projectId={_id}
        clientId={client?._id}
        project={project}
        setShareProjectModal={setShareProjectModal}
      />
      {editIsOpen && (
        <CreateProjectModal
          isOpen={editIsOpen}
          setIsOpen={setEditNotIsOpen}
          editProject={project}
          isEdit={true}
        />
      )}
      {shareProjectModal && (
        <ShareProjectModal
          isOpen={shareProjectModal}
          setIsOpen={setShareProjectModal}
          project={project}
          //  editProject={props}
        />
      )}
    </div>
  );
}

export default ProjectCard;
