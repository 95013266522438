import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch } from "react-redux";

import Contract from "../../../../../assets/Images/Contract-Mdaol.svg";
import { UpdateServiceActions } from "../../../../../store/actions/serviceAction";
import { UpdateTaskActions } from "../../../../../store/actions/tasksAction";
import DraftEditor from "../../../../comman/DraftEditor/DraftEditor";
import ContractIcon from "../../../../Icons/ContractIcon";

const DescriptionNew = ({
  isNew,
  item,
  user,
  types,
  clientDetail,
  // TODO: for hardcode (delete after)
  user_type,
  access_level,
  inShort = false,
}) => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (item?.description && item) {
      setContent(
        EditorState.createWithContent(
          convertFromRaw(
            item?.description?.entityMap
              ? item?.description
              : { ...item?.description, entityMap: {} }
          )
        )
      );
    }
  }, [item]);

  const debouncedChangeHandler = useRef(
    debounce((editData) => {
      if (types === "task") dispatch(UpdateTaskActions(editData));
      if (types === "service") dispatch(UpdateServiceActions(editData));
      setIsEdit(false);
    }, 400)
  ).current;

  const handleChange = () => {
    if (user_type) {
      debouncedChangeHandler({
        ...item,
        description: convertToRaw(content.getCurrentContent()),
        user_type: "client_user",
      });
    } else {
      debouncedChangeHandler({
        ...item,
        description: convertToRaw(content.getCurrentContent()),
      });
    }
  };

  return (
    <div className="relative flex flex-col w-full h-auto gap-4 py-3 pl-5 pr-2 rounded-lg bg-Neutral100">
      {!inShort && (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 text-AccentRegular">
            {/* <img src={Contract} className="mr-[12px]" alt="" /> */}
            <ContractIcon width={22} />
            <p className="text-AccentRegular text-[10px] font-semibold">
              DESCRIPTION
            </p>
          </div>

          {/* <button type="button">
          <MenuIcon />
        </button> */}
        </div>
      )}

      {!isEdit ? (
        <div
          className={`h-auto resize-none rounded-[4px] text-sm w-full 
            focus:outline-none m-0 break-all pr-5 ${!item?.description && ""}`}
          onClick={() => {
            if (
              isNew
                ? !isNew
                : (!user_type &&
                    (clientDetail?.is_admin ||
                      clientDetail?.is_manager ||
                      user?._id === item?.created_by?._id)) ||
                  (user_type && access_level === "full_access")
            ) {
              setIsEdit(true);
            }
          }}
        >
          <Editor
            className="placeholder:text-Neutral600 text-sm focus:outline-none w-full h-full rounded-r-lg text-[red]"
            placeholder="Write comment..."
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            editorState={content}
            onEditorStateChange={handleChange}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions: [],
            }}
            toolbarHidden
            editorStyle={{
              zIndex: 9999999,
              overflow: "hidden!important",
              color: "#5A5A66",
              fontSize: "14px",
              fontFamily: "Noto Sans",
              lineHeight: "21px",
            }}
            readOnly
            hashtag={{}}
          />
        </div>
      ) : (
        <DraftEditor
          comment={content}
          setComment={setContent}
          clientDetail={clientDetail}
          isDescription={true}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export default DescriptionNew;
