import { Menu, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ThreeDots from "../../assets/Images/three-dots.svg";
import {
  ChangeProposalStatusAction,
  GetProposalList,
  GetProposalListByClientId,
  GetProposalStatusList,
} from "../../store/actions/proposalAction";

const colors = ["Blue", "Green", "Red", "Neutral", "Orange"];

const colorCodition = (index) => {
  if (index < 5) {
    return colors[index];
  } else {
    return colors[index % colors.length];
  }
};

const generateColorObject = (color) => ({
  bg: `bg-${color}400`,
  bgActive: `bg-${color}200`,
  text: `hover:text-${color}800`,
  textactive: `text-${color}800`,
  statehover: `hover:text-${color}200`,
});

const ProposalsStatus = ({ id, status, proposal_id, isEditMode = false }) => {
  const dispatch = useDispatch();
  const [customProposalStatus, setCustomProposalStatus] = useState("");

  const clientProposalStatusList = useSelector(
    (state) => state.proposals.clientProposalStatusList
  );

  const [statusButton, setStatusButton] = useState(false);

  const [selctedStatus, setSelectedStatus] = useState(
    clientProposalStatusList.data.find((e) => e?.name === status) || {}
  );

  const handleStatusChange = (item, isCustom) => {
    setSelectedStatus(item);

    let oldStatus = selctedStatus;

    const haveError = () => {
      setSelectedStatus(oldStatus);
    };

    dispatch(
      ChangeProposalStatusAction(
        { id, status: item._id, proposal_id }, //added proposal_id
        haveError
      )
    );

    if (!isEditMode) {
      setTimeout(() => {
        dispatch(
          GetProposalList({
            page: 1,
            perPage: 50,
            search: "",
          })
        );
      }, 500);
    } else {
      dispatch(GetProposalListByClientId(id));
    }
  };

  const handleCreateCustomProposalList = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Check if the custom status input value is not empty
      if (customProposalStatus.length === 0) return;

      // Check if the newly created custom proposal does not already exists
      if (
        clientProposalStatusList.data?.some(
          (ele) =>
            ele.status ===
            customProposalStatus.toLowerCase().replace(/\s/g, "_")
        )
      ) {
        return;
      }

      try {
        const { data } = await axios.post(
          "api/v1/proposal-status/create-proposal-status",
          {
            name: customProposalStatus,
            color: generateColorObject(
              colorCodition(clientProposalStatusList.data.length)
            ),
          }
        );

        toast.success(data?.message);

        // Select the newly created status after successful creation
        if (data?.data) {
          setSelectedStatus(data.data);
          handleStatusChange(data.data, true);
        }

        // Refetch the status list with the new custom proposal status
        dispatch(GetProposalStatusList());
      } catch (error) {
        toast.error(error?.response?.data?.errors[0].msg);
      } finally {
        setCustomProposalStatus("");
      }
    }
  };

  const handleDeleteStatus = async (e, item) => {
    e.stopPropagation();
    try {
      const { data } = await axios.post(
        "api/v1/proposal-status/delete-proposal-status",
        {
          id: item._id,
        }
      );

      toast.success(data?.message);

      // Refetch the status list with the new custom proposal status
      dispatch(GetProposalStatusList());
    } catch (error) {
      toast.error(error?.response?.data?.errors[0].msg);
    }
  };

  return (
    <>
      <div className="flex items-center justify-end text-left w-full max-w-[290px] relative">
        <Menu
          as="div"
          className="relative inline-block text-right w-32 -ml-[16px]"
        >
          <Menu.Button>
            <div
              className={`flex items-center transition duration-300 ${
                statusButton ? "" : "opacity-100 w-fit"
              } `}
            >
              <span
                className={`text-[10px] 5xl:text-sm py-1 px-2 font-semibold ${selctedStatus?.color?.textactive} ${selctedStatus?.color?.bgActive} rounded-2xl leading-tight capitalize`}
              >
                {selctedStatus?.name?.replace(/_/g, " ")}
              </span>
              <img
                className="w-6 cursor-pointer h-fit"
                src={ThreeDots}
                alt=""
                onClick={() => setStatusButton(true)}
              />
            </div>
          </Menu.Button>
          <Transition as={Fragment}>
            <Menu.Items className="box shadow-[0px_8px_24px_rgba(0,0,0,0.1)] z-50 absolute top-[35px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pt-3 rounded-[0px_0px_4px_4px]">
              <div className="bg-white">
                <input
                  type="text"
                  placeholder="Create Custom"
                  className="font-normal text-[10px] placeholder:text-Neutral600 text-Neutral700 focus:outline-none w-full mb-3 px-3"
                  onKeyUp={(e) => {
                    e.preventDefault();
                  }}
                  value={customProposalStatus}
                  // onKeyDown={handleCreateCustomProposalList}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.stopPropagation();
                    } else if (e.key === "Enter") {
                      handleCreateCustomProposalList(e);
                    }
                  }}
                  onChange={(e) => setCustomProposalStatus(e.target.value)}
                />

                <div className="px-2 pb-2">
                  {clientProposalStatusList.data.map((item, id) => (
                    <div
                      key={id}
                      className={`transition-all duration-300 uppercase flex items-center justify-between w-full cursor-pointer whitespace-nowrap text-[10px] 5xl:text-sm py-2 font-semibold leading-tight rounded-[2px] text-Neutral800 group
						${item?.color?.text} ${item?.color?.statehover}
						${
              selctedStatus?.name === item?.name
                ? `${selctedStatus?.color?.bgActive} ${selctedStatus?.color?.textactive}`
                : ""
            }
						${
              !selctedStatus &&
              clientProposalStatusList.data.slice(1).length > 0
                ? `${clientProposalStatusList.data[1].color?.bgActive} ${clientProposalStatusList.data[1].color?.textactive}`
                : ""
            }`}
                      onClick={() => {
                        if (item.status === "viewed") return;
                        setStatusButton(false);
                        if (item.name) {
                          handleStatusChange(item);
                        }
                      }}
                    >
                      <div className="flex items-center">
                        <span
                          className={`w-2 h-2 rounded-full block mr-2 ml-2 ${item?.color.bg} uppercase `}
                        ></span>
                        {item?.name?.replace(/_/g, " ")}
                      </div>

                      <button
                        className="text-transparent group-hover:text-Red500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteStatus(e, item);
                        }}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.16663 4.62195H2.35922H11.9"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.14729 4.62252V3.45426C4.14729 3.14442 4.27294 2.84727 4.49659 2.62818C4.72024 2.40909 5.02359 2.28601 5.33988 2.28601H7.72507C8.04136 2.28601 8.3447 2.40909 8.56836 2.62818C8.79201 2.84727 8.91766 3.14442 8.91766 3.45426V4.62252M10.7065 4.62252V12.8003C10.7065 13.1101 10.5809 13.4073 10.3572 13.6264C10.1336 13.8455 9.83025 13.9686 9.51396 13.9686H3.55099C3.2347 13.9686 2.93136 13.8455 2.7077 13.6264C2.48405 13.4073 2.3584 13.1101 2.3584 12.8003V4.62252H10.7065Z"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default ProposalsStatus;
