function CloudLoaderIcon({ size = 24, className = "" }) {
  return (
    <svg
      width={size}
      height={size + 4}
      viewBox="0 0 24 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3334 17.3333L12 14L8.66669 17.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14V21.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9917 19.3249C19.8045 18.8818 20.4466 18.1806 20.8166 17.3321C21.1866 16.4835 21.2635 15.5359 21.0352 14.6388C20.8069 13.7417 20.2863 12.9462 19.5556 12.3778C18.8249 11.8094 17.9257 11.5005 17 11.4999H15.95C15.6978 10.5243 15.2277 9.61852 14.575 8.85073C13.9223 8.08295 13.1041 7.47311 12.1818 7.06708C11.2595 6.66104 10.2572 6.46937 9.25013 6.50647C8.2431 6.54358 7.25758 6.80849 6.36768 7.28129C5.47777 7.7541 4.70662 8.42249 4.11221 9.23622C3.5178 10.05 3.1156 10.9879 2.93584 11.9794C2.75609 12.9709 2.80345 13.9903 3.07437 14.961C3.3453 15.9316 3.83273 16.8281 4.50003 17.5832"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3334 17.3333L12 14L8.66669 17.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloudLoaderIcon;
