import React from "react";

function DashboardIcon({
  color = "Neutral800",
  width = 24,
  className = "text-[#5A5A66]",
}) {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 19.0003C17.9555 19.0003 19.0002 17.9556 19.0002 16.667C19.0002 15.3783 17.9555 14.3336 16.6668 14.3336C15.3782 14.3336 14.3335 15.3783 14.3335 16.667C14.3335 17.9556 15.3782 19.0003 16.6668 19.0003Z"
        stroke="var(--accent-color)" //#262680
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.33333 9.66667C8.622 9.66667 9.66667 8.622 9.66667 7.33333C9.66667 6.04467 8.622 5 7.33333 5C6.04467 5 5 6.04467 5 7.33333C5 8.622 6.04467 9.66667 7.33333 9.66667Z"
        stroke="var(--accent-color)" //#262680
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7778 7.33362H15.1112C15.5237 7.33362 15.9194 7.49751 16.2111 7.78923C16.5028 8.08095 16.6667 8.47661 16.6667 8.88917V14.3336"
        stroke="var(--accent-color)" //#262680
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.3335 9.66638V18.9997"
        stroke="var(--accent-color)" //#262680
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
