import { Menu } from "@headlessui/react";
import { Formik } from "formik";
import moment from "moment";
import { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import "react-time-picker-input/dist/components/TimeInput.css";
import * as Yup from "yup";

import addTagMinus from "../../assets/Images/addTag-minus.svg";
import CloseBtn from "../../assets/Images/close-btn.svg";
import addTagClose from "../../assets/Images/modal-close-icon.svg";
import pluseIcon from "../../assets/Images/pluse-icon.svg";
import threeDots from "../../assets/Images/three-dots-modals.svg";
import useMenu from "../../hooks/useMenu";
import {
  AddUserCategoryAction,
  RemoverUserCategoryAction,
} from "../../store/actions/authAction";
import { getFullClientList } from "../../store/actions/clientAction";
import { AddTimeEntryActions } from "../../store/actions/timeTrackingAction";
import ClientSelect from "../comman/Client/ClientSelect";
import { CreateTaskActions } from "../../store/actions/tasksAction";
import { GetProjectDetailAction } from "../../store/actions/projectAction";
import CalendarIcon from "../Icons/CalendarIcon";
import SelectDownArrow from "../Icons/SelectDownArrow";
import { Portal } from "../Portal";

const colors = ["Green400", "Orange400", "Red400", "Blue400", "Pink400"];

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "0",
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

const TrackingAddTimeEntryModal = (props) => {
  const addNewTagInput = useRef();
  const {
    modalIsOpen,
    setIsOpen,
    setAddNewTimeEntry,
    modalClientDetail,
    setTimeEntryList,
  } = props;
  const dispatch = useDispatch();

  const assignTimeValidation = Yup.object().shape({
    client: Yup.string().required("Choose client name"),
    title: Yup.string().required("Enter tracking title"),
    category: Yup.array().min(1, "add 1 category").required("required"),
    date: Yup.string().required("Enter tracking Date")
      .max("date", (obj) => {
        if(moment(obj.value) > moment(Date())){
          return `The date can't be greater than today's date`;
        }
    }),
    hours: Yup.string().required("Enter tracking Hours"),
    minutes: Yup.string().required("Enter tracking Minutes"),
    project: Yup.string().required("Choose project name"),
  });

  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  const [tempType, setTempType] = useState({
    client: "",
    title: "",
    color: randomColor,
    category: [],
    date: moment().format("YYYY-MM-DD"),
    hours: "00",
    minutes: "00",
    project: "",
  });
  const [selected, setSelected] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [removeClass, setIsRemoveClass] = useState(false);
  const [newTag, setNewTag] = useState(null);
  const [addTag, setIsAddTag] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const projectDetail = useSelector((state) => state.project.projectDetail);
  const loggedInUser = useSelector((state) => state.auth.user);
  const allClients = useSelector((state) => state.client.fullClientList);
  const userDateFormat = loggedInUser?.date_format;
  const { setAnchorRef, setMenuElementRef, menuStyles, menuAttributes } =
    useMenu({});

  useEffect(() => {
    if (!allClients?.data?.length && modalIsOpen) {
      dispatch(getFullClientList());
    }
  }, [allClients?.data?.length, dispatch, modalIsOpen]);

  useEffect(() => {
    if (modalClientDetail?._id || projectId) {
      dispatch(
        GetProjectDetailAction(projectId ? projectId : modalClientDetail?._id)
      );
    }
  }, [modalClientDetail, projectId]);

  useEffect(() => {
    if (modalClientDetail) {
      setTempType({
        ...tempType,
        client: modalClientDetail?.client?._id,
        project: modalClientDetail?._id,
      });
      setSelectedClient([modalClientDetail]);
      const tempClient = allClients?.data?.find(
        (e) => e?._id === modalClientDetail?.client?._id
      );
      let tempCategory = [];
      if (tempClient?.category?.length) {
        tempCategory = [...tempClient?.category, ...tempCategory];
      }

      if (loggedInUser?.category?.length) {
        tempCategory = [...loggedInUser?.category, ...tempCategory];
      }

      if (modalClientDetail?.category?.length) {
        tempCategory = [...modalClientDetail?.category, ...tempCategory];
      }

      tempCategory = tempCategory.reduce((acc, current) => {
        const x = acc.find(
          (item) => item?.toLowerCase() === current?.toLowerCase()
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setSelected({
        ...selected,
        ...tempClient,
        category: [...tempCategory],
      });
    }
  }, [
    modalClientDetail,
    allClients,
    // tempType,
    loggedInUser?.category,
    // selected,
  ]);

  const handleRemove = async (data, id) => {
    dispatch(RemoverUserCategoryAction(data));
  };

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative w-fit">
      <input
        type="text"
        className={`cursor-pointer caret-transparent example-custom-input whitespace-nowrap ${
          value ? "font-bold text-Neutral900" : "text-Neutral600"
        } text-Neutral800 h-[48px] font-medium font-feature-settings pl-[17.5px] pr-[46.5px] placeholder:text-sm text-sm leading-5 5xl:max-w-[180px] w-[148px] focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition add-new-event-time-color`}
        value={!value ? "Select date" : moment(value).format(userDateFormat)} // Formatting the display value
        onClick={onClick}
        readOnly
        ref={ref}
      />
      <CalendarIcon className="absolute top-[50%] right-[12px] translate-y-[-50%] pointer-events-none" />
    </div>
  ));

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="Example Modal"
      >
        <div className="relative bg-Neutral000 rounded-lg text-left transform transition-all sm:mt-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
          <div className="bg-Neutral000 p-6 rounded-lg w-[85vw] md:w-full">
            <div>
              <div className="pb-2 text-xs font-semibold border-b text-Neutral700 border-Neutral200">
                NEW TIME ENTRY
              </div>
              <Formik
                initialValues={tempType}
                enableReinitialize
                validationSchema={assignTimeValidation}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  const data = {
                    ...values,
                    time: `${values?.hours}:${values?.minutes}`,
                  };
                  setSubmitting(true);
                  dispatch(
                    CreateTaskActions({
                      client_id: values.client,
                      title: values.title,
                      project: values.project,
                      tag: "not_started",
                      status: "to_do",
                      column: projectDetail?.data?.task_column[0]?.name,
                      type: "GENERAL",
                    })
                  );
                  dispatch(
                    AddTimeEntryActions(
                      data,
                      setSubmitting,
                      setErrors,
                      setIsOpen,
                      setTempType,
                      setAddNewTimeEntry,
                      setTimeEntryList,
                      setProjectId
                    )
                  );
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form>
                    <div className="flex flex-col gap-2 max-h-[65vh] overflow-y-auto py-2">
                      <div className="space-y-1">
                        <label
                          for="countries"
                          className="inline-block mb-2 text-xs font-bold text-Neutral900"
                        >
                          Client
                        </label>
                        <ClientSelect
                          allClients={allClients}
                          onChange={(e) => {
                            const tempProject = JSON.parse(e.target.value);
                            setFieldValue("project", tempProject?._id);
                            setFieldValue("client", e?._id);
                            setProjectId(tempProject?._id);
                            setTempType({
                              ...tempType,
                              category: [],
                              project: "",
                            });
                            setSelectedClient(e?.projects);
                          }}
                          value={values?.client}
                          isDisabled={modalClientDetail?.client ? true : false}
                          onBlur={handleBlur}
                        />

                        {errors?.client && touched?.client && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors?.client.client}
                          </p>
                        )}
                      </div>

                      <div className="space-y-1">
                        <label
                          for="countries"
                          className="inline-block w-full mb-2 text-xs font-bold text-left text-Neutral900"
                        >
                          Project
                        </label>
                        <div className="relative">
                          <span className="absolute pointer-events-none z-[9] top-[50%] translate-y-[-50%] right-[16px]">
                            <SelectDownArrow />
                          </span>
                          <select
                            id="countries"
                            onChange={(e) => {
                              const tempProject = JSON.parse(e.target.value);
                              setFieldValue("project", tempProject?._id);
                              setTempType({
                                ...tempType,
                                category: [],
                              });
                              const tempClient = allClients?.data?.find(
                                (e) => e?._id === values?.client
                              );
                              let tempCategory = [];
                              if (tempProject?.category?.length) {
                                tempCategory = [
                                  ...tempProject?.category,
                                  ...tempCategory,
                                ];
                              }
                              if (tempClient?.category?.length) {
                                tempCategory = [
                                  ...tempClient?.category,
                                  ...tempCategory,
                                ];
                              }
                              if (loggedInUser?.category?.length) {
                                tempCategory = [
                                  ...loggedInUser?.category,
                                  ...tempCategory,
                                ];
                              }
                              tempCategory = tempCategory.reduce(
                                (acc, current) => {
                                  const x = acc.find(
                                    (item) =>
                                      item?.toLowerCase() ===
                                      current?.toLowerCase()
                                  );
                                  if (!x) {
                                    return acc.concat([current]);
                                  } else {
                                    return acc;
                                  }
                                },
                                []
                              );
                              setSelected({
                                ...selected,
                                category: [...tempCategory],
                              });
                            }}
                            onBlur={handleBlur}
                            name="project"
                            disabled={modalClientDetail?.client ? true : false}
                            className="text-Neutral800 bg-Neutral200 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-transparent hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition"
                          >
                            <option
                              value=""
                              disabled
                              selected={values?.project === ""}
                              defaultValue
                            >
                              Select Project...
                            </option>
                            {selectedClient?.map((item, i) => {
                              return (
                                <option
                                  selected={item?._id === values?.project}
                                  value={JSON.stringify(item)}
                                  key={`${item?._id + i}`}
                                >
                                  {item?.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {errors?.project && touched?.project && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors?.project}
                          </p>
                        )}
                      </div>

                      <div className="space-y-1">
                        <label
                          for="countries"
                          className="inline-block w-full mb-2 text-xs font-bold text-left text-Neutral900"
                        >
                          Title
                        </label>
                        <input
                          value={values.title}
                          type="text"
                          name="title"
                          className="relative text-Neutral800 placeholder:font-medium px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow appearance-none duration-300 transition border-Neutral300"
                          placeholder="Eg. Brand Building"
                          onChange={handleChange}
                        />
                        {errors?.title && touched?.title && (
                          <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                            {errors?.title}
                          </p>
                        )}
                      </div>

                      <Menu as="div" className="relative">
                        <Menu.Button
                          ref={setAnchorRef}
                          className="w-full focus-visible:outline-none popup-btn"
                          disabled={!values?.project && !values?.client}
                        >
                          <div className="space-y-1">
                            <label
                              for="countries"
                              className="inline-block w-full mb-2 text-xs font-bold text-left text-Neutral900"
                            >
                              Categories
                            </label>
                            <div className="relative flex flex-wrap">
                              {!tempType?.category?.length && (
                                <>
                                  <span className="absolute pointer-events-none z-[9] top-[50%] translate-y-[-50%] right-[16px]">
                                    <SelectDownArrow />
                                  </span>
                                  <input
                                    id="countries"
                                    onChange={handleChange}
                                    name="category"
                                    // disabled
                                    className="relative text-Neutral500 font-medium placeholder:text-Neutral800 px-4 placeholder:text-xs 5xl:placeholder:text-lg text-xs leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 bg-Neutral200 "
                                    placeholder="NONE"
                                  />
                                </>
                              )}
                              {tempType?.category?.length === 0
                                ? true
                                : " " && (
                                    <div className="relative flex flex-wrap items-center text-Neutral500 font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full min-h-[48px] focus:outline-none border-[1.5px] rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition border-Neutral300 bg-Neutral200">
                                      <span className="absolute pointer-events-none z-[9] top-[50%] translate-y-[-50%] right-[16px]">
                                        <SelectDownArrow />
                                      </span>
                                      {tempType?.category?.map((ele, index) => {
                                        return (
                                          <span
                                            key={index}
                                            className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] max-h-[30px] mt-[0px] mr-2 mb-0 font-medium rounded-[6px] cursor-pointer text-Neutral800 border border-Neutral400 relative"
                                          >
                                            <div className="px-2 py-1 tracking-wider uppercase">
                                              {ele}
                                            </div>
                                          </span>
                                        );
                                      })}
                                    </div>
                                  )}
                            </div>
                            {errors?.category && touched?.category && (
                              <p className="text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors?.category}
                              </p>
                            )}
                          </div>
                        </Menu.Button>

                        <Portal>
                          <Menu.Items
                            ref={setMenuElementRef}
                            className="z-[9999] rounded-md dropdowns-boxshadow bg-white focus:outline-none max-w-[360px] max-h-60 px-4 py-2 overflow-y-auto"
                            style={menuStyles}
                            {...menuAttributes}
                          >
                            <div>
                              <p className="text-xs font-semibold text-Neutral600">
                                Selected Category Tags
                              </p>
                              <div className="flex flex-wrap pt-1">
                                {tempType?.category?.map((ele, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="text-[12px] 5xl:text-xs 5xl:min-w-[40px] min-w-[32px] w-full max-w-fit flex items-center 5xl:min-h-[24px] min-h-[18px] mr-2 mb-1 font-semibold rounded-[6px] cursor-pointer text-Neutral900 border border-Neutral400 relative"
                                    >
                                      <div
                                        className="px-2 py-1 tracking-wider uppercase"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          const removedCategories =
                                            tempType?.category?.filter(
                                              (element) => element !== ele
                                            );
                                          setTempType({
                                            ...values,
                                            category: [...removedCategories],
                                          });
                                        }}
                                      >
                                        {ele}
                                      </div>
                                    </span>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="pt-1 mr-2">
                              <div className="flex items-center justify-between">
                                <p className="text-Neutral600 font-semibold text-[10px] py-2">
                                  + Add Category Tags
                                </p>
                                <div className="flex items-center justify-center w-4 h-4 cursor-pointer">
                                  <img
                                    onClick={() => setIsRemoveClass(true)}
                                    className={`${removeClass ? "hidden" : ""}`}
                                    src={threeDots}
                                    alt=""
                                  />
                                  <img
                                    onClick={() => {
                                      setIsRemoveClass(false);
                                      setIsAddTag(false);
                                    }}
                                    className={`${removeClass ? "" : "hidden"}`}
                                    src={addTagClose}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="flex flex-wrap gap-2">
                                {selected?.category?.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`flex uppercase text-xs px-2 py-1 relative cursor-pointer rounded-[6px] hover:text-Neutral800 duration-300 border border-Neutral300 ${
                                        tempType?.category?.includes(item)
                                          ? "text-Neutral900"
                                          : "text-Neutral700"
                                      }`}
                                    >
                                      <span
                                        onClick={(e) => {
                                          const tempCheck =
                                            tempType?.category?.includes(item);
                                          if (!tempCheck) {
                                            setTempType({
                                              ...values,
                                              category: [
                                                ...tempType?.category,
                                                item,
                                              ],
                                            });
                                          } else {
                                            e.stopPropagation();
                                            const removedCategories =
                                              tempType?.category?.filter(
                                                (element) => element !== item
                                              );
                                            setTempType({
                                              ...values,
                                              category: [...removedCategories],
                                            });
                                          }
                                        }}
                                      >
                                        {item}
                                      </span>
                                      <img
                                        className={`absolute -top-2 -right-2 ${
                                          removeClass && item !== "NONE"
                                            ? ""
                                            : "hidden"
                                        }`}
                                        src={addTagMinus}
                                        alt=""
                                        onClick={() => {
                                          setSelected({
                                            ...selected,
                                            category: selected?.category.filter(
                                              (e) => e !== item
                                            ),
                                          });
                                          const clientData =
                                            allClients?.data?.find(
                                              (e) => e?._id === values?.client
                                            );
                                          const logInUser = loggedInUser?.owner
                                            ? loggedInUser?.owner
                                            : loggedInUser?._id;
                                          if (clientData?.user === logInUser) {
                                            handleRemove(item, values?.client);
                                          }
                                        }}
                                      />
                                    </div>
                                  );
                                })}
                                {removeClass ? (
                                  <span className="cursor-pointer border border-dashed border-Neutral600 h-[25px] rounded-[6px] box-border">
                                    <p
                                      onClick={() => {
                                        addNewTagInput?.current?.focus();
                                        setIsAddTag(true);
                                      }}
                                      className={`py-1 px-2 flex text-AccentRegular font-medium text-xs ${
                                        addTag ? "hidden" : ""
                                      }`}
                                    >
                                      <img
                                        src={pluseIcon}
                                        className="mr-1"
                                        alt=""
                                      />{" "}
                                      ADD TAG{" "}
                                    </p>
                                    <input
                                      ref={addNewTagInput}
                                      type="text"
                                      className={`focus:outline-none px-1 h-[20px] uppercase ${
                                        addTag ? "" : "hidden"
                                      }`}
                                      size="5"
                                      autoFocus
                                      onChange={(e) =>
                                        setNewTag(e.target.value.toUpperCase())
                                      }
                                      onBlur={async () => {
                                        if (!newTag || newTag === "") {
                                          await setNewTag(null);
                                          setIsAddTag(false);
                                        } else {
                                          setSelected({
                                            ...selected,
                                            category: [
                                              ...selected?.category,
                                              newTag,
                                            ],
                                          });
                                          setIsAddTag(false);
                                          await setNewTag(null);
                                          if (
                                            allClients?.data?.find(
                                              (e) => e?._id === values?.client
                                            )?.user === loggedInUser?._id
                                          ) {
                                            await dispatch(
                                              AddUserCategoryAction(newTag)
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </Menu.Items>
                        </Portal>
                      </Menu>

                      <div className="space-y-1">
                        <label className="text-xs font-bold text-Neutral900">
                          Duration
                        </label>

                        <div className="flex add-event-time-date">
                          <div
                            className="min-w-fit max-w-fit text-Neutral800 font-medium placeholder:text-Neutral500 font-feature-settings flex items-center justify-center h-[48px] placeholder:text-sm text-sm leading-5 focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular time-inputs"
                            tabIndex="1"
                          >
                            <div className="flex">
                              <input
                                type="number"
                                className="number-input outline-none w-[22px] ml-[17px] text-Neutral800 placeholder:font-medium placeholder:text-sm 5xl:placeholder:text-lg"
                                min="0"
                                max="24"
                                maxLength="2"
                                name="hours"
                                value={values.hours}
                                onChange={(e) => {
                                  if (Number(e.target.value) > 24) {
                                    setFieldValue("hours", 23);
                                  } else {
                                    setFieldValue(
                                      "hours",
                                      ("0" + e.target.value).slice(-2)
                                    );
                                  }
                                }}
                                placeholder="00"
                              />
                              :
                              <input
                                type="number"
                                className="number-input outline-none w-[22px] mr-[17px] ml-[5px] text-Neutral800 placeholder:font-medium placeholder:text-sm 5xl:placeholder:text-lg"
                                min="0"
                                max="59"
                                placeholder="00"
                                maxLength="2"
                                value={values.minutes}
                                name="minutes"
                                onChange={(e) => {
                                  if (Number(e.target.value) > 59) {
                                    setFieldValue("minutes", 59);
                                  } else {
                                    setFieldValue(
                                      "minutes",
                                      ("0" + e.target.value).slice(-2)
                                    );
                                  }
                                }}
                              />
                            </div>
                            {errors?.hours && touched?.hours && (
                              <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors?.hours}
                              </p>
                            )}
                            <br />
                            {errors?.minutes && touched?.minutes && (
                              <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                {errors?.minutes}
                              </p>
                            )}
                          </div>
                          <div className="ml-[8px] w-full z-[10] custom-date-picker">
                            <DatePicker
                              selected={new Date(values?.date)}
                              customInput={
                                <DateInput
                                  value={new Date(values?.date).toISOString()}
                                />
                              }
                              onChange={(date) => {
                                handleChange({
                                  type: "date",
                                  target: {
                                    name: "date",
                                    value: date,
                                  },
                                });
                              }}
                            />
                            {errors?.date && touched?.date && (
                              <p className="ml-[30px] text-xs text-Red400 font-medium focus-visible:outline-none">
                                {errors?.date}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        className="relative block w-full py-3 overflow-hidden text-sm font-bold text-center transition duration-300 ease-in-out rounded btn button-hover form-primary-btn text-Neutral100 bg-AccentRegular drop-shadow-Texts focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight"
                      >
                        {isSubmitting ? (
                          <span className="relative z-10 drop-shadow-Texts">
                            <svg
                              className="w-5 h-5 m-auto text-white animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </span>
                        ) : (
                          <span className="relative z-10">Save</span>
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div
            className="absolute right-0 -top-[45px] z-30 cursor-pointer"
            onClick={() => setIsOpen(false)}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TrackingAddTimeEntryModal;
