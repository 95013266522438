import clx from "classnames";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import LightningIcon from "../../../Icons/LightningIcon";
import SectionTitle from "../../../common/typography/SectionTitle";
import prepareTasksAndTimeCounter from "../../../../utils/prepareTasksAndTimeCounter";

const colors = [
  "Neutral700",
  "Blue400",
  "Green400",
  "Yellow600",
  "Orange400",
  "Pink400",
];

function TasksManagement({
  project,
  setIsOpen = () => {},
  className = "",
  setFrontfilter,
  setCheckedTask,
}) {
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const navigate = useNavigate();

  return (
    <div
      className={clx(
        "flex flex-col p-[28px] pt-[23px] rounded-xl bg-Neutral000 border-[0.5px] border-Neutral200 shadow-[0_4px_15px_rgba(0,0,0,0.08)] w-full xl:max-w-[460px]",
        className
      )}
    >
      <div className="flex items-center justify-between w-full mb-5">
        <SectionTitle
          text="Tasks"
          className="flex justify-center items-center mr-[6px]"
        />
        <LightningIcon width={24} height={24} />
      </div>
      <div className="flex items-stretch justify-between pb-1 mb-6 overflow-x-auto xl:justify-start md:overflow-x-hidden scrollbar-h-4">
        <div
          className="min-w-[96px] flex flex-col items-center justify-between rounded border-[0.5px] border-Neutral400 pb-2 px-[14px] pt-[18px] mr-2 cursor-pointer"
          onClick={() => {
            setIsOpen(true);
            setFrontfilter([]);
            setCheckedTask(0);
          }}
        >
          <LightningIcon width={24} height={24} filled className="mb-[10px]" />
          <span className="leading-[100%] tracking-[-0.04em] text-Neutral900 text-5xl font-normal text-center mb-[22px]">
            {prepareTasksAndTimeCounter(project?.total_tasks ?? 0)}
          </span>
          <span className="leading-[14px] tracking-[0.02em] text-[10px] font-semibold uppercase text-Neutral800">
            Total tasks
          </span>
        </div>
        {/* tasks group */}
        <div className="min-w-[284px] flex flex-wrap justify-items-stretch gap-1">
          {/* {rest} */}
          {project?.total_tasks_by_column &&
            Object.keys(project?.total_tasks_by_column)?.map(
              (key, idx, arr) => {
                const numberValue = prepareTasksAndTimeCounter(
                  project?.total_tasks_by_column?.[key]
                );
                if (arr?.length < 4) {
                  return (
                    <div
                      key={key ?? idx}
                      className="flex flex-col items-center justify-between rounded border border-Neutral400 pb-2 px-[14px] pt-[26px] min-w-[92px] cursor-pointer"
                      onClick={() => {
                        setIsOpen(true);
                        setFrontfilter(key);
                        setCheckedTask("");
                      }}
                    >
                      <span
                        className={`block w-[8px] h-[8px] rounded-[50%] bg-${colors[idx]}`}
                      ></span>
                      <span
                        className={`leading-[100%] tracking-[-0.04em] text-${colors[idx]} text-[48px] font-light mb-[6px]`}
                      >
                        {numberValue}
                      </span>
                      <span className="leading-[14px] tracking-[0.02em] text-[10px] font-medium uppercase text-Neutral700 opacity-90">
                        {key}
                      </span>
                    </div>
                  );
                }

                if (arr?.length === 4) {
                  return (
                    <div
                      key={key ?? idx}
                      className="flex flex-col items-start justify-between rounded border-[0.5px] border-Neutral400 min-w-[140px] p-3 cursor-pointer"
                      onClick={() => {
                        setIsOpen(true);
                        setFrontfilter(key);
                        setCheckedTask("");
                      }}
                    >
                      <span
                        className={`leading-[100%] tracking-[-0.04em] text-${colors[idx]} text-[24px] font-extrabold mb-[6px]`}
                      >
                        {numberValue}
                      </span>
                      <span className="leading-[14px] tracking-[0.02em] text-[10px] font-medium uppercase text-Neutral700 opacity-90">
                        {key}
                      </span>
                    </div>
                  );
                }

                return (
                  <div
                    key={key ?? idx}
                    className={`flex flex-col items-start justify-between rounded border-[0.5px] w-[91.2px] border-Neutral400 p-3 justify-self-stretch cursor-pointer ${
                      arr?.length === 6 ? "" : "grow"
                    }`}
                    onClick={() => {
                      setIsOpen(true);
                      setFrontfilter(key);
                      setCheckedTask("");
                    }}
                  >
                    <span
                      className={`leading-[100%] tracking-[-0.04em] text-${colors[idx]} text-[24px] font-extrabold mb-[6px]`}
                    >
                      {numberValue}
                    </span>
                    <span className="leading-[14px] tracking-[0.02em] text-[10px] font-medium uppercase text-Neutral700 opacity-90">
                      {key}
                    </span>
                  </div>
                );
              }
            )}
        </div>
      </div>
      {/* controls */}
      <div className="flex justify-stretch items-stretch gap-[10px]">
        <button
          className="w-full rounded flex justify-center items-center tracking-[0.04em] text-xs text-Neutral000 bg-AccentRegular uppercase font-bold hover:bg-AccentMediumDark"
          onClick={() => {
            navigate(
              `/admin/clients/manage/${clientDetail?.data?._id}/project/${project?._id}?isNew=true`
            );
          }}
        >
          Add Task
        </button>
        <button
          className="w-full h-[48px] rounded flex justify-center items-center leading-[110%] tracking-[0.04em] text-xs text-AccentRegular bg-Neutral200 uppercase font-bold transition duration-300 hover:bg-AccentMediumLight"
          onClick={() => {
            setIsOpen(true);
            setFrontfilter([]);
            setCheckedTask(0);
          }}
        >
          View All
        </button>
      </div>
    </div>
  );
}

export default TasksManagement;
