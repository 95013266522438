import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useField,
  useFormikContext,
} from "formik";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { RemoverUserCategoryAction } from "../../../store/actions/authAction";
import { initInvoice } from "../../../store/actions/invoicesAction";
import {
  CreateTemplateActions,
  updateTemplateActions,
} from "../../../store/actions/templateAction";
import { getTagColor } from "../../../utils/getTagColor";
import CubeIcon from "../../Dashboard-v2/icons/CubeIcon";
import { Select } from "../../Inputs/CreatableSelect";
import { RecurringIcon, StandardIcon } from "./NewInvoiceModal";

export const MODAL_COLORS = {
  Questionnaire: "Blue",
  Proposal: "Green",
  Contract: "Red",
  Invoice: "Pink",
  Project: "Orange",
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
  },
};

function NewTemplateModal({
  isOpen,
  setIsOpen,
  type,
  editData,
  isCreateInvoice = false,
  clientId,
}) {
  let NewTemplateSchema = Yup.object().shape({
    name: Yup.string().required("Template Name Required"),
    category_tags: Yup.array(),
  });

  if (type === "Invoice") {
    NewTemplateSchema = NewTemplateSchema.shape({
      billingType: Yup.string().required("Billing Type Required"),
      interval: Yup.string()
        .when("billingType", {
          is: (val) => val === "recurring",
          then: Yup.string().required("Interval Required"),
        })
        .nullable(),
      repeats: Yup.number()
        .when("billingType", {
          is: (val) => val === "recurring",
          then: Yup.number().required("Repeats Required"),
        })
        .nullable(),
      startDate: Yup.date()
        .when("billingType", {
          is: (val) => val === "recurring",
          then: Yup.date().required("Start Date Required"),
        })
        .nullable(),
      endDate: Yup.date()
        .when(["billingType", "interval"], {
          is: (billingType, interval) =>
            billingType === "recurring" && interval === "indefinitely",
          then: Yup.date().required("End Date Required"),
        })
        .nullable(),
    });
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const modalColor = MODAL_COLORS[type];

  const [categoryTags, setCategoryTags] = useState([]);

  const userDateFormat = user?.date_format;

  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="relative">
      <input
        type="text"
        className="w-full px-4 py-2 text-sm border rounded-lg border-Neutral300 text-Neutral900"
        value={!value ? "Select date" : moment(value).format(userDateFormat)}
        onClick={onClick}
        readOnly
        ref={ref}
      />
    </div>
  ));

  const FormikDatePicker = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
      <DatePicker
        {...field}
        {...props}
        customInput={
          <DateInput
            value={(field.value && new Date(field.value).toISOString()) || null}
          />
        }
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
    );
  };

  useEffect(() => {
    let tags = [];

    if (user?.category?.length) {
      const category = user?.category?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });

      tags = [...tags, ...category];
    }

    tags = tags?.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.value?.toLowerCase() === current?.value?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = tags?.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );

      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });

    setCategoryTags(optionsWithColors);
  }, [modalColor, user]);

  const handleRemove = (data) => {
    const removedCategory = dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="New Template Modal"
      >
        <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-16 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp max-w-lg">
          <div className="bg-white px-6 xs:px-12 pt-8 pb-10 rounded-[8px]">
            <div>
              <div className="text-left">
                <h3
                  className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                  id="modal-title"
                >
                  {type === "Invoice"
                    ? "Select invoice type"
                    : `New ${type} Template`}
                </h3>
                <Formik
                  initialValues={{
                    name: editData?.title
                      ? editData?.title
                      : type === "Invoice"
                      ? isCreateInvoice
                        ? `Invoice ${moment().format("MMMM")}-${moment().format(
                            "YYYY"
                          )}`
                        : `${type} Template`
                      : "",
                    category_tags: editData?.category
                      ? editData?.category?.map((option, i) => {
                          const { bgColor: optionColor, text: optionText } =
                            getTagColor(modalColor, i, true);

                          const { bgColor: labelColor, text: labelText } =
                            getTagColor(modalColor, i, true);
                          return {
                            value: option?.name,
                            label: option?.name,
                            color: optionColor,
                            text: optionText,
                            labelColor,
                            labelText,
                          };
                        })
                      : [],
                    billingType: "",
                    interval: "weekly",
                    repeats: 1,
                    startDate: "",
                    endDate: "",
                    templateType: "build",
                  }}
                  enableReinitialize
                  validationSchema={NewTemplateSchema}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    const data = {
                      type: type,
                      title: values.name,
                      category: values?.category_tags?.map((item) => {
                        return {
                          name: item.value,
                          color: {
                            color: item.color,
                            text: item.text,
                            labelColor: item.labelColor,
                            labelText: item.labelText,
                          },
                        };
                      }),
                    };
                    if (type === "Invoice") {
                      data.billingType = values.billingType;

                      if (values.billingType === "recurring") {
                        data.interval = values.interval;
                        data.repeats = values.repeats;
                        data.startDate = values.startDate;
                      }
                      if (
                        values.billingType === "recurring" &&
                        values.interval === "indefinitely"
                      ) {
                        data.endDate = values.endDate;
                      }
                      data.category = [
                        {
                          name: "None",
                          color: {
                            color: "Pink100",
                            text: "Neutral000",
                            labelColor: "Pink800",
                            labelText: "Neutral000",
                          },
                        },
                      ];
                    }
                    if (isCreateInvoice) {
                      dispatch(
                        initInvoice(
                          {
                            client_id: clientId,
                            type: "build",
                            template: data,
                          },
                          setIsOpen,
                          navigate
                        )
                      );
                    } else {
                      if (editData && editData._id) {
                        dispatch(
                          updateTemplateActions(
                            { ...data, id: editData._id },
                            setSubmitting,
                            setErrors,
                            setIsOpen
                          )
                        );
                      } else {
                        data.templateType = values.templateType;
                        dispatch(
                          CreateTemplateActions(
                            type,
                            data,
                            setSubmitting,
                            setErrors,
                            navigate,
                            setIsOpen
                          )
                        );
                      }
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    setFieldValue,
                  }) => {
                    return (
                      <>
                        {type === "Invoice" &&
                          values?.billingType === "recurring" && (
                            <span
                              className="absolute top-0 flex items-center gap-2 mt-4 ml-4 text-xs font-semibold cursor-pointer right-12 text-Neutral900"
                              onClick={() => setFieldValue("billingType", "")}
                            >
                              <br />
                              Back
                            </span>
                          )}

                        <Form onSubmit={handleSubmit}>
                          {type === "Invoice" && (
                            <div className="flex flex-col gap-4">
                              {(!values?.billingType ||
                                values?.billingType !== "recurring") && (
                                <>
                                  <button
                                    type="submit"
                                    className="flex items-center gap-4 p-4 pb-4 text-left border rounded-lg cursor-pointer hover:bg-Neutral200"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setFieldValue("billingType", "standard");
                                    }}
                                  >
                                    <div className="flex flex-col gap-1">
                                      <StandardIcon />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                      <div className="text-sm font-semibold uppercase text-Neutral900">
                                        Standard
                                      </div>
                                      <div className="text-xs font-normal text-Neutral800">
                                        Send a once-off invoice.
                                      </div>
                                    </div>
                                  </button>
                                  <button
                                    className="flex items-center justify-between gap-4 p-4 pb-4 text-left border rounded-lg cursor-pointer hover:bg-Neutral200"
                                    type="button"
                                    onClick={() =>
                                      setFieldValue("billingType", "recurring")
                                    }
                                  >
                                    <div className="flex flex-col gap-1">
                                      <RecurringIcon />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                      <div className="text-sm font-semibold uppercase text-Neutral900">
                                        Recurring
                                      </div>
                                      <div className="text-xs font-normal text-Neutral800">
                                        Create a recurring invoice (monthly /
                                        bi-monthly / annual).
                                      </div>
                                    </div>
                                  </button>
                                </>
                              )}
                              {values?.billingType === "recurring" && (
                                <div className="flex flex-wrap gap-4">
                                  <div className="flex justify-between w-full gap-4">
                                    <div className="flex flex-col w-1/2 gap-1">
                                      <label
                                        htmlFor="interval"
                                        className="text-sm font-semibold text-Neutral900"
                                      >
                                        Billing Interval
                                      </label>
                                      <Field
                                        as="select"
                                        name="interval"
                                        className="w-full px-4 py-2 text-sm border rounded-lg border-Neutral300 text-Neutral900"
                                      >
                                        <option value="weekly">Weekly</option>
                                        <option value="bimonthly">
                                          Bi-Monthly
                                        </option>
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                        <option value="indefinitely">
                                          Indefinitely
                                        </option>
                                      </Field>
                                      <ErrorMessage name="interval">
                                        {(msg) => (
                                          <div className="text-xs text-red-500">
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    <div className="flex flex-col w-1/2 gap-1">
                                      <label
                                        htmlFor="repeats"
                                        className="text-sm font-semibold text-Neutral900"
                                      >
                                        Repetitions
                                      </label>
                                      <Field
                                        type="number"
                                        name="repeats"
                                        className="w-full px-4 py-2 text-sm border rounded-lg border-Neutral300 text-Neutral900"
                                        min={1}
                                        max={
                                          values?.interval !== "indefinitely" &&
                                          (values?.interval === "weekly"
                                            ? 52
                                            : values?.interval === "bimonthly"
                                            ? 6
                                            : values?.interval === "monthly"
                                            ? 12
                                            : 1)
                                        }
                                      />
                                      <ErrorMessage name="repeats">
                                        {(msg) => (
                                          <div className="text-xs text-red-500">
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                  </div>
                                  <div className="flex justify-between w-full gap-4">
                                    <div className="flex flex-col w-1/2 gap-1">
                                      <label
                                        className="text-sm font-semibold text-Neutral900"
                                        htmlFor="startDate"
                                      >
                                        Start Date
                                      </label>
                                      <FormikDatePicker
                                        name="startDate"
                                        className="px-4 py-2 text-sm border rounded-lg border-Neutral300 text-Neutral900"
                                      />
                                      <ErrorMessage name="startDate">
                                        {(msg) => (
                                          <div className="text-xs text-red-500">
                                            {msg}
                                          </div>
                                        )}
                                      </ErrorMessage>
                                    </div>
                                    {values?.interval === "indefinitely" && (
                                      <div className="flex flex-col w-1/2 gap-1">
                                        <label
                                          className="text-sm font-semibold text-Neutral900"
                                          htmlFor="startDate"
                                        >
                                          End Date
                                        </label>
                                        <FormikDatePicker
                                          name="endDate"
                                          className="px-4 py-2 text-sm border rounded-lg border-Neutral300 text-Neutral900"
                                        />
                                        <ErrorMessage name="endDate">
                                          {(msg) => (
                                            <div className="text-xs text-red-500">
                                              {msg}
                                            </div>
                                          )}
                                        </ErrorMessage>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                          {type !== "Invoice" && (
                            <>
                              <div className="mb-6">
                                <label className="text-xs font-bold text-Neutral900">
                                  Name
                                </label>
                                <div className="mt-2">
                                  <input
                                    className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                                    type="text"
                                    placeholder={`Eg. Web Design ${type}`}
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                  />

                                  {errors.name && touched.name && (
                                    <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                      {errors.name}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="mb-6">
                                <label className="text-xs font-bold text-Neutral900">
                                  Category Tags
                                </label>
                                <div className="mt-2">
                                  <Select
                                    id="category_tags"
                                    name="category_tags"
                                    isMulti
                                    isSearchable
                                    isClearable={false}
                                    options={categoryTags}
                                    handleRemove={handleRemove}
                                    value={values.category_tags}
                                    onBlur={handleBlur}
                                    {...{ setFieldValue, modalColor }}
                                  />

                                  {errors.category_tags &&
                                    touched.category_tags && (
                                      <p className="absolute mt-1 text-xs font-medium text-Red400 focus-visible:outline-none">
                                        {errors.category_tags}
                                      </p>
                                    )}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="mt-8">
                            {(editData && editData._id) ||
                            (type === "Invoice" &&
                              values?.billingType === "recurring") ? (
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`btn button-hover form-primary-btn relative text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out ${
                                  values?.billingType !== "recurring" &&
                                  type === "Invoice" &&
                                  "hidden"
                                }`}
                              >
                                {isSubmitting ? (
                                  <span className="relative">
                                    <svg
                                      className="w-5 h-5 m-auto text-white animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  </span>
                                ) : (
                                  <span className="relative drop-shadow-Texts">
                                    {isCreateInvoice
                                      ? "Save Invoice"
                                      : "Save as Template"}
                                  </span>
                                )}
                              </button>
                            ) : (
                              type !== "Invoice" && (
                                <>
                                  <button
                                    type="submit"
                                    onClick={() => {
                                      setFieldValue("templateType", "default");
                                    }}
                                    className="capitalize bg-AccentRegular text-center py-[18px] w-full h-[48px] rounded font-bold text-Neutral100 text-sm tracking-wider leading-3 focus-visible:outline-none focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out btn button-hover"
                                  >
                                    {isSubmitting &&
                                    values?.templateType === "default" ? (
                                      <span className="relative">
                                        <svg
                                          className="w-5 h-5 m-auto text-white animate-spin"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                          ></circle>
                                          <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          ></path>
                                        </svg>
                                      </span>
                                    ) : (
                                      <span className="relative drop-shadow-Texts">
                                        Use Existing Template
                                      </span>
                                    )}
                                  </button>
                                  <button
                                    type="submit"
                                    className="flex items-center justify-center border border-AccentRegular text-AccentRegular py-[18px] w-full h-[48px] rounded col-span-2 font-bold tracking-wider leading-3 mt-4"
                                    onClick={() => {
                                      setFieldValue("templateType", "build");
                                    }}
                                  >
                                    {isSubmitting &&
                                    values?.templateType === "build" ? (
                                      <span className="relative">
                                        <svg
                                          className="w-5 h-5 m-auto animate-spin text-AccentRegular"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                          ></circle>
                                          <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                          ></path>
                                        </svg>
                                      </span>
                                    ) : (
                                      <>
                                        <CubeIcon className="mr-2" />
                                        <span className="text-sm font-bold capitalize text-AccentRegular">
                                          Build from Scratch
                                        </span>
                                      </>
                                    )}
                                  </button>
                                </>
                              )
                            )}
                          </div>
                          {/*  */}
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div
            className="absolute right-0 top-[-50px] z-30 cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={CloseBtn} alt="" />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NewTemplateModal;
