import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import leadsColors from "../../../assets/json/leads-colors.json";
import CloseBtn from "../../../assets/Images/close-btn.svg";
import { customModalStyles } from "../modalsData";
import { Menu } from "@headlessui/react";
import LoadingIcon from "../../Icons/LoadingIcon";

function EditColumnColorsModal({
  isOpen = false,
  setIsOpen = () => {},
  editColumn = null,
  onApplyColor = () => {},
  isLoaded = false,
}) {
  const [selectedColor, setSelectedColor] = useState(editColumn?.color ?? null);
  const [columnName, setColumnName] = useState(
    editColumn?.name ?? "Column Name"
  );

  const [color, setColor] = useState(editColumn?.color);
  useEffect(() => {
    setSelectedColor(editColumn?.color);
    setColor(
      leadsColors[editColumn?.color]
        ? (editColumn?.color === "Orange" && "#F5933D") ||
            (editColumn?.color === "Green" && "#34B288") ||
            (editColumn?.color === "Blue" && "#5281E0") ||
            (editColumn?.color === "Neutral" && "#5A5A66") ||
            (editColumn?.color === "Pink" && "#D65CD2") ||
            (editColumn?.color === "Yellow" && "#E6E51A")
        : editColumn?.color
    );
    setColumnName(editColumn?.name);
  }, [editColumn]);

  const onApplyBtnClick = () => {
    if (
      color !==
      ((editColumn?.color === "Orange" && "#F5933D") ||
        (editColumn?.color === "Green" && "#34B288") ||
        (editColumn?.color === "Blue" && "#5281E0") ||
        (editColumn?.color === "Neutral" && "#5A5A66") ||
        (editColumn?.color === "Pink" && "#D65CD2") ||
        (editColumn?.color === "Yellow" && "#E6E51A") ||
        editColumn?.color)
    ) {
      onApplyColor(editColumn, "Custom", color, columnName, setIsOpen);
    } else {
      onApplyColor(editColumn, "Default", selectedColor, columnName, setIsOpen);
    }
  };

  const colorsItems = Object.keys(leadsColors).map((color) => {
    return (
      <label
        className={`w-[178px] h-[48px] flex items-center justify-center p-3 rounded border-[0.5px] hover:border-Neutral400 duration-250 transition-all cursor-pointer ${
          selectedColor === color ? "border-Neutral500" : "border-Neutral200"
        }`}
        key={color}
        style={{
          backgroundColor: leadsColors[color]?.backgroundOpacity,
        }}
      >
        <input
          id="color"
          name="color"
          type="radio"
          value={color}
          className="hidden"
          onChange={(e) => setSelectedColor(e.target.value)}
          checked={selectedColor === color}
        />
        <span
          className={`mr-2 w-[8px] h-[8px] rounded-[50%] inline-block bg-${leadsColors[color]?.text}`}
          aria-label="Column colored marker"
        ></span>
        <h3
          className={`mr-2 lex justify-center items-center text-${leadsColors[color]?.text} text-xs font-bold uppercase align-center`}
        >
          Column Name
        </h3>
        <span
          className={`flex items-center justify-center w-[18px] h-[18px] rounded-[50%] text-${leadsColors[color]?.text} bg-${leadsColors[color]?.badge} font-bold text-[10px] tracking-[0.08em]`}
        >
          8
        </span>
      </label>
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-12 py-8 rounded-[8px]">
          <div>
            <div className="text-left">
              <h3
                className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase"
                id="modal-title"
              >
                Edit Column Colours
              </h3>
              <div className="mb-3 p-5 border-[1px] border-Neutral200 rounded mb-[22px]">
                <div className="mb-3">
                  <h2 className="text-sm text-Neutral900 mb-2">
                    Name of the column:{" "}
                  </h2>
                  <input
                    type="text"
                    className="w-[100%] border-[1.5px] border-Neutral500 rounded p-2 text-sm text-Neutral900 focus:outline-none focus:ring-0"
                    placeholder="Column Name"
                    value={columnName}
                    name="columnName"
                    onChange={(e) => setColumnName(e.target.value)}
                  />
                </div>
                <h2 className="text-Neutral700 font-semibold text-xs mb-4 leading-[18px]">
                  Choose from default options:{" "}
                </h2>
                <Menu
                  as="div"
                  className="relative flex items-center justify-center w-[100%]"
                >
                  <div className="flex flex-wrap items-center justify-center gap-x-2 gap-y-3 w-[100%] max-w-[364px]">
                    {colorsItems}
                  </div>
                </Menu>
              </div>
              {/* <span className="w-[100%] inline-block text-center text-Neutral600 uppercase mb-3">
                or
              </span> */}
              <div className="p-5 mb-[22px]">
                <p className="text-Neutral700 w-[100%] mb-3 font-normal text-xs leading-[18px]">
                  Or pick colour manually:
                </p>
                <div className="flex items-center w-[100%] justify-center gap-2">
                  <div className="overflow-hidden rounded w-[178px] h-[40px] flex">
                    <input
                      type="color"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                      className="cursor-pointer w-[100%] h-[100%] mr-0  scale-[1.5]"
                    />
                    {/* <span className="absolute top-0 left-0 w-[100%] h-[100%] block z-20 border-[4px] rounded-[10px] border-Neutral000 pointer-events-none"></span> */}
                  </div>

                  <div
                    className="w-[178px] h-[48px] flex items-center justify-center p-3 rounded border-[0.5px] border-Neutral200"
                    style={{ background: color + "08" }}
                  >
                    <span
                      className={`mr-2 w-[8px] h-[8px] rounded-[50%] inline-block`}
                      aria-label="Column colored marker"
                      style={{ backgroundColor: color }}
                    />
                    <h3
                      className={`mr-2 lex justify-center items-center text-xs font-bold uppercase align-center`}
                      style={{ color: color }}
                    >
                      Column Name
                    </h3>
                    <span
                      className={`flex items-center justify-center w-[18px] h-[18px] rounded-[50%] font-bold text-[10px] tracking-[0.08em]`}
                      style={{ color: color, backgroundColor: color + "0D" }}
                    >
                      8
                    </span>
                  </div>
                </div>
              </div>
              <button
                className={`button-hover text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full min-w-[148px]  5xl:max-w-[172px] py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out`}
                onClick={onApplyBtnClick}
                disabled={isLoaded}
              >
                {isLoaded ? (
                  <LoadingIcon className="absolute left-3 top-1/2 transform -translate-y-1/2" />
                ) : (
                  <span className="relative drop-shadow-Texts z-10 uppercase">
                    Save Changes
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => setIsOpen(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default EditColumnColorsModal;
