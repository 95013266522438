function DownloadIcon({ size = 14, ...props }) {
  return (
    <svg
      width={size}
      height={size - 1}
      viewBox="0 0 13 12"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 7.33423V9.11201C10.5 9.34775 10.4063 9.57385 10.2397 9.74055C10.073 9.90724 9.84686 10.0009 9.61111 10.0009H3.38889C3.15314 10.0009 2.92705 9.90724 2.76035 9.74055C2.59365 9.57385 2.5 9.34775 2.5 9.11201V7.33423"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.27759 5.11108L6.49981 7.33331L8.72203 5.11108"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 7.33333V2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DownloadIcon;
