import axios from "axios";
import { toast } from "react-toastify";

export const CreateSubDoamainActions = (value, setErrors, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const data = await axios.post("api/v1/sub-domain/create-sub-domain", {
        subdomain: value,
      });

      setErrors({});
      setLoading(false);
      return dispatch({
        type: "CREATE_SUBDOMAIN_SUCCESS",
        payload: data.data,
      });
    } catch (error) {
      setLoading(false);
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);

        return dispatch({
          type: "CREATE_SUBDOMAIN_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CREATE_SUBDOMAIN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const getSubDomain = () => {
  return async (dispatch) => {
    try {
      const data = await axios.get(`api/v1/sub-domain/get-sub-domain-by-user`);
      return dispatch({
        type: "SET_SUBDOMAIN",
        payload: data.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
};

// export const updateSubDoamainActions = (value, setErrors, setIsChange) => {
//   return async (dispatch) => {
//     try {
//       const data = await axios.put("api/v1/sub-domain/update-sub-domain", {
//         subdomain: value,
//       });
//       setIsChange(false);
//       setErrors({});
//       return dispatch({
//         type: "UPDATE_SUBDOMAIN_SUCCESS",
//         payload: data.data,
//       });
//     } catch (error) {
//       if (error.response.status === 422) {
//         let err = {};
//         error.response.data.errors.forEach((element) => {
//           err[element.param] = element.msg;
//         });
//         setErrors(err);
//         return dispatch({
//           type: "UPDATE_SUBDOMAIN_ERROR",
//           payload: error,
//         });
//       } else {
//         return dispatch({
//           type: "UPDATE_SUBDOMAIN_ERROR",
//           payload: error,
//         });
//       }
//     }
//   };
// };

export const updateSubDoamainActions = (
  value,
  setErrors,
  setLoading
) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      const data = await axios.put(
        "api/v1/sub-domain/update-sub-domain",
        {
          subdomain: value,
        },
      );

      setErrors({});

      dispatch({
        type: "UPDATE_SUBDOMAIN_SUCCESS_BY_USER",
        payload: data.data,
      });
      setLoading(false);
      return dispatch({
        type: "UPDATE_SUBDOMAIN_SUCCESS",
        payload: data.data,
      });
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
      } else if (error.response && error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        return dispatch({
          type: "UPDATE_SUBDOMAIN_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "UPDATE_SUBDOMAIN_ERROR",
          payload: error,
        });
      }
    }
  };
};
