import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "swiper/css";

import DangerZone from "../../components/DangerZone";
import CalendarIcon from "../../components/Icons/CalendarIcon";
import ContractIcon from "../../components/Icons/ContractIcon";
import CreateClientModal from "../../components/Modals/CreateClientModal";
import CreateMemberModal from "../../components/Modals/CreateMemberModal";
import ExpandTeamMemberModal from "../../components/Modals/ExpandTeamMember";
import ExpandTeamsModal from "../../components/Modals/ExpandTeamMembers";
import ProjectDescriptionModal from "../../components/Modals/ProjectDescriptionModal";
import NewProjectModal from "../../components/Modals/ProjectModal/NewProjectModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import AllContributorsSection from "../../components/sections/AllContributorsSection/AllContributorsSection";
import ProjectManagementSection from "../../components/sections/ProjectManagementSection/ProjectManagementSection";
import SaveAsTemplateSection from "../../components/sections/SaveAsTemplateSection/SaveAsTemplateSection";
import useIsAdmin from "../../hooks/useIsAdmin";
import { GetClientDetailAction } from "../../store/actions/clientAction";
import { getMemberList } from "../../store/actions/memberAction";
import {
  ArchiveProjectAction,
  DeleteProjectAction,
  GetProjectDetailAction,
  RestoreArchivedProjectAction,
} from "../../store/actions/projectAction";
import {
  SaveAsTemplateActions,
  saveUpdateTemplateActions,
} from "../../store/actions/templateAction";
import { getTagColor } from "../../utils/getTagColor";

function Project() {
  const { projectId = null, clientId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin(["project_manager"]);

  const member = useSelector((state) => state.member.members);
  const projectDetail = useSelector((state) => state.project.projectDetail);
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const loggedInUser = useSelector((state) => state.auth.user);

  const [isDescModalOpen, setIsDescModalOpen] = useState(false);
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);
  const [createClientModal, setCreateClientModal] = useState(false);
  const [createMemberModal, setCreateMemberModal] = useState(false);
  const [editTeamMembersModal, setEditTeamMembersModal] = useState(false);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [teamContributors, setTeamContributors] = useState([]);
  const [archiving, setArchiving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [focusField, setFocusField] = useState(null);

  const modalColor = "Orange";

  const handleOpenField = (field) => {
    setFocusField(field);
    setIsProjectModalOpen(true);
  };

  const handleArchiving = (value) => {
    if (!value) {
      setArchiving(true);
      dispatch(ArchiveProjectAction(projectId, navigate));
      setArchiving(false);
    } else {
      setArchiving(true);
      dispatch(RestoreArchivedProjectAction(projectId, navigate));
      setArchiving(false);
    }
  };

  const handleDeleting = () => {
    setDeleting(true);
    dispatch(DeleteProjectAction(projectId, null, navigate));
    setDeleting(false);
  };

  const reloadProject = () => {
    setIsReload((prev) => !prev);
  };

  const fetchCategoryWithColor = () => {
    const categoryColor = projectDetail?.data?.category?.map((ele, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );
      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );
      return {
        name: ele,
        color: {
          color: optionColor,
          text: optionText,
          labelColor,
          labelText,
        },
      };
    });
    return categoryColor;
  };

  const handleSavingTemplate = (templateName) => {
    setIsLoading(true);
    dispatch(
      SaveAsTemplateActions(
        {
          type: "Project",
          title: templateName,
          projectId,
          category: fetchCategoryWithColor(),
        },
        setIsLoading,
        reloadProject
      )
    );
  };

  const handleUpdatingTemplate = (templateName, templateProjectId) => {
    setIsLoading(true);
    dispatch(
      saveUpdateTemplateActions(
        {
          type: "Project",
          title: templateName,
          projectId,
          templateProjectId,
          category: fetchCategoryWithColor(),
        },
        setIsLoading,
        reloadProject
      )
    );
  };

  useEffect(() => {
    dispatch(GetProjectDetailAction(projectId));
  }, [dispatch, isReload, projectId]);

  useEffect(() => {
    if (state?.project) {
      dispatch({
        type: "SET_PROJECT_DETAIL",
        payload: {
          isLoading: false,
          data: state?.project,
        },
      });
    } else {
      if (projectId !== projectDetail.data._id) {
        dispatch(GetProjectDetailAction(projectId));
      }
    }
    if (state?.client) {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: {
          isLoading: false,
          isUpdating: false,
          data: state?.client,
        },
      });
    } else {
      if (clientId !== clientDetail.data._id) {
        dispatch(GetClientDetailAction(clientId));
      }
    }
  }, [
    projectId,
    clientId,
    dispatch,
    state?.project,
    state?.client,
    projectDetail.data._id,
    clientDetail.data._id,
  ]);

  useEffect(() => {
    if (!member?.data?.length) {
      dispatch(getMemberList());
    }
  }, [dispatch, member?.data?.length]);

  const handleFocusMemberView = (teamMemberId = null) => {
    if (teamMemberId) {
      setEditTeamMembersModal(false);
      setSelectedTeamMemberId(teamMemberId);
    }
  };

  const onlyTimeTrackingMembers = useCallback(async () => {
    const mergedContributorsAndremovedDuplicates = await [
      ...(projectDetail?.data?.time_contributors || []),
      ...(projectDetail?.data?.task_contributors || []),
    ].reduce((acc, current) => {
      const x = acc.find((item) => item._id === current._id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const tempTeam = await clientDetail?.data?.members?.filter((member) => {
      return mergedContributorsAndremovedDuplicates?.some((item) => {
        return item._id === member?.user?._id;
      });
    });
    setTeamContributors(tempTeam);
  }, [
    clientDetail?.data?.members,
    projectDetail?.data?.task_contributors,
    projectDetail?.data?.time_contributors,
  ]);

  useEffect(() => {
    onlyTimeTrackingMembers();
  }, [projectDetail, clientDetail, onlyTimeTrackingMembers]);

  const getDueDateText = (dueDate) => {
    if (!dueDate) return "Set Due date";

    const today = moment().startOf("day");
    const due = moment(dueDate).startOf("day");
    const daysDiff = due.diff(today, "days");

    if (due.isBefore(today, "day")) {
      return `Due: ${moment(dueDate).format("DD.MM.YYYY")} (Overdue)`;
    } else if (daysDiff === 0) {
      return `Due: ${moment(dueDate).format("DD.MM.YYYY")} (Today)`;
    } else {
      return `Due: ${moment(dueDate).format("DD.MM.YYYY")} (${daysDiff} ${
        daysDiff === 1 ? "Day" : "Days"
      })`;
    }
  };

  return (
    <div>
      <AdminNavbar
        title={projectDetail?.data?.title}
        subtitle="Manage tasks, tracked time, and other related info"
        link={`/admin/clients/manage/${clientId}`}
        backLink={`Back to ${
          clientDetail?.data?.business_name || clientDetail?.data?.contact_name
        }'s Dashboard`}
      />

      <div className="flex px-6 border-b border-gray-100 gap-7 md:px-12">
        <button
          className="flex items-center gap-1 py-2 text-xs font-semibold text-indigo-500"
          onClick={() => setIsDescModalOpen(true)}
        >
          <ContractIcon className="w-4 h-4 text-AccentRegular" />
          <span className="text-xs font-semibold text-AccentRegular">
            Description
          </span>
        </button>
      </div>

      <div className="flex gap-5 px-6 mt-5 md:px-12">
        {isAdmin && (
          <>
            <button
              data-for="budget-Tooltip"
              data-tip="Only visible to Admin & PM roles"
              className="flex items-center gap-2 px-3 py-2 text-xs font-semibold border rounded text-AccentRegular bg-AccentLight border-AccentMediumLight"
              onClick={() => handleOpenField("budget")}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M12 2 A 10 10 0 0 1 23 15 L12 12 Z"
                  fill="currentColor"
                />
              </svg>
              <span className="text-xs font-semibold">
                {projectDetail.data?.budget
                  ? `Budget: ${
                      projectDetail?.data?.currency?.symbol
                        ? projectDetail?.data?.currency?.symbol
                        : loggedInUser?.currency?.symbol
                    }${projectDetail.data?.budget} ${
                      projectDetail?.data?.currency?.short_name
                        ? projectDetail?.data?.currency?.short_name
                        : loggedInUser?.currency?.short_name
                    }`
                  : "Set Budget"}
              </span>
            </button>
            <ReactTooltip id="budget-Tooltip" place="top" effect="solid" />
          </>
        )}

        <button
          className="flex items-center gap-1.5 py-2 text-xs font-semibold border rounded text-AccentRegular bg-AccentLight border-AccentMediumLight px-3"
          onClick={() => handleOpenField("due_date")}
        >
          <CalendarIcon width={16} />
          <span className="text-xs font-semibold">
            {getDueDateText(projectDetail.data?.due_date)}
          </span>
        </button>
      </div>

      <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
        <div className="md:pb-0 pb-[100px] mb-[68px]">
          <div className="flex flex-col">
            {/* New Team Members Section */}
            <AllContributorsSection
              members={teamContributors}
              isLoading={clientDetail?.isLoading}
            />

            {/* Project Dashboards Section */}
            <ProjectManagementSection
              project={projectDetail?.data}
              className="mb-16"
              members={clientDetail?.data?.members}
            />

            {/* Share */}
            {/* <ShareProjectSection projectLink={window.location.href} /> */}

            {/* Save as Template */}
            <SaveAsTemplateSection
              clientDetail={projectDetail}
              handleSavingTemplate={handleSavingTemplate}
              handleUpdatingTemplate={handleUpdatingTemplate}
              isLoading={isLoading}
            />

            {/* Danger Zone Section */}
            {clientDetail?.data?.is_admin === true ? (
              <DangerZone
                clientDetail={projectDetail}
                isProject={true}
                handleArchiving={handleArchiving}
                handleDeleting={handleDeleting}
                archiving={archiving}
                deleting={deleting}
              />
            ) : null}
          </div>
        </div>
      </div>

      <CreateClientModal
        isOpen={createClientModal}
        setModal={setCreateClientModal}
      />

      <CreateMemberModal
        isOpen={createMemberModal}
        setModal={setCreateMemberModal}
        remainingMembers={member?.spot}
      />

      {/* Plural Member */}
      <ExpandTeamsModal
        isOpen={editTeamMembersModal}
        setModal={setEditTeamMembersModal}
        focusOnMember={handleFocusMemberView}
      />

      {/* Singular Member */}
      {selectedTeamMemberId && (
        <ExpandTeamMemberModal
          isOpen={selectedTeamMemberId ? true : false}
          setModal={() => setSelectedTeamMemberId(null)}
          memberId={selectedTeamMemberId}
        />
      )}

      <ProjectDescriptionModal
        setNotIsOpen={setIsDescModalOpen}
        notIsOpen={isDescModalOpen}
        projectDetails={projectDetail?.data}
      />

      <NewProjectModal
        isOpen={isProjectModalOpen}
        setIsOpen={setIsProjectModalOpen}
        editProject={projectDetail.data}
        focusField={focusField}
      />
    </div>
  );
}

export default Project;
