import { useEffect } from "react";

import useBgColor from "./hooks/useBgColor";
import useBgAccent from "./hooks/useAccentColor";

export const AccentColorProvider = ({ children }) => {
  const bgColor = useBgColor();
  const accentColor = useBgAccent();

  useEffect(() => {
    document.documentElement.style.setProperty("--bg-color", bgColor);
    document.documentElement.style.setProperty("--accent-color", accentColor);
  }, [bgColor, accentColor]);

  return <>{children}</>;
};
