import CheckMarkIcon from "../../assets/Images/green-check-mark.svg";
import PaypalIcon from "../CreateInvoiceForm/PaypalIcon";
import StripeIcon from "../CreateInvoiceForm/StripeIcon";

function InvoiceConnectionBtn({ onClick, isCheck, text, logo, type }) {
  return (
    <div className="relative flex-1 lg:flex-none">
      <button
        onClick={onClick}
        className={`text-AccentRegular border border-AccentLight bg-white hover:bg-Neutral300 button-hover w-full min-w-[6rem] h-10 sm:min-w-[11rem] sm:h-11 large-primary-btn text-xs sm:text-sm text-center flex items-center justify-center relative font-semibold py-2 px-[15px] rounded focus-visible:outline focus-visible:outline-[6px] gap-2 focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out`}
      >
        <span className="mr-1">{isCheck ? "Connected" : text}</span>
        <spna>
          {type === "stripe" 
            ? <StripeIcon /> 
            : <PaypalIcon />
          }
        </spna>
        {/* <img src={logo} alt="logo" className="h-[20px] w-[20px] mr-1" /> */}
      </button>
      {isCheck && (
        <img
          src={CheckMarkIcon}
          alt="CheckMarkIcon"
          className="h-[26px] w-[26px] absolute top-[-10px] right-[-10px]"
        />
      )}
    </div>
  );
}

export default InvoiceConnectionBtn;
