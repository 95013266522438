import { Tab } from "@headlessui/react";

import Contacts from "../Settings/Contacts";
import ViewEditPermission from "../Settings/ViewEditPermission";

function ClientPortalTab({ isClientPortalActivated }) {
  return (
    <Tab.Panel className="5xl:px-[96px] px-6 md:px-0 w-full pt-0">
      <div className="flex flex-col gap-10 md:gap-16 md:flex-row">
        <Contacts />
        <ViewEditPermission isClientPortalActivated={isClientPortalActivated} />
      </div>
    </Tab.Panel>
  );
}

export default ClientPortalTab;
