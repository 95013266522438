import axios from "axios";
import { toast } from "react-toastify";
import confetti from "canvas-confetti";

import SuccessAlert from "../../components/Alerts/SuccessAlert";

export const CreateClientAction = (
  {
    email,
    business_name,
    phone_number,
    profile,
    contact_name,
    members,
    assign_all_members,
    second_contact_name,
    second_email,
    second_phone_number,
    address,
    website_one,
    website_two,
    vat_number,
    contact_person_job_title,
    second_contact_person_job_title,
    custom_fields,
  },
  setSubmitting,
  setErrors,
  setModal,
  navigate,
  setIsAddMoreContact
) => {
  return async (dispatch) => {
    try {
      const assigned_members = members?.toString();
      let { data } = await axios.post(
        "api/v1/client/create-client",
        {
          email,
          business_name,
          contact_name,
          phone_number,
          profile,
          assigned_members,
          assign_all_members,
          second_contact_name,
          second_email,
          second_phone_number,
          address,
          website_one,
          website_two,
          vat_number,
          contact_person_job_title,
          second_contact_person_job_title,
          custom_fields:
            custom_fields?.length > 0 ? JSON.stringify(custom_fields) : null,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch(getFullClientList());
      setModal(false);
      setSubmitting(false);
      setIsAddMoreContact(false);

      toast.success(
        <SuccessAlert
          titleDescription="Client created successfully"
          description="You have added a client to your client dashboard. Managing this client is as easy as navigating to their detailed page."
        />,
        {
          autoClose: 10000,
          pauseOnHover: true,
          hideProgressBar: true,
          progress: undefined,
          icon: false,
          toastId: "customize-toster",
        }
      );

      if (data?.data?._id) {
        navigate(`/admin/clients/manage/${data?.data?._id}`);
      }

      // Party animation after creation of client
      confetti({ ticks: 500, spread: 70, particleCount: 80 });

      return dispatch({
        type: "CLIENT_CREATE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });

        setErrors(err);
        setSubmitting(false);

        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);

        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const getFullClientList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_FULL_CLIENTS_LIST",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(`api/v1/client/full-client-list`);
      return dispatch({
        type: "SET_FULL_CLIENTS_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CLIENTS_FULL_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CLIENTS_FULL_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ClientListWithNoProposal = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_CLIENTS_LIST_WITH_NO_PROPOSAL",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(
        `api/v1/proposal/client-list-with-no-proposals`
      );
      return dispatch({
        type: "SET_CLIENTS_LIST_WITH_NO_PROPOSAL",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_CLIENTS_LIST_WITH_NO_PROPOSAL_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "SET_CLIENTS_LIST_WITH_NO_PROPOSAL_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ClientListWithNoTimeTrackingProject = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_CLIENTS_LIST_WITH_NO_TIME_TRACKING_PROJECT",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(
        `api/v1/time-tracking/client-list-with-no-time-tracking-project`
      );
      return dispatch({
        type: "SET_CLIENTS_LIST_WITH_NO_TIME_TRACKING_PROJECT",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "SET_CLIENTS_LIST_WITH_NO_TIME_TRACKING_PROJECT_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "SET_CLIENTS_LIST_WITH_NO_TIME_TRACKING_PROJECT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const getAssignMemberClientList = async (id) => {
  // return async (dispatch) => {
  try {
    let { data } = await axios.get(
      `api/v1/client/get-assign-memer-client?id=${id}`
    );
    return data.data;
  } catch (error) {
    if (error.response.status === 401) {
      toast.error(error?.response?.data?.message);
      // return dispatch({
      //   type: "CLIENTS_FULL_LIST_GET_ERROR",
      //   payload: error,
      // });
      return error;
    } else {
      return error;
      // return dispatch({
      //   type: "CLIENTS_FULL_LIST_GET_ERROR",
      //   payload: error,
      // });
    }
  }
  // };
};

export const getMyFullClientList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_MY_FULL_CLIENTS_LIST",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(`api/v1/client/my-full-client-list`);
      return dispatch({
        type: "SET_MY_FULL_CLIENTS_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CLIENTS_MY_FULL_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CLIENTS_MY_FULL_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetClientAction = ({
  page,
  perPage,
  search,
  sortColumn,
  sortOrder,
}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_CLIENTS_LIST",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(
        `api/v1/client/get-client-list?page=${page}&limit=${perPage}&search=${search}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`
      );
      // setModal(false);
      // setSubmitting(false);
      // toast.success("Client created successfully");
      return dispatch({
        type: "SET_CLIENTS_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CLIENTS_LIST_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CLIENTS_LIST_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetArchivedClientAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_ARCHIVED_CLIENTS_LIST",
        payload: { isLoading: true },
      });
      let { data } = await axios.get(`api/v1/client/get-archived-client-list`);
      return dispatch({
        type: "SET_ARCHIVED_CLIENTS_LIST",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      return dispatch({
        type: "GET_ARCHIVED_CLIENTS_LIST_GET_ERROR",
        payload: error,
      });
    }
  };
};

export const ChangeClientStatusAction = (
  { id, status },
  haveError
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/client/change-client-status`, {
        id,
        status,
      });
      return dispatch({
        type: "UPDATE_CLIENT_STATUS",
        payload: { isLoading: false, isUpdating: false, data: data.data },
      });
    } catch (error) {
      haveError();
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ToggleFavouriteAction = (clientId, newFavouriteStatus) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(
        `api/v1/client/toggle-client-favourite`,
        {
          id: clientId,
          favourite: newFavouriteStatus,
        }
      );

      if (data.success) {
        dispatch({
          type: "UPDATE_CLIENT_FAVOURITE",
          payload: { clientId, favourite: data.data.is_favourite },
        });
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      dispatch({
        type: "CLIENT_FAVOURITE_TOGGLE_ERROR",
        payload: error,
      });
    }
  };
};

export const GetClientDetailAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: true, isUpdating: false, data: {} },
      });
      let { data } = await axios.get(`api/v1/client/get-client-by-id?id=${id}`);
      return dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        if (navigate) navigate("/admin/clients");
        toast.error("Looks like Client ID is not valid");
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const GetCollabClientDetailAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: true, isUpdating: false, data: {} },
      });
      let { data } = await axios.get(
        `api/v1/client/get-client-by-id-for-collab?id=${id}`
      );
      return dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        navigate("/admin/clients");
        toast.error("Looks like Client ID is not valid");
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_DETAIL_GET_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateClientAction = (
  id,
  value,
  setSubmitting,
  setErrors,
  navigate
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_CLIENT_DETAIL",
        payload: { isUpdating: true },
      });
      const { data } = await axios.put(
        "api/v1/client/update-client",
        {
          id: id,
          ...value,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // setModal(false);
      setSubmitting(false);
      // toast.success("Updated Detail successfully");
      dispatch({
        type: "UPDATE_CLIENT_DETAIL",
        payload: {
          isUpdating: false,
          data: {
            ...data.data,
          },
        },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      dispatch({
        type: "UPDATE_CLIENT_DETAIL",
        payload: { isUpdating: false },
      });
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false, true);
        setErrors(err);
        return dispatch({
          type: "CLIENT_UPDATE_ERROR",
          payload: error,
        });
      } else if (error.response.status === 401) {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_UPDATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ArchiveClientAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      await axios.post(`api/v1/client/archive-client?id=${id}`);
      toast.success("Archived successfully");
      navigate("/admin/clients");
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CLIENT_ARCHIVE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_ARCHIVE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ResoreArchivedClientAction = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/client/restore-client?id=${id}`
      );
      toast.success("Client restored successfully");
      dispatch(GetArchivedClientAction());
      dispatch(GetClientAction({ page: 1, perPage: 10, search: "" }));
      return dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CLIENT_RESTORE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_RESTORE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteClientAction = (id, navigate) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/client/delete-client?id=${id}`);
      toast.success("Client Deleted successfully");
      navigate("/admin/clients");
      dispatch(GetArchivedClientAction());
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CLIENT_DELETE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_DELETE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const AssignTeamMemberClientAction = (
  values,
  setSubmitting,
  setErrors,
  setEditNotIsOpen,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/client/asign-team-member",
        values
      );
      setEditNotIsOpen(false);
      setSubmitting(false);
      toast.success("Member created successfully");
      navigate(``, {
        state: data.data,
        replace: true,
      });
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { data: data.data },
      });
      return dispatch({
        type: "ASSIGN_MEMBER_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "ASSIGN_MEMBER_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "ASSIGN_MEMBER_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const AssignTeamMemberUpdateClientAction = (
  values,
  setSubmitting,
  setErrors,
  setEditNotIsOpen,
  navigate
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/client/edit-asigned-team-member",
        values
      );
      setEditNotIsOpen(false);
      setSubmitting(false);
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return dispatch({
        type: "ASSIGN_MEMBER_UPDATE_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error?.response?.status === 422) {
        let err = {};
        error?.response?.data?.errors?.forEach((element) => {
          err[element?.param] = element?.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "ASSIGN_MEMBER_UPDATE_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "ASSIGN_MEMBER_UPDATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteAssignTeamMemberClientAction = (body, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `api/v1/client/remove-team-member`,
        body
      );
      toast.success("Member Deleted successfully");
      navigate(``, {
        state: data.data,
        replace: true,
      });
      dispatch({
        type: "SET_CLIENT_DETAIL",
        payload: { data: data.data },
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "CLIENT_MEMBER_DELETE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error.response.data.message);
        return dispatch({
          type: "CLIENT_MEMBER_DELETE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateNewScreenClientAction = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/client/add-screen", values);
      dispatch({
        type: "CREATE_SCREEN_SUCCESS",
        payload: { data: data.data },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_TASK_SCREEN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_SCREEN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateNewColumnClientAction = (
  values,
  navigate,
  setIsOpenColumn,
  setColumn
) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/client/add-column", values);
      dispatch({
        type: "CREATE_COLUMN_SUCCESS",
        payload: { data: data.data },
      });
      if (values?.old_name) {
        if (values?.types === "task") {
          dispatch({
            type: "UPDATE_TASK_COLUMN_NAME",
            payload: { data: data.data },
          });
        }
        if (values?.types === "service") {
          dispatch({
            type: "UPDATE_SERVICE_COLUMN_NAME",
            payload: { data: data.data },
          });
        }
      }
      if (setColumn) setColumn("");
      if (setIsOpenColumn) setIsOpenColumn(false);
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
          toast.error(element.msg);
        });
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ChangeColumnPositionInClient = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/client/update-column-position",
        values
      );
      dispatch({
        type: "UPDATE_COLUMN_POSITION_SUCCESS",
        payload: { data: data.data },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_COLUMN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const RemoveClientColumnAction = (values, navigate) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post("api/v1/client/remove-column", values);
      dispatch({
        type: "REMOVE_COLUMN_SUCCESS",
        payload: { data: data.data },
      });
      navigate(``, {
        state: data.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "REMOVE_TASK_COLUMN_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "REMOVE_TASK_COLUMN_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const ImportFile = (
  values,
  setisSubmitting,
  setPreviewData,
  setIsPreviewModalOpen,
  setErrors
) => {
  return async (dispatch) => {
    try {
      await axios.post("api/v1/client/import-client-csv-file", values);

      setisSubmitting(false);
      setIsPreviewModalOpen(false);
      setPreviewData([]);

      dispatch(GetClientAction({ page: 1, perPage: 10, search: "" }));
      dispatch(getFullClientList());

      // Party animation on bulk clients import
      confetti({ ticks: 500, spread: 70, particleCount: 80 });

      toast.success("Clients Added successfully");

      return dispatch({
        type: "CLIENT_CREATE_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });

        setErrors(err);

        setisSubmitting(false);
        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);

        setisSubmitting(false);
        return dispatch({
          type: "CLIENT_CREATE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export function GetClientStatusList() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "api/v1/client-status/get-client-status-list"
      );

      if (data.success) {
        dispatch({ type: "GET_CLIENT_STATUS_LIST", payload: data });
      } else {
        console.log("Soething went wrong", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export function UpdateClientStatusList(newStatus) {
  return async (dispatch) => {
    try {
      dispatch({ type: "UPDATE_CLIENT_STATUS_LIST", payload: newStatus });
    } catch (err) {
      console.log(err);
    }
  };
}

export const invitedClientAction = () => {
  return async (dispatch) => {
    try {
      let { data } = await axios.get(
        `api/v1/invited-client-portal/invited-clients`
      );
      return dispatch({
        type: "SET_INVITED_CLIENTS_LIST",
        payload: { data: data.data },
      });
    } catch (error) {
      return dispatch({
        type: "GET_INVITED_CLIENTS_LIST_GET_ERROR",
        payload: error,
      });
    }
  };
};

export const getClientPortalClientDetails = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/invited-client-portal/all-clients`,
        {
          headers: {
            user_type: "user",
          },
        }
      );
      return dispatch({
        type: "GET_CLIENT_PORTAL_CLIENT_DETAILS",
        payload: { data: data.data },
      });
    } catch (error) {
      console.log(error);
      // return dispatch({
      //   type: "GET_INVITED_CLIENTS_LIST_GET_ERROR",
      //   payload: error,
      // });
    }
  };
};

export const updateClientAccessLevel = (values) => {
  return async (dispatch) => {
    try {
      let { data } = await axios.post(
        "api/v1/invited-client-portal/update-access-level-by-user",
        values
      );
      return dispatch({
        type: "UPDATE_CLIENT_ACCESS_LEVEL",
        payload: { data: data.data },
      });
    } catch (error) {
      return dispatch({
        type: "UPDATE_CLIENT_ACCESS_LEVEL_ERROR",
        payload: error,
      });
    }
  };
};
