import React from "react";

function DeleteIcon({ color = "currentColor", width = 16, className = "" }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.23047 5.04395H4.33132H13.1382"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.98234 5.04444V4.02222C5.98234 3.75111 6.09832 3.49111 6.30477 3.2994C6.51122 3.1077 6.79123 3 7.08319 3H9.2849C9.57687 3 9.85687 3.1077 10.0633 3.2994C10.2698 3.49111 10.3858 3.75111 10.3858 4.02222V5.04444M12.037 5.04444V12.2C12.037 12.4711 11.9211 12.7311 11.7146 12.9228C11.5082 13.1145 11.2281 13.2222 10.9362 13.2222H5.43191C5.13994 13.2222 4.85994 13.1145 4.65349 12.9228C4.44704 12.7311 4.33105 12.4711 4.33105 12.2V5.04444H12.037Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon;
