import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import leadsColors from "../../assets/json/leads-colors.json";
import LeadListLoader from "../../components/ContentLoaders/LeadListLoader";
import ReportIcon from "../../components/Icons/ReportIcon";
import AddImportModal from "../../components/Modals/AddImportModal";
import AddLeadModal from "../../components/Modals/AddLeadModal";
import ArchivedLeadsModal from "../../components/Modals/ArchivedLeadsModal";
import CreateClientModal from "../../components/Modals/CreateClientModal";
import CreateMemberModal from "../../components/Modals/CreateMemberModal";
import EditLeadModal from "../../components/Modals/EditLeadModal";
import ExistingProposalLeadModal from "../../components/Modals/ExistingProposalLeadModal";
import PreviewLeadModal from "../../components/Modals/PreviewLeadModal";
import { NewEntityTemplateModal } from "../../components/Modals/Templates/NewEntityTemplateModal";
import { SelectTemplate } from "../../components/Modals/Templates/SelectTemplate";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import LeadsList from "../../components/comman/Leads/LeadsList";
import { GetLeadsAction } from "../../store/actions/leadAction";
import {
  CloseModalAction,
  OpenModalAction,
} from "../../store/actions/modalsAction";
import addPositionIdx from "../../utils/addPositionIdx";

const Leads = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { name, isOpen } = useSelector((state) => state?.modals?.modal);
  const leadsList = useSelector((state) => state?.lead?.leads);
  const user = useSelector((state) => state?.auth?.user);

  const query = new URLSearchParams(search);
  const id = query.get("cardId");

  const [isOpenColumn, setIsOpenColumn] = useState(false);
  const [isAPICalling, setIsAPICalling] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isArchivedLoading, setIsArchivedLoading] = useState(false);
  const [channel, setChannel] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [isPreview, setIsPreview] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [leads, setLeads] = useState(
    addPositionIdx(
      leadsList?.data?.filter((lead) => lead?.is_archived === false)
    )
  );

  useEffect(() => {
    if (id) {
      dispatch(OpenModalAction("lead_details"));
    }
  }, [id]);

  useEffect(() => {
    if (user?.role !== "general") {
      return;
    } else {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    if (leadsList?.data?.length === 0) {
      setIsLoading(true);
      dispatch(GetLeadsAction(false, setIsLoading));
    }
  }, [dispatch]);

  useEffect(() => {
    setLeads(
      addPositionIdx(
        leadsList?.data?.filter((lead) => lead?.is_archived === false)
      )
    );
  }, [leadsList]);

  const handleIsArchived = () => {
    if (isAPICalling) {
      setIsArchivedLoading(true);
      dispatch(GetLeadsAction(true, setIsArchivedLoading));
      setIsAPICalling(false);
    }
  };

  const csvToJson = async (csv, e) => {
    const lines = csv.split("\n");
    const result = [];

    let headers = lines[0]
      .split(",")
      .map((header) => header.toLowerCase().trim().replaceAll(" ", "_"));

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      if (!line) continue;

      let obj = {};
      let field = "";
      let isInsideQuotes = false;
      let charIndex = 0;
      let fieldIndex = 0;

      for (let char of line) {
        if (char === '"' && (charIndex === 0 || line[charIndex - 1] !== "\\")) {
          isInsideQuotes = !isInsideQuotes;
        } else if (char === "," && !isInsideQuotes) {
          obj[headers[fieldIndex]] = field;
          field = "";
          fieldIndex++;
        } else {
          field += char;
        }
        charIndex++;
      }

      if (fieldIndex < headers.length) {
        obj[headers[fieldIndex]] = field;
      }

      fieldIndex++;

      if (fieldIndex !== headers.length) {
        alert("Please check your CSV file.");
        if (e && e.target) {
          e.target.value = null;
        }
        throw new Error(
          "CSV format error: Field count does not match header count."
        );
      }

      // remove the before first character space
      obj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          value.trim().replaceAll("", ""),
        ])
      );

      result.push(obj);
    }

    return result;
  };

  const renderColor = () => {
    const colors = Object.keys(leadsColors);

    const fallbackColor = colors[user?.lead_column?.length % colors.length];

    const newColor = colors.find(
      (color) => !user?.lead_column?.some((column) => column?.color === color)
    );

    return newColor || fallbackColor;
  };

  return (
    <div>
      <AdminNavbar
        title="Leads Pipeline"
        subtitle="Track the progress of your leads"
      />

      <div className="5xl:px-[96px] px-6 lg:px-12 w-full pt-0">
        <div className="pt-5">
          <div className="flex justify-between items-center mb-[25px]">
            <div className="flex items-center justify-between ">
              <h2 className="text-Neutral700 text-xs font-semibold uppercase leading-[16px] tracking-[0.04em]">
                All Leads
              </h2>
              <span
                title="Leads amount"
                className="w-[18px] h-[18px] flex items-center justify-center bg-Neutral800 text-Neutral100 rounded-full text-[10px] ml-[8px] leading-[140%] tracking-[0.06em] font-bold "
              >
                {/* MOCK  */}
                {leads?.length}
              </span>
            </div>
            <div className="flex gap-2 right-5 lg:right-12 5xl:right-24">
              <button
                className="5xl:text-sm text-[12px] uppercase text-Neutral800 hover:bg-AccentRegular hover:text-Neutral200 5xl:w-[120px] w-[88px] flex px-[10px] items-center ml-auto rounded focus-visible:outline-none focus:outline focus:outline-4 focus:outline-Neutral200 font-semibold"
                onClick={() => {
                  handleIsArchived();
                  dispatch(OpenModalAction("leads_archive"));
                }}
              >
                <ReportIcon className="mr-1" />
                <span>Archive</span>
              </button>
              <div
                className="text-AccentRegular bg-Neutral200 hover:bg-Neutral300 button-hover md:w-full large-primary-btn text-xs sm:text-xs text-center flex items-center justify-center relative font-semibold py-2 px-[15px] rounded focus-visible:outline focus-visible:outline-[6px] focus-visible:drop-shadow-none focus-visible:outline-AccentMediumLight transition duration-300 ease-in-out cursor-pointer"
                onClick={() => setOpenImportModal(true)}
              >
                <span>Import via CSV</span>
                <input
                  id="import-leads"
                  type="file"
                  className="hidden"
                  accept=".csv"
                  onChange={async (e) => {
                    if (e.target.files[0]) {
                      const file = e.target.files[0];
                      const fileUrl = URL.createObjectURL(file);
                      const res = await fetch(fileUrl);
                      const text = await res.text();
                      const tempResult = await csvToJson(text, e);
                      if (openImportModal) setOpenImportModal(false);
                      setPreviewData(tempResult);
                      setIsPreview(true);
                      e.target.value = null;
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {isLoading ? (
            <LeadListLoader />
          ) : (
            <LeadsList
              setIsOpenColumn={setIsOpenColumn}
              isOpenColumn={isOpenColumn}
              leads={leads}
              setLeads={setLeads}
              setChannel={setChannel}
              renderColor={renderColor}
            />
          )}

          <AddLeadModal
            isOpen={name === "add_lead" && isOpen}
            setIsOpen={() => dispatch(CloseModalAction("add_lead"))}
            channel={channel}
            totalLeads={leads?.length}
          />
          <EditLeadModal
            isOpen={name === "edit_lead" && isOpen}
            setIsOpen={() => {
              dispatch(CloseModalAction("edit_lead"));
              navigate({ search: "" });
            }}
          />
          <CreateClientModal
            isOpen={name === "add_client" && isOpen}
            setIsOpen={() => dispatch(CloseModalAction("add_client"))}
          />
          <CreateMemberModal
            isOpen={name === "invite_team_member" && isOpen}
            setModal={() => dispatch(CloseModalAction("invite_team_member"))}
          />
          {/* For Questionnaire */}
          <NewEntityTemplateModal
            isOpen={name === "create_questionnaire" && isOpen && id}
            setIsOpen={() => dispatch(CloseModalAction("create_questionnaire"))}
            type="Questionnaire"
            onCustomTemplateClick={() => {
              dispatch(
                OpenModalAction("create_questionnaire_custom_template", {
                  type: "Questionnaire",
                })
              );
            }}
            id={id}
          />
          <SelectTemplate
            type="Questionnaire"
            id={id}
            isOpen={name === "create_questionnaire_custom_template" && isOpen}
            setIsOpen={() =>
              dispatch(CloseModalAction("create_questionnaire_custom_template"))
            }
          />

          {/* For Proposal */}
          <NewEntityTemplateModal
            isOpen={name === "create_proposal" && isOpen && id}
            setIsOpen={() => dispatch(CloseModalAction("create_proposal"))}
            type="Proposal"
            onCustomTemplateClick={() => {
              dispatch(
                OpenModalAction("create_proposal_custom_template", {
                  type: "Proposal",
                })
              );
            }}
            id={id}
          />
          <SelectTemplate
            type="Proposal"
            isOpen={name === "create_proposal_custom_template" && isOpen}
            setIsOpen={() =>
              dispatch(CloseModalAction("create_proposal_custom_template"))
            }
            id={id}
          />
          <ExistingProposalLeadModal
            modalIsOpen={name === "existing_proposal" && isOpen && id}
            closeModal={() => dispatch(CloseModalAction("existing_proposal"))}
            lead={leadsList?.data?.find((lead) => lead?._id === id)}
          />

          <ArchivedLeadsModal
            isOpen={name === "leads_archive" && isOpen}
            setIsOpen={() => dispatch(CloseModalAction("leads_archive"))}
            isArchivedLoading={isArchivedLoading}
          />

          {isPreview && (
            <PreviewLeadModal
              isPreviewModalOpen={isPreview}
              setIsPreviewModalOpen={setIsPreview}
              previewData={previewData}
              setPreviewData={setPreviewData}
            />
          )}
          {openImportModal && (
            <AddImportModal
              isOpen={openImportModal}
              setIsOpen={setOpenImportModal}
              type="lead"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Leads;
