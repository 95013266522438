import React from "react";
import ImgIcon from "../../assets/Images/img-icon.svg";
import Close from "../../assets/Images/Close-gray.svg";

const UploadPDF = (props) => {
  const {
    value,
    error,
    touched,
    handleBlue,
    setFieldValue,
    contract,
    handleChangeeffect,
    accept
  } = props;
  return (
    <>
      <div className="relative w-full">
        <label
          htmlFor="uploadlogo"
          className="placeholder:text-Neutral500 p-2 px-3 text-sm leading-5 w-full focus-visible:outline-none border-[1px] rounded border-Neutral400 hover:border-Neutral800 bg-Neutral100 transition duration-300 border-dashed inline-block cursor-pointer"
        >
          <span
            className="flex items-center"
            style={{ justifyContent: value?.file && "space-between" }}
          >
            {!value?.file && <img src={ImgIcon} alt="" />}
            <a target="_blank" rel="noreferrer" href={contract?.existing_file_upload?.url}>
              <p className="text-Neutral600 ml-2 text-xs font-medium">
                {value?.file ? value?.file?.name || value?.file : props.title}
              </p>
            </a>
            <div className="flex">
              {value?.file ? (
                <>
                  {" "}
                  <img
                    className="cursor-pointer"
                    src={Close}
                    alt=""
                    name="file"
                    onClick={() => setFieldValue(`file`, "")}
                  />
                </>
              ) : null}
            </div>
          </span>{" "}
        </label>
        {!value?.file && (
          <input
            id="uploadlogo"
            className={`hidden ${
              touched?.file && error?.file
                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                : ""
            }`}
            type="file"
            accept={accept || ".pdf"}
            name="file"
            placeholder="Eg. very_good_password"
            onChange={(e) => {
              setFieldValue("file", e.target.files[0]);
              if (handleChangeeffect) {
                handleChangeeffect(e);
              }
            }}
            onBlur={handleBlue}
          />
        )}
        {touched?.file && error?.file && (
          <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
            {touched?.file && error?.file}{" "}
          </p>
        )}
      </div>
    </>
  );
};

export default UploadPDF;
