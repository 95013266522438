import { Form, Formik } from "formik";
import React, { Fragment, useRef, useState } from "react";
import Modal from "react-modal";
import {
  UploadExistingInvoice,
  getInvoiceList,
  getInvoiceListByClientId,
} from "../../store/actions/invoicesAction";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import UploadPDF from "../Buttons/UploadPDF";
import CloseBtn from "../../assets/Images/close-btn.svg";
import { currencyList } from "../../assets/json/currencyList";
import { Menu, Transition } from "@headlessui/react";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  client: Yup.string().required("Client is required"),
  type: Yup.string(),
  file: Yup.string().when("type", {
    is: "file",
    then: Yup.string().required("Select File").nullable(),
  }),
  url: Yup.string().when("type", {
    is: "url",
    then: Yup.string()
      .matches(
        /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
        "Enter correct url!"
      )
      .required("Url is required")
      .nullable(),
  }),
  currency: Yup.object().required("Currency is required").nullable(),
  amount: Yup.number().required("Amount is required").nullable(),
});

const UploadExistingInvoiceSpecificClientModal = ({
  modalIsOpen,
  closeModal,
  client,
  is_reload,
}) => {
  const currencyref = useRef();
  const dispatch = useDispatch();
  const [uploadHere, setUploadHere] = useState(false);
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="View Form Preview Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white px-6 xs:px-12 pt-8 pb-12 rounded-[8px]">
          <div>
            <Formik
              initialValues={{
                title: "",
                client: client,
                file: "",
                url: "",
                type: "file",
                currency: { name: "DOLLAR", short_name: "USD", symbol: "$" },
                amount: "",
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                setSubmitting(true);
                const reloadInvoice = async () => {
                  if (!is_reload) {
                    await dispatch(getInvoiceListByClientId(values.client));
                  } else {
                    await dispatch(
                      getInvoiceList({
                        page: 1,
                        limit: 10,
                        search: "",
                        sortColumn: "createdAt",
                        sortType: "desc",
                      })
                    );
                  }
                };
                const formData = new FormData();
                formData.append("file", values.file);
                formData.append("title", values.title);
                formData.append("client", values.client);
                formData.append("type", values.type);
                formData.append("url", values.url);
                formData.append("currency", values?.currency?.short_name);
                formData.append("amount", values.amount);
                await dispatch(
                  UploadExistingInvoice(
                    formData,
                    reloadInvoice,
                    setSubmitting,
                    closeModal,
                    setErrors
                  )
                );
              }}
            >
              {({
                touched,
                errors,
                values,
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="text-left">
                    <h3
                      className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8"
                      id="modal-title"
                    >
                      UPLOAD EXISTING INVOICE
                    </h3>

                    <div className="mb-6">
                      <label className="text-Neutral900 font-bold text-xs">
                        Invoice Title
                      </label>
                      <div className="mt-2">
                        <div className="relative">
                          <input
                            className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-base leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                              touched?.title && errors?.title
                                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                : ""
                            }`}
                            type="text"
                            name="title"
                            placeholder="E.g Invoice #1"
                            value={values?.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.title && touched.title && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.title}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mb-6">
                      <label className="text-Neutral900  font-bold text-xs">
                        Upload Invoice
                      </label>
                      <div className="mt-2">
                        <div
                          className={`w-full ${
                            values.type === "url"
                              ? "opacity-0 w-0 h-0 invisible"
                              : "opacity-100 w-full h-fit"
                          } `}
                        >
                          <UploadPDF
                            title="Upload your invoice PDF."
                            value={values}
                            error={errors}
                            touched={touched}
                            handleBlue={handleBlur}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div
                          className={`w-full transition duration-300 ${
                            values.type === "file"
                              ? "opacity-0 w-0 h-0 invisible"
                              : "opacity-100 w-full h-fit"
                          } `}
                        >
                          <input
                            className={`text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-base leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition ${
                              touched?.url && errors?.url
                                ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                                : ""
                            }`}
                            type="text"
                            name="url"
                            placeholder="Eg.  drive.google.com/drive..."
                            value={values?.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.url && touched.url && (
                            <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                              {errors.url}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                      <p
                        className={`text-xs text-Neutral700 transition duration-300 font-medium pt-5 ${
                          !uploadHere
                            ? "opacity-0 w-0 h-0 invisible"
                            : "opacity-100 w-fit h-fit"
                        }`}
                      >
                        Have a PDF invoice already?{" "}
                        <button
                          type="button"
                          className="text-AccentRegular"
                          name="type"
                          onClick={() => {
                            setFieldValue("type", "file");
                            setFieldValue("url", "");
                            setUploadHere(false);
                          }}
                        >
                          {" "}
                          Upload it here.
                        </button>
                      </p>
                      <p
                        className={`text-xs text-Neutral700 transition duration-300 font-medium ${
                          uploadHere
                            ? "opacity-0 w-0 h-0 invisible"
                            : "opacity-100 w-fit h-fit"
                        }`}
                      >
                        Have an existing invoice URL?{" "}
                        <button
                          type="button"
                          className="text-AccentRegular"
                          name="type"
                          onClick={() => {
                            setFieldValue("type", "url");
                            setFieldValue("file", "");
                            setUploadHere(true);
                          }}
                        >
                          Paste it here.
                        </button>
                      </p>
                    </div>

                    <div className="mb-6 relative">
                      <label className="text-Neutral900 font-bold text-xs flex items-center justify-between">
                        Amount
                      </label>
                      <div className="mt-2 relative z-50">
                        <input
                          className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition pr-[100px]"
                          type="number"
                          inputMode="decimal"
                          placeholder="50"
                          value={values?.amount}
                          name="amount"
                          onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                              handleChange(e);
                            }
                          }}
                        />
                        <div className="absolute right-0 top-[50%] -translate-y-2/4">
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            <Menu.Button
                              ref={currencyref}
                              className={`focus-visible:outline-none popup-btn relative w-[89px] h-[36px] p-[4px_8px] mr-2 rounded-[4px] hover:bg-Neutral300`}
                            >
                              <div
                                className={`flex items-center text-Neutral700 text-sm font-medium justify-between`}
                              >
                                <span className="whitespace-nowrap text-ellipsis overflow-hidden">
                                  {values?.currency
                                    ? `${values?.currency?.short_name} - ${values?.currency?.symbol}`
                                    : "USD - $"}
                                </span>
                                <svg
                                  width="11"
                                  height="8"
                                  viewBox="0 0 11 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                    fill="#5A5A66"
                                  ></path>
                                </svg>
                              </div>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right max-h-[241px] z-[999999] absolute top-[42px] right-[4px] rounded-[8px] dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 focus:outline-none overflow-auto cursor-pointer w-[180px] p-2">
                                {currencyList
                                  ?.sort((a, b) =>
                                    a?.short_name?.localeCompare(b?.short_name)
                                  )
                                  ?.map((item) => {
                                    return (
                                      <div
                                        className="hover:bg-Neutral200 currency-hover rounded-[4px] py-2 px-2"
                                        key={item?.name}
                                        onClick={() => {
                                          setFieldValue("currency", item);
                                          setTimeout(() => {
                                            currencyref?.current?.click();
                                          }, 0);
                                        }}
                                      >
                                        <p className="text-Neutral800 font-normal text-xs group-hover:text-text-Neutral900 transition-all duration-300">
                                          {item?.name}
                                        </p>
                                        <p className="text-Neutral600 font-normal text-xs">
                                          {item?.short_name} - {item?.symbol}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </Menu.Items>
                            </Transition>
                            {/* )} */}
                          </Menu>
                        </div>
                      </div>
                      {errors?.amount && touched?.amount && (
                        <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                          {errors?.amount}
                        </p>
                      )}

                      {errors?.currency && touched?.currency && (
                        <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none">
                          {errors?.currency}
                        </p>
                      )}
                    </div>
                    <button
                      className="btn button-hover large-primary-btn text-sm text-center inline-block text-Neutral100 bg-AccentRegular font-semibold w-full py-3 rounded drop-shadow-Texts focus:outline focus:outline-8 focus:outline-AccentMediumLight focus-visible:outline-0 transition duration-300 ease-in-out"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="relative z-10 drop-shadow-Texts">
                          <svg
                            className="animate-spin m-auto h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </span>
                      ) : (
                        <span className="relative drop-shadow-Texts z-10">
                          Save
                        </span>
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => closeModal(false)}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default UploadExistingInvoiceSpecificClientModal;
