/**
 * Upgrade Badge Icon
 *
 * @param {import("react").SVGProps} props - SVG Icon Props
 */
const UpgradeBadgeIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12.2726C12.9289 12.2726 15.3032 9.89833 15.3032 6.96948C15.3032 4.04063 12.9289 1.66632 10 1.66632C7.07114 1.66632 4.69684 4.04063 4.69684 6.96948C4.69684 9.89833 7.07114 12.2726 10 12.2726Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.12879 11.4318L6.2121 18.3335L10.0001 16.0607L13.788 18.3335L12.8713 11.4242"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UpgradeBadgeIcon;
