import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import CloseIcon from "../../../components/Icons/CloseIcon";
import { GetClientDetailAction } from "../../../store/actions/clientAction";
import {
  DeleteServiceAction,
  UpdateServiceActions,
} from "../../../store/actions/serviceAction";
import {
  DeleteTaskAction,
  UpdateTaskActions,
} from "../../../store/actions/tasksAction";
import DeleteIcon from "../../Icons/DeleteIcon";
import FullScreenModal from "./components/FullScreenModal/FullScreenModal";
import MenuOfColumns from "./components/MenuOfColumns/MenuOfColumns";
import TaskMainContent from "./components/TaskMainContent";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
    overflow: "hidden",
  },
  content: {
    inset: "50% auto auto 50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
    border: "none",
    background: "none",
    borderRadius: "10px",
    overflow: "visible",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const TaskDetailModal = ({
  clientId,
  types,
  projectId,
  columnsName,
  column,
  project,
  setIsOpenTimer,
  setSelectedTask,
  dashboardTask = null,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const cardId = params.get("cardId");
  const isNew = params.get("isNew");

  const [modalClient, setIsModalClient] = useState(false);
  const [columnChange, setColumnChange] = useState(column);

  const user = useSelector((state) => state.auth.user);
  const data = useSelector((state) => {
    return types === "task"
      ? state.tasks.all_tasks
      : state.service.all_services;
  });
  const clientDetail = useSelector((state) => state.client.clientDetail);
  const [editableTitle, setEditableTitle] = useState("");

  const [taskId, setTaskId] = useState(null);
  const [newTask, setNewTask] = useState(false);
  const [item, setItem] = useState(
    dashboardTask ??
      (types === "task"
        ? data[projectId]?.find((ele) => ele?._id === taskId)
        : data[taskId])
  );

  useEffect(() => {
    if(cardId){
      setTaskId(cardId);
      setIsModalClient(true);
    }
  }, [cardId]);

  useEffect(() => {
    if (!taskId && isNew) {
      setNewTask(true);
      setIsModalClient(true);
    }

    if (!taskId && !isNew) {
      setNewTask(false);
    }
  }, [taskId, isNew]);

  useEffect(() => {
    if (column) {
      setColumnChange(column);
    }
  }, [column]);

  useEffect(() => {
    if (clientId && clientDetail?.data?._id !== clientId) {
      dispatch(GetClientDetailAction(clientId));
    }
  }, [clientDetail?.data?._id, clientId, dispatch]);

  const handleClose = () => {
    setIsModalClient(false);
    setTaskId(null);
    navigate({ search: "" });
    onClose();
  };

  const handleAddAnotherTask = (e) => {
    // Generate a unique identifier
    const uniqueId = Date.now();
    // Navigate to create a new task with a unique identifier
    navigate(
      `/admin/clients/manage/${clientDetail?.data?._id}/project/${project?._id}?isNew=true&uniqueId=${uniqueId}`
    );
    // Reset the newTask state
    setNewTask(true);
    // Reset the taskId
    setTaskId(null);
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setItem(
        types === "task"
          ? data[projectId]?.find((ele) => ele?._id === taskId)
          : data[taskId]
      );
    }
  }, [data, projectId, taskId, types]);

  const handleClickColumnChange = (column) => {
    setColumnChange(column);
    if (types === "task") {
      dispatch(UpdateTaskActions({ ...item, column: column.name }));
    }
    if (types === "service") {
      dispatch(UpdateServiceActions({ ...item, column: column.name }));
    }
  };

  const handleDeleteTask = (id) => {
    if (types === "task") {
      dispatch(
        DeleteTaskAction(
          {
            _id: id,
            project: projectId,
          },
          handleClose
        )
      );
    }
    if (types === "service") {
      dispatch(DeleteServiceAction(id));
    }
  };

  useEffect(() => {
    window.history.replaceState({}, "", window.location.pathname);
  }, []);

  return (
    modalClient && (
      <Modal
        isOpen={modalClient}
        onRequestClose={() => handleClose()}
        style={customStyles}
        closeTimeoutMS={300}
        contentLabel="View Form Preview Modal"
        key={`${taskId}-${isNew}-${params.get("uniqueId")}`}
      >
        <div className="relative animate__fadeInUp w-[85vw] md:w-full text-left transform transition-all h-[85vh] overflow-auto lg:overflow-visible lg:mt-5 lg:h-[95vh] scrollbar-hide">
          <MenuOfColumns
            column={columnChange ? columnChange : { name: item?.column }}
            columnsName={columnsName}
            projectId={projectId}
            clientId
            project={project}
            handleClickColumnChange={handleClickColumnChange}
            clientDetail={clientDetail?.data}
            item={item}
          />

          <TaskMainContent
            clientDetail={clientDetail?.data}
            types={types}
            item={item}
            project={project}
            isNew={newTask}
            cardId={taskId}
            setIsOpenTimer={setIsOpenTimer}
            setSelectedTask={setSelectedTask}
            setTaskId={setTaskId}
            setNewTask={setNewTask}
            setEditableTitle={setEditableTitle}
          />

          <div className="absolute right-0 z-10 flex items-center justify-end gap-5 -top-1 w-fit">
            <button
              name="anotherTask_top"
              className="px-4 py-2 text-xs font-semibold tracking-wider uppercase rounded-md text-Neutral000 bg-AccentRegular hover:bg-AccentMediumDark lg:hidden"
              onClick={handleAddAnotherTask}
            >
              Add Another
            </button>

            <FullScreenModal
              clientDetail={clientDetail?.data}
              types={types}
              item={item}
              project={project}
            />
            {clientDetail?.data?.is_admin ||
            project?.client?.is_admin ||
            clientDetail?.data?.is_manager ||
            project?.client?.is_manager ||
            user?._id === item?.created_by?._id ? (
              <div className="flex px-1 ">
                <Menu as="div" className="relative inline-block">
                  <div className="flex items-center">
                    <Menu.Button
                      className="relative inline-flex justify-center w-full focus-visible:outline-none"
                      disabled={newTask ? true : false}
                    >
                      <DeleteIcon
                        width={18}
                        color="currentColor"
                        className="text-[#E56780] hover:text-Neutral000 hover:bg-[#E56780] duration-300 transition-all rounded-[50%] h-[32px] w-[32px] flex items-center justify-center p-1 bg-[#FFF0F3]"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="z-10 origin-top-right absolute -top-[20px] right-[-100px] pt-4 pb-6 w-[384px] px-6 rounded-lg Timetrackingdropdown bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none"
                      onClick={(e) => {
                        if (e.target === e.currentTarget) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <div className="">
                        <p className="pb-2 text-xs font-semibold text-Neutral700">
                          Are you sure?
                        </p>
                      </div>
                      <div className="overflow-hidden border-0">
                        <h3
                          className="w-full py-4 mt-5 text-xs font-semibold text-center text-white duration-300 rounded cursor-pointer bg-Red400 hover:bg-Red600"
                          onClick={() => handleDeleteTask(item?._id)}
                        >
                          Yes, Delete Task
                        </h3>
                      </div>
                      <div className="overflow-hidden border-0 !border-transparent divide-0">
                        <Menu.Button className="relative inline-flex justify-center w-full focus-visible:outline-none">
                          <h3 className="w-full py-4 mt-3 text-xs font-semibold text-center duration-300 rounded cursor-pointer text-Neutral800 bg-Neutral200 hover:bg-Neutral500 hover:text-Neutral900">
                            No, I Still Want This Task
                          </h3>
                        </Menu.Button>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : null}
            <button className="cursor-pointer " onClick={() => handleClose()}>
              <CloseIcon
                width={18}
                className="text-Neutral000 hover:text-Red400 hover:border-Red400 duration-300 transition-all rounded-[50%] border border-Neutral400 h-[32px] w-[32px] flex items-center justify-center p-1"
              />
            </button>
          </div>

          <div className="absolute hidden py-4 right-2 lg:block">
            <button
              name="anotherTask_bottom"
              className="px-4 py-2 text-xs font-semibold tracking-wider uppercase rounded-md lg:text-sm text-Neutral000 bg-AccentRegular hover:bg-AccentMediumDark disabled:cursor-not-allowed disabled:bg-Neutral500 disabled:text-Neutral700"
              onClick={handleAddAnotherTask}
              disabled={editableTitle === ""}
            >
              Add Another
            </button>
          </div>
        </div>
      </Modal>
    )
  );
};

export default TaskDetailModal;
