import { cn } from "../../../../utils";
import prepareCounter from "../../../../utils/prepareTasksAndTimeCounter";
import CommentBadge from "../../../common/badges/CommentBadge";
import EntryBadge from "../../../common/badges/EntryBadge";
import TaskBadge from "../../../common/badges/NotificationBadge";
import CardSubTitle from "../../../common/typography/CardSubtitle";
import ClockIcon from "../../../Icons/ClockIcon";

function TrackingTimeBlock({ trackingTime = null, className = "" }) {
  if (!trackingTime || !Object.keys(trackingTime)?.length) {
    return null;
  }

  const mappedTrackingTime = Object.keys(trackingTime)?.reduce((obj, time) => {
    obj[time] = prepareCounter(trackingTime[time]);
    return obj;
  }, {});

  return (
    <div className={cn("flex flex-col", className)}>
      <div className="flex justify-between w-full mb-[22px] border-t-[0.5px] border-Neutral200 pt-3">
        <div className="flex items-center justify-center">
          <CardSubTitle text="Tracking Time" className="mr-[6px]" />
          <TaskBadge number={trackingTime?.notification} className="mr-1" />
          <EntryBadge number={trackingTime?.new_entries} className="mr-1" />
          {trackingTime?.comments && <CommentBadge />}
        </div>
        <ClockIcon width={14} />
      </div>
      <div className="flex items-stretch justify-between w-full mb-1">
        <ul className="flex flex-col justify-between">
          <li className="text-base font-medium text-Neutral800 leading-[20px] tracking-[-0.04em] flex justify-between items-baseline w-[104px] pb-[7px] border-b-[0.5px] border-Neutral300 mb-[9px]">
            <span className="block text-[40px] leading-[100%] text-Neutral700 tracking-[-0.04em]">
              {prepareCounter(mappedTrackingTime?.hours, true)}
            </span>
            <span className="block text-[8px] font-['Noto_Sans'] uppercase text-Neutral600 leading-[11px] tracking-[0.02em]">
              hrs
            </span>
          </li>
          <li className="text-base font-medium text-Neutral800 leading-[20px] tracking-[-0.04em] flex justify-between items-baseline w-[104px] pb-[10px] border-b-[0.5px] border-Neutral300">
            <span className="block text-[40px] leading-[100%] text-Neutral500 tracking-[-0.04em]">
              {prepareCounter(mappedTrackingTime?.minutes, true)}
            </span>
            <span className="block text-[8px] font-['Noto_Sans'] uppercase text-Neutral600 leading-[11px] tracking-[0.02em]">
              mins
            </span>
          </li>
        </ul>
        <div className="w-[112px] flex flex-col items-end justify-start pb-[10px] border-b-[0.5px] border-Neutral300">
          <span className="block text-[80px] text-Neutral700 leading-[100%] font-light tracking-[-0.08em] mb-[3px]">
            {mappedTrackingTime?.entries}
          </span>
          <span className="block font-['Noto_Sans'] uppercase text-Neutral600 font-medium leading-[11px] tracking-[0.02em] text-[8px]">
            No. of entries
          </span>
        </div>
      </div>
    </div>
  );
}

export default TrackingTimeBlock;
