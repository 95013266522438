import { useSelector } from "react-redux";

const useHasWhiteLabelSubscription = () => {
  const loggedInUser = useSelector((state) => state.auth.user);

  return (
    loggedInUser?.brandIdentity &&
    !loggedInUser?.brandIdentity?.is_deleted &&
    loggedInUser?.brandIdentity?.status
  );
};

export default useHasWhiteLabelSubscription;
