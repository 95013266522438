import axios from "axios";
import { StrictMode as ReactStrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import WhiteLabelProvider from "./contexts/WhiteLabelProvider";
import "./index.css";
import setupAxios from "./setupAxios";
import Store from "./store";

setupAxios(axios);

// ReactDOM.render(
//   <ReactStrictMode>
//     <Provider store={Store}>
//       <App />
//     </Provider>
//   </ReactStrictMode>,
//   document.getElementById("root")
// );

// Migrate to React@18
ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={Store}>
      <WhiteLabelProvider>
        <App />
      </WhiteLabelProvider>
    </Provider>
);
