import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { usePopper } from "react-popper";

import { Portal } from "../../Portal";
import TimePicker from "../../TimePicker";

function Dropdown({
  options,
  value,
  onChange,
  placeholder,
  selectedDays,
  category,
  isCustomTimeOpen,
  handleCustomTimeToggle,
  handleAddCustomTime,
  placement = "bottom-start",
}) {
  const [fromTime, setFromTime] = useState("");
  const [toTime, setToTime] = useState("");

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, 6] } }],
  });

  const getCustomTimeError = () => {
    if (fromTime && toTime && fromTime >= toTime) {
      return "End time must be later than start time";
    }
    return null;
  };

  const customTimeError = getCustomTimeError();

  const saveCustomTime = () => {
    const error = getCustomTimeError();
    if (!error) {
      handleAddCustomTime(fromTime, toTime);
      setFromTime("");
      setToTime("");
    }
  };

  const isOptionDisabled = (option) =>
    category === "day" &&
    selectedDays.includes(option.title) &&
    option.title !== value;

  if (isCustomTimeOpen && category === "time") {
    return (
      <div className="flex flex-col">
        <div className="flex items-center justify-between gap-2">
          <TimePicker
            value={fromTime}
            onChange={(time) => setFromTime(time)}
            className="w-32"
          />
          <span>-</span>
          <TimePicker
            value={toTime}
            onChange={(time) => setToTime(time)}
            className="w-32"
          />
        </div>
        {customTimeError && (
          <div className="mt-1 text-xs text-red-500">{customTimeError}</div>
        )}
        <div className="flex justify-end mt-2 space-x-2">
          <button
            type="button"
            className="px-3 py-2 text-sm font-medium rounded text-AccentRegular bg-AccentLight"
            onClick={() => handleCustomTimeToggle(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-5 py-2 text-sm text-white rounded bg-AccentRegular hover:bg-AccentMediumDark"
            onClick={saveCustomTime}
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <Menu.Button
        ref={setReferenceElement}
        className="flex items-center justify-between w-full p-3 text-sm font-medium bg-white border rounded border-Neutral300 text-Neutral800"
      >
        {value.length > 0 ? value : placeholder}
        <svg
          className="absolute right-[20px]"
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
            fill="#5A5A66"
          />
        </svg>
      </Menu.Button>
      <Portal>
        <Transition as={Fragment}>
          <Menu.Items
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-[999999] min-w-60 max-h-[30vh] overflow-y-auto scrollbar-w-4 rounded-md shadow-lg bg-white focus:outline-none px-2 py-2"
          >
            {options.map((option, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <div
                    className={`${
                      active ? "bg-Neutral200" : ""
                    } flex items-center px-2 py-3 cursor-pointer text-sm`}
                    onClick={() =>
                      !isOptionDisabled(option) && onChange(option.title)
                    }
                  >
                    <input
                      type="checkbox"
                      checked={value === option.title}
                      readOnly
                      className="mr-2 accent-[var(--accent-color)]"
                    />
                    <span
                      className={
                        isOptionDisabled(option) ? "text-gray-500" : ""
                      }
                    >
                      {option.title}
                    </span>
                  </div>
                )}
              </Menu.Item>
            ))}
            {category === "time" && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-Neutral200" : "bg-Neutral200"
                    } w-full text-AccentRegular text-sm px-4 py-2`}
                    onClick={() => handleCustomTimeToggle(true)}
                  >
                    Enter custom time
                  </button>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Portal>
    </Menu>
  );
}

export default Dropdown;
