import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { arrayMoveImmutable } from "array-move";
import { Formik } from "formik";
import { debounce } from "lodash";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import * as Yup from "yup";
import Add from "../../assets/Images/Add.svg";
import CheckmarkIcon16 from "../../assets/Images/Checkmark-16.svg";
import CloudLoaderIcon from "../../assets/Images/Cloud-loader.svg";
import CloundSuccess from "../../assets/Images/Cloud-success-green.svg";
import edit from "../../assets/Images/Edit-gray.svg";
import EditlightIcon from "../../assets/Images/Edit-white.svg";
import DeleteQuestionsButton from "../../components/Buttons/DeleteQuestionsButton";
import ClientQuestionnaireLoader from "../../components/ContentLoaders/ClientQuestionnaireLoader";
import DeleteIcon from "../../components/Icons/DeleteIcon";
import { Select } from "../../components/Inputs/CreatableSelect";
import AddQuestionnaireModal from "../../components/Modals/AddQuestionnaireModal";
import QuestionnaireFormPreview from "../../components/Modals/QuestionnaireFormPreview";
import { MODAL_COLORS } from "../../components/Modals/Templates/NewTemplateModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import TagList from "../../components/Templates/TemplateInfo/components/TagList";
import { RemoverUserCategoryAction } from "../../store/actions/authAction";
import {
  UpdateQuestionTemplate,
  UpdateTemplateQuestionnaireQuestion,
  getTemplateById,
} from "../../store/actions/templateAction";
import { getTagColor } from "../../utils/getTagColor";

const Drag = SortableHandle(() => (
  <div className="drags-btn w-6	h-6 flex justify-center items-center absolute top-3 right-3">
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 10C9.55228 10 10 9.55228 10 9C10 8.44772 9.55228 8 9 8C8.44772 8 8 8.44772 8 9C8 9.55228 8.44772 10 9 10Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 17C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15C8.44772 15 8 15.4477 8 16C8 16.5523 8.44772 17 9 17Z"
        fill="#C2C2CC"
        stroke="#C2C2CC"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
));

const SortableItem = SortableElement(
  ({
    tabIndex,
    event,
    handleBlur,
    errors,
    touched,
    questionnaire,
    changeFormValue,
    changeFormStatus,
    setErrors,
    setSubmitting,
  }) => {
    const index = tabIndex;
    return (
      <div
        key={event.questionId}
        className="flex flex-col justify-between w-full max-w-[388px] pt-8 px-8 pb-5 border border-Neutral300 rounded hover:translate-y-1 transition duration-300 ease-in-out relative"
      >
        <Drag />

        {!event.isEdit ? (
          <>
            <p className="text-Neutral600 font-bold text-[10px]">Title</p>
            <h3 className="text-Neutral900 text-sm font-bold pt-1 mb-4 ">
              {event.title}
            </h3>
            <div className="form-overlay relative">
              <p className="text-Neutral600 font-bold text-[10px]">
                Description
              </p>
              <p className="text-Neutral800 text-sm font-normal overflow-y-auto overflow-hidden pt-1 mb-4">
                {event.question}
              </p>
              {event?.type === "radio" || event?.type === "checkbox" ? null : (
                <>
                  <p className="text-Neutral600 font-bold text-[10px]">
                    Placeholder Text
                  </p>
                  <p className="text-Neutral800 italic text-sm font-normal overflow-y-auto overflow-hidden pt-1 mb-6">
                    {event?.placeholder}
                  </p>
                </>
              )}
              {event?.type === "radio" || event?.type === "checkbox" ? (
                <div className="flex flex-col h-[88px] overflow-y-auto overflow-hidden mb-4 pb-[28px]">
                  {event?.[`${event?.type}_options`]?.map((item, idx) => (
                    <div
                      key={idx}
                      className="flex items-center mb-2 text-Neutral800 text-sm font-normal"
                    >
                      <input
                        type={event?.type}
                        id={`title_${index}_${idx}`}
                        className="mr-2"
                        name={`questions[${index}].answer`}
                      />
                      <label htmlFor={`title_${index}_${idx}`}>
                        {item?.name}
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <>
            <p className="text-Neutral600 font-bold text-[10px]">Title</p>
            <input
              type="text"
              id={`title_${index}`}
              focus={event.isEdit}
              className="text-Neutral800 font-medium placeholder:text-sm 5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 h-[48px] px-4 mb-2 text-sm w-full focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              placeholder={`Eg.${event.title}`}
              value={event.clone_title}
              name={`questions[${index}].clone_title`}
              onBlur={handleBlur}
              onChange={(e) =>
                changeFormValue(index, "clone_title", e.target.value)
              }
            />
            {errors?.questions?.[index]?.clone_title &&
              touched.questions?.[index]?.clone_title && (
                <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                  {errors?.questions?.[index]?.clone_title}
                </p>
              )}
            <p className="text-Neutral600 font-bold text-[10px]">Description</p>
            <textarea
              className="text-Neutral800 resize-none font-normal placeholder:text-sm  5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 px-4 mb-2 5xl:h-[116px] h-[84px] overflow-y-auto text-sm w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              placeholder={`${event.placeholder}`}
              value={event.clone_question}
              name={`questions[${index}].clone_question`}
              onBlur={handleBlur}
              onChange={(e) => {
                e.preventDefault();
                changeFormValue(index, "clone_question", e.target.value);
              }}
            />
            {errors?.questions?.[index]?.clone_question &&
              touched.questions?.[index]?.clone_question && (
                <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                  {errors?.questions?.[index]?.clone_question}
                </p>
              )}
            <p className="text-Neutral600 font-bold text-[10px]">
              Placeholder Text
            </p>
            <input
              type="text"
              id={`title_${index}`}
              focus={event.isEdit}
              className="text-Neutral800 italic font-medium placeholder:text-sm 5xl:placeholder:text-lg placeholder:text-Neutral500 py-3 h-[48px] px-4 mb-2 text-sm w-full focus:outline-none border-[1.5px] rounded-md border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
              placeholder={`Eg. clientname@mail.com...`}
              value={event.clone_placeholder}
              name={`questions[${index}].clone_placeholder`}
              onBlur={handleBlur}
              onChange={(e) => {
                changeFormValue(index, "clone_placeholder", e.target.value);
              }}
            />
            {errors?.questions?.[index]?.clone_placeholder &&
              touched.questions?.[index]?.clone_placeholder && (
                <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                  {errors?.questions?.[index]?.clone_placeholder}
                </p>
              )}
            <div className="flex justify-between items-center mb-4">
              <label className="text-Neutral900 font-bold text-xs">
                Required
              </label>
              <label className="toggle-switch flex cursor-pointer select-none items-center">
                <div className="relative">
                  <input
                    type="checkbox"
                    id={`isRequired_${index}`}
                    name={`questions[${index}].clone_isRequired`}
                    className="sr-only"
                    checked={event.clone_isRequired}
                    onChange={(e) =>
                      changeFormValue(
                        index,
                        "clone_isRequired",
                        e.target.checked
                      )
                    }
                  />
                  <div className="block h-[20px] switch w-[40px] rounded-full border-Neutral400 border-2 bg-Neutral100"></div>
                  <div className="dot absolute left-0 top-0 h-5 w-5 rounded-full bg-Neutral800 transition shadow-[0px_1.5px_3px_1px_rgba(0,0,0,0.2)]"></div>
                </div>
              </label>
            </div>
            {event?.type === "radio" || event?.type === "checkbox" ? (
              <div className="flex flex-col h-[88px] overflow-y-auto overflow-hidden mb-4 pb-[28px] scrollbar-w-4">
                <label className="text-Neutral900 font-bold text-xs">
                  {event?.type?.charAt(0).toUpperCase() + event?.type?.slice(1)}{" "}
                  Options
                </label>
                {event?.[`clone_${event?.type}_options`]?.map((item, idx) => (
                  <div key={idx} className="flex w-full">
                    <input
                      type="text"
                      className="text-Neutral800 font-medium placeholder:text-Neutral500 py-2 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition mb-2"
                      name={`questions[${index}].clone_${event?.type}_options[${idx}].name`}
                      value={item?.name}
                      onChange={(e) => {
                        const cloneOptions =
                          event?.[`clone_${event?.type}_options`];
                        cloneOptions[idx].name = e.target.value;
                        cloneOptions[idx].value = `${e.target.value}-${idx}`;
                        changeFormValue(
                          index,
                          `clone_${event?.type}_options`,
                          cloneOptions
                        );
                      }}
                    />
                    {errors?.questions?.[index]?.[
                      `clone_${event?.type}_options`
                    ] &&
                      touched.questions?.[index]?.[
                        `clone_${event?.type}_options`
                      ] && (
                        <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                          {
                            errors?.questions?.[index]?.[
                              `clone_${event?.type}_options`
                            ]
                          }
                        </p>
                      )}
                    {/* delete button */}
                    <div title="delete option">
                      <button
                        className="text-Neutral800 font-medium placeholder:text-Neutral500 py-3 px-2 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full focus:outline-none focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition"
                        onClick={() => {
                          const cloneOptions =
                            event?.[`clone_${event?.type}_options`];
                          cloneOptions.splice(idx, 1);
                          changeFormValue(
                            index,
                            `clone_${event?.type}_options`,
                            cloneOptions
                          );
                        }}
                      >
                        <span className="relative z-10">
                          <DeleteIcon color="red" />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
                {/* Add button */}
                <button
                  className="text-AccentRegular flex items-center justify-center text-xs rounded h-8 focus-visible:outline-purple font-semibold transition duration-300 mx-auto py-2"
                  type="button"
                  onClick={() => {
                    const cloneOptions =
                      event?.[`clone_${event?.type}_options`];
                    cloneOptions.push({ name: "", value: "" });
                    changeFormValue(
                      index,
                      `clone_${event?.type}_options`,
                      cloneOptions
                    );
                  }}
                >
                  + Add Options
                </button>
              </div>
            ) : null}
          </>
        )}

        <div className="flex justify-between">
          <div className="flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[16px] 5xl:text-[22px] text-Neutral800">
            {index + 1}
          </div>
          {!event.isEdit && (
            <div className="flex items-center">
              <DeleteQuestionsButton
                questionId={event?._id}
                questionnaireID={questionnaire?._id}
                is_template={true}
              />
              <button
                className="text-Neutral100 bg-AccentRegular flex items-center justify-center text-xs rounded ml-3 h-8 5xl:w-[100px] w-[80px] focus-visible:outline-purple font-semibold hover:bg-AccentMediumDark transition duration-300"
                type="button"
                onClick={() => changeFormStatus(index, "edit", event._id)}
              >
                Edit
                <img className="ml-1" src={EditlightIcon} alt="" />
              </button>
            </div>
          )}
          {event.isEdit && (
            <div className="flex justify-between relative bottom-0">
              <div className="flex items-center">
                <button
                  className="text-AccentDark bg-AccentLight flex items-center justify-center text-xs rounded h-8 5xl:w-[100px] w-[80px] border border-Neutral300 focus-visible:outline-none focus:outline focus:outline-4 focus:outline-AccentMediumLight font-semibold hover:bg-AccentMediumLight transition duration-300"
                  type="button"
                  onClick={() => changeFormStatus(index, "cancel", event._id)}
                >
                  Cancel
                </button>
                <button
                  className="btn button-hover large-primary-btn text-xs rounded ml-3 h-8 5xl:w-[100px] w-[80px] text-Neutral100 bg-AccentRegular drop-shadow-Texts focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0 transition duration-300 ease-in-out flex items-center justify-center"
                  type="save"
                  disabled={
                    errors?.questions?.[index]?.clone_question ||
                    errors?.questions?.[index]?.clone_placeholder ||
                    errors?.questions?.[index]?.clone_title
                  }
                  onClick={() => {
                    changeFormStatus(
                      index,
                      "save",
                      event._id,
                      setSubmitting,
                      setErrors
                    );
                  }}
                >
                  Save
                  <img className="ml-1" src={CheckmarkIcon16} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    formQuestions,
    handleBlur,
    errors,
    touched,
    setSubmitting,
    setErrors,
    changeFormValue,
    changeFormStatus,
    handleChange,
    setIsOpenQuestionnaire,
    questionnaire,
  }) => {
    return (
      <div className="flex flex-wrap justify-start items-center w-[100%] gap-10 mb-20 md:mb-10">
        {formQuestions?.map((event, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              tabIndex={index}
              event={event}
              changeFormValue={changeFormValue}
              changeFormStatus={changeFormStatus}
              handleBlur={handleBlur}
              errors={errors}
              questionnaire={questionnaire}
              touched={touched}
              handleChange={handleChange}
              setErrors={setErrors}
              setSubmitting={setSubmitting}
            />
          );
        })}
        <div
          className="flex flex-col justify-center items-center w-full max-w-[388px] h-[308px] pt-8 px-8 pb-5 border border-AccentRegular rounded  border-dashed cursor-pointer hover:border-Neutral800 transition duration-300 ease-in-out"
          onClick={() => setIsOpenQuestionnaire(true)}
        >
          <img className="w-[24px]" src={Add} alt="" />
        </div>
      </div>
    );
  }
);

const RequestInfoSchema = Yup.object().shape({
  title: Yup.string().required("Form Title Required"),
  category_tags: Yup.array().required("Category Tags Required"),
  questions: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title required").nullable(),
      question: Yup.string().required("Question required").nullable(),
      isEdit: Yup.string(),
      clone_title: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Title required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      clone_question: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Question required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      clone_placeholder: Yup.string().when("isEdit", {
        is: "edit",
        then: Yup.string().required("Placeholder required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
});

function QuestionnaireBuild() {
  const dispatch = useDispatch();
  const params = useParams();
  const questionnaire = useSelector(
    (state) => state.template.template.question
  );
  const user = useSelector((state) => state.auth.user);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [formQuestions, setformQuestions] = useState(questionnaire?.questions);
  const [isOpenQuestionnaire, setIsOpenQuestionnaire] = useState(false);
  const [categoryTags, setCategoryTags] = useState([]);
  const modalColor = MODAL_COLORS["Questionnaire"];
  const [modalIsOpen, setIsOpen] = useState(false);
  const type = "Questionnaire";

  const getServerProposalConnent = async () => {
    dispatch(getTemplateById({ type: "Questionnaire", id: params.id }));
  };

  useEffect(() => {
    getServerProposalConnent();
  }, [dispatch]);

  useEffect(() => {
    if (questionnaire?._id) {
      let DBQuestoins = [];
      questionnaire?.questions?.map((item) => {
        DBQuestoins.push({
          _id: item._id,
          placeholder: item.placeholder,
          title: item.title,
          type: item.type,
          question: item.question,
          isEdit: false,
          isRequired: item.is_required,
          radio_options: item.radio_options,
          checkbox_options: item.checkbox_options,
        });
      });
      setformQuestions(DBQuestoins);
    }
  }, [questionnaire]);

  useEffect(() => {
    let tags = [];

    if (user?.category?.length) {
      const category = user?.category?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      tags = [...tags, ...category];
    }

    tags = tags?.reduce((acc, current) => {
      const x = acc.find(
        (item) => item?.value?.toLowerCase() === current?.value?.toLowerCase()
      );
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const optionsWithColors = tags?.map((option, i) => {
      const { bgColor: optionColor, text: optionText } = getTagColor(
        modalColor,
        i,
        true
      );

      const { bgColor: labelColor, text: labelText } = getTagColor(
        modalColor,
        i,
        true
      );

      return {
        ...option,
        color: optionColor,
        text: optionText,
        labelColor,
        labelText,
      };
    });

    setCategoryTags(optionsWithColors);
  }, [user]);

  const handleRemove = async (data) => {
    const removedCategory = await dispatch(RemoverUserCategoryAction(data));

    if (removedCategory?.data) {
      setCategoryTags(categoryTags.filter((e) => e !== data));
    }
  };

  const changeFormValue = (index, field, value) => {
    let formClone = formQuestions;
    formClone[index][field] = value;
    setformQuestions([...formClone]);
  };

  const changeFormStatus = (index, status, id, setSubmitting, setErrors) => {
    if (status === "edit") {
      let formClone = formQuestions;
      formClone[index].clone_title = formClone[index].title;
      formClone[index].clone_question = formClone[index].question;
      formClone[index].clone_placeholder = formClone[index].placeholder;
      formClone[index].isEdit = status;
      formClone[index].clone_isRequired = formClone[index].isRequired;
      formClone[index].clone_radio_options = formClone[index].radio_options;
      formClone[index].clone_checkbox_options =
        formClone[index].checkbox_options;
      setformQuestions([...formClone]);
      const ele = document.getElementById(`title_${index}`);
      if (ele) {
        ele.focus();
      }
      return;
    }
    if (status === "cancel") {
      let formClone = formQuestions;
      formClone[index].clone_title = null;
      formClone[index].clone_question = null;
      formClone[index].clone_placeholder = null;
      formClone[index].isEdit = false;
      formClone[index].clone_isRequired = null;
      formClone[index].clone_radio_options = null;
      formClone[index].clone_checkbox_options = null;
      setformQuestions([...formClone]);
      return;
    }
    if (status === "save") {
      setSubmitting(true);
      let formClone = formQuestions;
      formClone[index].title = formClone[index].clone_title;
      formClone[index].question = formClone[index].clone_question;
      formClone[index].placeholder = formClone[index].clone_placeholder;
      formClone[index].clone_title = null;
      formClone[index].clone_question = null;
      formClone[index].clone_placeholder = null;
      formClone[index].isEdit = false;
      formClone[index].isRequired = formClone[index].clone_isRequired;
      formClone[index].radio_options = formClone[index].clone_radio_options;
      formClone[index].checkbox_options =
        formClone[index].clone_checkbox_options;
      formClone[index].clone_isRequired = null;
      formClone[index].clone_radio_options = null;
      formClone[index].clone_checkbox_options = null;
      setformQuestions([...formClone]);
      const question_id = id;

      dispatch(
        UpdateTemplateQuestionnaireQuestion(
          {
            placeholder: formClone[index]?.placeholder,
            question: formClone[index]?.question,
            title: formClone[index]?.title,
            question_id: question_id,
            questionnaire_id: params.id,
            isRequired: formClone[index]?.isRequired,
            radio_options: formClone[index]?.radio_options,
            checkbox_options: formClone[index]?.checkbox_options,
          },
          setSubmitting,
          setErrors
        )
      );
      return;
    }
  };

  function setModal(value) {
    setIsOpen(value);
  }

  const handlePreview = async () => {
    await getServerProposalConnent();
    setModal(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }, setSubmitting, setErrors) => {
    setSubmitting(true);
    const changeOrder = arrayMoveImmutable(formQuestions, oldIndex, newIndex);
    setformQuestions(changeOrder);
    dispatch(
      UpdateQuestionTemplate(
        {
          questionnaireId: params.id,
          type,
          title: questionnaire?.data?.title,
          questions: changeOrder,
          category: questionnaire?.data?.category,
        },
        setSubmitting,
        setErrors
      )
    );
  };

  return (
    <>
      <div>
        <AdminNavbar
          link={`/admin/templates`}
          title="Questionnaire Builder"
          backLink={"Go Back to Templates Dashboard"}
        />
        {!questionnaire?.questions || !questionnaire ? (
          <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
            <ClientQuestionnaireLoader />
          </div>
        ) : (
          <>
            <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
              <Formik
                initialValues={{
                  title: questionnaire?.title ? questionnaire?.title : "",
                  questions: formQuestions,
                  category_tags: questionnaire?.category
                    ? questionnaire?.category?.map((option, i) => {
                        const { bgColor: optionColor, text: optionText } =
                          getTagColor(modalColor, i, true);

                        const { bgColor: labelColor, text: labelText } =
                          getTagColor(modalColor, i, true);
                        return {
                          value: option?.name,
                          label: option?.name,
                          color: optionColor,
                          text: optionText,
                          labelColor,
                          labelText,
                        };
                      })
                    : [],
                }}
                enableReinitialize
                validationSchema={RequestInfoSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                  setSubmitting(true);
                  if (questionnaire?._id) {
                    const category = values?.category_tags?.map((item) => {
                      return {
                        name: item.value,
                        color: {
                          color: item.color,
                          text: item.text,
                          labelColor: item.labelColor,
                          labelText: item.labelText,
                        },
                      };
                    });

                    dispatch(
                      UpdateQuestionTemplate(
                        {
                          questionnaireId: params.id,
                          type,
                          question: values.questions.map((item) => ({
                            question: item.question,
                            title: item.title,
                            type: item.type,
                            placeholder: item.placeholder,
                            _id: item._id,
                          })),
                          title: values?.title,
                          category: category,
                        },
                        setSubmitting,
                        setErrors
                      )
                    );
                    setIsEditCategory(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  resetForm,
                  setErrors,
                  setSubmitting,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="pt-4 md:pt-12 pb-6">
                      <div className="flex justify-between items-center">
                        <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em]">
                          FORM TITLE
                        </h5>
                      </div>
                      <div className="flex mt-2 sm:mt-4 mb-2 flex-col sm:flex-row">
                        <div className="w-full 5xl:max-w-[400px] max-w-[320px] mb-6 sm:mb-0">
                          <input
                            type="text"
                            className={`text-Neutral800 font-medium placeholder:text-sm placeholder:text-Neutral500 5xl:placeholder:text-lg py-3 px-4 text-sm leading-5 w-full focus:outline-none border-[1.5px] rounded border-Neutral300 hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition
                        ${
                          errors.title && touched.title
                            ? "border-Red400 focus:border-Red400 focus:drop-shadow-Errorboxshadow"
                            : ""
                        }`}
                            name="title"
                            value={values.title}
                            placeholder="Eg. Info Request"
                            onChange={handleChange}
                            onBlur={debounce((e) => {
                              handleBlur(e);
                              if (
                                e.target.value !== questionnaire?.title &&
                                e.target.value !== ""
                              ) {
                                handleSubmit();
                              }
                            })}
                          />
                          {errors.title && touched.title && (
                            <p className="mt-2 text-xs text-Red400 font-medium focus-visible:outline-none">
                              {errors.title}
                            </p>
                          )}
                          <p className="font-medium text-xs mt-2 text-Neutral700 ml-1">
                            This will be the header of the questionnaire.{" "}
                          </p>
                        </div>
                        <div className="w-[234px] flex flex-col gap-2 ml-0 sm:ml-4">
                          <div className="flex gap-2 w-full">
                            <button
                              type="button"
                              onClick={(e) => {
                                resetForm();
                                setTimeout(() => {
                                  setformQuestions([...questionnaire]);
                                }, 200);
                              }}
                              className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                            >
                              RESET
                            </button>
                            <button
                              type="button"
                              onClick={() => handlePreview()}
                              className="px-6	py-3 h-[48px] bg-Neutral200 rounded flex justify-center items-center grow text-AccentRegular text-[12px] text-center font-bold	focus:outline focus:outline-8 focus:outline-none focus-visible:outline-0 transition duration-300 ease-in-out hover:bg-Neutral400"
                            >
                              PREVIEW
                            </button>
                          </div>
                          <div className="w-full">
                            {isSubmitting ? (
                              <span className="text-[#757580] text-xs font-semibold leading-4 ml-2.5 flex items-center">
                                <img
                                  src={CloudLoaderIcon}
                                  alt=""
                                  className="mr-1 w-[35px]"
                                />
                                Saving...
                              </span>
                            ) : (
                              <span className="text-Green500 text-xs font-semibold leading-4 ml-2.5 flex items-center">
                                <img
                                  src={CloundSuccess}
                                  alt=""
                                  className="mr-1 w-[35px]"
                                />
                                Saved
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="pt-[36px]">
                        <div className="flex justify-between items-center max-w-[135px]">
                          <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em] uppercase">
                            category tags
                          </h5>
                          <Menu
                            as="div"
                            className="relative flex justify-center items-center"
                          >
                            <Menu.Button className="focus-visible:outline-none popup-btn relative w-full">
                              <button
                                type="button"
                                className=" text-center client-btn-hover cursor-pointer flex justify-center items-center"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.9287 12.4988C12.2049 12.4988 12.4287 12.2749 12.4287 11.9988C12.4287 11.7226 12.2049 11.4988 11.9287 11.4988C11.6526 11.4988 11.4287 11.7226 11.4287 11.9988C11.4287 12.2749 11.6526 12.4988 11.9287 12.4988Z"
                                    fill="#9C9CA6"
                                    stroke="#9C9CA6"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M11.9286 8.9989C12.2047 8.9989 12.4286 8.77504 12.4286 8.4989C12.4286 8.22276 12.2047 7.9989 11.9286 7.9989C11.6524 7.9989 11.4286 8.22276 11.4286 8.4989C11.4286 8.77504 11.6524 8.9989 11.9286 8.9989Z"
                                    fill="#9C9CA6"
                                    stroke="#9C9CA6"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M11.9287 15.9988C12.2049 15.9988 12.4287 15.7749 12.4287 15.4988C12.4287 15.2226 12.2049 14.9988 11.9287 14.9988C11.6526 14.9988 11.4287 15.2226 11.4287 15.4988C11.4287 15.7749 11.6526 15.9988 11.9287 15.9988Z"
                                    fill="#9C9CA6"
                                    stroke="#9C9CA6"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="origin-top-right z-20 absolute -right-[3px] w-[100px] rounded-lg client-edite-view-shadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none px-2 top-0 pt-2 pb-2 hover:bg-Neutral200 cursor-pointer transition-all duration-300 ease-in-out">
                                <div
                                  className="mx-2"
                                  onClick={() => setIsEditCategory(true)}
                                >
                                  <p className="flex">
                                    <img src={edit} alt="" />{" "}
                                    <span className="px-2 text-xs text-Neutral900">
                                      Edit
                                    </span>
                                  </p>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                        <div className="max-w-[300px] pt-[6px]">
                          {isEditCategory ? (
                            <div className="mt-2">
                              <Select
                                id="category_tags"
                                name="category_tags"
                                isMulti
                                isSearchable
                                isClearable={false}
                                options={categoryTags}
                                value={values.category_tags}
                                handleRemove={handleRemove}
                                onBlur={debounce((e) => {
                                  handleBlur(e);
                                  if (
                                    JSON.stringify(
                                      values.category_tags?.map(
                                        (ele) => ele?.value
                                      )
                                    ) !==
                                    JSON.stringify(
                                      questionnaire?.category.map(
                                        (ele) => ele?.name
                                      )
                                    )
                                  ) {
                                    handleSubmit();
                                  }
                                  setIsEditCategory(false);
                                }, 1000)}
                                {...{ setFieldValue, modalColor }}
                              />
                              {errors.category_tags &&
                                touched.category_tags && (
                                  <p className="mt-1 text-xs text-Red400 font-medium focus-visible:outline-none absolute">
                                    {errors.category_tags}
                                  </p>
                                )}
                            </div>
                          ) : (
                            <TagList
                              color={"Blue"}
                              tags={questionnaire?.category?.map(
                                (ele) => ele?.name
                              )}
                            />
                          )}
                        </div>
                      </div>
                      <div className="pt-[60px]">
                        <h5 className="text-Neutral700 text-xs font-semibold tracking-[0.08em]">
                          FORM QUESTIONS
                        </h5>
                        <div className="mt-6 h-[480px]">
                          <div className="flex justify-between w-full max-w-[816px] flex-wrap">
                            <SortableList
                              formQuestions={formQuestions}
                              onSortEnd={({ oldIndex, newIndex }) => {
                                onSortEnd(
                                  { oldIndex, newIndex },
                                  setSubmitting,
                                  setErrors
                                );
                              }}
                              handleBlur={handleBlur}
                              errors={errors}
                              handleChange={handleChange}
                              changeFormStatus={changeFormStatus}
                              changeFormValue={changeFormValue}
                              touched={touched}
                              setSubmitting={setSubmitting}
                              setErrors={setErrors}
                              questionnaire={questionnaire}
                              axis="xy"
                              useDragHandle
                              helperClass="questionnaire-wrapper drag-btn"
                              setIsOpenQuestionnaire={setIsOpenQuestionnaire}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
              <QuestionnaireFormPreview
                modalIsOpen={modalIsOpen}
                setModal={setModal}
                preview={true}
              />
              <AddQuestionnaireModal
                isOpenQuestionnaire={isOpenQuestionnaire}
                setIsOpenQuestionnaire={setIsOpenQuestionnaire}
                questionnaire={questionnaire}
                formQuestions={formQuestions}
                setformQuestions={setformQuestions}
                is_template={true}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default QuestionnaireBuild;
