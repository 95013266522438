import React, { useRef } from "react";
import ImageUploadings from "./ImageUploadings";
import AppEditor from "../FroalaEditor/AppEditor";

const PublicFroalaBuilderComp = (props) => {
  const { textEditor, editor, isLoading, setIsLoading, type } =
    props;
  const pdfComponentRef = useRef(null);
  return (
    <div className={`Proposal-Builder mb-32`} id="public-proposal-editor">
      <div className={`relativeCSS m-auto`} ref={pdfComponentRef}>
        {textEditor?.images?.key || textEditor?.images?.url ? (
          <ImageUploadings textEditor={textEditor} />
        ) : null}
        <AppEditor
          mode="ghost"
          editor={editor}
          model={textEditor?.text}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          textEditor={textEditor}
          type={type}
        />
      </div>
    </div>
  );
};

export default PublicFroalaBuilderComp;
