import axios from "axios";
import { toast } from "react-toastify";

export const getContactList = (user) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `api/v1/contacts/get-contacts`,
        { user: user },
        { headers: { user_type: "user" } }
      );

      dispatch({
        type: "GET_CONTACT_LIST",
        payload: response.data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch({
        type: "GET_CONTACT_ERROR",
        payload: error.response.data,
      });
    }
  };
};

export const addContactAction = (values, setErrors, setSubmitting) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`api/v1/contacts/add-contact`, values);
      dispatch({
        type: "CREATE_CONTACT_SUCCESS",
        payload: response.data.data,
      });
      setSubmitting(false);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let err = {};
        error.response.data.errors?.forEach((e) => {
          err[e.param] = e.msg;
        });
        setErrors(err);
      } else {
        console.error("Error:", error.message);
      }
      setSubmitting(false);
    }
  };
};

export const deleteContact = (contact_id, setOpen) => {
  return async (dispatch) => {
    try {
      await axios.delete(`api/v1/contacts/delete-contact/${contact_id}`);

      dispatch({
        type: "DELETE_CONTACT_SUCCESS",
        payload: contact_id,
      });
      return { success: true };
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg || "An error occurred");
      setOpen(false);
      //   throw new Error(error?.response?.data?.errors[0]?.msg);
    }
  };
};
