import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  UpdateProjectAction
} from "../../store/actions/projectAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const ProjectDescriptionModal = ({ setNotIsOpen, notIsOpen, projectDetails }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  useEffect(() => {
    if (projectDetails?.description) {
      setValue(projectDetails?.description);
    } else {
      setValue("");
    }
  }, [projectDetails]);

  const handleSubmit = () => {
    if (projectDetails?._id && value !== projectDetails?.description) {
        const formData = new FormData();
        formData.append("title", projectDetails?.title);
        projectDetails?.category?.forEach((item, index) =>
            formData.append(`category[${index}]`, item)
        );
        formData.append("profile", projectDetails?.profile);
        formData.append("id", projectDetails?._id);
        formData.append("description", value);
        formData.append("due_date", !projectDetails?.due_date ? "" : projectDetails?.due_date);
        formData.append("budget", !projectDetails?.budget ? "" : projectDetails?.budget);
        formData.append("currency", !projectDetails?.currency?.name ? "" : JSON.stringify(projectDetails?.currency));
        dispatch(UpdateProjectAction(formData));
        setNotIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={notIsOpen}
      onRequestClose={setNotIsOpen}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white p-7 rounded-[8px]">
          <div className="relative">
            <textarea
              rows="24"
              cols="48"
              className="mb-16 caret-AccentRegular w-full h-full focus:outline-none placeholder:text-sm placeholder:text-Neutral500  placeholder:font-medium font-medium text-Neutral800 text-sm"
              placeholder="Add Description..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <div className="bg-white -mt-12 flex items-center relative">
              <button
                onClick={handleSubmit}
                disabled={value == projectDetails?.description}
                className={`z-10 btn form-primary-btn relative text-sm text-center block text-Neutral100  font-bold w-1/2 py-3 mr-[6px] rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out shadow-none ${value == projectDetails?.description ? 'disabled bg-AccentMediumLight' : 'bg-AccentRegular button-hover'}`}
              >
                <span className="relative z-10">Save</span>
              </button>
              <button
                onClick={() => {
                  setNotIsOpen(false);
                  setValue(projectDetails?.description);
                }}
                className="z-10 text-Neutral800 bg-Neutral300 hover:bg-Neutral400 text-sm font-semibold rounded flex items-center justify-center py-3 ml-[6px] w-1/2 focus-visible:outline-none focus:outline focus:outline-8 focus:outline-AccentMediumLight transition duration-300"
               >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setNotIsOpen(false);
            setValue(projectDetails?.description);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default ProjectDescriptionModal;
