const MenuIcon = ({ color = "currentColor" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9287 12.4988C12.2049 12.4988 12.4287 12.2749 12.4287 11.9988C12.4287 11.7226 12.2049 11.4988 11.9287 11.4988C11.6526 11.4988 11.4287 11.7226 11.4287 11.9988C11.4287 12.2749 11.6526 12.4988 11.9287 12.4988Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9286 8.9989C12.2047 8.9989 12.4286 8.77504 12.4286 8.4989C12.4286 8.22276 12.2047 7.9989 11.9286 7.9989C11.6524 7.9989 11.4286 8.22276 11.4286 8.4989C11.4286 8.77504 11.6524 8.9989 11.9286 8.9989Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9287 15.9988C12.2049 15.9988 12.4287 15.7749 12.4287 15.4988C12.4287 15.2226 12.2049 14.9988 11.9287 14.9988C11.6526 14.9988 11.4287 15.2226 11.4287 15.4988C11.4287 15.7749 11.6526 15.9988 11.9287 15.9988Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default MenuIcon;
