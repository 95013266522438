import axios from "axios";
import { toast } from "react-toastify";

export const GetLeadsAction = (is_archived, setIsLoading) => {
  return async (dispatch) => {
    const { data } = await axios.get(`api/v1/lead/get-list-of-leads?is_archived=${is_archived}`);
    if (setIsLoading) setIsLoading(false);
    return dispatch({
      type: "SET_LEADS",
      payload: {
        isLoading: false,
        is_called: true,
        data: data.data,
        is_archived,
      },
    });
  };
};

export const GetLeadDetailAction = (leadId, questionnaireId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `api/v1/questionnaire/get-lead-questionnaire/${leadId}/${questionnaireId}`
      );
      return dispatch({
        type: "SET_LEAD_DETAIL",
        payload: { isLoading: false, isUpdating: false, data: data.data },
      });
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const createLeadAction = (values, setSubmitting, setErrors, setIsOpen) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.post(`api/v1/lead/create-lead`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSubmitting(false);
      setIsOpen(false);
      toast.success(response?.message);
      return dispatch({
        type: "CREATE_LEAD_SUCCESS",
        payload: { isLoading: false, data: response.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setSubmitting(false);
        return dispatch({
          type: "CREATE_LEAD_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_LEAD_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateLeadAction = (values, setSubmitting, setErrors, setIsOpen) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.post(`api/v1/lead/update-lead`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (setSubmitting) setSubmitting(false);
      if (setIsOpen) setIsOpen(false);
      return dispatch({
        type: "UPDATE_LEAD_SUCCESS",
        payload: { isLoading: false, data: response.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        if (setErrors) setErrors(err);
        if (setSubmitting) setSubmitting(false);
        return dispatch({
          type: "UPDATE_LEAD_ERROR",
          payload: error,
        });
      } else {
        if (setSubmitting) setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_LEAD_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateLeadFollowUpAction = (id, date) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.post(`api/v1/lead/reminder-lead`, {
        id: id,
        date: date,
      });
      return dispatch({
        type: "UPDATE_LEAD_SUCCESS",
        payload: { isLoading: false, data: response.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "UPDATE_LEAD_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_LEAD_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const deleteLeadAction = (id) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.delete(`api/v1/lead/delete-lead/${id}`);
      return dispatch({
        type: "DELETE_LEAD_SUCCESS",
        payload: { isLoading: false, data: response.data, id },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "DELETE_LEAD_ERROR",
        payload: error,
      });
    }
  };
};

export const createLeadColumnAction = (values, navigate, setIsLoaded, setIsOpen) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.post(`api/v1/user/add-lead-column`, values);
      toast.success(response?.message);
      dispatch({
        type: "CREATE_LEAD_COLUMN_SUCCESS",
        payload: { isLoading: false, data: response.data },
      });
      if (values?.old_column) {
        if (values?.old_column !== values?.column.name) {
          dispatch(GetLeadsAction());
        }
        setIsLoaded(false);
        setIsOpen(false);
      }
      navigate(``, {
        state: response.data,
        replace: true,
      });
      return;
    } catch (error) {
      if (values?.old_column) {
        setIsLoaded(false);
        setIsOpen(false);
      }
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "CREATE_LEAD_COLUMN_ERROR",
        payload: error,
      });
    }
  };
};

export const updateLeadColumnPositionAction = (values, navigate) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.post(
        `api/v1/user/update-lead-column-position`,
        values
      );
      dispatch({
        type: "UPDATE_LEAD_COLUMN_POSITION_SUCCESS",
        payload: { isLoading: false, data: response.data },
      });
      navigate(``, {
        state: response.data,
        replace: true,
      });
      return;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "UPDATE_LEAD_COLUMN_POSITION_ERROR",
        payload: error,
      });
    }
  };
};

export const removeLeadColumnAction = (values) => {
  return async (dispatch) => {
    try {
      const { data: response } = await axios.post(`api/v1/user/remove-lead-column`, values);
      toast.success(response?.message);
      return dispatch({
        type: "REMOVE_LEAD_COLUMN_SUCCESS",
        payload: { isLoading: false, data: response.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "REMOVE_LEAD_COLUMN_ERROR",
        payload: error,
      });
    }
  };
};

export const updateLeadColumnAction = (values, update_store, old_data) => {
  return async (dispatch) => {
    try {
      await axios.put(`api/v1/lead/update-lead-column`, values);
      return dispatch({
        type: "UPDATE_LEAD_COLUMN_WITH_POSITION_SUCCESS",
        payload: { isLoading: false, data: update_store },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_LEAD_COLUMN_ABORT",
        payload: { isLoading: false, data: old_data },
      });
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "UPDATE_TASK_ERROR",
          payload: error,
        });
      } else {
        return dispatch({
          type: "UPDATE_TASK_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const addNoteToLeadAction = (values, setSubmitting, setClose) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/add-note-to-lead`, values);
      setSubmitting(false);
      setClose(false);
      toast.success(data?.message);
      return dispatch({
        type: "ADD_NOTE_TO_LEAD_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        return dispatch({
          type: "CREATE_TASK_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_TASK_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateNoteToLeadAction = (values, setSubmitting, setClose) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/update-note-to-lead`, values);
      setSubmitting(false);
      setClose(false);
      toast.success(data?.message);
      return dispatch({
        type: "UPDATE_NOTE_TO_LEAD_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setSubmitting(false);
        return dispatch({
          type: "UPDATE_NOTE_TO_LEAD_ERROR",
          payload: error,
        });
      } else {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_NOTE_TO_LEAD_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const deleteNoteFromLeadAction = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/delete-note-from-lead`, values);
      toast.success(data?.message);
      return dispatch({
        type: "DELETE_NOTE_TO_LEAD_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "DELETE_NOTE_TO_LEAD_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_NOTE_TO_LEAD_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const updateColumnNameAction = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/update-column-name`, values);
      return dispatch({
        type: "UPDATE_COLUMN_NAME_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "UPDATE_COLUMN_NAME_ERROR",
        payload: error,
      });
    }
  };
};

export const updateLeadSourceAction = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/update-lead-source`, values);
      return dispatch({
        type: "UPDATE_COLUMN_NAME_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "UPDATE_LEAD_SOURCE_ERROR",
        payload: error,
      });
    }
  };
};

export const convertLeadToClientAction = (values, setConverted, setIsLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/convert-lead-to-client`, values);
      if (setConverted) setConverted(true);
      setIsLoading(false);
      toast.success(data?.message);
      return dispatch({
        type: "CONVERT_LEAD_TO_CLIENT_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (setConverted) setConverted(false);
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "CONVERT_LEAD_TO_CLIENT_ERROR",
        payload: error,
      });
    }
  };
};

export const updateProposalTitleAction = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/update-proposal-title`, values);
      return dispatch({
        type: "UPDATE_PROPOSAL_TITLE_SUCCESS",
        payload: { isLoading: false, data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        return dispatch({
          type: "UPDATE_PROPOSAL_TITLE_ERROR",
          payload: error,
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "UPDATE_PROPOSAL_TITLE_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const deleteArchivedLeadAction = (id, setIsLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/delete-archived-lead`, {
        id,
      });
      setIsLoading(null);
      return dispatch({
        type: "DELETE_ARCHIVED_LEAD_SUCCESS",
        payload: { isLoading: false, data: data.data, id },
      });
    } catch (error) {
      setIsLoading(null);
      toast.error(error?.response?.data?.message);
      return dispatch({
        type: "DELETE_ARCHIVED_LEAD_ERROR",
        payload: error,
      });
    }
  };
};

export const ImportLeadsAction = (
  previewData,
  setisSubmitting,
  setPreviewData,
  setIsPreviewModalOpen,
  setErrors
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`api/v1/lead/import-leads-from-csv`, previewData);
      setisSubmitting(false);
      setPreviewData([]);
      setIsPreviewModalOpen(false);
      dispatch(GetLeadsAction());
      toast.success(data?.message);
      return dispatch({
        type: "IMPORT_LEADS_SUCCESS",
        payload: {},
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setErrors(err);
        setisSubmitting(false);
        return dispatch({
          type: "CREATE_LEAD_ERROR",
          payload: error,
        });
      } else {
        setisSubmitting(false);
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "CREATE_LEAD_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const CreateLeadCommentActions = (
  value,
  setLoading,
  setComment,
  EditorState,
  setAttachments
) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("api/v1/lead-comment/create-lead-comment", value);
      setLoading(false);
      setComment(EditorState.createEmpty());
      setAttachments([]);
      return dispatch({
        type: "CREATE_LEAD_COMMENT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setLoading(false);
        return dispatch({
          type: "CREATE_LEAD_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setLoading(false);
        return dispatch({
          type: "CREATE_LEAD_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const UpdateLeadCommentActions = (value, setIsEdit, setIsLoading) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put("api/v1/lead-comment/update-lead-comment", value);
      setIsEdit(false);
      setIsLoading(false);
      return dispatch({
        type: "UPDATE_LEAD_COMMENT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        let err = {};
        error.response.data.errors.forEach((element) => {
          err[element.param] = element.msg;
        });
        setIsLoading(false);
        return dispatch({
          type: "UPDATE_LEAD_COMMENT_ERROR",
          payload: error,
        });
      } else {
        setIsLoading(false);
        return dispatch({
          type: "UPDATE_LEAD_COMMENT_ERROR",
          payload: error,
        });
      }
    }
  };
};

export const DeleteLeadCommentAction = (id, lead_id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.delete(
        `api/v1/lead-comment/delete-lead-comment?id=${id}&lead_id=${lead_id}`
      );
      return dispatch({
        type: "DELETE_LEAD_COMMENT_SUCCESS",
        payload: { data: data.data },
      });
    } catch (error) {
      if (error.response.status === 422) {
        error.response.data.errors.forEach((element) => {
          toast.error(element.msg);
        });
        return dispatch({
          type: "DELETE_LEAD_COMMENT_ERROR",
          payload: { error },
        });
      } else {
        toast.error(error?.response?.data?.message);
        return dispatch({
          type: "DELETE_LEAD_COMMENT_ERROR",
          payload: { error },
        });
      }
    }
  };
};
