import React from "react";

const FolderIcon = ({
  width = 18,
  color = "var(--accent-color)",
  className = "",
}) => {
  return (
    <svg
      width={width}
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.9524 13.4444C16.9524 13.857 16.7815 14.2527 16.4773 14.5444C16.1731 14.8361 15.7605 15 15.3302 15H2.35245C1.92221 15 1.50959 14.8361 1.20536 14.5444C0.901137 14.2527 0.730225 13.857 0.730225 13.4444V2.55556C0.730225 2.143 0.901137 1.74733 1.20536 1.45561C1.50959 1.16389 1.92221 1 2.35245 1H6.408L8.03022 3.33333H15.3302C15.7605 3.33333 16.1731 3.49722 16.4773 3.78894C16.7815 4.08067 16.9524 4.47633 16.9524 4.88889V13.4444Z"
        fill={color}
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FolderIcon;
