function CloudSuccessIcon({ size = 24, className = "" }) {
  return (
    <svg
      width={size}
      height={size + 4}
      viewBox="0 0 24 28"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0001 10.333H15.9501C15.6383 9.12551 14.9941 8.02966 14.0906 7.17C13.1871 6.31034 12.0607 5.72133 10.8392 5.4699C9.61768 5.21848 8.35015 5.31471 7.18062 5.74767C6.01108 6.18063 4.98644 6.93295 4.22312 7.91916C3.45979 8.90536 2.9884 10.0859 2.86249 11.3266C2.73659 12.5674 2.96123 13.8185 3.51088 14.938C4.06054 16.0574 4.91317 17.0002 5.97189 17.6593C7.03061 18.3184 8.25297 18.6673 9.50007 18.6663H17.0001C18.1051 18.6663 19.1649 18.2274 19.9463 17.446C20.7278 16.6646 21.1667 15.6047 21.1667 14.4997C21.1667 13.3946 20.7278 12.3348 19.9463 11.5534C19.1649 10.772 18.1051 10.333 17.0001 10.333Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6667 12L10.8021 15.3333L9.5 13.8182"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloudSuccessIcon;
