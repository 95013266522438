/**
 * Link Icon
 *
 * @param {import("react").SVGProps} props - SVG Icon Props
 */
function LinkIcon(props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8892 10.9147V14.9009C13.8892 15.2532 13.7526 15.5912 13.5095 15.8404C13.2664 16.0896 12.9367 16.2296 12.5929 16.2296H5.46329C5.11949 16.2296 4.78977 16.0896 4.54667 15.8404C4.30357 15.5912 4.16699 15.2532 4.16699 14.9009V7.59299C4.16699 7.24059 4.30357 6.90263 4.54667 6.65345C4.78977 6.40427 5.11949 6.26428 5.46329 6.26428H9.35218"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9453 4.27087H15.8342V8.25699"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7041 11.5787L15.8337 4.27087"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LinkIcon;
