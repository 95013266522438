import { useEffect, useState } from "react";
import { Formik } from "formik";
import Modal from "react-modal";
import * as yup from "yup";
import Select, { components } from "react-select";
import { useSelector, useDispatch } from "react-redux";

import Close from "../../assets/Images/Close-gray.svg";
import CloseIcon from "../Icons/CloseIcon";
import {
  addContactAction,
  deleteContact,
} from "../../store/actions/contactAction";
import SelectDownArrow from "../Icons/SelectDownArrow";
import selectStyles from "../../utils/selectStyle";
import { getMemberList } from "../../store/actions/memberAction";

/** @type {import("react-modal").Styles} */

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {" "}
      <SelectDownArrow className="mr-4" />
    </components.DropdownIndicator>
  );
};

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "50px",
    background: "none",
    borderRadius: "10px",
  },
};

const formSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  title: yup.string().required("Title is required"),
});

const ContactCard = ({ role, name, email, profileImg, handleDelete }) => (
  (
    <div className="flex items-center gap-3 py-5 first-of-type:pt-0 last-of-type:pb-0 ">
      <div className="relative inline-flex items-center justify-center overflow-hidden rounded-full size-8 bg-Neutral300 shrink-0">
        {/* Placeholder image */}
        {profileImg ? (
          <img
            src={profileImg}
            alt={"Profile Picture"}
            fill
            objectFit="cover"
          />
        ) : (
          <span className="text-sm font-medium uppercase text-Neutral700">
            {name?.charAt(0)}
          </span>
        )}
      </div>
      <div className="flex-grow">
        <h4 className="text-xs font-semibold capitalize text-Neutral900">
          {role ? role : "Default Role"}
        </h4>
        <p className="text-[10px] text-Neutral800 mt-[2px]">
          <span className="capitalize">{name ? name : "John Doe"}</span>
          &nbsp;&nbsp;&nbsp;
          <span className="font-semibold">
            {email ? email : "johndoe@gmail.com"}
          </span>
        </p>
      </div>
      <button
        className="text-Neutral400 hover:text-Red400 shrink-0"
        type="button"
        onClick={handleDelete}
      >
        <img className="cursor-pointer" src={Close} alt="" name="file" />
      </button>
    </div>
  )
);

function EditContactModal({ isOpen, setOpen, contactLists, setContactLists }) {
  const dispatch = useDispatch();
  const member = useSelector((state) => state.member.members);
  const [formStep, setFormStep] = useState(1);

  const handleClose = () => {
    setFormStep(1);
    setOpen(false);
  };

  useEffect(() => {
    if (!member?.data?.length) {
      dispatch(getMemberList());
    }
  }, [dispatch]);

  const handleAddContact = () => {
    setFormStep(2);
  };
  const handleDelete = async (contactId) => {
    try {
      const res = await dispatch(deleteContact(contactId, setOpen));
      if (res?.success === true) {
        //  update the state after delete
        setContactLists(contactLists.filter((item) => item._id !== contactId));
      }
      handleClose();
    } catch (error) {
      handleClose();
    }
  };

  const nameOptions = member?.data?.map((user) => ({
    value: user.user_name,
    label: user.user_name,
  }));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customModalStyles}
      closeTimeoutMS={300}
      contentLabel="Notice Board Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all w-[80vw] sm:my-6 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp sm:max-w-sm">
        <div className="w-full px-8 py-10 bg-white rounded-lg">
          {/* Header */}
          {formStep === 1 ? (
            <>
              <div className="flex justify-between pb-2 border-b border-Neutral200">
                <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold uppercase">
                  Edit contacts
                </h3>
              </div>

              {/* Body */}
              <div className="my-7">
                <div className="divide-y divide-Neutral200 h-[296px] overflow-y-auto">
                  {contactLists &&
                    contactLists?.map((contact) => {
                      return (
                        <ContactCard
                          key={contact.name}
                          name={contact.name}
                          role={contact.title}
                          // imageUrl={contact.imageUrl}
                          email={contact.email}
                          handleDelete={() => handleDelete(contact._id)}
                        />
                      );
                    })}
                </div>
              </div>

              {/* Footer */}
              <div className="w-full">
                <button
                  className="flex items-center justify-center w-full gap-2 py-2.5 text-xs font-semibold text-center uppercase transition border border-dashed rounded border-AccentRegular text-AccentRegular hover:bg-AccentRegular hover:text-Neutral000"
                  onClick={handleAddContact}
                >
                  + Add New Contact
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="border-b border-Neutral300">
                <button
                  type="button"
                  className="inline-flex items-center gap-3 text-xs font-semibold tracking-wider uppercase transition text-AccentRegular hover:text-AccentMediumDark"
                  onClick={() => setFormStep(1)}
                >
                  Go Back
                </button>
              </div>

              <div className="mt-5">
                <Formik
                  initialValues={{ name: "", email: "", title: "" }}
                  validationSchema={formSchema}
                  onSubmit={async (values, { setSubmitting, setErrors }) => {
                    try {
                      await dispatch(
                        addContactAction(
                          { ...values },
                          setErrors,
                          setSubmitting
                        )
                      );
                      handleClose();
                    } catch (error) {
                      throw new Error(error.message);
                    }
                    setSubmitting(false);
                  }}
                >
                  {({
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    handleChange,
                    values,
                    isValid,
                    dirty,
                    errors,
                    touched,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="space-y-5">
                        <div className="space-y-2">
                          <label
                            htmlFor="name"
                            className="block text-xs font-bold text-Neutral800"
                          >
                            Name
                          </label>
                          <Select
                            name="name"
                            styles={selectStyles}
                            options={nameOptions}
                            value={
                              values.name
                                ? nameOptions.find(
                                    (o) => o.value === values.name
                                  )
                                : ""
                            }
                            onChange={(option) =>
                              setFieldValue("name", option ? option.value : "")
                            }
                            onBlur={handleBlur}
                            components={{ DropdownIndicator }}
                            classNamePrefix="react-select"
                          />
                          {errors.name && touched.name && (
                            <span className="text-xs font-medium text-Red400">
                              {errors.name}
                            </span>
                          )}
                        </div>

                        <div className="space-y-2">
                          <label
                            htmlFor="title"
                            className="block text-xs font-bold text-Neutral800"
                          >
                            Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            onChange={handleChange}
                            placeholder="Eg. Lead Engineer"
                            className="w-full border-[1.5px] border-Neutral300 focus:outline focus:outline-2 rounded px-4 py-3 text-sm focus:outline-AccentRegular font-medium placeholder:text-Neutral500 text-Neutral700 transition"
                          />
                          {errors.title && touched.title && (
                            <span className="text-xs font-medium text-Red400">
                              {errors.title}
                            </span>
                          )}
                        </div>

                        <div className="space-y-2">
                          <label
                            htmlFor="email"
                            className="block text-xs font-bold text-Neutral800"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Eg. team@mail.com"
                            className="w-full border-[1.5px] border-Neutral300 focus:outline focus:outline-2 rounded px-4 py-3 text-sm focus:outline-AccentRegular font-medium placeholder:text-Neutral500 text-Neutral700 transition"
                          />
                          {errors.email && touched.email && (
                            <span className="text-xs font-medium text-Red400">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="flex mt-6">
                        <button
                          type="submit"
                          disabled={!(isValid && dirty) || isSubmitting}
                          className="w-full py-4 text-xs font-semibold uppercase transition rounded disabled:bg-Neutral200 bg-AccentRegular disabled:text-Neutral500 text-Neutral000"
                        >
                          {isSubmitting ? "Adding..." : "Add new contact"}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </>
          )}
        </div>
        {/* ----- Close Modal Button ----- */}
        <button
          className="absolute right-0 top-[-50px] z-30 cursor-pointer text-Neutral300 hover:text-rose-400 transition"
          onClick={handleClose}
        >
          <CloseIcon
            width={18}
            className="text-Neutral000 hover:text-Red400 hover:border-Red400 duration-300 transition-all rounded-[50%] border border-Neutral400 h-[32px] w-[32px] flex items-center justify-center p-1"
          />
        </button>
      </div>
    </Modal>
  );
}

export default EditContactModal;
