import { Dialog } from "@headlessui/react";
import { useNavigate } from "react-router";

import CheckMarkIcon from "../Icons/CheckMarkIcon";
import CloseIcon from "../Icons/CloseIcon";
import UpgradeBadgeIcon from "../Icons/UpgradeBadgeIcon";

function FeaturesModal({ isOpen = false, setIsOpen = () => {}, data = null }) {
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="fixed inset-0 z-[99999]"
    >
      <div className="flex items-center justify-center min-h-screen px-4 text-center">
        <Dialog.Overlay className="fixed inset-0 bg-[#29293366]/60 backdrop-blur-xs" />
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <div className="w-full max-w-[320px] text-left px-5 py-4 overflow-hidden transition-all transform bg-white shadow-xl rounded-xl">
          <div className="flex items-center justify-between w-full mb-3">
            <div className="flex items-center gap-1 px-3 py-2 pr-4 rounded-3xl text-AccentRegular bg-AccentLight">
              <UpgradeBadgeIcon className="size-4" />
              <span className="text-xs font-semibold uppercase text-AccentRegular">
                UPGRADE
              </span>
            </div>
            <button className="text-Neutral500" onClick={handleClose}>
              <CloseIcon className="size-5" />
            </button>
          </div>

          <h2 className="mb-4 text-base font-semibold text-Neutral900">
            {data?.title || "Customise what your clients see..."}
          </h2>

          <ul className="mb-4 space-y-2 text-xs text-Neutral900">
            {data?.features?.map((feature, idx) => {
              const uniqueId = feature?.substring(0, 5)?.trim() + idx;

              return (
                <li key={uniqueId} className="flex items-center gap-2">
                  <CheckMarkIcon width={18} color="#34B288" />
                  {feature}
                </li>
              );
            })}
          </ul>

          <button
            className="w-full py-2.5 text-sm font-bold border rounded border-Neutral300 text-AccentRegular hover:bg-AccentRegular hover:text-white"
            onClick={
              data?.onViewPlanClick ||
              (() => {
                navigate("/white-label/plans");
              })
            }
          >
            View Plans
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default FeaturesModal;
