import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import TemplateLoader from "../../components/ContentLoaders/TemplateLoader";
import AddIcon from "../../components/Icons/AddIcon";
import EditInvoiceAndInvoiceTemplateModal from "../../components/Modals/EditInvoiceAndInvoiceTemplateModal";
import SelectClientModal from "../../components/Modals/SelectClientModal";
import NewInvoiceModal from "../../components/Modals/Templates/NewInvoiceModal";
import NewTemplateModal from "../../components/Modals/Templates/NewTemplateModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import TemplateInfo from "../../components/Templates/TemplateInfo/TemplateInfo";
import {
  CreateDuplicateTemplateActions,
  deleteTemplateAction,
  getTemplateList,
} from "../../store/actions/templateAction";

function TemplatesDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const openModal = query.get("openModal");

  const templateList = useSelector((state) => state.template.template);

  const [isOpen, setIsOpen] = useState(false);
  const [editProposal, setEditProposal] = useState("");
  const [modalData, setModalData] = useState({ type: "" });
  const [editData, setEditData] = useState(null);
  const [editInvoiceModal, setEditInvoiceModal] = useState(false);
  const [isOpenSelectClientModal, setIsOpenSelectClientModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState({
    isLoading: false,
    id: null,
    type: null,
  });

  useEffect(() => {
    if (location.state === "Invoice") {
      setIsOpen(true);
      setModalData({ type: "Invoice" });
      try {
        window.history.replaceState({}, document.title);
      } catch (error) {
        console.log(error);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (
      templateList?.invoice?.length === 0 &&
      templateList?.questionnaire?.length === 0 &&
      templateList?.proposal?.length === 0 &&
      templateList?.contract?.length === 0 &&
      templateList?.project?.length === 0
    ) {
      dispatch(getTemplateList());
    }
  }, [
    dispatch,
    templateList?.contract?.length,
    templateList?.invoice?.length,
    templateList?.project?.length,
    templateList?.proposal?.length,
    templateList?.questionnaire?.length,
  ]);

  const handleOpenNewTemplateModal = (type) => () => {
    setIsOpen(true);
    setModalData({ type });
  };

  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);

  const handleDelete = (id, type) => {
    dispatch(deleteTemplateAction(id, type, setIsLoaded));
  };

  const handleEditTemp = (template, type) => {
    navigate(`/admin/${type}/template/${template?._id}`);
    setEditProposal(template);
  };

  const handleEditeQuestion = (template, type) => {
    navigate(`/admin/${type}-builder/${template?._id}`);
  };

  const handleEditInvoice = (template, type) => {
    navigate(`/admin/${type}-creator/${template?._id}`);
  };

  const handleDuplicate = (id, type, title) => {
    dispatch(CreateDuplicateTemplateActions({ id, type, title }));
  };

  useEffect(() => {
    if (openModal) {
      if (openModal === "invoice") {
        setIsInvoiceModalOpen(true);
      }
    }
  }, [openModal]);

  return (
    <div>
      <AdminNavbar
        title="Templates"
        subtitle="Create templates and reuse them for clients to save time"
      />
      {/* banner */}
      {/* <div className="5xl:px-[96px] px-12 w-full md:mt-3">
        <div className="bg-fuchsia-50 rounded-lg border border-fucshia-400 flex-col justify-start items-start gap-2.5 flex w-max px-6 py-2">
          <div className="inline-flex items-center justify-center gap-4">
            <div className="px-2 py-1 bg-fuchsia-500 rounded border border-fuchsia-500 justify-start items-start gap-2.5 flex">
              <div className="flex items-center w-6 h-3 text-xs font-normal text-center text-white">
                New
              </div>
            </div>
            <div className="text-sm font-medium leading-tight tracking-wide text-yellow-950">
              Allows you to easily add and issue invoices to your clients!{" "}
            </div>
          </div>
        </div>
      </div> */}
      {/* banner */}

      {templateList?.isLoading ? (
        <div className="5xl:px-[96px] px-0 md:px-12 w-full md:pt[108]">
          <TemplateLoader />
        </div>
      ) : (
        <div className="5xl:px-[96px] px-0 md:px-12 w-full md:pt[108px]">
          <div className="w-full mt-4 bg-Neutral100 px-7 py-7">
            <div className="flex items-center">
              <h4 className="uppercase font-semibold mr-6 tracking-[0.04em]">
                Invoices
              </h4>

              <button
                onClick={handleOpenNewTemplateModal("Invoice")}
                className="px-3 py-2.5 text-Blue500 hover:text-AccentRegular border-[1px] border-Blue500 hover:border-AccentRegular rounded-lg uppercase text-xs flex items-center justify-center w-[90px] h-8 transition-all duration-300 group"
              >
                <AddIcon
                  className={`group-hover:text-AccentRegular leading-4`}
                />
                <span className="ml-[6.34px] font-bold tracking-[0.04em]">
                  Create
                </span>
              </button>
            </div>
            <div className="mt-1 text-sm text-Neutral600">
              Create reusable invoice templates to save time and duplicate them
              for new clients
            </div>

            {templateList?.invoice?.length ? (
              <div className="flex flex-wrap gap-8 mt-8">
                {templateList?.invoice?.map((template, i) => (
                  <TemplateInfo
                    key={i}
                    title={template?.invoiceName || template?.title}
                    tags={template?.categoryTags?.map((tag) => tag?.name)}
                    color="Pink"
                    handleDelete={() => {
                      handleDelete(template?._id, "Invoice");
                    }}
                    handleEditeTemp={() => {
                      handleEditInvoice(template, "invoice");
                    }}
                    handleDuplicate={async () => {
                      const tempLength = await templateList?.invoice?.filter(
                        (item) =>
                          item?.invoiceName?.includes(template?.invoiceName)
                      )?.length;

                      handleDuplicate(
                        template?._id,
                        "Invoice",
                        `${template?.invoiceName} (${tempLength})`
                      );
                    }}
                    handleEdit={() => {
                      setEditData(template);
                      setEditInvoiceModal(true);
                      setModalData({ type: "Invoice" });
                    }}
                    handleUse={() => {
                      setEditData(template);
                      setIsOpenSelectClientModal(true);
                      setModalData({ type: "Invoice" });
                    }}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                    id={template?._id}
                    type="Invoice"
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="w-full mt-10 bg-Neutral100 px-7 py-7">
            <div className="flex items-center">
              <h4 className="uppercase font-semibold mr-6 tracking-[0.04em]">
                Questionnaires
              </h4>

              <button
                onClick={handleOpenNewTemplateModal("Questionnaire")}
                className="px-3 py-2.5 text-Blue500 hover:text-Blue600 border-[1px] border-Blue500 hover:border-Blue600 rounded-lg uppercase text-xs flex items-center justify-center w-[90px] h-8 transition-all duration-300 group"
              >
                <AddIcon
                  color="stroke-Blue500"
                  className={`stroke-Blue500 group-hover:stroke-Blue600 leading-4`}
                />
                <span className="ml-[6.34px] font-bold tracking-[0.04em]">
                  Create
                </span>
              </button>
            </div>
            <div className="mt-1 text-sm text-Neutral600">
              Create reusable client intake questionnaires to save time and
              duplicate them for new clients
            </div>

            {templateList?.questionnaire?.length ? (
              <div className="flex flex-wrap gap-8 mt-8">
                {templateList?.questionnaire?.map((template, i) => (
                  <TemplateInfo
                    key={i}
                    title={template?.title}
                    tags={template?.category?.map((tag) => tag?.name)}
                    color="Blue"
                    handleDelete={() => {
                      handleDelete(template?._id, "Questionnaire");
                    }}
                    handleEditeTemp={() => {
                      handleEditeQuestion(template, "questionnaire");
                    }}
                    handleDuplicate={async () => {
                      const tempLength =
                        await templateList?.questionnaire?.filter((item) =>
                          item?.title.includes(template?.title)
                        )?.length;

                      handleDuplicate(
                        template?._id,
                        "Questionnaire",
                        `${template?.title} (${tempLength})`
                      );
                    }}
                    handleEdit={() => {
                      setEditData(template);
                      setIsOpen(true);
                      setModalData({ type: "Questionnaire" });
                    }}
                    handleUse={() => {
                      setEditData(template);
                      setIsOpenSelectClientModal(true);
                      setModalData({ type: "Questionnaire" });
                    }}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                    id={template?._id}
                    type="Questionnaire"
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full mt-8 bg-Neutral100 px-7 py-7">
            <div className="flex items-center">
              <h4 className="uppercase font-semibold mr-6 tracking-[0.04em]">
                Proposals
              </h4>

              <button
                onClick={handleOpenNewTemplateModal("Proposal")}
                className="px-[14px] py-2.5 text-Green500 hover:text-Green600 border-[1px] border-Green500 hover:border-Green500 rounded-lg uppercase text-xs flex items-center w-[90px] h-8 transition-all duration-300 group"
              >
                <AddIcon
                  color="stroke-Green500"
                  className={`stroke-Green500 group-hover:stroke-Green600 leading-4`}
                />
                <span className="ml-[6.34px] font-bold">Create</span>
              </button>
            </div>
            <div className="mt-1 text-sm text-Neutral600">
              Create reusable proposals to save time and duplicate them for new
              clients
            </div>

            {templateList?.proposal?.length ? (
              <div className="flex flex-wrap gap-8 mt-8">
                {templateList?.proposal?.map((template, i) => (
                  <TemplateInfo
                    key={i}
                    title={template?.title}
                    tags={template?.category?.map((tag) => tag?.name)}
                    color="Green"
                    handleDelete={() => {
                      handleDelete(template?._id, "Proposal");
                    }}
                    handleEditeTemp={() => {
                      handleEditTemp(template, "proposal");
                    }}
                    handleDuplicate={async () => {
                      const tempLength = await templateList?.proposal?.filter(
                        (item) => item?.title.includes(template?.title)
                      )?.length;

                      handleDuplicate(
                        template?._id,
                        "Proposal",
                        `${template?.title} (${tempLength})`
                      );
                    }}
                    handleEdit={() => {
                      setEditData(template);
                      setIsOpen(true);
                      setModalData({ type: "Proposal" });
                    }}
                    handleUse={() => {
                      setEditData(template);
                      setIsOpenSelectClientModal(true);
                      setModalData({ type: "Proposal" });
                    }}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                    id={template?._id}
                    type="Proposal"
                  />
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="w-full mt-10 bg-Neutral100 px-7 py-7">
            <div className="flex items-center">
              <h4 className="uppercase font-semibold mr-6 tracking-[0.04em]">
                Contracts
              </h4>

              <button
                onClick={handleOpenNewTemplateModal("Contract")}
                className="px-3 py-2.5 text-Red500 hover:text-Red600 border-[1px] border-Red500 hover:border-Red600 rounded-lg uppercase text-xs flex items-center w-[90px] h-8 transition-all duration-300 group"
              >
                <AddIcon
                  color="stroke-Red500"
                  className={`stroke-Red500 group-hover:stroke-Red600 leading-4`}
                />
                <span className="ml-[6.34px] font-bold">Create</span>
              </button>
            </div>
            <div className="mt-1 text-sm text-Neutral600">
              Create reusable contracts to save time and duplicate them for new
              clients
            </div>

            {templateList?.contract?.length ? (
              <div className="flex flex-wrap gap-8 mt-8">
                {templateList?.contract?.map((template, i) => (
                  <TemplateInfo
                    key={i}
                    title={template?.title}
                    tags={template?.category?.map((tag) => tag?.name)}
                    color="Red"
                    handleDelete={() => {
                      handleDelete(template?._id, "Contract");
                    }}
                    handleEditeTemp={() => {
                      handleEditTemp(template, "contract");
                    }}
                    handleDuplicate={async () => {
                      const tempLength = await templateList?.contract?.filter(
                        (item) => item?.title.includes(template?.title)
                      )?.length;

                      handleDuplicate(
                        template?._id,
                        "Contract",
                        `${template?.title} (${tempLength})`
                      );
                    }}
                    handleEdit={() => {
                      setEditData(template);
                      setIsOpen(true);
                      setModalData({ type: "Contract" });
                    }}
                    handleUse={() => {
                      setEditData(template);
                      setIsOpenSelectClientModal(true);
                      setModalData({ type: "Contract" });
                    }}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                    id={template?._id}
                    type="Contract"
                  />
                ))}
              </div>
            ) : (
              false
            )}
          </div>

          <div className="w-full mt-8 bg-Neutral100 px-7 py-7">
            <div className="flex items-center">
              <h4 className="uppercase font-semibold mr-6 tracking-[0.04em]">
                Projects
              </h4>

              {/* <button
                onClick={handleOpenNewTemplateModal("Project")}
                className="px-[14px] py-2.5 text-Orange500 hover:text-Orange600 border-[1px] border-Orange500 hover:border-Orange500 rounded-lg uppercase text-xs flex items-center w-[90px] h-8 transition-all duration-300 group"
              >
                <AddIcon
                  color="stroke-Orange500"
                  className={`stroke-Orange500 group-hover:stroke-Orange600 leading-4`}
                />
                <span className="ml-[6.34px] font-bold">Create</span>
              </button> */}
            </div>
            <div className="mt-1 text-sm text-Neutral600">
              Please click on your client projects to save them as reusable
              templates for future clients, which you can access from here.
            </div>

            {templateList?.project?.length ? (
              <div className="flex flex-wrap gap-8 mt-8">
                {templateList?.project?.map((template, i) => {
                  return (
                    <TemplateInfo
                      key={i}
                      title={template?.title}
                      tags={template?.category?.map((tag) => tag?.name)}
                      color="Orange"
                      handleDelete={() => {
                        handleDelete(template?._id, "Project");
                      }}
                      handleEditeTemp={() => {
                        handleEditTemp(template, "project");
                      }}
                      handleDuplicate={async () => {
                        const tempLength = await templateList?.project?.filter(
                          (item) => item?.title.includes(template?.title)
                        )?.length;

                        handleDuplicate(
                          template?._id,
                          "Project",
                          `${template?.title} (${tempLength})`
                        );
                      }}
                      handleEdit={() => {
                        setEditData(template);
                        setIsOpen(true);
                        setModalData({ type: "Project" });
                      }}
                      handleUse={() => {
                        setEditData(template);
                        setIsOpenSelectClientModal(true);
                        setModalData({ type: "Project" });
                      }}
                      isLoaded={isLoaded}
                      setIsLoaded={setIsLoaded}
                      id={template?._id}
                      type="Project"
                    />
                  );
                })}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {isInvoiceModalOpen && (
        <>
          <h3 className="text-xs text-Neutral700 leading-[18px] font-semibold	pb-2 border-b border-Neutral200 w-full mb-8 uppercase">
            Invoice Type
          </h3>
          <NewInvoiceModal
            isOpen={isInvoiceModalOpen}
            setIsOpen={(e) => {
              setIsInvoiceModalOpen(e);
            }}
          />
        </>
      )}
      {isOpen && (
        <NewTemplateModal
          isOpen={isOpen}
          setIsOpen={(e) => {
            setIsOpen(e);
            if (!e) {
              setEditData(null);
            }
          }}
          type={modalData.type}
          editData={editData}
        />
      )}
      <SelectClientModal
        setIsOpenSelectClientModal={setIsOpenSelectClientModal}
        isOpenSelectClientModal={isOpenSelectClientModal}
        type={modalData.type}
        editData={editData}
      />
      {editInvoiceModal && (
        <EditInvoiceAndInvoiceTemplateModal
          isOpen={editInvoiceModal}
          setIsOpen={setEditInvoiceModal}
          editData={editData}
        />
      )}
    </div>
  );
}

export default TemplatesDashboard;
