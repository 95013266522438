import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, Fragment, useState, useRef } from "react";
import dog from "../../../assets/Images/Big-Shoes-Animal.svg";
import { useNavigate } from "react-router";
import "swiper/css";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import Plussign from "../../../assets/Images/plus.png";
import Plusico from "../../../assets/Images/Pluspurple.png";
import Plusorg from "../../../assets/Images/plusorange.png";
import IndividualsPoster from "./Components/Svgs/IndividualsPoster";
import SmallTeamsPoster from "./Components/Svgs/SmallTeamsPoster";
import LargeTeamsPoster from "./Components/Svgs/LargeTeamsPoster";
import BackNavArrow from "../../../assets/Images/back-nav-arrow.svg";
import { UpdateToDiscountedPrice } from "../../../store/actions/authAction";
import { GetPlanConfig } from "../../../store/actions/appConfigAction";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
export const accountTypeWithPlan = [
  {
    name: "For Freelancer",
    account_type: "individual",
    user: "1",
    image: <IndividualsPoster />,
    border_color: "border-b-Blue400",
    user_lenth_text_class: "Individuals-card",
    text_color: "text-Blue400",
    card_wrapper_class: "Individuals",
    sign_color: "#5CACE5",
    list_class: "",
    plus_icon: Plussign,
    description:
      "Are you a solo freelancer? This plan is for you. Access to all features with no limitations.",
  },
  {
    name: "For Small Teams",
    account_type: "small_team",
    user: "2-10",
    image: <SmallTeamsPoster />,
    border_color: "border-b-AccentRegular",
    user_lenth_text_class: "small-teams-card",
    text_color: "text-AccentRegular",
    card_wrapper_class: "small-teams",
    sign_color: "#6D6DF2",
    list_class: "",
    plus_icon: Plusico,
    description:
      "Are you a small team? This plan is for you. Access to all features with no limitations.",
  },
  {
    name: "For Large Teams",
    account_type: "large_team",
    user: "11-50",
    image: <LargeTeamsPoster />,
    border_color: "border-b-Red400",
    user_lenth_text_class: "large-teams-card",
    text_color: "text-Red400",
    card_wrapper_class: "large-teams",
    sign_color: "#E5675C",
    list_class: "large-teams-list",
    plus_icon: Plusorg,
    description:
      "Are you a large team? This plan is for you. Access to all features with no limitations.",
  },
];
const UpgradeToUpsellPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const planRef = useRef();
  const accountTypeRef = useRef();
  const logedinUser = useSelector((state) => state.auth.user);
  const [planDetail, setPlanDetail] = useState({
    accountType: null,
    plan: null,
  });

  const [isPlanChangingLoading, setIsPlanChangingLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);
  const Paddle = window.Paddle;

  const predifineplan = () => {
    if (logedinUser?.payment?.selected_account_plan) {
      const tempAccount = accountTypeWithPlan.filter(
        (e) => e.account_type === logedinUser?.payment?.selected_account_plan
      );
      if (tempAccount && tempAccount?.length) {
        if (tempAccount?.[0]) {
          if (
            tempAccount?.[0] &&
            tempAccount?.[0]?.yearly_30_0ff &&
            logedinUser?.payment?.paddel_plan_id ===
              tempAccount?.[0]?.yearly_30_0ff
          ) {
            setPlanDetail({
              plan: tempAccount?.[0]?.yearly_30_0ff,
              accountType: tempAccount?.[0],
            });
          } else {
            setPlanDetail({
              plan: tempAccount?.[0]?.yearly_30_0ff,
              accountType: tempAccount?.[0],
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    predifineplan();
  }, [logedinUser, accountTypeWithPlan]);

  const getData = async () => {
    const res = await dispatch(GetPlanConfig());
    const plansData = res.data.plans;
    accountTypeWithPlan.forEach((item) => {
      const plan = plansData.find((plan) => plan.type === item.account_type);
      item.yearly_30_0ff = plan.yearly_30_0ff;
      // item.life_time = plan.life_time;
      // item.monthly = plan?.monthly;
      // item.yearly = plan?.yearly;
    });
    predifineplan();
  };

  useEffect(() => {
    if (!logedinUser?.payment && planDetail?.plan) {
      Paddle.Checkout.open({
        method: "inline",
        product: planDetail?.plan?.plan_id,
        email: logedinUser?.email,
        allowQuantity: false,
        disableLogout: true,
        customData: {
          user: logedinUser?._id,
          plan: planDetail?.plan?.plan_type,
          account: planDetail?.accountType?.account_type,
        },
        frameTarget: "payment-box",
        frameStyle:
          "width:100%; min-width:312px; background-color: transparent; border: none;",
        successCallback: (data, err) => {
          navigate("/admin/settings");
          toast.success("Payment Success, will be affect in few mins");
        },
      });
    }
  }, [planDetail, logedinUser]);

  useEffect(() => {
    getData();
    return () => {
      console.log("Component did unmount");
    };
  }, [dispatch]);

  const ChangeUserPlan = () => {
    setIsPlanChangingLoading(true);
    dispatch(
      UpdateToDiscountedPrice(
        {
          account: planDetail?.accountType?.account_type,
          plan: planDetail?.plan?.plan_type,
          plan_id: planDetail?.plan?.plan_id,
        },
        setIsPlanChangingLoading,
        navigate
      )
    );
  };
  return (
    <>
      <section className="container m-auto min-h-[90vh] lg:flex items-center justify-center">
        <div className="flex-col h-full justify-center items-center flex onboading-form">
          <div className={`w-full px-6 md:px-0`}>
            <div className="md:flex w-full">
              <div className="ms:mr-20 mt-10">
                <p className="text-xs lg:text-base text-Neutral800 font-semibold flex justify-between items-baseline">
                  <Link className="flex" to="/admin/settings">
                    <img
                      className="mr-1 transition duration-300 ease-out"
                      src={BackNavArrow}
                      alt=""
                    />
                    Back to Setting
                  </Link>
                  <img className="hidden md:block" src={dog} alt="" />
                </p>
                <h2 className="text-Neutral900 font-bold text-xl lg:text-[32px] pt-2 pb-1">
                  You’re almost there!
                </h2>
                <p className=" text-Neutral800 font-normal text-xs md:text-base">
                  Take a second to check that your details are <br /> correct
                  before proceeding.
                </p>
                <div className="mt-[44px]">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-Neutral600 text-[10px] font-medium uppercase">
                        email
                      </p>
                      <p className="font-medium text-sm text-Neutral900">
                        {logedinUser?.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-40 -ml-[16px] mt-5"
                    >
                      <Menu.Button
                        ref={accountTypeRef}
                        className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                      >
                        <span className="focus-visible:outline-none pb-2 border-b border-Neutral200 w-full">
                          <div className="members-text text-left w-full">
                            <p className="text-Neutral600 text-[10px]">
                              Account type
                            </p>
                            <div className="flex flex-wrap text-[14px] items-center text-Neutral900 font-medium">
                              {planDetail?.accountType?.name}
                              <svg
                                className="absolute right-[16px]"
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                  fill="#5A5A66"
                                />
                              </svg>
                            </div>
                          </div>
                        </span>
                      </Menu.Button>
                      <Transition as={Fragment}>
                        <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[56px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                          <div className="bg-white">
                            <div>
                              {accountTypeWithPlan?.map((item) => {
                                if (
                                  item?.account_type !==
                                  planDetail?.accountType?.account_type
                                ) {
                                  return (
                                    <div
                                      key={item?.account_type}
                                      className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                                      onClick={() => {
                                        setPlanDetail({
                                          ...planDetail,
                                          accountType: item,
                                          plan: item?.yearly_30_0ff,
                                        });
                                        setTimeout(() => {
                                          accountTypeRef?.current?.click();
                                        }, 0);
                                      }}
                                    >
                                      <p className="text-Neutral800 text-sm font-normal">
                                        {item?.name}
                                      </p>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <Menu
                      as="div"
                      className="relative inline-block text-left w-40 -ml-[16px] mt-5"
                    >
                      <Menu.Button
                        ref={planRef}
                        disabled
                        className="rounded-[4px] client-name bg-Neutral200 inline-flex w-full focus-visible:outline-none popup-btn relative px-[16px] pb-[10px] pt-[10px]"
                      >
                        <span className="focus-visible:outline-none pb-2 border-b border-Neutral200 w-full">
                          <div className="members-text text-left w-full">
                            <p className="text-Neutral600 text-[10px]">
                              Payment plan
                            </p>
                            {planDetail?.plan?.price && (
                              <div className="flex flex-wrap text-[14px] items-center text-Neutral900 font-medium">
                                ${planDetail?.plan?.price} -{" "}
                                {planDetail?.plan?.plan_type}
                                {/* <svg
                                  className="absolute right-[16px]"
                                  width="11"
                                  height="8"
                                  viewBox="0 0 11 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.324856 1.63059C-0.206125 0.977077 0.258936 0 1.10097 0H9.89903C10.7411 0 11.2061 0.977078 10.6751 1.63059L6.27611 7.04478C5.87592 7.53733 5.12408 7.53733 4.72389 7.04478L0.324856 1.63059Z"
                                    fill="#5A5A66"
                                  />
                                </svg> */}
                              </div>
                            )}
                          </div>
                        </span>
                      </Menu.Button>
                      <Transition as={Fragment}>
                        <Menu.Items className="box shadow-[0px_30px_40px_rgba(0,0,0,0.2)] z-50 absolute top-[56px] right-0 bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none w-full pb-4 px-4 rounded-[0px_0px_4px_4px]">
                          <div className="bg-white">
                            <div>
                              <div
                                onClick={() => {
                                  setPlanDetail({
                                    ...planDetail,
                                    plan: planDetail?.accountType
                                      ?.yearly_30_0ff,
                                  });
                                  setTimeout(() => {
                                    planRef?.current?.click();
                                  }, 0);
                                }}
                                className="flex items-center rounded-[2px] py-[6px] hover:bg-Neutral200 ml-[-8px] pl-[10px] cursor-pointer"
                              >
                                <p className="text-Neutral800 text-sm font-normal">
                                  $
                                  {
                                    planDetail?.accountType?.yearly_30_0ff
                                      ?.price
                                  }{" "}
                                  - Yearly
                                </p>
                              </div>
                            </div>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="border-t border-Neutral400 flex justify-between items-center py-2">
                    <p className="text-Neutral900 text-sm font-semibold">
                      TOTAL
                    </p>
                    {planDetail?.plan?.price && (
                      <p className="text-Neutral900 text-xl font-bold">
                        ${planDetail?.plan?.price}{" "}
                        <span className="text-Neutral600 text-sm font-medium">
                          {planDetail?.plan?.plan_type === "yearly" && "/ yr"}
                          {planDetail?.plan?.plan_type === "monthly" && "/ mo"}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`md:ml-20 mt-5 md:mt-0 ${
                  !logedinUser?.payment && planDetail?.plan ? "" : "hidden"
                }`}
              >
                <div className="w-full md:w-[470px] h-[562px] bg-[#ffffff] payment-box overflow-auto"></div>
                {/* <svg
                  width="470"
                  height="562"
                  viewBox="0 0 470 562"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="470" height="562" rx="16" fill="white" />
                </svg> */}
              </div>
            </div>
            <button
              class={`mt-10 btn button-hover form-primary-btn relative shadow-none text-sm text-center block text-Neutral100 bg-AccentRegular font-bold w-full py-3 rounded overflow-hidden focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight transition duration-300 ease-in-out ${
                logedinUser?.payment?.paddel_plan_id &&
                logedinUser?.payment?.paddel_plan_id ===
                  planDetail?.plan?.plan_id
                  ? "hidden"
                  : ""
              }`}
              onClick={ChangeUserPlan}
              disabled={isPlanChangingLoading}
            >
              {isPlanChangingLoading ? (
                <span class="relative z-10 drop-shadow-Texts">Loading</span>
              ) : (
                <span class="relative z-10 drop-shadow-Texts">Change Plan</span>
              )}
            </button>
          </div>
        </div>
      </section>
      <div className="lg:flex hidden justify-end mr-8 mb-8">
        <p className=" text-Neutral800 text-xs font-medium">
          Have a question? Email us at{" "}
          <span className="text-AccentRegular">support@clientmanager.io</span>
        </p>
      </div>
    </>
  );
};

export default UpgradeToUpsellPlan;
