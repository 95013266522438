import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router";

import { cn } from "../../utils";
import AddClientIcon from "../Icons/AddClientIcon";
import AddIcon from "../Icons/AddIcon";
import BagIcon from "../Icons/BagIcon";
import DollarSignIcon from "../Icons/DollarSignIcon";
import LightningIcon from "../Icons/LightningIcon";
import SelectDownArrow from "../Icons/SelectDownArrow";
import AddLeadModal from "../Modals/AddLeadModal";
import ClientSelectModal from "../Modals/ClientSelectModal";
import CreateClientModal from "../Modals/CreateClientModal";
import CreateMemberModal from "../Modals/CreateMemberModal";
import SchedulerModal from "../Modals/ScheduleMeetingModal/SchedulerModal";
import SelectClientAndProjectModal from "../Modals/SelectClientAndProjectModal";
import TaskNameModal from "../Modals/TaskNameModal";
import { NewEntityTemplateModal } from "../Modals/Templates/NewEntityTemplateModal";
import NewTemplateModal from "../Modals/Templates/NewTemplateModal";
import { SelectTemplate } from "../Modals/Templates/SelectTemplate";
import TrackingAddTimeEntryModal from "../Modals/TrackingAddTimeEntryModal";

const newActionItems = [
  {
    icon: <BagIcon width={24} height={24} />,
    title: "Create Proposal",
    modal: "select_client",
    type: "Proposal",
    role: ["master_admin", "admin"],
  },
  {
    icon: <AddClientIcon width={24} />,
    title: "Add Client",
    modal: "add_client",
    role: ["master_admin", "admin", "project_manager"],
  },
  {
    icon: <AddIcon width={24} />,
    title: "Add Lead",
    modal: "add_lead",
    role: ["master_admin", "admin", "project_manager"],
  },
  {
    icon: <DollarSignIcon width={24} />,
    title: "Create invoice",
    modal: "select_client",
    // redirect: '/admin/templates',
    state: "Invoice",
    type: "Invoice",
    role: ["master_admin", "admin"],
  },
  // {
  //   icon: <ClockIcon color='#6D6DF2' width={24} />,
  //   title: 'Start Time Tracker',
  //   modal: 'start_time_tracker',
  // },
  {
    icon: <AddIcon width={24} />,
    title: "Invite Team Member",
    modal: "invite_team_member",
    role: ["master_admin", "admin"],
  },
  {
    icon: <LightningIcon width={24} height={24} />,
    title: "Create Task",
    modal: "create_task",
    role: ["master_admin", "admin", "project_manager", "general"],
  },
  {
    icon: <LightningIcon width={24} height={24} />,
    title: "Create Meeting Schedule",
    modal: "create_meeting_schedule",
    role: ["master_admin", "admin", "project_manager", "general"],
  },
];

const NewActionMenuButton = ({ role, className = "" }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState({
    create_proposal: false,
    add_client: false,
    start_time_tracker: false,
    invite_team_member: false,
    new_entity_template: false,
    custom_template: false,
    new_template: false,
    select_client: false,
    create_task: false,
    create_meeting_schedule: false,
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [templateType, setTemplateType] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [taskNameModalOpen, setTaskNameModalOpen] = useState(false);

  const actionsMenuItems = newActionItems
    .filter((item) => item.role.includes(role))
    .map((item, idx) => {
      return (
        <li key={idx}>
          <button
            className="w-[100%] flex items-center py-3 px-2 pl-[19px] hover:bg-Neutral200 transition-all duration-250 cursor-pointer rounded hover:text-AccentRegular"
            onClick={() => {
              if (item?.redirect) {
                navigate(item?.redirect, { state: item?.state });
              }
              if (item.type) {
                setTemplateType(item.type);
              }
              if (item.modal) {
                setIsModalOpen({ ...isModalOpen, [item.modal]: true });
              }
            }}
          >
            <span className="flex items-center justify-center">
              {item.icon}
            </span>
            <span className="ml-4 text-xs uppercase leading-[16px] tracking-[0.04em] font-normal text-left">
              {item.title}
            </span>
          </button>
        </li>
      );
    });

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className={cn("flex items-center", className)}>
          <Menu.Button className="flex items-center justify-center w-[106px] h-[48px] focus-visible:outline-none popup-btn relative bg-AccentRegular rounded-[12px] p-[18px] hover:bg-AccentMediumDark transition-all duration-300 active:bg-AccentMediumDark">
            <span className="uppercase text-Neutral000 text-[14px] flex items-center justify-center font-bold tracking-[0.04em] after:content-[''] after:inline-block after:h-[20px] after:w-[1px] after:bg-Neutral500 after:ml-3 mr-[14px]">
              New
            </span>
            <SelectDownArrow width={12} rounded color="#FFF" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute top-[100%] right-[0] mt-2 w-[240px] rounded-lg dropdowns-boxshadow bg-white ring-Neutral300 ring-opacity-5 divide-y divide-Neutral300 focus:outline-none">
            <h3 className="text-Neutral700 text-xs font-semibold leading-[16px] tracking-[0.08em] uppercase py-5 px-6 pb-3">
              New Action
            </h3>

            <ul className="p-2 list-none">{actionsMenuItems}</ul>
          </Menu.Items>
        </Transition>
      </Menu>

      <ClientSelectModal
        isOpen={isModalOpen.select_client}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, select_client: value })
        }
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        handleOpenNewEntityTemplateModal={() => {
          setIsModalOpen({
            ...isModalOpen,
            select_client: false,
            new_entity_template: true,
          });
        }}
        type={templateType}
      />

      <AddLeadModal
        isOpen={isModalOpen.add_lead}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, add_lead: value })
        }
      />

      <SelectClientAndProjectModal
        isOpen={isModalOpen.create_task}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, create_task: value })
        }
        isTask={true}
        selectedProject={selectedProject}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        setSelectedProject={setSelectedProject}
        handleSave={() => {
          setIsModalOpen({ ...isModalOpen, create_task: false });

          // First navigate without isNew=true
          navigate(
            `/admin/clients/manage/${selectedClient}/project/${selectedProject}`
          );

          // Set a timeout to navigate again with isNew=true after a delay
          setTimeout(() => {
            navigate(
              `/admin/clients/manage/${selectedClient}/project/${selectedProject}?isNew=true`
            );
          }, [1000]); // Replace [desired_delay_in_milliseconds] with the number of milliseconds you want to wait
        }}
      />

      <TaskNameModal
        taskNameModalOpen={taskNameModalOpen}
        setTaskNameModalOpen={setTaskNameModalOpen}
        selectedProject={selectedProject}
        selectedClient={selectedClient}
      />

      <SchedulerModal
        meetingModalIsOpen={isModalOpen.create_meeting_schedule}
        setMeetingModalIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, create_meeting_schedule: value })
        }
      />

      <NewEntityTemplateModal
        isOpen={isModalOpen.new_entity_template}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, new_entity_template: value })
        }
        clientId={selectedClient?._id}
        type={templateType}
        onCustomTemplateClick={() => {
          setIsModalOpen({
            ...isModalOpen,
            new_entity_template: false,
            custom_template: true,
          });
        }}
        openBuildInvoiceModal={() => {
          setIsModalOpen({
            ...isModalOpen,
            new_entity_template: false,
            new_template: true,
          });
        }}
      />

      <SelectTemplate
        isOpen={isModalOpen.custom_template}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, custom_template: value })
        }
        type={templateType}
        clientId={selectedClient?._id}
        openNewTemplateModal={() => {
          setIsModalOpen({
            ...isModalOpen,
            custom_template: false,
            new_template: true,
          });
        }}
      />

      <NewTemplateModal
        isOpen={isModalOpen.new_template}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, new_template: value })
        }
        type={templateType}
        clientId={selectedClient?._id}
        isCreateInvoice={templateType === "Invoice"}
      />

      <CreateClientModal
        isOpen={isModalOpen.add_client}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, add_client: value })
        }
      />

      <TrackingAddTimeEntryModal
        modalIsOpen={isModalOpen.start_time_tracker}
        setIsOpen={(value) =>
          setIsModalOpen({ ...isModalOpen, start_time_tracker: value })
        }
      />

      <CreateMemberModal
        isOpen={isModalOpen.invite_team_member}
        setModal={(value) =>
          setIsModalOpen({ ...isModalOpen, invite_team_member: value })
        }
      />
    </>
  );
};

export default NewActionMenuButton;
