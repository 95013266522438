import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import CloseBtn from "../../assets/Images/close-btn.svg";
import {
  CreateNoteAction,
  GetNotesActions,
} from "../../store/actions/notesAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const NoteModal = ({ setNotIsOpen, notIsOpen }) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();

  const note = useSelector((state) => state?.note?.note);

  const [value, setValue] = useState("");

  useEffect(() => {
    if (clientId !== undefined && clientId !== note?.client) {
      dispatch(GetNotesActions(clientId));
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (note?.data?.text) {
      setValue(note?.data?.text);
    } else {
      setValue("");
    }
  }, [note?.data]);

  const handleSubmit = () => {
    if (clientId && value !== note?.data?.text) {
      dispatch(CreateNoteAction({ text: value, client_id: clientId }));
      setNotIsOpen(false);
    }
  };

  return (
    <Modal
      isOpen={notIsOpen}
      onRequestClose={setNotIsOpen}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Example Modal"
    >
      <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[480px] sm:min-w-[416px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
        <div className="bg-white p-7 rounded-[8px]">
          <div className="relative">
            <textarea
              rows="24"
              cols="48"
              className="w-full h-full mb-16 text-sm font-medium caret-AccentRegular focus:outline-none placeholder:text-sm placeholder:text-Neutral500 placeholder:font-medium text-Neutral800"
              placeholder="Make a note..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <div className="relative flex items-center -mt-12 bg-white">
              <button
                onClick={handleSubmit}
                disabled={value === note?.data?.text || value === ""}
                className={`z-10 btn relative text-sm text-center block font-bold w-1/2 py-3 mr-[6px] rounded focus-visible:outline focus-visible:outline-8 focus-visible:outline-AccentLight shadow-none bg-AccentRegular disabled:cursor-not-allowed`}
              >
                <span className="relative z-10">Save</span>
              </button>
              <button
                onClick={() => {
                  setNotIsOpen(false);
                  setValue(note?.data?.text);
                }}
                className="z-10 text-AccentRegular bg-Bg-Regular hover:bg-Bg-MediumDark text-sm font-semibold rounded flex items-center justify-center py-3 ml-[6px] w-1/2 focus-visible:outline-none focus:outline focus:outline-8 focus:outline-AccentMediumLight transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={() => {
            setNotIsOpen(false);
            setValue(note?.data?.text);
          }}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
};

export default NoteModal;
