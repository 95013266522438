import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import AccountCencelled from "../../assets/Images/account-cencelled.png";
import CloseBtn from "../../assets/Images/close-btn.svg";
import PaymentDetail from "../../components/Accountplan/PaymentDetail";
import TopMenu from "../../components/ClientCollab/TopMenu";
import DiscountOfferModal from "../../components/Modals/30PercentDiscountOfferModal";
import CancelAccountModal from "../../components/Modals/CancelAccountModal";
import CreateMemberModal from "../../components/Modals/CreateMemberModal";
import RemoveMemberModal from "../../components/Modals/RemoveMemberModal";
import AdminNavbar from "../../components/Navbars/AdminNavbar";
import AutoFollowUpEmail from "../../components/Settings/AutoFollowUpEmail";
import Contacts from "../../components/Settings/Contacts";
import Currency from "../../components/Settings/Currency";
import DateFormat from "../../components/Settings/DateFormat";
import EmailNotification from "../../components/Settings/EmailNotification";
import Profile from "../../components/Settings/Profile";
import QuestionnaireText from "../../components/Settings/QuestionnaireText";
import Security from "../../components/Settings/Security";
import TeamMember from "../../components/Settings/TeamMember";
import ViewEditPermission from "../../components/Settings/ViewEditPermission";
import { GetUserPlanDetailAction } from "../../store/actions/authAction";
import { getMemberList } from "../../store/actions/memberAction";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const Settings = () => {
  const dispatch = useDispatch();

  const userAccountDetail = useSelector((state) => state.auth.accountDetail);
  const logedinUser = useSelector((state) => state.auth.user);
  const collabLogedinUser = useSelector((state) => state?.collab_auth?.user);
  const member = useSelector((state) => state.member.members);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalDiscount, setmodalDiscount] = useState(false);
  const [accountCecelled, setaccountCecelled] = useState(false);
  const [cancelPlan, setcancelPlan] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [removeMember, setRemoveMember] = useState(null);
  const [createMemberModal, setCreateMemberModal] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  const location = useLocation();
  const signInPath = location.pathname.startsWith("/client-collab");

  useEffect(() => {
    if (logedinUser && !logedinUser?.invited && !signInPath) {
      dispatch(GetUserPlanDetailAction());
    }
    if (!member?.data?.length && !signInPath) {
      dispatch(getMemberList());
    }
  }, [dispatch, logedinUser]);

  useEffect(() => {
    if (!openModal) {
      setRemoveMember(null);
    }
  }, [openModal]);

  const handleRemoveId = (member) => {
    setRemoveMember(member);
    setOpenModal(true);
  };

  return (
    <>
      {!signInPath ? (
        <div>
          <AdminNavbar title="Settings" subtitle="Manage your account here" />
          <div className="5xl:px-[96px] px-6 md:px-12 w-full pt-0">
            <div className={`pt-[60px] pb-10 flex flex-col md:flex-row`}>
              <div className="w-full 5xl:max-w-[500px] md:max-w-[408px] mr-[60px]">
                <Profile logedinUser={logedinUser} />
                <Security />

                {/* Update Questionnaire Preview text  */}
                <QuestionnaireText logedinUser={logedinUser} />
              </div>
              <div className="w-full 5xl:max-w-[500px] md:max-w-[408px] mb-10 md:mb-0">
                {logedinUser?.invited === false && (
                  <PaymentDetail
                    data={
                      userAccountDetail?.response &&
                      userAccountDetail?.response[0]
                        ? userAccountDetail?.response[0]
                        : null
                    }
                    planData={userAccountDetail?.planData}
                    isLoading={userAccountDetail?.isLoading}
                    setcancelPlan={setcancelPlan}
                    lemonsqueezy={userAccountDetail?.response}
                  />
                )}
                <div className={`pb-10 pt-10 flex`}>
                  {logedinUser?.invited === false && (
                    <EmailNotification logedinUser={logedinUser} />
                  )}
                </div>
                {logedinUser?.invited === false ||
                (logedinUser?.owner && logedinUser?.invited === true) ? (
                  <TeamMember
                    setCreateMemberModal={setCreateMemberModal}
                    userAccountDetail={userAccountDetail}
                    setIsOpen={setIsOpen}
                    logedinUser={logedinUser}
                    member={member}
                    handleRemoveId={handleRemoveId}
                  />
                ) : null}

                <div className="py-10">
                  <Currency logedinUser={logedinUser} />
                </div>

                <div className="pb-10">
                  <DateFormat logedinUser={logedinUser} />
                </div>

                <div className={`pb-10 flex flex-col md:flex-row`}>
                  <div className="w-full 5xl:max-w-[500px] md:max-w-[408px]">
                    <AutoFollowUpEmail />
                  </div>
                </div>
              </div>
            </div>

            {/* client portal */}
            <div className="pt-[40px]">
              <div>
                <h5 className="text-Neutral700 text-xs font-bold tracking-[0.1em] uppercase">
                  Client Portal
                </h5>
                <span className="text-xs font-medium text-Neutral700">
                  Manage your client portal settings here and in the dashboard.
                </span>
              </div>
              <div className={`pb-10 flex flex-col md:flex-row`}>
                <div className="w-full 5xl:max-w-[500px] md:max-w-[408px] mr-[60px]">
                  <Contacts />
                </div>
                <div className="w-full 5xl:max-w-[500px] md:max-w-[408px]">
                  <ViewEditPermission />
                </div>
              </div>
              {/* <div className="pb-10">
                <SubDomain />
              </div> */}
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            closeTimeoutMS={300}
            contentLabel="Archived client list Modal"
          >
            <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[455px] sm:min-w-[455px]  max-w-[455px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
              <div className="bg-white p-[24px_32px_30px_32px] rounded-[8px]">
                <h6 className="uppercase font-medium text-xs text-Neutral700 tracking-[0.04em] pb-2 border-b-[0.56px] border-Neutral300 w-full">
                  how it works
                </h6>
                <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
                  <div className="w-full">
                    <h3 className="text-xs font-bold uppercase text-Neutral900">
                      General
                    </h3>
                    <p className="text-Neutral600 font-medium text-[10px]">
                      Ideal for <span className="font-bold">employees</span>
                    </p>
                  </div>
                  <div className="md:min-w-[225px]">
                    <p className="text-xs font-normal text-right">
                      This is the most restricted role preventing members from
                      viewing sensitive client & project information.
                    </p>
                  </div>
                </div>
                <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
                  <div className="w-full">
                    <h3 className="text-xs font-bold uppercase text-Neutral900">
                      Project Manager
                    </h3>
                    <p className="text-Neutral600 font-medium text-[10px]">
                      Ideal for <span className="font-bold">managers</span>
                    </p>
                  </div>
                  <div className="md:min-w-[225px]">
                    <p className="text-xs font-normal text-right">
                      A supervisory role, a member assigned this can view and
                      access most of the app but does not have full access.
                    </p>
                  </div>
                </div>
                <div className="flex justify-between pt-6 pb-5 border-b-[0.5px] border-Neutral300 gap-3">
                  <div className="w-full">
                    <h3 className="text-xs font-bold uppercase text-Neutral900">
                      Admin
                    </h3>
                    <p className="text-Neutral600 font-medium text-[10px]">
                      Ideal for <span className="font-bold">owners</span>
                    </p>
                  </div>
                  <div className="md:min-w-[225px]">
                    <p className="text-xs font-normal text-right">
                      Has full access and can change permissions for other
                      users.
                    </p>
                  </div>
                </div>
                <h6 className="italic font-medium text-xs text-Neutral700 tracking-[0.04em] pt-6">
                  Only Admin + PM roles can view/edit contracts, invoices,
                  proposals, leads pipeline, and hourly rates.
                </h6>
              </div>
              <div
                className="absolute right-0 top-[-50px] z-30 cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                <img src={CloseBtn} alt="" />
              </div>
            </div>
          </Modal>
          <DiscountOfferModal
            modalDiscount={modalDiscount}
            setmodalDiscount={setmodalDiscount}
            cancle_url={userAccountDetail?.response?.[0]?.cancel_url}
            payment_gateway={userAccountDetail?.planData?.payment_gateway}
            logedinUser={logedinUser}
            isWarning={isWarning}
            setIsWarning={setIsWarning}
          />
          <Modal
            isOpen={accountCecelled}
            onRequestClose={() => setaccountCecelled(false)}
            style={customStyles}
            closeTimeoutMS={300}
            contentLabel="Archived client list Modal"
          >
            <div className="relative bg-white rounded-lg text-left transform transition-all sm:my-8 5xl:min-w-[384px] sm:min-w-[384px]  max-w-[384px] sm:w-full shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp">
              <div className="bg-white p-[24px_32px_32px_32px] rounded-[8px]">
                <div className="flex pb-2 border-b-[0.56px] border-Neutral200">
                  <h6 className="uppercase font-semibold text-xs text-Neutral700 tracking-[0.04em]  w-full">
                    ACCOUNT CANCELLED
                  </h6>
                </div>
                <div className="flex justify-between pt-[37px]">
                  <div className="">
                    <div className="pb-8 text-center">
                      <img
                        src={AccountCencelled}
                        alt=""
                        className="mx-auto pb-[18px]"
                      />
                      <p className="text-sm font-normal text-Neutral800">
                        Your account data will be archived for
                        <Link to="#" className="text-base font-bold">
                          {" "}
                          30 days{" "}
                        </Link>{" "}
                        in case you decide to come back.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <button className="w-full h-12 text-sm font-bold transition duration-300 ease-in-out rounded button-hover large-primary-btn text-Neutral000 bg-AccentRegular drop-shadow-Texts focus:outline focus:outline-4 focus:outline-AccentLight focus-visible:outline-0">
                    <span className="relative drop-shadow-Texts z-10 flex items-center justify-center font-[700]">
                      Reactivate account
                    </span>
                  </button>
                </div>
              </div>
              <div
                className="absolute right-0 top-[-50px] z-30 cursor-pointer"
                onClick={() => setaccountCecelled(false)}
              >
                <img src={CloseBtn} alt="" />
              </div>
            </div>
          </Modal>
          <CancelAccountModal
            setcancelPlan={setcancelPlan}
            setmodalDiscount={setmodalDiscount}
            cancelPlan={cancelPlan}
            planData={userAccountDetail?.planData}
            cancle_url={userAccountDetail?.response?.[0]?.cancel_url}
            setIsWarning={setIsWarning}
          />
          <RemoveMemberModal
            setModal={setOpenModal}
            isOpen={openModal}
            member={removeMember}
          />
          <CreateMemberModal
            isOpen={createMemberModal}
            setModal={setCreateMemberModal}
          />
        </div>
      ) : (
        <div className={`relative flex bg-gray-100`}>
          <TopMenu />
          <div className=" py-5 mt-16 md:mt-0 mx-auto w-full md:w-[800px] min-h-screen flex flex-col items-center md:items-start justify-center">
            <header className="flex items-center justify-center w-full pb-4 md:justify-start">
              <h1 className="text-2xl font-bold text-Neutral900">
                YOUR ACCOUNT
              </h1>
            </header>
            <div className="flex flex-col justify-center gap-5 md:flex-row">
              <div className="">
                {/* Profile section goes here */}
                <Profile logedinUser={collabLogedinUser} isCollab={true} />
              </div>
              <div className="">
                {/* Security section goes here */}
                <Security isCollab={true} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;
