import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { cn } from "../../../../utils";
import ShareIcon from "../../../Icons/ShareIcon";

function CardControls({
  projectId = null,
  className = "",
  clientId = null,
  project,
  setShareProjectModal,
}) {
  const clientDetail = useSelector((state) => state.client.clientDetail);

  return (
    <div className={cn("flex justify-stretch gap-x-2", className)}>
      <Link
        to={`/admin/clients/manage/${clientId}/project/${projectId}`}
        state={{ project, client: clientDetail?.data }}
        onClick={() => {}}
        className="flex justify-center items-center grow rounded bg-Neutral200 py-[16.5px] text-sm font-bold text-AccentRegular leading-[110%] hover:bg-Bg-MediumDark"
      >
        Manage
      </Link>
      <button
        onClick={() => {
          setShareProjectModal(true);
        }}
        aria-label="Share"
        className="flex rounded border-Neutral300 border-[0.5px] p-3"
      >
        <ShareIcon />
      </button>
    </div>
  );
}

export default CardControls;
