import { Tab } from "@headlessui/react";
import { useSelector } from "react-redux";

import AutoFollowUpEmail from "../Settings/AutoFollowUpEmail";
import Currency from "../Settings/Currency";
import DateFormat from "../Settings/DateFormat";
import QuestionnaireText from "../Settings/QuestionnaireText";

function GeneralTab() {
  const loggedInUser = useSelector((state) => state.auth.user);

  return (
    <Tab.Panel className="5xl:px-[96px] px-6 md:px-0 w-full pt-0">
      <div className="flex flex-col gap-10 md:gap-16 md:flex-row">
        <div className="flex flex-col gap-10">
          <Currency loggedInUser={loggedInUser} />
          <QuestionnaireText loggedInUser={loggedInUser} />
        </div>
        <div className="flex flex-col gap-10">
          <DateFormat loggedInUser={loggedInUser} />
          <AutoFollowUpEmail />
        </div>
      </div>
    </Tab.Panel>
  );
}

export default GeneralTab;
