import { debounce } from "lodash";
import { useRef } from "react";
import { useDispatch } from "react-redux";

import ImgIcon from "../../../assets/Images/img-icon.svg";
import CheckMarkIcon from "../../Icons/CheckMarkIcon";
import UploadQuestionnaireFiles from "../../Inputs/UploadQuestionnaireFiles";

const QuestionCard = ({
  item,
  index,
  colorBox,
  setAnswers,
  setIsRequiredList,
  isRequiredList,
}) => {
  const dispatch = useDispatch();

  const internalHandleSubmit = (ele) => {
    if (!ele?.answer && !ele?.selectedFiles?.length) {
      setAnswers((prev) => ({
        ...prev,
        [ele?._id]: {
          ...ele,
          isAnswered: false,
        },
      }));
      return;
    }
    if (ele?.type === "text" && ele?.answer?.length === 0) {
      setAnswers((prev) => ({
        ...prev,
        [ele?._id]: {
          ...ele,
          isAnswered: false,
        },
      }));
      return;
    }
    if (ele?.type === "file" && !ele?.selectedFiles?.length) {
      setAnswers((prev) => ({
        ...prev,
        [ele?._id]: {
          ...ele,
          isAnswered: false,
        },
      }));
      return;
    }
    if (ele?.type === "files" && !ele?.selectedFiles?.length) {
      setAnswers((prev) => ({
        ...prev,
        [ele?._id]: {
          ...ele,
          isAnswered: false,
        },
      }));
      return;
    }
    if (ele?.type === "image" && !ele?.selectedFiles?.length) {
      setAnswers((prev) => ({
        ...prev,
        [ele?._id]: {
          ...ele,
          isAnswered: false,
        },
      }));
      return;
    }
    if (ele?.type === "radio" || ele?.type === "checkbox") {
      if (!ele?.[`${ele?.type}_options`]?.find((eleee) => eleee?.is_selected)) {
        setAnswers((prev) => ({
          ...prev,
          [ele?._id]: {
            ...ele,
            isAnswered: false,
          },
        }));
        return;
      }
    }
    setAnswers((prev) => ({
      ...prev,
      [ele?._id]: {
        ...ele,
        isAnswered: true,
      },
    }));
  };

  const debouncedHandleTextChange = useRef(
    debounce((item, value) => {
      internalHandleSubmit({ ...item, answer: value });
    }, 300)
  ).current;

  const handleTextChange = (e, item) => {
    const newAnswer = e.target.value;

    dispatch({
      type: "SET_QUESTIONNAIRE_QUESTION_ANSWER",
      payload: {
        _id: item._id,
        answer: newAnswer,
      },
    });

    const removeItem = isRequiredList?.filter((ele) => ele?._id !== item?._id);
    setIsRequiredList(removeItem);

    debouncedHandleTextChange(item, newAnswer);
  };

  return (
    <div className="pt-[48px]" key={item?._id}>
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-base font-semibold text-black font-inter">
          {item?.title}
        </h3>
        <div className="flex flex-row space-x-1">
          <div
            className={`flex items-center justify-center w-[28px] font-medium h-[28px] border border-Neutral300 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] transition duration-300 ${
              colorBox.text
            } ${item?.answer ? "bg-Neutral000" : "bg-Neutral300"}`}
          >
            {index + 1}
          </div>
          {item?.answer && (
            <div
              className={`flex items-center justify-center w-[28px] font-medium h-[28px] border border-green-100 rounded-full font-feature-settings text-[18px] 5xl:text-[22px] transition duration-300 bg-green-100`}
            >
              <CheckMarkIcon color="#34b288" />
            </div>
          )}
        </div>
      </div>
      <p className="mb-4 text-xs font-normal text-Neutral800">
        {item?.question}{" "}
        {item?.is_required && (
          <strong
            className={`text-AccentRegular transition duration-300 ${colorBox.text}`}
          >
            *
          </strong>
        )}
      </p>

      {(item?.type === "files" || item?.type === "file") && (
        <div className="flex justify-between">
          <div className="w-full max-w-[50%] pr-2">
            <UploadQuestionnaireFiles
              type={item?.type}
              files={item?.files}
              files_path={item?.files_path}
              label={
                (item?.type === "files" && "Upload Files") ||
                (item?.type === "file" && "Upload File")
              }
              id={`file-${index}`}
              name={`file-${index}`}
              handleChange={(files) => {
                dispatch({
                  type: "SET_QUESTIONNAIRE_QUESTION_ANSWER",
                  payload: {
                    _id: item._id,
                    answer: files?.length ? "_" : null,
                    file: files,
                  },
                });
                const removeItem = isRequiredList?.filter(
                  (ele) => ele?._id !== item?._id
                );
                setIsRequiredList(removeItem);

                internalHandleSubmit({
                  ...item,
                  answer: files?.length ? "_" : null,
                  selectedFiles: files?.length ? files : [],
                });
              }}
              value={item?.selectedFiles}
              accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf"
              multiple={
                (item?.type === "files" && true) ||
                (item?.type === "file" && false)
              }
              icon={ImgIcon}
              handleSubmit={debounce(async (files) => {
                internalHandleSubmit({
                  ...item,
                  selectedFiles: files,
                });
              }, 400)}
            />
          </div>
        </div>
      )}

      {(item?.type === "profile" || item?.type === "image") && (
        <div className="flex justify-between">
          <div className="w-full max-w-[50%] pr-2">
            <UploadQuestionnaireFiles
              type={item?.type}
              label={
                (item?.type === "profile" && "Upload Profile") ||
                (item?.type === "image" && "Upload Image")
              }
              files={item?.files}
              files_path={item?.files_path}
              id={`${item?.type}-${index}`}
              name={item?.type}
              handleChange={(files) => {
                dispatch({
                  type: "SET_QUESTIONNAIRE_QUESTION_ANSWER",
                  payload: {
                    _id: item._id,
                    answer: files?.length ? "_" : null,
                    file: files,
                  },
                });
                const removeItem = isRequiredList?.filter(
                  (ele) => ele?._id !== item?._id
                );
                setIsRequiredList(removeItem);
                internalHandleSubmit({
                  ...item,
                  answer: files?.length ? "_" : null,
                  selectedFiles: files?.length ? files : [],
                });
              }}
              value={item?.selectedFiles}
              accept="image/x-png,image/gif,image/jpeg"
              multiple={false}
              icon={ImgIcon}
              handleSubmit={debounce(async (files) => {
                internalHandleSubmit({
                  ...item,
                  selectedFiles: files,
                });
              }, 1000)}
            />
          </div>
          <div className="w-full max-w-[50%] pr-2"></div>
        </div>
      )}

      {item?.type === "text" && (
        <textarea
          id={`answers[${index}].answer`}
          name={`answers[${index}].answer`}
          className={`mt-4 border border-Neutral400 bg-Neutral000 w-full resize-none p-6 h-[168px] placeholder:text-sm 5xl:placeholder:text-lg placeholder:font-medium font-medium focus:outline-none rounded hover:border-Neutral800 focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow duration-300 transition placeholder:text-Neutral500`}
          placeholder={item?.placeholder || "Type your answer here..."}
          value={item?.answer ? item?.answer : ""}
          onChange={(e) => handleTextChange(e, item)}
        />
      )}

      {(item?.type === "radio" || item?.type === "checkbox") && (
        <div className="flex flex-wrap mt-4">
          {item?.[`${item?.type}_options`]?.map((ele, index) => (
            <div
              className={`flex items-center mr-4 mb-4 ${
                item?.type === "radio" ? "w-1/2" : "w-1/3"
              }`}
              key={index}
            >
              <input
                type={item?.type}
                id={`[${item._id}][${item?.type}_options][${index}].answer`}
                name={`[${item._id}][${item?.type}_options][${index}].answer`}
                className="mr-2"
                value={ele?.value}
                checked={ele?.is_selected}
                onChange={async (e) => {
                  const payload = {
                    ...item,
                    answer: "_",
                    _id: item._id,
                    [`${item?.type}_options`]: item?.[
                      `${item?.type}_options`
                    ]?.map((ele) => {
                      return {
                        ...ele,
                        is_selected:
                          item?.type === "radio"
                            ? ele?.value === e.target.value
                              ? true
                              : false
                            : ele?.value === e.target.value
                            ? !ele?.is_selected
                            : ele?.is_selected,
                      };
                    }),
                  };
                  await dispatch({
                    type: "SET_QUESTIONNAIRE_QUESTION_ANSWER",
                    payload: {
                      ...payload,
                      answer: payload[`${item?.type}_options`]?.find(
                        (res) => res.is_selected
                      )
                        ? "_"
                        : null,
                    },
                  });

                  const removeItem = isRequiredList?.filter(
                    (ele) => ele?._id !== item?._id
                  );

                  setIsRequiredList(removeItem);
                  internalHandleSubmit(payload);
                }}
              />
              <label
                htmlFor={`[${item._id}][${item?.type}_options][${index}].answer`}
                className="text-xs font-medium text-Neutral800"
              >
                {ele?.name}
              </label>
            </div>
          ))}
        </div>
      )}

      {isRequiredList?.find((ele) => ele?._id === item?._id) && (
        <p className="mt-1 text-xs text-red-500">This question is required</p>
      )}
    </div>
  );
};

export default QuestionCard;
