import { useEffect, useState } from "react";

import SelectDownArrow from "../../Icons/SelectDownArrow";

const ClientSelect = ({
  onChange = () => {},
  onBlur = () => {},
  isDisabled = false,
  value = "",
  allClients = {},
}) => {
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (!value) return;
    const client = allClients?.data?.find((item) => item?._id === value);
    setSelectedClient(client);
  }, [allClients, value]);

  return (
    <div className="relative text-AccentRegular">
      <span className="absolute pointer-events-none z-[999] top-[50%] translate-y-[-50%] right-[16px]">
        <SelectDownArrow />
      </span>
      <select
        id="countries"
        className="relative text-AccentRegular font-medium placeholder:text-Neutral500 px-4 placeholder:text-sm 5xl:placeholder:text-lg text-sm leading-5 w-full h-[48px] focus:outline-none border-[1.5px] rounded border-AccentRegular focus:border-AccentRegular focus:drop-shadow-Purpleboxshadow cursor-pointer appearance-none duration-300 transition"
        onChange={(e) => {
          onChange(JSON.parse(e.target.value));
        }}
        onBlur={onBlur}
        disabled={isDisabled}
        name="client"
        value={selectedClient ? JSON.stringify(selectedClient) : null}
        // It is necessary to keep null in value, otherwise the client will not be selected in the proposal,
        // previously here was {selectedClient ? JSON.stringify(selectedClient) : " "}
      >
        <option
          className="block py-2 text-sm uppercase text-Neutral800"
          selected
          disabled
        >
          Select Client
        </option>
        {allClients?.data?.map((item, i) => {
          return (
            <option
              key={`1${i}`}
              value={JSON.stringify(item)}
              className="hover:bg-Neutral200"
            >
              {item?.business_name ? item?.business_name : item?.contact_name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ClientSelect;
