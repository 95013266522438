import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import CloseBtn from "../../../assets/Images/close-btn.svg";
import { getMyTasksByPriorityAction } from "../../../store/actions/dashboard-v2";
import YourTaskRow from "../YourTasksRow";
import ChevronDown from "../icons/ChevronDown";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(41, 41, 51, 0.7)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    padding: "47px",
    background: "none",
    borderRadius: "10px",
  },
};

const filterOptionsMap = {
  all: "All",
  week: "Weekly",
  month: "Monthly",
};

function UpcomingTasksOverviewModal({
  isOpen,
  setOpen,
  priority = "all",
  title,
}) {
  const dispatch = useDispatch();
  const myTasksByPriority = useSelector(
    (state) => state.dashboard.myTasksByPriority
  );
  const [upcomingTaskMonthFilter, setUpcomingTaskMonthFilter] =
    useState("month");

  const handleClose = () => {
    setOpen(false);
  };

  const hasNonZeroValues = (item) => {
    return (
      item.incompleteTasks !== 0 ||
      item.completedTasks !== 0 ||
      item.inProgressTasks !== 0 ||
      item.projects !== 0
    );
  };

  useEffect(() => {
    dispatch(getMyTasksByPriorityAction(priority, upcomingTaskMonthFilter));
  }, [dispatch, priority, upcomingTaskMonthFilter]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      closeTimeoutMS={300}
      contentLabel="Tasks Overview Modal"
    >
      <div className="relative transition-all sm:w-full my-4 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] animate__fadeInUp md:w-[65dvw]">
        <div className="w-full space-y-5">
          <div className="flex items-center justify-between px-4 py-2 rounded-md bg-Neutral000">
            <h4 className="text-base text-Neutral800">
              {title || "Upcoming Tasks"}
            </h4>
            <Menu as="div" className="relative">
              <Menu.Button className="inline-flex items-center gap-1 py-1.5 pl-3 pr-2 text-xs font-medium rounded-[30px] text-Neutral000 bg-Neutral900">
                {filterOptionsMap[upcomingTaskMonthFilter]}
                <ChevronDown className="text-Neutral000 size-4" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  as="div"
                  className="absolute right-0 z-20 w-full p-1 mt-1 border divide-y rounded-md shadow-xl bg-Neutral000 border-Neutral300 divide-Blue100 min-w-[100px]"
                >
                  <Menu.Item
                    as="button"
                    type="button"
                    className="w-full p-1 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                    onClick={() => setUpcomingTaskMonthFilter("all")}
                  >
                    All
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    type="button"
                    className="w-full p-1 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                    onClick={() => setUpcomingTaskMonthFilter("week")}
                  >
                    Weekly
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    type="button"
                    className="w-full p-1 text-sm text-left rounded text-Neutral900 hover:bg-Blue100"
                    onClick={() => setUpcomingTaskMonthFilter("month")}
                  >
                    Monthly
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <div className="px-5 py-6 overflow-y-auto rounded-md bg-Neutral000">
            {/* Table Header */}
            <div className="flex px-6 text-[10px] uppercase text-Neutral600">
              <span className="w-[40%]">Name</span>
              <div className="w-[60%] flex items-center gap-3 justify-evenly">
                <span>Incomplete</span>
                <span>In Progress</span>
                <span>Completed</span>
                <span>Projects</span>
              </div>
            </div>

            {/* Table Rows */}
            <div className="flex flex-col gap-4 mt-5 overflow-y-auto md:max-h-[345px] pr-2">
              {!!myTasksByPriority.data?.length &&
                myTasksByPriority.data
                  ?.filter(hasNonZeroValues)
                  .map((task) => (
                    <YourTaskRow
                      key={task.clientId}
                      userName={
                        task?.client?.business_name ||
                        task?.client?.contact_name ||
                        task?.client?.client_name
                      }
                      userImage={task?.client?.profile_path}
                      incomplete={task?.incompleteTasks}
                      inProgress={task?.inProgressTasks}
                      completed={task?.completedTasks}
                      clients={task?.clients}
                      projects={task?.projects}
                      tasks={task?.tasks}
                    />
                  ))}
            </div>
          </div>
        </div>
        <div
          className="absolute right-0 top-[-50px] z-30 cursor-pointer"
          onClick={handleClose}
        >
          <img src={CloseBtn} alt="" />
        </div>
      </div>
    </Modal>
  );
}

export default UpcomingTasksOverviewModal;
