import clx from "classnames";

import prepareTasksAndTimeCounter from "../../../../utils/prepareTasksAndTimeCounter";
import LightningIcon from "../../../Icons/LightningIcon";

const colors = ["#6D6DF2", "#E56780", "#757580", "#73A2FF", "#42D6A6"];

function TasksFilter({
  tasks = [],
  className = "",
  setCheckedTask,
  checkedTask,
}) {
  return (
    <ul className={clx("flex mr-8 mb-1 gap-x-2", className)}>
      {Object.entries({
        total: tasks?.total_tasks,
        urgent: tasks?.total_tasks_by_priority?.urgent,
        completed: tasks?.total_tasks_by_tag?.completed,
        in_progress: tasks?.total_tasks_by_tag?.in_progress,
        not_started: tasks?.total_tasks_by_tag?.not_started,
      }).map(([key, value], index) => {
        return (
          <li
            className={`min-w-[96px] max-w-[96px] rounded border-[0.5px] border-${
              checkedTask === index ? "AccentRegular" : "Neutral400"
            }`}
            key={index}
          >
            <label className="w-full h-full flex flex-col items-center justify-between pb-2 px-[14px] pt-[18px] cursor-pointer">
              <input
                type="radio"
                name="task"
                id={key}
                className="mr-4 visually-hidden"
                value={prepareTasksAndTimeCounter(value)}
                checked={checkedTask === index}
                onChange={() => setCheckedTask(index)}
              />
              <LightningIcon
                width={24}
                height={24}
                color={checkedTask === index ? colors[0] : colors[index]}
                filled={checkedTask === index}
                className="mb-[6px]"
              />
              <span
                className={`leading-[100%] tracking-[-0.04em] text-${
                  checkedTask === index ? "AccentRegular" : "Neutral600"
                } text-5xl font-normal text-center mb-[20px] cursor-pointer`}
              >
                {prepareTasksAndTimeCounter(value)}
              </span>
              <span
                className={`leading-[14px] tracking-[0.02em] text-[10px] font-semibold uppercase text-${
                  checkedTask === index ? "AccentRegular" : "Neutral600"
                } cursor-pointer whitespace-nowrap`}
              >
                {key?.replace("_", " ")}
                {key === "total" ? " tasks" : ""}
              </span>
            </label>
          </li>
        );
      })}
    </ul>
  );
}

export default TasksFilter;
