function LinkIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1818 8.36377H16.3636C16.8412 8.36377 17.314 8.45783 17.7552 8.64057C18.1964 8.82332 18.5973 9.09117 18.9349 9.42884C19.2726 9.7665 19.5405 10.1674 19.7232 10.6086C19.9059 11.0497 20 11.5226 20 12.0001C20 12.4777 19.9059 12.9505 19.7232 13.3917C19.5405 13.8329 19.2726 14.2338 18.9349 14.5714C18.5973 14.9091 18.1964 15.177 17.7552 15.3597C17.314 15.5424 16.8412 15.6365 16.3636 15.6365H14.1818M9.81818 15.6365H7.63636C7.15883 15.6365 6.68597 15.5424 6.24479 15.3597C5.8036 15.177 5.40273 14.9091 5.06507 14.5714C4.38312 13.8895 4 12.9646 4 12.0001C4 11.0357 4.38312 10.1108 5.06507 9.42884C5.74702 8.74689 6.67194 8.36377 7.63636 8.36377H9.81818"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09082 12H14.909"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LinkIcon;
