import CMLogo from "../Icons/CMLogo";

const PageLoader = () => {
  return (
    <div className="grid h-screen place-items-center">
      <div className="text-AccentRegular">
        <div className="stage">
          <div className="dot-falling"></div>
        </div>
        <CMLogo />
      </div>
    </div>
  );
};
export default PageLoader;
