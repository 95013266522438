import { cn } from "../../utils";
import UpgradeBadgeIcon from "../Icons/UpgradeBadgeIcon";

function WhiteLabelButton({
  title = "",
  className = "",
  iconSize = 16,
  onClick = () => {},
}) {
  return (
    <button
      id="white-label-btn"
      className={cn(
        "px-3 pr-4 py-2 bg-AccentRegular text-Neutral000 font-semibold hover:bg-AccentMediumDark rounded-3xl inline-flex items-center gap-1 ring-4 ring-AccentLight",
        className
      )}
      onClick={onClick}
    >
      <UpgradeBadgeIcon style={{ width: iconSize, height: iconSize }} />
      <span>{title}</span>
    </button>
  );
}

export default WhiteLabelButton;
