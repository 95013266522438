import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paperclip from "./assets/Paperclip.svg";
// import MenuIcon from '../MenuIcon';
import Overlay from "./assets/OVERLAY.png";
import VisibleIcon from "./assets/Visible.svg";
import VideoIcon from "./assets/Video.svg";
import RedirectIcon from "./assets/Redirect.svg";
import PlayIcon from "./assets/play.svg";
import ImageIcon from "./assets/Image.svg";
import WatchMediaModal from "../WatchMediaModal";
import InvoiceIcon from "../../../../../assets/Images/Invoice-white.svg";
import LinkIcon from "../../../../../assets/Images/Link.svg";
import { Menu, Transition } from "@headlessui/react";
import AddIcon from "../Icons/AddIcon";
import ExternalLinkIcon from "../Icons/ExternalLinkIcon";
import MenuIcon from "../Icons/MenuIcon";
import moment from "moment";
import {
  AddMediaToTaskAction,
  DeleteMediaFromTaskAction,
} from "../../../../../store/actions/tasksAction";
import { toast } from "react-toastify";
import AddLinkModal from "../Comment/commponent/AddLinkModal";
import {
  AddMediaToServiceAction,
  DeleteMediaFromServiceAction,
} from "../../../../../store/actions/serviceAction";
import PaperclipIcon from "../../../../Icons/PaperclipIcon";

const formatBytes = (bytes) => {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes < 1024 * 1024 * 1024) {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
};

const Media = ({
  isNew,
  item,
  user,
  types,
  clientDetail,
  user_type,
  access_level,
}) => {
  const dispatch = useDispatch();
  // TODO:save and load media
  const [media, setMedia] = useState(item?.media);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [drag, setDrag] = useState(false);
  const [isExternalLinkModalOpen, setIsExternalLinkModalOpen] = useState(false);
  const authSelector = useSelector((state) => state.auth);
  const authCollabSelector = useSelector((state) => state?.collab_auth);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setMedia(item?.media);
  }, [item]);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (e) => {
    setIsLoading(true);
    const selectedFile = e.target.files[0];
    // max size 20mb
    if (selectedFile.size > 20 * 1024 * 1024) {
      toast.error("File size should be less than 20mb");
      e.target.value = null;
      setIsLoading(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("id", item?._id);
    if (types === "task") {
      if (user_type) {
        dispatch(AddMediaToTaskAction(formData, setIsLoading, null, user_type));
      } else {
        dispatch(AddMediaToTaskAction(formData, setIsLoading));
      }
    }
    if (types === "service") {
      dispatch(AddMediaToServiceAction(formData, setIsLoading));
    }
    e.target.value = null;
  };

  const addExternalLink = () => {
    setIsExternalLinkModalOpen(true);
  };

  const dragStartHandler = (e) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDropHandler = (e) => {
    e.preventDefault();
    let files = [...e.dataTransfer.files];

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("file", file);
      formData.append("some other data", "some other data");
    });

    // setMedia()

    setDrag(false);
  };
  const getWatchingIcon = (type) => {
    switch (type) {
      case "image":
        return VisibleIcon;
      case "video":
        return PlayIcon;
      default:
        return RedirectIcon;
    }
  };
  const clickOnMediaHandler = (item) => {
    if (item?.is_external) {
      window.open(item?.url, "_blank");
    } else {
      if (
        item?.file?.mimetype.includes("video") ||
        item?.file?.mimetype.includes("image")
      ) {
        setIsModalOpen(true);
        setCurrentFile(item);
      } else {
        window.open(item?.url, "_blank");
      }
    }
  };

  const deleteFile = (file) => {
    if (types === "task") {
      if (user_type) {
        dispatch(
          DeleteMediaFromTaskAction(
            {
              id: item?._id,
              media_id: file?._id,
              user_type: user_type,
            },
            setIsModalOpen
          )
        );
      } else {
        dispatch(
          DeleteMediaFromTaskAction(
            {
              id: item?._id,
              media_id: file?._id,
            },
            setIsModalOpen
          )
        );
      }
    }
    if (types === "service") {
      dispatch(
        DeleteMediaFromServiceAction(
          {
            id: item?._id,
            media_id: file?._id,
          },
          setIsModalOpen
        )
      );
    }
  };

  return (
    <div
      className={`h-[48px] relative w-full bg-Neutral100 rounded-lg pl-5 py-3 pr-2 flex flex-col gap-4
      ${drag ? " outline-2 outline-Neutral400 outline-dashed" : ""}`}
      onDragStart={dragStartHandler}
      onDragLeave={dragLeaveHandler}
      onDragOver={dragStartHandler}
      onDrop={onDropHandler}
    >
      <Menu as="div" className="flex items-center justify-between ">
        <div className="flex items-center">
          <Menu.Button
            className="inline-flex items-center w-full gap-2 text-AccentRegular"
            disabled={
              isNew
                ? isNew
                : (!user_type &&
                    (isLoading ||
                    clientDetail?.is_admin ||
                    clientDetail?.is_manager ||
                    user?._id === item?.created_by?._id
                      ? false
                      : true)) ||
                  (user_type && access_level !== "full_access")
            }
          >
            {/* <img src={Paperclip} className="mr-[12px]" alt="" /> */}
            <PaperclipIcon width={22} />
            <p className="text-AccentRegular text-[10px] font-semibold">
              MEDIA
            </p>
          </Menu.Button>
        </div>
        <div className="flex items-center">
          <Menu.Button
            className="inline-flex items-center w-full"
            disabled={
              isNew
                ? isNew
                : (!user_type &&
                    (isLoading ||
                    clientDetail?.is_admin ||
                    clientDetail?.is_manager ||
                    user?._id === item?.created_by?._id
                      ? false
                      : true)) ||
                  (user_type && access_level !== "full_access")
            }
          >
            {isLoading ? (
              <div className="w-3 h-3 border-b-2 rounded-full animate-spin border-Neutral900"></div>
            ) : (
              <MenuIcon />
            )}
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="edit-menu-servies edit-delete-menu absolute z-[999] top-0 right-0 rounded-lg bg-Neutral000 border-[0.5px] border-Neutral200 divide-y divide-Neutral300 focus:outline-none z-90 overflow-hidden">
            <form method="POST" action="#">
              <Menu.Item>
                {({ active }) => (
                  <div>
                    <div
                      className="flex items-center justify-start gap-2 px-4 py-2 text-xs font-semibold text-left transition duration-300 cursor-pointer text-Neutral800 whitespace-nowrap hover:bg-Neutral200 "
                      onClick={() => {
                        handleUploadClick();
                      }}
                    >
                      <AddIcon />
                      <span>Upload File</span>
                    </div>
                    <div
                      className="flex gap-2 text-Neutral800 text-left px-4 py-2 text-xs whitespace-nowrap flex justify-start items-center border-t-[0.5px] border-Neutral200 cursor-pointer font-semibold hover:bg-Neutral200  transition duration-300"
                      onClick={() => {
                        addExternalLink();
                      }}
                    >
                      <ExternalLinkIcon />
                      <span>Add External Link</span>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </form>
          </Menu.Items>
        </Transition>
      </Menu>

      <input
        type="file"
        ref={fileInputRef}
        className="hidden "
        onChange={handleFileChange}
      />

      <ul className="flex flex-col gap-3">
        {media?.map((item) => (
          <li key={item?._id} className="flex gap-3">
            <div
              className="relative cursor-pointer w-[148px] h-[105px]"
              onClick={() => clickOnMediaHandler(item)}
            >
              {item?.file?.mimetype.includes("video") ? (
                <video
                  src={item?.url}
                  className="w-full h-full object-cover rounded-lg blur-[1px]"
                  loop
                  muted
                />
              ) : item?.file?.mimetype.includes("image") ? (
                <img
                  src={item?.url}
                  alt={item?.name}
                  className="w-full h-full object-cover rounded-lg blur-[1px]"
                />
              ) : item?.file?.mimetype.includes("pdf") ? (
                <img
                  src={Overlay}
                  alt={item?.name}
                  className="w-full h-full rounded-lg blur-[1px]"
                />
              ) : (
                <img
                  src={Overlay}
                  alt={item?.name}
                  className="w-full h-full object-cover rounded-lg blur-[1px]"
                />
              )}

              <img
                src={
                  item?.file?.mimetype.includes("video")
                    ? VideoIcon
                    : item?.file?.mimetype.includes("image")
                    ? ImageIcon
                    : item?.file?.mimetype.includes("pdf")
                    ? InvoiceIcon
                    : LinkIcon
                }
                alt=""
                className="w-6 h-6 absolute top-[8px] left-[10px]"
              />
              <img
                src={getWatchingIcon(item?.file?.mimetype.split("/")[0])}
                alt=""
                className="w-6 h-6 absolute top-[41px] left-[62px]"
              />
              {((authSelector?.user?._id &&
                authSelector?.user?._id === item?.created_by?._id) ||
                (authCollabSelector?.user?._id ===
                  item?.created_collab_user_by?._id &&
                  access_level === "full_access")) && (
                <div
                  className="w-6 h-6 absolute top-[8px] left-[118px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteFile(item);
                  }}
                >
                  <svg
                    width="1.5rem"
                    height="1.5rem"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.16663 4.62195H2.35922H11.9"
                      stroke="#E56780"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.14729 4.62252V3.45426C4.14729 3.14442 4.27294 2.84727 4.49659 2.62818C4.72024 2.40909 5.02359 2.28601 5.33988 2.28601H7.72507C8.04136 2.28601 8.3447 2.40909 8.56836 2.62818C8.79201 2.84727 8.91766 3.14442 8.91766 3.45426V4.62252M10.7065 4.62252V12.8003C10.7065 13.1101 10.5809 13.4073 10.3572 13.6264C10.1336 13.8455 9.83025 13.9686 9.51396 13.9686H3.55099C3.2347 13.9686 2.93136 13.8455 2.7077 13.6264C2.48405 13.4073 2.3584 13.1101 2.3584 12.8003V4.62252H10.7065Z"
                      stroke="#E56780"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>

            <div className="flex flex-col justify-between pt-2 pb-3">
              <div className="flex flex-col gap-2">
                <p className="font-semibold	text-sm	text-Neutral800 truncate max-w-[150px] w-full">
                  {item?.name}
                </p>
                <div className="flex gap-1 ">
                  {item?.file?.mimetype.split("/")[1] && (
                    <span className="flex items-center justify-center min-w-[37px] text-[8px] leading-[7px] uppercase  py-1.5 px-2.5 bg-Neutral300 rounded-[20px]">
                      {item?.file?.mimetype.split("/")[1]}
                    </span>
                  )}
                  <span
                    className={`flex items-center justify-center min-w-[37px] text-[8px] leading-[7px] py-1.5 px-2.5 bg-Neutral300 rounded-[20px]
                 ${item.is_external ? "" : "uppercase"}`}
                  >
                    {item?.is_external
                      ? "External Link"
                      : formatBytes(item?.file?.size)}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-1.5">
                <div className="flex items-center justify-center w-6 h-6 overflow-hidden rounded-full">
                  {item?.created_by?.profile ||
                  item?.created_collab_user_by?.profile ? (
                    <img
                      src={
                        item?.created_by?.profile_path ||
                        item?.created_collab_user_by?.profile_path
                      }
                      alt="avatar"
                      className="object-cover w-6 h-6"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-6 h-6 font-semibold rounded-full bg-Neutral300 text-Neutral700">
                      {item?.created_by?.user_name[0] ||
                        item?.created_collab_user_by?.user_name[0]}
                    </div>
                  )}
                </div>
                <div className="flex flex-col justify-center  gap-1.5">
                  <p className="text-Neutral800 font-normal	text-[10px] leading-[8px]">
                    {item?.created_by?.user_name ||
                      item?.created_collab_user_by?.user_name}
                  </p>
                  <p className="text-Neutral600 font-normal	text-[10px] leading-[8px] italic">
                    {/* 2 min ago using moment */}
                    {moment(item?.created_at).fromNow()}
                  </p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>

      {isModalOpen && (
        <WatchMediaModal
          file={currentFile}
          onClose={() => setIsModalOpen(false)}
          onDelete={deleteFile}
          user_type={user_type}
          access_level={access_level}
        />
      )}
      {isExternalLinkModalOpen && (
        <AddLinkModal
          isModalOpen={isExternalLinkModalOpen}
          setIsModalOpen={setIsExternalLinkModalOpen}
          taskId={item?._id}
          types={types}
          user_type={user_type}
          access_level={access_level}
        />
      )}
    </div>
  );
};

export default Media;
